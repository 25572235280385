import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, Grid, Box, IconButton, fade, Typography, ButtonBase, useTheme, Button } from '@material-ui/core';
import ShieldAccountOutline from 'mdi-material-ui/ShieldAccountOutline';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiCancel } from '@mdi/js';
import { useStateWithRef } from '../../../../../hooks/statewithref.hook';
import { CheckControl } from '../../../../generic';
import { ITransferTreeItem, IItemProp } from '../../../../transfertree/transfertree.types';
import {
    PermissionTreeActions,
    PermissionTreeProvider,
    PermissionTreeState,
    usePermissionTreeDispatch,
    usePermissionTreeState
} from './permissiontree.context';
import { IIdx } from '../../../../../types/general.type';
import { Models } from 'shipmenttrackers-domain/dist';
import { useStyles } from './common';

interface IPermissionTree {
    items: ITransferTreeItem[];
    onStateChange?: (state: PermissionTreeState) => void;
    sectionColor?: string;
    sectionColorAlt?: string;
    allowed?: Models.UserEntities;
    disallowed?: { [index: string]: boolean };
}

const Component: React.FC<IPermissionTree> = ({ items, sectionColor, sectionColorAlt, allowed, disallowed }) => {
    const classes = useStyles();
    const dispatch = usePermissionTreeDispatch();

    useEffect(() => {
        dispatch({ type: PermissionTreeActions.setAllowed, payload: allowed });
        dispatch({ type: PermissionTreeActions.setDisallowed, payload: disallowed });
    }, [allowed, disallowed, dispatch]);

    return (
        <Box bgcolor={sectionColor} paddingLeft={1}>
            {items.map((item, i) => {
                return <PermissionItemComponent key={item.uid} item={item} sectionColor={sectionColor} sectionColorAlt={sectionColorAlt} classes={classes} />;
            })}
        </Box>
    );
};

export const PermissionTree: React.FC<IPermissionTree> = ({ onStateChange, ...props }) => {
    return (
        <PermissionTreeProvider>
            <Component {...props} />
            <Watcher onStateChange={onStateChange} />
        </PermissionTreeProvider>
    );
};

const Watcher: React.FC<{ onStateChange?: (state: PermissionTreeState) => void }> = ({ onStateChange }) => {
    const state = usePermissionTreeState();
    useEffect(() => {
        onStateChange?.(state);
    }, [onStateChange, state]);
    return null;
};

interface IPermissionItemComponent {
    item: ITransferTreeItem;
    // onSelectionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    classes: { [index: string]: string };
    sectionColor?: string;
    sectionColorAlt?: string;
    canDisallow?: boolean;
}

const PermissionItemComponent: React.FC<IPermissionItemComponent> = ({ item, classes, sectionColor, sectionColorAlt, canDisallow }) => {
    // const transferTreeState = useTransferTreeState();
    // const item = transferTreeState.itemsByUid[uid];
    // const hideProps = transferTreeState[side].hideProps;
    const permissionTreeState = usePermissionTreeState();
    const [isOpen, setIsOpen, isOpenRef] = useStateWithRef<boolean>(item.startOpen ?? false);
    useEffect(() => {
        setIsOpen(!!item.startOpen);
    }, [item.startOpen, setIsOpen]);
    const theme = useTheme();

    const isAllowed = item.uid === `${permissionTreeState.allowed?.entityLevel} ${permissionTreeState.allowed?.entityID}`;
    const level = item.uid.split(' ')[0];
    const isAllowedChild = item.uid === `${level} ${permissionTreeState.hierarchy[level]}`;
    const isDisallowed = permissionTreeState.disallowed?.[item.uid];

    const toggle = () => {
        setIsOpen(!isOpenRef.current);
        isOpenRef.current = !isOpenRef.current;
    };

    const hasGrandChildren = useMemo(() => !!item.children?.find((c) => c.children?.length), [item]);

    // return useMemo(() => {
    // const hasGrandChildren = !!item.children?.find((c) => transferTreeState.itemsByUid[c].children?.length);
    return (
        <Grid container>
            <div className={classes.labelContainer}>
                {item.children?.length ? (
                    item.toggleIcon ? (
                        <div onClick={toggle}>{item.toggleIcon}</div>
                    ) : (
                        <Box width={theme.spacing(3)} height={theme.spacing(3)}>
                            <ButtonBase component="div" className={classes.toggleButton} onClick={toggle} color={theme.palette.secondary.main}>
                                <Icon
                                    size={1}
                                    rotate={isOpen ? 90 : 0}
                                    path={mdiChevronRight}
                                    className={classes.fullArrow}
                                    color={theme.palette.secondary.main}
                                />
                            </ButtonBase>
                        </Box>
                    )
                ) : (
                    <Box width="24px" height="24px" />
                )}
                <TreeLabel
                    item={item}
                    // onSelectionClick={onSelectionClick}
                    classes={classes}
                    // side={side}
                    // hideProps={hideProps}
                    isAllowed={isAllowed}
                    isAllowedChild={isAllowedChild}
                    isDisallowed={isDisallowed}
                    canDisallow={canDisallow}
                />
            </div>
            {isOpen && item.children?.length && (
                <Box
                    paddingLeft={1}
                    marginRight={1}
                    marginBottom={1}
                    width="100%"
                    position="relative"
                    borderRadius={12}
                    bgcolor={hasGrandChildren ? sectionColorAlt : undefined}
                >
                    {item.children.map((nextItem) => (
                        <PermissionItemComponent
                            sectionColor={sectionColorAlt}
                            sectionColorAlt={sectionColor}
                            key={nextItem.uid}
                            item={nextItem}
                            // onSelectionClick={onSelectionClick}
                            classes={classes}
                            // side={side}
                            canDisallow={isAllowed || canDisallow}
                        />
                    ))}
                </Box>
            )}
        </Grid>
    );
    // }, [item, toggle, theme, classes, isOpen, onSelectionClick, side, hideProps, sectionColorAlt, transferTreeState.itemsByUid, sectionColor, isAllowed, isDisallowed]);
};

interface ITreeLabel {
    item: ITransferTreeItem;
    // onSelectionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    classes: { [index: string]: string };
    // side: TransferSide;
    // hideProps?: boolean;
    isAllowed?: boolean;
    isAllowedChild?: boolean;
    isDisallowed?: boolean;
    canDisallow?: boolean;
}

const TreeLabel: React.FC<ITreeLabel> = ({ item, classes, isAllowed, isAllowedChild, canDisallow, isDisallowed }) => {
    const dispatch = usePermissionTreeDispatch();

    const onDisallow: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        dispatch({ type: PermissionTreeActions.disallowed, payload: { [item.uid]: !isDisallowed } });
    };

    return (
        <>
            <Grid item container>
                <Box flexGrow={1} display="flex">
                    <div
                        className={`${classes.itemButton} ${item.classes?.label || ''}`}
                        // onClick={onSelectionClick}
                        // name={item!.uid}
                    >
                        {canDisallow && (
                            <IconButton
                                size="small"
                                onClick={onDisallow}
                                // color={isDisallowed ? 'error' : 'inherit'}
                            >
                                <Icon className={isDisallowed ? classes.error : classes.fade} path={mdiCancel} size={1} />
                            </IconButton>
                        )}{' '}
                        {item!.text}
                    </div>
                </Box>

                {item!.properties && (
                    <Box flexGrow={0} display="flex" alignItems="center">
                        <IconButton
                            size="small"
                            onClick={() => dispatch({ type: PermissionTreeActions.allowed, payload: isAllowed ? undefined : item.uid })}
                            color={isAllowed ? 'secondary' : isAllowedChild ? 'primary' : 'inherit'}
                            className={!isAllowed && !isAllowedChild ? classes.fade : ''}
                        >
                            <ShieldAccountOutline />
                        </IconButton>
                    </Box>
                )}
            </Grid>
            {item!.properties && isAllowed && (
                <>
                    <div></div>
                    <Grid container item>
                        <Box margin={1}>
                            <ItemProps item={item} />
                        </Box>
                    </Grid>
                </>
            )}
        </>
    );
};

const ItemProps: React.FC<{ item: ITransferTreeItem }> = ({ item }) => {
    const theme = useTheme();
    const permissionState = usePermissionTreeState();

    const permissionTreeDispatch = usePermissionTreeDispatch();

    const propChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            permissionTreeDispatch({ type: PermissionTreeActions.setPermission, payload: { [event.target.name]: checked } });
        },
        [permissionTreeDispatch]
    );

    const [master, setMaster] = useState<IItemProp | undefined>();

    const propGroups = useMemo(() => {
        if (!item.properties) return [];
        const groups: { [index: string]: { title: string; props: IItemProp[] } } = {};

        Object.values(item.properties).forEach((p) => {
            if (p.isMaster) {
                setMaster(p);
                return;
            }
            const group = p.propGroup || '';
            if (!groups[group]) {
                groups[group] = { title: group, props: [] };
            }
            groups[group].props.push(p);
        });
        return Object.values(groups);
    }, [item.properties]);

    const setAll = (val: boolean) => () => {
        permissionTreeDispatch({ type: PermissionTreeActions.setAllPermissions, payload: val });
    };

    return (
        <Box>
            {/* {master && ( */}
            <Box width="100%" borderBottom={`1px solid ${fade(theme.palette.common.black, 0.25)}`} padding={1}>
                <Grid container wrap="nowrap" spacing={1}>
                    <Grid item>
                        <Button variant="outlined" onClick={setAll(true)}>
                            Select All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={setAll(false)}>
                            Clear
                        </Button>
                    </Grid>
                </Grid>

                {/* <CheckControl checked={master.checked || false} onChange={propChange} name={master.uid} key={master.uid}>
                        {master.text}
                    </CheckControl> */}
            </Box>
            {/* )} */}
            {
                // ((master && !master.checked) || !master) &&
                propGroups.map((g, i) => (
                    <Box key={`${g.title}${i}`}>
                        {g.title && <Typography>{`${g.title}: `}</Typography>}
                        {g.props?.length &&
                            g.props.map((p) => (
                                <CheckControl checked={!!(permissionState.allowed as IIdx)?.[p.uid]} onChange={propChange} name={p.uid} key={p.uid}>
                                    {p.text}
                                </CheckControl>
                            ))}
                    </Box>
                ))
            }
        </Box>
    );
};
