import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useAppState } from '../../../contexts/app.context';
import { useCreateActions, useGetClient, useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { GridMin } from '../../generic';
import { FakeInput } from '../../generic/containers/fakeinput.component';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { AuditActions, useAuditDispatch } from '../audit/audit.context';
import { BodyContainer, Header, ModalContainer } from './common.component';

const billingLevel: { [index: string]: number } = {
    Client: 5,
    Company: 4,
    Division: 3,
    Location: 2
    // Account: 1
};

const useStyles = makeStyles((theme) => ({
    selectContainer: {
        display: 'flex',
        '& > *': {
            minWidth: theme.spacing(20),
            '&:not(:first-child)': {
                marginLeft: theme.spacing(2)
            }
        },
        paddingBottom: theme.spacing(2)
    }
}));

interface EntityState {
    Client?: Models.Client;
    Company?: Models.Company;
    Division?: Models.Division;
    Location?: Models.Location;
    // Account?: Models.CarrierAccount;
}

const reducer = (state: EntityState, update: EntityState) => ({ ...state, ...update });

export const BillingEntityModal: React.FC<{ invoice: Models.CarrierInvoices; close: () => void }> = ({ invoice, close }) => {
    const appState = useAppState();
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const classes = useStyles();
    const [state, update] = useReducer(reducer, {});
    const auditDispatch = useAuditDispatch();
    const { updateInvoices, loading } = useUpdateInvoices();
    const { createAction, loading: actionloading } = useCreateActions();
    const { getClient, loading: clientLoading } = useGetClient();

    useEffect(() => {
        (async () => {
            let c: Models.Client | undefined = undefined;
            try {
                const res = await getClient(invoice.clientID);

                if (res.status === 200) {
                    c = res.data[0];
                }
            } catch (e) {}
            const newState: EntityState = {};
            newState.Client = c ?? appState.data.entitiesById.clients[invoice.clientID];
            newState.Company = newState.Client?.companies.find((co) => co.companyID === invoice.companyID);
            newState.Division = newState.Company?.divisions.find((d) => d.divisionID === invoice.divisionID);
            newState.Location = newState.Division?.locations.find((l) => l.locationID === invoice.locationID);

            update(newState);
        })();

        // newState.Account = newState.Location?.accounts.find((a) => a.carrierAccountID.toString() === invoice.carrierAccountID);
    }, [invoice, update, appState.data, getClient]);

    const onChange = (level: string) => (evt: any, value: any) => {
        const l = billingLevel[level];
        const newState: EntityState = { [level]: value };
        if (l > 4) {
            newState.Company = newState.Client?.companies.length === 1 ? newState.Client?.companies[0] : undefined;
            newState.Division = undefined;
            newState.Location = undefined;
            // newState.Account = undefined;
        }
        if (l > 3) {
            newState.Division = newState.Company?.divisions.length === 1 ? newState.Company?.divisions[0] : undefined;
            newState.Location = undefined;
            // newState.Account = undefined;
        }
        if (l > 2) {
            newState.Location = newState.Division?.locations.length === 1 ? newState.Division?.locations[0] : undefined;
            // newState.Account = undefined;
        }
        // if (l > 1) {
        //     newState.Account = newState.Location?.accounts.length === 1 ? newState.Location?.accounts[0] : undefined;
        // }
        update(newState);
    };

    const onSave = async () => {
        if (!invoice) return;
        let billingEntityUpdate = '';
        const entityUpdate: string[] = [];
        const updates: Partial<Models.CarrierInvoices> = {};

        const entities = appState.data.entitiesById;

        updates.companyID = state.Company?.companyID;
        if (state.Company?.companyID && state.Company?.companyID !== invoice.companyID) {
            const ch = `from ${entities.companies[invoice.companyID].companyName} to ${state.Company.companyName}`;
            entityUpdate.push(`Chaged Company ${ch}`);
            if (invoice.clientBillingLevel === 'Company') {
                billingEntityUpdate = `Changed billing entity (Company) ${ch}`;
            }
        }

        updates.divisionID = state.Division?.divisionID;
        if (state.Division?.divisionID && state.Division?.divisionID !== invoice.divisionID) {
            const ch = `from ${entities.divisions[invoice.divisionID].divisionName} to ${state.Division?.divisionName}`;
            entityUpdate.push(`Chaged Division ${ch}`);
            if (invoice.clientBillingLevel === 'Division') {
                billingEntityUpdate = `Changed billing entity (Division) ${ch}`;
            }
        }
        updates.locationID = state.Location?.locationID;
        if (state.Location?.locationID && state.Location?.locationID !== invoice.locationID) {
            const ch = `from ${entities.locations[invoice.locationID].locationName} to ${state.Location?.locationName}`;
            entityUpdate.push(`Changed Location ${ch}`);
            if (invoice.clientBillingLevel === 'Location') {
                billingEntityUpdate = `Changed billing entity (Location) ${ch}`;
            }
        }

        if (billingEntityUpdate) {
            entityUpdate.unshift(billingEntityUpdate);
        }

        const res = await updateInvoices([{ carrierInvoiceID: invoice.carrierInvoiceID, ...updates }]);
        if (res.status === 200) {
            auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
            const actionRes = await createAction({ carrierInvoiceID: invoice.carrierInvoiceID, action: `Changed Entities: \n ${entityUpdate.join('\n')}` });
            if (actionRes.status === 201) {
                auditDispatch({ type: AuditActions.addAction, payload: actionRes.data });
                close();
            }
        }
    };

    return (
        <ModalContainer>
            <GridMin>
                <Header variant="subtitle1">{`Update Billing Entity: ${invoice.clientBillingLevel}`}</Header>
            </GridMin>
            <BodyContainer item container direction="column">
                <div className={classes.selectContainer}>
                    {/* {billingLevel[invoice.clientBillingLevel] > 4 && ( */}
                    <FakeInput label="Client">{state.Client?.clientName}</FakeInput>
                    {/* <SingleSelectAuto<Models.Client>
                            className={inputClasses.input}
                            menuClasses={menuClasses}
                            disableNull={false}
                            value={state.Client}
                            onValChange={onChange('Client')}
                            options={appState.data.clients}
                            getText={(c) => c.clientName || ''}
                            getValue={(c) => c.clientID}
                            label="Client"
                            variant="filled"
                        /> */}
                    {/* )} */}
                    {/* {billingLevel[invoice.clientBillingLevel] > 3 && ( */}
                    <SingleSelectAuto<Models.Company>
                        className={inputClasses.input}
                        menuClasses={menuClasses}
                        disableNull={false}
                        value={state.Company}
                        onValChange={onChange('Company')}
                        options={state.Client?.companies}
                        getText={(c) => c.companyName || ''}
                        getValue={(c) => c.companyID}
                        label="Company"
                        variant="filled"
                    />
                    {/* )} */}
                    {/* {billingLevel[invoice.clientBillingLevel] > 2 && ( */}
                    <SingleSelectAuto<Models.Division>
                        className={inputClasses.input}
                        menuClasses={menuClasses}
                        disableNull={false}
                        value={state.Division}
                        onValChange={onChange('Division')}
                        options={state.Company?.divisions}
                        getText={(d) => d.divisionName || ''}
                        getValue={(d) => d.divisionID}
                        label="Division"
                        variant="filled"
                    />
                    {/* )} */}
                    {/* {billingLevel[invoice.clientBillingLevel] > 1 && ( */}
                    <SingleSelectAuto<Models.Location>
                        className={inputClasses.input}
                        menuClasses={menuClasses}
                        disableNull={false}
                        value={state.Location}
                        onValChange={onChange('Location')}
                        options={state.Division?.locations}
                        getText={(l) => l.locationName || ''}
                        getValue={(l) => l.locationID}
                        label="Location"
                        variant="filled"
                    />
                    {/* )} */}
                    {/* {billingLevel[invoice.clientBillingLevel] > 0 && (
                        <SingleSelectAuto<Models.CarrierAccount>
                            className={inputClasses.input}
                            menuClasses={menuClasses}
                            disableNull={false}
                            value={state.Account}
                            onValChange={onChange('account')}
                            options={state.Location?.accounts}
                            getText={(a) => a.carrierAccount || ''}
                            getValue={(a) => a.carrierAccountID}
                        />
                    )} */}
                </div>
                <Grid item container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item>
                        <ExtendedButton color="secondary" variant="contained" type="submit" loading={!!loading || !!actionloading} onClick={onSave}>
                            Save
                        </ExtendedButton>
                    </Grid>
                    <Grid item>
                        <ExtendedButton variant="contained" onClick={close} color="error">
                            Cancel
                        </ExtendedButton>
                    </Grid>
                </Grid>
            </BodyContainer>
        </ModalContainer>
    );
};
