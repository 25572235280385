import React, { useCallback, memo, useEffect } from 'react';
import { useAppState, useAppDispatch } from '../contexts/app.context';
import { Header } from '../components/layout/header/header.component';
import { GridMin, PageContainer, GridGrow, STITooltip } from '../components/generic';
import { useTheme, Box } from '@material-ui/core';
import { DivisionSearch } from '../components/layout/search/division.component';
import { SummaryPanel } from '../components/layout/summary/summary.component';
import { HomeTable } from '../components/layout/tables/hometable.component';
import { STIModal } from '../components/generic/containers/stimodal.component';
import { AppActions, SearchType, AppFilterActions } from '../types/contexts/app-context.type';
// import { Cart } from '../components/layout/modals/cart.component';
import { InvoiceConfirmation } from '../components/layout/modals/invoiceconfirmation.component';
import { InvoiceNumberSearch } from '../components/layout/search/invoice.component';
import { DateAmountSearch } from '../components/layout/search/date.component';
import { Footer } from '../components/layout/footer/footer.component';
import { PaySummaryPanel } from '../components/layout/summary/paysummary.component';
// import { useGetPermissionsNew } from '../hooks/stiapi.hook';
import { ExtendedIconButton } from '../components/generic/controls/extendediconbutton.component';
import { LoadingSplash } from '../components/layout/home/loadingsplash';
import { useGetLatestRemittance } from '../hooks/stiapi.hook';

const Component: React.FC = () => {
    const theme = useTheme();
    const appState = useAppState();
    // const { getPermissions } = useGetPermissionsNew();

    useEffect(() => {
        document.title = 'STI : Tracking Outside the Box';
    }, []);

    return (
        <PageContainer>
            <LoadingSplash />
            <ModalHandler />
            <GridMin>
                <Header />
            </GridMin>

            <GridGrow container direction="column">
                <Box bgcolor={theme.palette.primary.dark}>
                    <GridMin container wrap="nowrap" justify="space-between" alignItems="center">
                        <Box padding={2} flexBasis="auto" flexGrow={1} flexShrink={1}>
                            {appState.searchType === SearchType.division ? (
                                <DivisionSearch />
                            ) : appState.searchType === SearchType.invoice ? (
                                <InvoiceNumberSearch
                                    action={AppFilterActions.invoiceIDS}
                                    label="Invoice Numbers"
                                    placeholder="Invoice Numbers (space or comma deliminated)"
                                    summary
                                />
                            ) : appState.searchType === SearchType.dateAmt ? (
                                <DateAmountSearch />
                            ) : appState.searchType === SearchType.remittance ? (
                                <InvoiceNumberSearch
                                    action={AppFilterActions.remittanceNumbers}
                                    label="Remittance Numbers"
                                    placeholder="Remittance Numbers (space or comma deliminated)"
                                />
                            ) : appState.searchType === SearchType.disbursement ? (
                                <InvoiceNumberSearch
                                    action={AppFilterActions.disbursementInvoiceIDS}
                                    label="Disbursement Numbers"
                                    placeholder="Disbursement Numbers (space or comma deliminated)"
                                />
                            ) : appState.searchType === SearchType.paymentConfirmation ? (
                                <InvoiceNumberSearch
                                    action={AppFilterActions.paymentConfirmationIDS}
                                    label="Payment Confirmation Numbers"
                                    placeholder="Payment Confirmation Numbers (space or comma deliminated)"
                                />
                            ) : (
                                <div></div>
                            )}
                        </Box>
                        <Box flexBasis="auto" flexGrow={0} flexShrink={9999999}>
                            {appState.homeTableMode === 'pay' ? <PaySummaryPanel /> : <SummaryPanel />}
                        </Box>

                        {appState.homeTableMode === 'pay' ? <PayButton /> : null}
                    </GridMin>
                </Box>
                <GridGrow>
                    <HomeTable />
                </GridGrow>
            </GridGrow>
            <GridMin>
                <Footer />
            </GridMin>
        </PageContainer>
    );
};

// const usePayStyles = makeStyles((theme) => ({
//     root: {
//         color: theme.palette.common.white
//     },
//     button: {
//         height: '100%',
//         borderRadius: 999,
//         padding: theme.spacing(1),
//         backgroundColor: theme.palette.common.white
//     },
//     container: {
//         borderRadius: 999,
//         padding: theme.spacing(1),
//         backgroundColor: theme.palette.secondary.main,
//         color: theme.palette.common.white
//     }
// }));

const PayButton = () => {
    const appDispatch = useAppDispatch();
    const openModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        appDispatch({ type: AppActions.showInvConf, payload: true });
    };
    return (
        <ExtendedIconButton onClick={openModal} color="white">
            <STITooltip title="Confirm Payments">
                <Box display="flex" width="64px" height="64px" justifyContent="center">
                    <img src={`${process.env.PUBLIC_URL}/img/btn_dollar@2x.png`} height="64px" alt="confirm" />
                </Box>
            </STITooltip>
        </ExtendedIconButton>
    );
};

export const HomePage = memo(Component);

const ModalHandler = () => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();

    const closeModal = useCallback(
        (type: string) => (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
            appDispatch({ type, payload: false });
        },
        [appDispatch]
    );

    return (
        <>
            {/* <STIModal open={appState.showCart} onClose={closeModal(AppActions.showCart)}>
                <Cart />
            </STIModal> */}
            <STIModal open={appState.showInvConf} onClose={closeModal(AppActions.showInvConf)}>
                <InvoiceConfirmation />
            </STIModal>
        </>
    );
};
