import React from 'react';
import { styled, PopperProps, Grow, Popper, ClickAwayListener } from '@material-ui/core';

interface IPopper {
    transformOrigin?: string;
    close: () => void;
}

export const STIPopper: React.FC<IPopper & PopperProps> = ({ children, transformOrigin = 'center 0 0', close, ...props }) => {
    return (
        <ClickAwayListener onClickAway={close}>
            <Popper
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window',
                    },
                    offset: {
                        // offset: `0, 16`,
                        // enabled: true,
                    },
                    zIndex: 100,
                }}
                transition
                style={{ zIndex: 1250 }}
                {...props}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} timeout={350} style={{ transformOrigin }}>
                        <PopperWrapper>{children}</PopperWrapper>
                    </Grow>
                )}
            </Popper>
        </ClickAwayListener>
    );
};

const PopperWrapper = styled('div')({
    '& > *': {
        margin: '0px',
    },
});
