import React, { useState, useEffect, FormEvent, useMemo, useRef } from 'react';

import { TextField, makeStyles, Popper, fade, ListItemText, CircularProgress, useTheme } from '@material-ui/core';
import { Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails, AutocompleteProps } from '@material-ui/lab';
import { useFonts } from '../../../styles/font.style';
import { useStateWithRef } from '../../../hooks/statewithref.hook';

export interface ISingleSelectAuto<T> extends Partial<Omit<AutocompleteProps<T, false, boolean | undefined, boolean | undefined>, 'getOptionLabel'>> {
    label?: string;
    onValChange?: {
        (event: React.ChangeEvent<{}> | FormEvent<HTMLDivElement>, value?: T, reason?: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T>): void;
    };
    options?: T[];
    menuClasses?: any;
    initialVal?: T | undefined;
    variant?: 'filled' | 'standard' | 'outlined' | undefined;
    nullString?: string;
    disableNull?: boolean;
    // createNullValue?: boolean;
    // emptyVal?: T;
    value?: T;
    getText?: (opt: T) => string;
    getSubtext?: (opt: T) => string;
    getValue?: (opt: T) => any;
    createValue?: (s: string) => T;
    addFree?: boolean;
    name?: string;
    required?: boolean;
}

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        '& fieldset': {
            display: 'none'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(12px, 10px) scale(0.75)'
        },
        '& .MuiInputBase-root': {
            paddingTop: '27px',
            paddingBottom: '10px',
            paddingRight: '9px !important'
        },
        '& .MuiAutocomplete-input': {
            paddingBottom: '0px !important',
            paddingTop: '0px !important'
        }
    },
    list: {
        '& .MuiAutocomplete-option[data-focus="true"]': {
            backgroundColor: fade(theme.palette.primary.main, 0.33)
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: fade(theme.palette.primary.main, 0.15)
        },
        '& .MuiAutocomplete-groupLabel': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    }
}));

export function SingleSelectAuto<T>({
    label,
    onValChange,
    options = [],
    menuClasses,
    initialVal,
    className,
    variant,
    renderInput,
    freeSolo,
    nullString = '',
    disableNull = true,
    loading,
    getText,
    getSubtext,
    getValue,
    createValue,
    addFree = true,
    required = false,
    name,
    fullWidth,
    ...props
}: ISingleSelectAuto<T>) {
    const theme = useTheme();

    const [val, setVal] = useStateWithRef<T | undefined>(props.value);
    const classes = useStyles();
    const fontClasses = useFonts();
    const [free, setFree] = useState<T | undefined>();

    useEffect(() => {
        setVal(props.value);
    }, [props.value, setVal]);

    const allOptions: T[] = useMemo(() => {
        const o = [];
        if (free) o.push(free);
        return o.concat(options);
    }, [free, options]);

    const handleChange = (
        evt: React.ChangeEvent<{}> | FormEvent<HTMLDivElement>,
        value: T,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<T>
    ) => {
        if (value === undefined) {
            onValChange && value && onValChange(evt, value);
        } else {
            const v = allOptions.find((o) => (getValue ? getValue(o) === getValue(value) : o === value));
            if (v) {
                setVal(v);
                onValChange && value && onValChange(evt, v);
            } else {
                if (typeof value === 'string') {
                    const freeVal = createValue && value ? createValue(value) : value;
                    if (addFree) {
                        setFree(freeVal);
                        setVal(freeVal);
                    }

                    onValChange && value && onValChange(evt, freeVal);
                }
            }
        }
    };

    const changeRef = useRef(handleChange);
    changeRef.current = handleChange;

    useEffect(() => {
        if (initialVal !== undefined && allOptions) {
            if (freeSolo) {
                const foundVal = allOptions.find((o) => (getValue ? getValue(o) === getValue(initialVal) : o === initialVal));
                if (!foundVal && initialVal !== undefined) {
                    const freeVal = initialVal;
                    setFree(freeVal);
                    setVal(freeVal);
                } else {
                    setVal(foundVal);
                }
            } else {
                setVal(allOptions.find((o) => (getValue ? getValue(o) === getValue(initialVal) : o === initialVal)));
            }
        }
    }, [setVal, initialVal, allOptions, freeSolo, setFree, getValue, createValue]);

    return (
        <Autocomplete
            placeholder="No Selection"
            {...props}
            defaultValue={undefined}
            freeSolo={freeSolo}
            className={`${className} ${classes.autocomplete}`}
            disableClearable
            openOnFocus
            includeInputInList
            onChange={handleChange as any}
            value={((props.value || val) as NonNullable<T>) || ''}
            options={allOptions}
            getOptionSelected={
                props.getOptionSelected
                    ? props.getOptionSelected
                    : (o) => {
                          const v = props.value || val;
                          if (getValue && v !== undefined) {
                              return getValue(o) === getValue(v);
                          } else {
                              return o === v;
                          }
                      }
            }
            getOptionLabel={(option: any) => {
                return getText ? getText(option) : option;
            }}
            getOptionDisabled={(option) => disableNull && (getValue ? getValue(option) : option) === null}
            popupIcon={null}
            PopperComponent={({ style, className, ...props }) => (
                <Popper placement="bottom-start" {...props} style={{ ...style, width: 'auto', maxWidth: '360px' }} className={`${className} ${classes.list}`} />
            )}
            renderOption={(option, i) => <ListItemText primary={getText ? getText(option) : option} secondary={getSubtext ? getSubtext(option) : ''} />}
            renderInput={
                renderInput
                    ? renderInput
                    : ({ InputProps, inputProps, ...params }) => (
                          <TextField
                              data-lpignore="true"
                              className={`${fontClasses.openSans}`}
                              InputProps={{ ...InputProps, endAdornment: loading && <CircularProgress size={theme.spacing(2.25)} /> }}
                              {...params}
                              inputProps={{ ...inputProps, name, 'data-lpignore': 'true' }}
                              label={label}
                              variant={variant}
                              required={required}
                              fullWidth={fullWidth ?? true}
                          />
                      )
            }
        />
    );
}
