import React, { useMemo, useRef } from 'react';
import { useDataTableState } from '../datatable.context';
import { IRow, IColumn } from '../datatable.types';

export type RowDataHook = (index: number) => IRow;

interface IBodyRow {
    // columnsData: IColumn[];
    index: number;
    onRowClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, rowIndex: IRow) => void;
    useGetRowData: RowDataHook;
}

interface IRowComponent {
    rowData: IRow;
    columnsData: IColumn[];
    index: number;
    onRowClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, rowIndex: IRow) => void;
}

export const BodyRow: React.FC<React.HTMLAttributes<HTMLDivElement> & IBodyRow> = ({ useGetRowData, ...props }) => {
    const rowData = useGetRowData(props.index);
    const propRef = useRef(props);
    propRef.current = props;
    const { columns, colWidths } = useDataTableState();

    return useMemo(() => <RowComponent rowData={rowData} columnsData={columns} {...propRef.current} />, [propRef, rowData, columns]);
};

const RowComponent: React.FC<React.HTMLAttributes<HTMLDivElement> & IRowComponent> = ({ rowData, columnsData, onRowClick, index, ...props }) => {
    const onClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onRowClick && onRowClick(evt, rowData);
    };

    return (
        <div onClick={onClick} {...props}>
            {rowData &&
                columnsData
                    .filter((c) => c.active)
                    .map((c, i) => {
                        const value = c.value ? c.value!(rowData) : rowData[c.key];
                        let display = value;
                        if (c.Component) {
                            display = <c.Component value={value} row={rowData} index={index} column={c} className={'inner'} />;
                        } else if (c.pipe) {
                            display = c.pipe!(value, rowData);
                        }
                        if (!c.Component) {
                            display = <div className="inner">{display}</div>;
                        }
                        return (
                            <div key={c.key} className={`control_body_width_${c.key} ${c.className ?? ''}`}>
                                {display}
                            </div>
                        );
                    })}
        </div>
    );
};
