export const killEvt = (event: React.MouseEvent<any> | React.KeyboardEvent<any> | React.UIEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
};

export function iterMap<K = any, V = any, R = any>(
    map: Map<K, V> | undefined,
    callback: (value: V, key: K, map: Map<K, V>) => R,
    filterFunc?: (value: V, key: K, map: Map<K, V>) => boolean
): R[] {
    if (!map) return [];
    const r: R[] = [];
    map.forEach((value, key, map) => {
        if (filterFunc && !filterFunc(value, key, map)) return;
        r.push(callback(value, key, map));
    });
    return r;
}

export const dollarRegex = /^[\$€]?[-]?(([0-9]{1}[0-9]{0,2}(?:[0-9]*(?:[.][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?))|(?:0[.][0-9]{2}))(\s*)(CAD)?$/;

export const toCurrencyDecimal = (n: number) => parseFloat((Math.round(n * 100) / 100).toFixed(2));

export const toQueryParams = (query: string) => {
    const trim = query.replace(/^\?/, '');
    const arr = trim.split('&');
    const params: Record<string, string> = {};

    return arr.reduce((acc: Record<string, string>, p) => {
        const entry: string[] = p.split('=');
        acc[entry[0]] = entry[1];
        return acc;
    }, {});
};
