import React, { ChangeEvent, useState, useCallback, useEffect, memo } from 'react';
import { FormControl, InputLabel, Select, Input, Chip, MenuItem, FormControlProps, CircularProgress, styled } from '@material-ui/core';
import { IMultiSelectOption } from './controls.types';

export type IMultiSelectClasses = Partial<{
    label: string;
    select: string;
    input: string;
    chip: string;
}>;

interface IMultiSelect {
    label: string;
    onChange: { (event: ChangeEvent<{ name?: string; value: unknown }>, values: unknown[], name: string | undefined): void };
    options: IMultiSelectOption[];
    loading: boolean;
    name: string;
    initialValues: unknown[];
    emptyOption: string;
    maxChips: number;
    classes: IMultiSelectClasses;
}

// const useSelectMenuStyles = makeStyles((theme) => ({
//     paper: {
//         border: `1px solid rgba(255,255,255,.25)`,
//     },
// }));

export const Component: React.FC<Partial<IMultiSelect> & React.HTMLAttributes<Element> & FormControlProps> = ({
    label,
    options = [],
    onChange,
    loading,
    name,
    initialValues,
    emptyOption = 'No Options Available',
    maxChips = -1,
    classes,
    ...props
}) => {
    // const selectMenuClasses = useSelectMenuStyles();

    const [initials] = useState<unknown[]>(initialValues || []);

    const [selectedIndices, setSelectedIndices] = useState<number[]>([0]);

    useEffect(() => {
        setSelectedIndices(
            initials?.reduce((acc: number[], v) => {
                const idx = options.findIndex((o) => o.value === v);
                if (idx > -1) {
                    acc.push(idx);
                }
                return acc;
            }, []) || []
        );
    }, [initials, options, setSelectedIndices]);

    const updateValues = useCallback(
        (evt, child) => {
            const newVals = evt.target.value;
            setSelectedIndices(newVals);
            const valueArray = newVals.map((idx: number) => options[idx].value);
            onChange && onChange(evt, valueArray, name);
        },
        [setSelectedIndices, onChange, options, name]
    );

    const deselect = useCallback(
        (idx: number) => (evt: any) => {
            const filtered = selectedIndices.filter((sI) => idx !== sI);
            setSelectedIndices(filtered);
            const valueArray = filtered.map((idx: number) => options[idx].value);
            onChange && onChange(evt, valueArray, name);
        },
        [selectedIndices, setSelectedIndices, name, onChange, options]
    );

    const killEvt = useCallback((evt) => evt.stopPropagation(), []);

    return (
        <MultiSelectControl {...props}>
            <InputLabel color='primary' id='demo-mutiple-name-label'>
                {label}
            </InputLabel>
            <Select
                // MenuProps={{ classes: selectMenuClasses }}
                labelId='demo-mutiple-name-label'
                id='demo-mutiple-name'
                multiple
                value={selectedIndices}
                onChange={updateValues}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                input={
                    <Input
                        endAdornment={
                            loading ? (
                                <LoadWrapper>
                                    <CircularProgress color='inherit' size={20} />
                                </LoadWrapper>
                            ) : null
                        }
                        className={classes?.input}
                    />
                }
                renderValue={(selected) => (
                    <ChipsContainer>
                        {(selected as number[]).slice(0, maxChips === -1 ? (selected as number[]).length : maxChips).map((index) => (
                            <StyledChip key={index} label={options[index]?.text} onDelete={deselect(index)} onMouseDown={killEvt} />
                        ))}
                        {maxChips !== -1 && (selected as number[]).length > maxChips && <StyledChip key='remainderChips' label={`+ ${(selected as number[]).length - maxChips}`} />}
                    </ChipsContainer>
                )}
            >
                {options?.length ? (
                    options.map((o, i) => (
                        <MenuItem key={o.text} value={i}>
                            {o.text}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value={undefined} disabled>
                        {emptyOption}
                    </MenuItem>
                )}
            </Select>
        </MultiSelectControl>
    );
};

export const MultiSelect = memo(Component);

const MultiSelectControl = styled(FormControl)(({ theme }) => ({
    background: theme.palette.common.white,
    borderTopRightRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
}));

const LoadWrapper = styled('div')({
    marginRight: '20px',
});

const ChipsContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const StyledChip = styled(Chip)({
    margin: 2,
    maxWidth: '100%',
});
