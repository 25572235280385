import React, { useState, useRef, useEffect, useCallback } from 'react';

export function useStateWithRef<T = any>(initial: T | (() => T)): [T, (newVal: T) => void, React.MutableRefObject<T>] {
    const [val, setVal] = useState<T>(initial);
    const valRef = useRef<T>(val);
    valRef.current = val;

    const set = useCallback((v) => {
        // console.log('set', v);
        setVal(v);
        valRef.current = v;
    }, []);

    return [val, set, valRef];
}
