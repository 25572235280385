import React, { memo, useEffect, useCallback, useRef } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { Box, TextField, useTheme, TextFieldProps, Typography } from '@material-ui/core';
import { CheckControl, Scroller } from '../../../generic';
import { useReducerWithRef } from '../../../../hooks/reducerwithref.hook';
import { IIdx } from '../../../../types/general.type';
import { IAction } from '../../../../types/contexts/context.type';
import { useInputStyle } from '../../../../styles/stiinput.style';
import { UserSelect } from '../filter/common';
import { AuditAmountInput } from '../../audit/amountinput.component';
import { useMenuStyles } from '../../../../styles/stimenu.style';
import { currency } from '../../../../helpers/pipes';
import { useManageStateRef } from '../manage.context';
import { usePermission } from '../../../../hooks/permission/permissions.hook';
import { ExtendedButton } from '../../../generic/controls/extendedbutton.component';
import { useResendWelcomeEmail } from '../../../../hooks/stiapi.hook';

export interface IUserDetails extends Partial<Models.User> {
    confirmEmail?: string;
    confirmPassword?: string;
    manager?: Models.User;
    approvalLimitString?: string;
    [index: string]: any;
}
interface IUserInfo {
    user?: IUserDetails;
    submitRef?: React.RefObject<HTMLButtonElement>;
    onSomethingChanged?: (details: IUserDetails) => void;
    requirePassword?: boolean;
    requireEmail?: boolean;
    error?: string;
    requireUser?: boolean;
    mode: 'create' | 'edit';
}

enum DetailsActions {
    reset = 'reset',
    values = 'values'
}

const detailsReducer = (state: IUserDetails, action: IAction): IUserDetails => {
    switch (action.type) {
        case DetailsActions.reset:
            return {};
        case DetailsActions.values:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const UserDetails: React.FC<IUserInfo> = ({
    user,
    mode,
    submitRef,
    onSomethingChanged,
    requireEmail = true,
    requirePassword = true,
    error,
    requireUser
}) => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const theme = useTheme();
    const [state, dispatch] = useReducerWithRef(detailsReducer, {});
    const acctType = useRef(user?.accountType ?? 2);
    const manageStateRef = useManageStateRef();
    const checkPermission = usePermission();
    const { resendWelcomeEmail, loading: resending } = useResendWelcomeEmail();

    useEffect(() => {
        onSomethingChanged && onSomethingChanged(state || {});
    }, [state, onSomethingChanged]);

    useEffect(() => {
        if (user) {
            dispatch({ type: DetailsActions.reset });
            dispatch({
                type: DetailsActions.values,
                payload: {
                    confirmEmail: user.email,
                    userID: user.userID,
                    approvalLimitString: currency(user.approvalLimit ?? 0),
                    forcePassword: user.forcePassword,
                    manager: user.manager ?? manageStateRef.current.allUsers.find((u) => u.userID === user.managerUserID)
                }
            });
        }
    }, [dispatch, manageStateRef, user]);

    const handleChange = useCallback(
        (name: string, value: any) => {
            if ((user as IIdx)[name] !== value) {
                dispatch({ type: DetailsActions.values, payload: { [name]: value } });
            } else {
                dispatch({ type: DetailsActions.values, payload: { [name]: undefined } });
            }
        },
        [dispatch, user]
    );

    const inputChange = useCallback(
        (evt) => {
            handleChange(evt.target.name, evt.target.value);
        },
        [handleChange]
    );

    const typeChange = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            acctType.current = checked ? 1 : 2;
            handleChange(evt.target.name, checked ? 1 : 2);
        },
        [handleChange]
    );

    const deactivate = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            handleChange(evt.target.name, checked ? 0 : acctType.current ?? user?.accountType);
        },
        [handleChange, user]
    );

    const onManagerChange = (event: React.ChangeEvent<{}>, value?: Partial<Models.User>) => {
        handleChange('manager', value as Partial<Models.User>);
    };

    const forcePasswordChange = (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        handleChange('forcePassword', checked);
    };

    const resendWelcome = () => {
        user?.userID && resendWelcomeEmail(user.userID);
    };

    return (
        <Scroller>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100%">
                <Box height="0px" display="flex" justifyContent="center" alignItems="baseline" position="relative" bottom="1rem">
                    <Typography color="error">{error}</Typography>
                </Box>

                {requireUser && !user?.userID && (
                    <Typography variant="h5" color="primary">
                        Please select a user to update.
                    </Typography>
                )}
                {((requireUser && user?.userID) || !requireUser) && (
                    <Box
                        display="grid"
                        margin={2}
                        gridTemplateColumns="1fr 1fr"
                        gridAutoRows="min-content"
                        alignItems="center"
                        gridColumnGap={theme.spacing(2)}
                        gridRowGap={theme.spacing(4)}
                        width={theme.spacing(120)}
                    >
                        <MemoTextField
                            initialValue={user?.userID ? user?.username : ''}
                            value={state?.username}
                            onChange={inputChange}
                            name="username"
                            label="User Name"
                            tabIndex={0}
                            required
                        />
                        <Box display="grid" gridTemplateColumns="1fr 1fr">
                            <CheckControl
                                checked={state?.accountType !== undefined ? state?.accountType === 1 : user?.accountType === 1}
                                onChange={typeChange}
                                name="accountType"
                                disabled={state?.accountType !== undefined ? state?.accountType === 0 : user?.accountType === 0}
                            >
                                STI User
                            </CheckControl>
                            <CheckControl
                                checked={state?.accountType !== undefined ? state?.accountType === 0 : user?.accountType === 0}
                                onChange={deactivate}
                                name="accountType"
                            >
                                Deactivate User
                            </CheckControl>
                            {checkPermission() && user?.userID ? (
                                <CheckControl checked={!!state?.forcePassword} name="forcePassword" onChange={forcePasswordChange}>
                                    Force Password Change
                                </CheckControl>
                            ) : null}
                            {checkPermission() && user?.userID ? (
                                <Box display="flex" alignItems="center">
                                    <ExtendedButton variant="outlined" loading={!!resending} name="forcePassword" onClick={resendWelcome}>
                                        Resend Welcome Email
                                    </ExtendedButton>
                                </Box>
                            ) : null}
                        </Box>
                        <MemoTextField
                            initialValue={user?.firstName}
                            value={state?.firstName}
                            onChange={inputChange}
                            name="firstName"
                            label="First Name"
                            tabIndex={1}
                        />
                        <MemoTextField
                            initialValue={user?.lastName}
                            value={state?.lastName}
                            onChange={inputChange}
                            name="lastName"
                            label="Last Name"
                            tabIndex={2}
                        />
                        <MemoTextField
                            initialValue={user?.email}
                            value={state?.email}
                            onChange={inputChange}
                            name="email"
                            label="Email"
                            type="Email"
                            tabIndex={3}
                            required={requireEmail}
                        />
                        {mode === 'edit' ? (
                            <MemoTextField
                                value={state?.password !== undefined ? state?.password : ''}
                                onChange={inputChange}
                                name="password"
                                label="Password"
                                type="Password"
                                tabIndex={5}
                                required={requirePassword}
                            />
                        ) : null}
                        <Box marginBottom="-22px">
                            <MemoTextField
                                fullWidth
                                initialValue={user?.email}
                                value={state?.confirmEmail}
                                onChange={inputChange}
                                name="confirmEmail"
                                label="Confirm Email"
                                type="Email"
                                error={state?.email ? state?.confirmEmail !== state?.email : false}
                                helperText={state?.email && state?.confirmEmail !== state?.email ? 'Emails Do Not Match' : ' '}
                                tabIndex={4}
                                required={requireEmail}
                            />
                        </Box>
                        {mode === 'edit' ? (
                            <Box marginBottom="-22px">
                                <MemoTextField
                                    fullWidth
                                    value={state?.confirmPassword !== undefined ? state?.confirmPassword : ''}
                                    onChange={inputChange}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="Password"
                                    error={state?.password ? state?.confirmPassword !== state?.password : false}
                                    tabIndex={6}
                                    helperText={state?.password && state?.confirmPassword !== state?.password ? 'Passwords Do Not Match' : ' '}
                                    required={requirePassword}
                                />
                            </Box>
                        ) : null}
                        <Box marginBottom="-22px">
                            <UserSelect
                                label="Manager"
                                showSelf
                                className={inputClasses.input}
                                menuClasses={menuClasses}
                                color="primary"
                                variant="filled"
                                fullWidth
                                onChange={onManagerChange}
                                useManagedUser={false}
                                value={state?.manager}
                            />
                        </Box>

                        <Box marginBottom="-22px">
                            <AuditAmountInput
                                value={state?.approvalLimitString ?? '$0'}
                                fullWidth
                                disableErrorColor
                                label="Approval Limit"
                                errorNonEmptyOnly
                                name="approvalLimitString"
                                onChange={inputChange}
                            />
                        </Box>

                        <Box display="none">
                            <button ref={submitRef} type="submit" />
                        </Box>
                    </Box>
                )}
            </Box>
        </Scroller>
    );
};

const MemoTextField: React.FC<TextFieldProps & { initialValue?: any }> = memo(({ value, initialValue, ...props }) => {
    const classes = useInputStyle();
    return <TextField {...props} variant="filled" className={classes.input} value={(value !== undefined && value !== null ? value : initialValue) || ''} />;
});
