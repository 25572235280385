import React, { useCallback } from 'react';
import { useAuditDispatch, useAuditState, AuditActions } from '../audit.context';
import { useTheme, fade, darken } from '@material-ui/core';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import { ExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';
import { STITooltip } from '../../../generic';

export const GLDelete: React.FC<{ rowNum: number }> = ({ rowNum }) => {
    const auditDispatch = useAuditDispatch();
    const auditState = useAuditState();
    const theme = useTheme();

    const handleDelete = useCallback(() => {
        auditDispatch({ type: AuditActions.deleteRow, rowNum });
    }, [auditDispatch, rowNum]);

    return auditState.fillLast && rowNum === auditState.glcoding.length - 1 ? null : (
        <ExtendedIconButton
            color="inherit"
            size={'small'}
            iconSize={4}
            onClick={handleDelete}
            tabIndex={-1}
            bgColor={darken(theme.palette.primary.main, 0.15)}
            hoverColor={fade(theme.palette.primary.dark, 0.75)}
            rippleColor={theme.palette.primary.dark}
        >
            <STITooltip title="Delete Row">
                <DeleteOutline />
            </STITooltip>
        </ExtendedIconButton>
    );
};
