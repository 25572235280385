import React, { useMemo } from 'react';
import { useUserState } from '../../../contexts/user.context';
import { currency } from '../../../helpers/pipes';
import { FailureObject } from '../../../hooks/stiapi.hook';
import { FailureSection } from './failuresection';

export type DisburseFailureTypes = 'voided' | 'disputed' | 'permission';

export const failureTitles: Record<string, string> = {
    voided: 'Voided',
    disputed: 'In Dispute',
    permission: 'Insufficient Permissions'
};

interface DisburseAllFailedProps {
    fails: FailureObject<DisburseFailureTypes>;
}

export const DisburseAllFailed: React.FC<DisburseAllFailedProps> = ({ fails }) => {
    const { user } = useUserState();
    const sortedFails = useMemo(() => {
        const failureMap = Object.entries(fails).reduce((acc: Record<string, string[]>, [id, v]) => {
            const type = Object.keys(v)[0];
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(id);
            return acc;
        }, {});
        return Object.entries(failureMap);
    }, [fails]);
    return (
        <>
            {sortedFails.map(([type, ids]) => (
                <FailureSection title={failureTitles[type]} invoices={ids} />
            ))}
        </>
    );
};
