import React from 'react';
import { styled, fade, BoxProps } from '@material-ui/core';

interface ITitledDiv {
    title?: string;
}

export const TitledDiv: React.FC<ITitledDiv> = ({ title, children, ...props }) => {
    return (
        <Fieldset>
            <legend>{title}</legend>
            {children}
        </Fieldset>
    );
};

const Fieldset: React.ComponentType<Partial<BoxProps>> = styled('fieldset')(({ theme }) => ({
    border: `1px solid ${fade(theme.palette.common.white, 0.25)}`,
    borderRadius: theme.spacing(1),
    '& legend': {
        color: theme.palette.common.white
    }
}));
