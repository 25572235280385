import { List, ListItem, Grid, Box, makeStyles, darken } from '@material-ui/core';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle, SortEndHandler } from 'react-sortable-hoc';
import { CheckControl, Scroller } from '../../../generic';
import { useDataTableDispatch, useDataTableState } from '../../datatable.context';
import { DataTableActions, IColumn } from '../../datatable.types';
import Sort from 'mdi-material-ui/Sort';

const useStyles = makeStyles((theme) => ({
    menu: {
        height: '100%'
    },
    sortableHelper: {
        zIndex: 9999,
        background: theme.palette.background.default,
        borderRadius: theme.spacing(2)
    },
    sortList: {
        boxShadow: theme.shadows[1],
        '& > :nth-child(2n)': {
            backgroundColor: theme.palette.background.paper
        },
        '& > :nth-child(2n-1)': {
            backgroundColor: darken(theme.palette.background.paper, 0.05)
        }
    }
}));

export const ColumnOrderMenu: React.FC = () => {
    const classes = useStyles();
    const { columns } = useDataTableState();
    const dataTableDispatch = useDataTableDispatch();

    const onReorderEnd: SortEndHandler = ({ oldIndex, newIndex }, evt) => {
        const newSort = [...columns];
        newSort.splice(newIndex, 0, ...newSort.splice(oldIndex, 1));
        dataTableDispatch({ type: DataTableActions.columns, payload: newSort });
    };

    const onDeactivate = (index: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newSort = [...columns];
        newSort[index] = { ...newSort[index], active: checked };
        dataTableDispatch({ type: DataTableActions.columns, payload: newSort });
    };

    return (
        <Scroller paddingTop={1}>
            <SortableList axis="y" useDragHandle onSortEnd={onReorderEnd} helperClass={classes.sortableHelper}>
                <List className={classes.sortList} disablePadding>
                    {columns.map((o, i) => (
                        <SortableListItem index={i} key={o.key || i}>
                            <SortItem key={o.key || i} orderItem={o} onChange={onDeactivate(i)} />
                        </SortableListItem>
                    ))}
                </List>
            </SortableList>
        </Scroller>
    );
};

const SortableList = SortableContainer(({ children }: { children: any }) => <>{children}</>);

const SortableListItem = SortableElement(({ children, ...props }: { children: any }) => <ListItem {...props}>{children}</ListItem>);

const Handle = SortableHandle(({ children }: { children: any }) => (
    <Grid item className="handle">
        {children}
    </Grid>
));

interface ISortItem {
    orderItem: IColumn;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const SortItem: React.FC<ISortItem> = ({ orderItem, onChange }) => {
    return (
        <Grid container wrap="nowrap" spacing={1} alignItems="center">
            <Handle>
                <Box paddingLeft={1} paddingRight={1}>
                    <Sort />
                </Box>
            </Handle>
            <Grid item>
                <CheckControl checked={orderItem.active} onChange={onChange}>
                    {orderItem.label}
                </CheckControl>
            </Grid>
        </Grid>
    );
};
