import { Models } from 'shipmenttrackers-domain/dist';
import { IIdx } from '../../../types/general.type';

export const getBillingEntityID = (invoice?: Models.CarrierInvoices) => {
    switch (invoice?.clientBillingLevel) {
        case 'Client':
            return invoice.clientID;
        case 'Company':
            return invoice.companyID;
        case 'Division':
            return invoice.divisionID;
        case 'Location':
            return invoice.locationID;
        default:
            return undefined;
    }
};

export const billingEntityFieldName: IIdx = {
    Client: 'clientID',
    Company: 'companyID',
    Division: 'divisionID',
    Location: 'locationID'
};
