import React from 'react';
import { ButtonProps, Box, Button, makeStyles, CircularProgress, CircularProgressProps, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { hoverColor?: string; wrap: boolean }>((theme) => ({
    button: {
        position: 'relative',
        whiteSpace: (props) => (!props.wrap ? 'nowrap' : undefined),
        '&:hover': {
            backgroundColor: (props) => (props.hoverColor ? props.hoverColor : theme.palette.action.hover)
        }
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        opacity: '75'
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark
        }
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    },
    wrap: {}
}));
const useRippleStyles = makeStyles<
    Theme,
    {
        rippleColor?: string;
    }
>((theme) => ({
    child: {
        backgroundColor: (props) => (props.rippleColor ? props.rippleColor : theme.palette.action.selected)
    }
}));

interface IExtendedButton extends Omit<ButtonProps, 'color'> {
    loading?: boolean;
    progressProps?: CircularProgressProps;
    color?: 'primary' | 'secondary' | string;
    hoverColor?: string;
    rippleColor?: string;
    wrap?: 'wrap' | 'nowrap';
}

export const ExtendedButton: React.FC<IExtendedButton> = ({ loading, children, color, progressProps, hoverColor, rippleColor, wrap, ...props }) => {
    const classes = useStyles({ hoverColor, wrap: wrap === 'wrap' });
    const rippleClasses = useRippleStyles({ rippleColor });
    const c = color === 'primary' || color === 'secondary' ? color : 'inherit';
    return (
        <Box color={c === 'inherit' ? color : undefined} position="relative">
            <Button
                {...props}
                color={c}
                className={`${props.className} ${classes[color || ''] ? classes[color || ''] : classes.button} `}
                disabled={(props.disabled !== undefined && props.disabled) || loading}
                TouchRippleProps={{ classes: rippleColor ? rippleClasses : undefined }}
            >
                {children}
            </Button>
            {loading && <CircularProgress color={c} className={classes.progress} variant="indeterminate" size={24} {...progressProps} />}
        </Box>
    );
};
