import React, { useMemo, ChangeEvent, useRef } from 'react';

import { Grid, Button, Box, useTheme, makeStyles } from '@material-ui/core';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { useAppState, useAppDispatch, useValidateFilterAgainstData, useAppStateRef } from '../../../contexts/app.context';
import { AppFilterActions, DivisionWId, LocationWId, CarrierAccountWId } from '../../../types/contexts/app-context.type';
import { SingleSelectAuto, ISingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { FilterOptionsState } from '@material-ui/lab';
import { Models } from 'shipmenttrackers-domain/dist';
import { useCurrentUser } from '../../../contexts/user.context';
import { CheckControl } from '../../generic';

const useDivisionStyles = makeStyles((theme) => ({
    input: {
        width: '8rem'
    }
}));

export const DivisionSearch: React.FC = () => {
    const theme = useTheme();
    const inputClasses = useInputStyle();
    const divisionClasses = useDivisionStyles();
    const menuClasses = useMenuStyles();
    const appDispatch = useAppDispatch();
    const [user] = useCurrentUser();
    const {
        filter: { autofill }
    } = useAppState();

    const reset = () => {
        appDispatch({ type: AppFilterActions.resetDivisions });
    };

    return (
        <Grid container spacing={2} alignItems="center">
            {user?.accountType === 1 && (
                <Grid item>
                    <ClientSelect
                        className={`${divisionClasses.input} ${inputClasses.input}`}
                        menuClasses={menuClasses}
                        color="primary"
                        variant="filled"
                        label="Client"
                    />
                </Grid>
            )}
            <Grid item>
                <CompanySelect
                    className={`${divisionClasses.input} ${inputClasses.input}`}
                    menuClasses={menuClasses}
                    color="primary"
                    variant="filled"
                    label="Company"
                />
            </Grid>
            <Grid item>
                <DivisionSelect
                    className={`${divisionClasses.input} ${inputClasses.input}`}
                    menuClasses={menuClasses}
                    color="primary"
                    variant="filled"
                    label="Division"
                />
            </Grid>
            <Grid item>
                <LocationSelect
                    className={`${divisionClasses.input} ${inputClasses.input}`}
                    menuClasses={menuClasses}
                    color="primary"
                    variant="filled"
                    label="Location"
                />
            </Grid>
            <Grid item>
                <CarrierSelect
                    className={`${divisionClasses.input} ${inputClasses.input}`}
                    menuClasses={menuClasses}
                    color="primary"
                    variant="filled"
                    label="Carrier"
                />
            </Grid>
            <Grid item>
                <AccountSelect
                    className={`${divisionClasses.input} ${inputClasses.input}`}
                    menuClasses={menuClasses}
                    color="primary"
                    variant="filled"
                    label="Account"
                />
            </Grid>

            <Grid item>
                <Box color={theme.palette.common.white}>
                    <Grid container direction="column">
                        <CheckControl checked={autofill} onChange={(evt, checked) => appDispatch({ type: AppFilterActions.autofill, payload: checked })}>
                            Autofill
                        </CheckControl>
                        <Button variant="outlined" color="inherit" onClick={reset}>
                            Reset
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

type DivisionSelector<T> = React.FC<Partial<ISingleSelectAuto<Partial<T>>>>;

const ClientSelect: DivisionSelector<Models.Client> = (props) => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const {
        filter: { autofill }
    } = appState;

    const clientOptions = useMemo(() => {
        let all: Partial<Models.Client>[] = [{ clientName: 'All Clients', clientID: -1 }];
        if (appState.data.clients) {
            const clients = appState.data.clients.sort((a, b) => a.clientName.localeCompare(b.clientName));
            all = all.concat(clients);
        }
        return all;
    }, [appState.data.clients]);

    const onChange = (event: React.ChangeEvent<{}>, value?: Partial<Models.Client>) => {
        const payload = value && value.clientID !== -1 ? value : undefined;
        appDispatch({ type: AppFilterActions.client, payload });
    };

    return (
        <SingleSelectAuto
            {...props}
            disableNull={false}
            // nullString="All Clients"
            value={appState.filter.client || clientOptions[0]}
            onValChange={onChange}
            options={clientOptions}
            getText={(c) => c.clientName || ''}
            getValue={(c) => c.clientID}
        />
    );
};

const CompanySelect: DivisionSelector<Models.Company> = (props) => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const {
        filter: { autofill }
    } = appState;

    const companyOptions: Partial<Models.Company>[] = useMemo(() => {
        let all: Partial<Models.Company>[] = [];
        if (appState.data.companies.length) {
            const clientsCompanysOptions = appState.filter.client?.companies?.sort((a, b) => a.companyName.localeCompare(b.companyName)) || [];

            const companyOptions =
                Object.values(appState.data.companies)
                    ?.filter((c) => c.clientID !== appState.filter.client?.clientID)
                    .sort((a, b) => a.companyName.localeCompare(b.companyName)) || [];
            if (clientsCompanysOptions.length) {
                all.push({ companyName: `All ${appState.filter.client?.clientName}`, clientID: appState.filter.client?.clientID, companyID: -1 });
            }

            all = all.concat(clientsCompanysOptions);
            if (companyOptions.length > 1 && !clientsCompanysOptions.length) {
                all.push({ companyName: `All Companies`, clientID: -1, companyID: -2 });
            }
            all = all.concat(companyOptions);
        }
        if (all.length === 1 && autofill) {
            appDispatch({ type: AppFilterActions.company, payload: all[0] });
        }
        return all;
    }, [appState.data.companies, appState.filter.client, autofill, appDispatch]);

    const onChange = (event?: ChangeEvent<{}>, value?: Partial<Models.Company>) => {
        const payload = value && value.companyID !== -1 ? value : undefined;
        appDispatch({ type: AppFilterActions.company, payload });
    };

    const groupBy = (o: Partial<Models.Company>) => {
        return appState.filter.client && o.clientID === appState.filter.client.clientID ? appState.filter.client.clientName : 'More Companies';
    };

    return (
        <SingleSelectAuto
            groupBy={appState.filter.client && groupBy}
            value={appState.filter.company || companyOptions[0]}
            onValChange={onChange}
            options={companyOptions}
            {...props}
            getText={(c) => c.companyName || ''}
            getValue={(c) => c.companyID}
        />
    );
};

const DivisionSelect: DivisionSelector<DivisionWId> = (props) => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const {
        filter: { autofill }
    } = appState;

    const divisionOptions: Partial<DivisionWId>[] = useMemo(() => {
        let all: Partial<DivisionWId>[] = [];

        if (appState.data.companies.length) {
            let companyDivisions: DivisionWId[] = [];
            let clientDivisions: DivisionWId[] = [];
            let restDivisions: DivisionWId[] = [];

            const cId = appState.filter.client?.clientID;
            const wId = appState.filter.company?.companyID;

            appState.data.companies.forEach((c) => {
                c.divisions.forEach((d) => {
                    if (d.companyID === wId) {
                        companyDivisions.push(d);
                    } else if (d.clientID === cId) {
                        clientDivisions.push(d);
                    } else {
                        restDivisions.push(d);
                    }
                });
            });

            companyDivisions.sort((a, b) => a.divisionName.localeCompare(b.divisionName));
            clientDivisions.sort((a, b) => a.divisionName.localeCompare(b.divisionName));
            restDivisions.sort((a, b) => a.divisionName.localeCompare(b.divisionName));

            if (companyDivisions.length) {
                all.push({
                    divisionName: `All ${appState.filter.company?.companyName || 'of Company'}`,
                    companyID: appState.filter.company?.companyID,
                    divisionID: -1
                });
            }
            all = all.concat(companyDivisions);

            if (clientDivisions.length) {
                all.push({
                    divisionName: `All ${appState.filter.client?.clientName || 'of Client'}`,
                    clientID: appState.filter.client?.clientID,
                    companyID: -1,
                    divisionID: -2
                });
            }
            all = all.concat(clientDivisions);

            if (restDivisions.length) {
                all.push({ divisionName: `All Divisions`, clientID: -1, divisionID: -3 });
            }
            all = all.concat(restDivisions);
        }
        if (all.length === 1 && autofill) {
            appDispatch({ type: AppFilterActions.division, payload: all[0] });
        }
        return all;
    }, [appState.data.companies, appState.filter.client, appState.filter.company, autofill, appDispatch]);

    const onChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (value?.companyID === -1) {
            appDispatch({ type: AppFilterActions.company });
        } else {
            const payload = value && value.divisionID !== -1 ? value : undefined;
            appDispatch({ type: AppFilterActions.division, payload });
        }
    };

    const groupBy = (o: Partial<DivisionWId>) => {
        if (appState.filter.company && o.companyID === appState.filter.company.companyID) {
            return `Company: ${appState.filter.company.companyName}`;
        } else if (appState.filter.client && o.clientID === appState.filter.client.clientID) {
            return `Client: ${appState.filter.client.clientName}`;
        } else {
            return 'More Divisions';
        }
    };

    return (
        <SingleSelectAuto
            {...props}
            disableNull={false}
            value={appState.filter.division || divisionOptions[0]}
            onValChange={onChange}
            options={divisionOptions}
            getText={(d) => d.divisionName || ''}
            getValue={(d) => d.divisionID}
            groupBy={appState.filter.client ? groupBy : undefined}
        />
    );
};

const LocationSelect: DivisionSelector<LocationWId> = (props) => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const {
        filter: { autofill }
    } = appState;

    const locationOptions: Partial<LocationWId>[] = useMemo(() => {
        let all: Partial<LocationWId>[] = [];

        if (appState.data.companies.length) {
            let divisionLocations: LocationWId[] = [];
            let companyLocations: LocationWId[] = [];
            let clientLocations: LocationWId[] = [];
            let restLocations: LocationWId[] = [];

            const cId = appState.filter.client?.clientID;
            const wId = appState.filter.company?.companyID;
            const dId = appState.filter.division?.divisionID;

            appState.data.companies.forEach((c) => {
                c.divisions.forEach((d) => {
                    d.locations.forEach((l) => {
                        if (l.divisionID === dId) {
                            divisionLocations.push(l);
                        } else if (l.companyID === wId) {
                            companyLocations.push(l);
                        } else if (l.clientID === cId) {
                            clientLocations.push(l);
                        } else {
                            restLocations.push(l);
                        }
                    });
                });
            });
            divisionLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));
            companyLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));
            clientLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));
            restLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));

            if (divisionLocations.length) {
                all.push({
                    locationName: `All ${appState.filter.division?.divisionName || 'of Division'}`,
                    divisionID: appState.filter.division?.divisionID,
                    locationID: -1
                });
            }
            all = all.concat(divisionLocations);

            if (companyLocations.length) {
                all.push({
                    locationName: `All ${appState.filter.company?.companyName || 'of Company'}`,
                    companyID: appState.filter.company?.companyID,
                    divisionID: -1,
                    locationID: -2
                });
            }
            all = all.concat(companyLocations);

            if (clientLocations.length) {
                all.push({
                    locationName: `All ${appState.filter.client?.clientName || 'of Client'}`,
                    clientID: appState.filter.client?.clientID,
                    companyID: -1,
                    locationID: -3
                });
            }
            all = all.concat(clientLocations);

            if (restLocations.length) {
                all.push({ locationName: `All Locations`, clientID: -1, locationID: -4 });
            }
            all = all.concat(restLocations);
        }
        if (all.length === 1 && autofill) {
            appDispatch({ type: AppFilterActions.location, payload: all[0] });
        }
        return all;
    }, [appState.data.companies, appState.filter.client, appState.filter.company, appState.filter.division, autofill, appDispatch]);

    const onChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (value?.companyID === -1) {
            appDispatch({ type: AppFilterActions.company });
        } else if (value?.divisionID === -1) {
            appDispatch({ type: AppFilterActions.division });
        } else {
            const payload = value && value.locationID !== -1 ? value : undefined;
            appDispatch({ type: AppFilterActions.location, payload });
        }
    };

    const groupBy = (o: Partial<LocationWId>) => {
        if (appState.filter.division && o.divisionID === appState.filter.division.divisionID) {
            return `Division: ${appState.filter.division.divisionName}`;
        } else if (appState.filter.company && o.companyID === appState.filter.company.companyID) {
            return `Company: ${appState.filter.company.companyName}`;
        } else if (appState.filter.client && o.clientID === appState.filter.client.clientID) {
            return `Client: ${appState.filter.client.clientName}`;
        } else {
            return 'More Locations';
        }
    };

    return (
        <SingleSelectAuto
            {...props}
            disableNull={false}
            // nullString="All Locations"
            // createNullValue={false}
            value={appState.filter.location || locationOptions[0]}
            onValChange={onChange}
            options={locationOptions}
            getText={(l) => l.locationName || ''}
            getValue={(l) => l.locationID}
            groupBy={appState.filter.client ? groupBy : undefined}
        />
    );
};

const CarrierSelect: DivisionSelector<Models.Carrier> = (props) => {
    const appState = useAppState();
    const appStateRef = useAppStateRef();
    const appDispatch = useAppDispatch();

    const {
        filter: { autofill }
    } = appState;

    const carrierOptions: Partial<Models.Carrier>[] = useMemo(() => {
        // if (!appState.filter.location) return [];
        let all: Partial<Models.Carrier>[] = [];
        const carriers = appState.data.carriers.sort((a, b) => a.carrierName.localeCompare(b.carrierName)) || [];
        if (carriers.length > 1) {
            all = [{ carrierName: 'All Carriers', carrierID: -2 }, ...all];
        }
        all = [...all, ...carriers];
        if (all.length === 1 && autofill) {
            // if (all.length === 1 && autofill && appStateRef.current.filter.carrier?.carrierID !== all[0].carrierID) {
            appDispatch({ type: AppFilterActions.carrier, payload: all[0] });
        } else if (!appStateRef.current.filter.client && !appStateRef.current.filter.userCarrieSelection) {
            appDispatch({ type: AppFilterActions.carrier, payload: undefined });
        } else if (appStateRef.current.filter.userCarrieSelection === undefined) {
            appDispatch({ type: AppFilterActions.carrier, payload: undefined });
        } else if (!carriers.find((c) => c.carrierID === appStateRef.current.filter.userCarrieSelection?.carrierID)) {
            appDispatch({ type: AppFilterActions.carrier, payload: undefined });
        }
        return all;
    }, [appState.data.carriers, autofill, appStateRef, appDispatch]);

    const onChange = (event: React.ChangeEvent<{}>, value?: Partial<Models.Carrier>) => {
        const payload = value && value.carrierID !== -2 ? value : undefined; // -2 is 'all' option

        appDispatch({ type: AppFilterActions.carrier, payload });
    };

    return (
        <SingleSelectAuto
            {...props}
            disableNull={false}
            value={appState.filter.carrier || carrierOptions[0]}
            onValChange={onChange}
            options={carrierOptions}
            getText={(c) => c.carrierName || ''}
            // getSubtext={(c) => c.carrierSTICode || ''}
            getValue={(c) => c.carrierID}
            filterOptions={(options, state) => {
                const lower = state.inputValue.toLocaleLowerCase();
                return options.filter((o) => o.carrierName?.toLocaleLowerCase().includes(lower) || o.carrierSTICode?.toLocaleLowerCase().includes(lower));
            }}
        />
    );
};

const AccountSelect: DivisionSelector<CarrierAccountWId> = (props) => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const validate = useValidateFilterAgainstData();
    const {
        filter: { autofill }
    } = appState;

    const accountOptions: Partial<CarrierAccountWId>[] = useMemo(() => {
        let all: Partial<CarrierAccountWId>[] = [];

        if (appState.data.companies.length) {
            let carrierAccounts: CarrierAccountWId[] = [];
            let locationAccounts: CarrierAccountWId[] = [];
            let divisionAccounts: CarrierAccountWId[] = [];
            let companyAccounts: CarrierAccountWId[] = [];
            let clientAccounts: CarrierAccountWId[] = [];
            let restAccounts: CarrierAccountWId[] = [];

            const cId = appState.filter.client?.clientID;
            const wId = appState.filter.company?.companyID;
            const dId = appState.filter.division?.divisionID;
            const lId = appState.filter.location?.locationID;
            const carId = appState.filter.carrier?.carrierID;

            appState.data.companies.forEach((c) => {
                c.divisions.forEach((d) => {
                    d.locations.forEach((l) => {
                        l.accounts.forEach((a) => {
                            if (a.locationID === lId && a.carrierID === carId) {
                                carrierAccounts.push(a);
                            } else if (a.locationID === lId) {
                                locationAccounts.push(a);
                            } else if (a.divisionID === dId) {
                                divisionAccounts.push(a);
                            } else if (a.companyID === wId) {
                                companyAccounts.push(a);
                            } else if (a.clientID === cId) {
                                clientAccounts.push(a);
                            } else {
                                restAccounts.push(a);
                            }
                        });
                    });
                });
            });

            const sortFunc = (a: CarrierAccountWId, b: CarrierAccountWId) => {
                // if (a.accountName && b.accountName) {
                //     return a.accountName.localeCompare(b.accountName);
                // } else {
                return a.carrierAccount.localeCompare(b.carrierAccount);
                // }
            };

            carrierAccounts.sort(sortFunc);
            locationAccounts.sort(sortFunc);
            divisionAccounts.sort(sortFunc);
            companyAccounts.sort(sortFunc);
            clientAccounts.sort(sortFunc);
            restAccounts.sort(sortFunc);

            if (carrierAccounts.length) {
                all.push({
                    accountName: `All ${
                        appState.filter.carrier ? `${appState.filter.carrier.carrierName} (${appState.filter.carrier.carrierSTICode})` : 'of Carrier'
                    }`,
                    carrierID: appState.filter.carrier?.carrierID,
                    carrierAccountID: -1
                });
            }
            all = all.concat(carrierAccounts);

            if (locationAccounts.length) {
                all.push({
                    accountName: `All ${appState.filter.location?.locationName || 'of Location'}`,
                    locationID: appState.filter.location?.locationID,
                    carrierAccountID: -2
                });
            }
            all = all.concat(locationAccounts);

            if (divisionAccounts.length) {
                all.push({
                    accountName: `All ${appState.filter.division?.divisionName || 'of Division'}`,
                    divisionID: appState.filter.division?.divisionID,
                    locationID: -1,
                    carrierAccountID: -3
                });
            }
            all = all.concat(divisionAccounts);

            if (companyAccounts.length) {
                all.push({
                    accountName: `All ${appState.filter.company?.companyName || 'of Company'}`,
                    companyID: appState.filter.company?.companyID,
                    divisionID: -1,
                    carrierAccountID: -4
                });
            }
            all = all.concat(companyAccounts);

            if (clientAccounts.length) {
                all.push({
                    accountName: `All ${appState.filter.client?.clientName || 'of Client'}`,
                    clientID: appState.filter.client?.clientID,
                    companyID: -1,
                    carrierAccountID: -5
                });
            }
            all = all.concat(clientAccounts);

            if (restAccounts.length) {
                all.push({ accountName: `All Accounts`, carrierAccountID: -6 });
            }
            all = all.concat(restAccounts);
        }
        if (all.length === 1 && autofill) {
            appDispatch({ type: AppFilterActions.carrierAccount, payload: all[0] });
        }
        return all;
    }, [
        appState.data.companies,
        appState.filter.client,
        appState.filter.company,
        appState.filter.division,
        appState.filter.location,
        appState.filter.carrier,
        autofill,
        appDispatch
    ]);

    const onChange = async (event: React.ChangeEvent<{}>, value?: Partial<CarrierAccountWId>) => {
        // appDispatch({ type: AppFilterActions.filterBulk, payload: validate(value) });
        if (value?.companyID === -1) {
            appDispatch({ type: AppFilterActions.company });
        } else if (value?.divisionID === -1) {
            appDispatch({ type: AppFilterActions.division });
        } else if (value?.locationID === -1) {
            appDispatch({ type: AppFilterActions.location });
        } else if (accountOptions.find((a) => a.carrierAccountID === value?.carrierAccountID)) {
            const payload = value && value.carrierAccountID !== -1 ? value : undefined;
            appDispatch({
                type: AppFilterActions.carrierAccount,
                payload
            });
        }
    };

    const groupBy = (o: Partial<CarrierAccountWId>) => {
        if (appState.filter.location && o.locationID === appState.filter.location.locationID) {
            if (appState.filter.carrier && o.carrierID === appState.filter.carrier.carrierID) {
                return `Carrier: ${appState.filter.carrier.carrierName} (${appState.filter.carrier.carrierSTICode})`;
            } else {
                return `Location: ${appState.filter.location.locationName}`;
            }
        } else if (appState.filter.division && o.divisionID === appState.filter.division.divisionID) {
            return `Division: ${appState.filter.division.divisionName}`;
        } else if (appState.filter.company && o.companyID === appState.filter.company.companyID) {
            return `Company: ${appState.filter.company.companyName}`;
        } else if (appState.filter.client && o.clientID === appState.filter.client.clientID) {
            return `Client: ${appState.filter.client.clientName}`;
        } else {
            return 'More Accounts';
        }
    };

    return (
        <SingleSelectAuto
            {...props}
            disableNull={false}
            value={appState.filter.carrierAccount || accountOptions[0]}
            onValChange={onChange}
            options={accountOptions}
            filterOptions={accountSelectFilter}
            getText={(a) => a.carrierAccount || ''}
            getSubtext={(a) => a.accountName || a.carrierAccount || ''}
            getValue={(a) => a.carrierAccountID}
            freeSolo
            addFree={false}
            createValue={(id) => ({ carrierAccount: id })}
            groupBy={appState.filter.client ? groupBy : undefined}
        />
    );
};

const accountSelectFilter = (options: Partial<Models.CarrierAccount>[], state: FilterOptionsState<Models.CarrierAccount>) => {
    const val = state.inputValue;
    if (!val) return options;
    return options.filter(
        (o) => o.accountName?.toLocaleLowerCase().includes(val.toLocaleLowerCase()) || o.carrierAccount?.toLocaleLowerCase().includes(val.toLocaleLowerCase())
    );
};
