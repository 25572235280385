import React from 'react';
import { Grid, Typography, styled, Box } from '@material-ui/core';
import { STIButtonbase } from '../generic/display/stibuttonbase.component';
import PagePreviousOutline from 'mdi-material-ui/PagePreviousOutline';
import PageNextOutline from 'mdi-material-ui/PageNextOutline';
import { STITooltip } from '../generic';

interface IPager {
    currentPage: number;
    totalPages: number;
    onPageChange: (p: number) => void;
    text?: string;
    hideControls?: boolean;
}

export const Pager: React.FC<IPager> = ({ currentPage, totalPages, onPageChange, text, hideControls }) => {
    const handlePageChange = (dir: number) => () => {
        const idx = (currentPage || 0) + dir < 0 ? totalPages - 1 : (currentPage || 0) + dir >= totalPages ? 0 : (currentPage || 0) + dir;
        onPageChange(idx);
    };
    return (
        <PagerContainer container wrap="nowrap" justify="space-between" alignItems="center">
            {!hideControls && (
                <STITooltip title="Previous Invoice">
                    <STIButtonbase onClick={handlePageChange(-1)}>
                        <Box paddingLeft={1.5} paddingRight={1.5} marginBottom="-5px">
                            <PagePreviousOutline />
                        </Box>
                    </STIButtonbase>
                </STITooltip>
            )}
            <Grid container alignItems="center" justify="center">
                <Typography variant="subtitle2">{`${currentPage + 1}/${totalPages}`}</Typography>
            </Grid>
            {!hideControls && (
                <STITooltip title="Next Invoice">
                    <STIButtonbase onClick={handlePageChange(1)}>
                        <Box paddingLeft={1.5} paddingRight={1.5} marginBottom="-5px">
                            <PageNextOutline />
                        </Box>
                    </STIButtonbase>
                </STITooltip>
            )}
        </PagerContainer>
    );
};

const PagerContainer = styled(Grid)({
    height: '100%',
    '& > *': {
        height: '100%'
    }
});
