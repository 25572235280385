import React, { useState, useRef } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useCreateActions } from '../../../../hooks/stiapi.hook';
import { useAuditDispatch, useAuditStateRef, AuditActions } from '../audit.context';
import EmailSendOutline from 'mdi-material-ui/EmailSendOutline';
import NotePlusOutline from 'mdi-material-ui/NotePlusOutline';
import { GridGrow, GridMin, STIModal, STITooltip } from '../../../generic';
import { CollabButton } from '../collabButton.component';

import { EmailNotesModal } from '../../modals/emailnotesmodal.component';
import { ExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';

import { NoteInput } from './noteinput.component';
import { useHandleAtMentions } from './useHandleAtMentions.hook';

const useStyles = makeStyles((theme) => ({
    charCount: {
        opacity: 0.67,
        marginLeft: theme.spacing(0.5)
    }
}));

export const NewNoteInline: React.FC = () => {
    const [newNote, setNewNote] = useState<string>('');
    const { createAction } = useCreateActions();
    const auditDispatch = useAuditDispatch();
    const auditStateRef = useAuditStateRef();
    // const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();

    // const submitRef = useRef<HTMLAnchorElement>(null);
    const { addTag, handleNoteTags } = useHandleAtMentions();
    const classes = useStyles();
    const [openEmail, setOpenEmail] = useState(false);
    const [loading, setLoading] = useState(false);

    const saveNote = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (auditStateRef.current.invoice?.carrierInvoiceID === undefined || !newNote) return;
        setLoading(true);
        // double check tags
        await handleNoteTags(newNote);

        const res = await createAction({ carrierInvoiceID: auditStateRef.current.invoice.carrierInvoiceID, action: newNote });
        if (res.status === 201) {
            auditDispatch({ type: AuditActions.addAction, payload: res.data });
            setNewNote('');
        }
        setLoading(false);
    };

    // const enterSubmit = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    //     if ((evt.key === '\n' || evt.key === 'Enter') && evt.ctrlKey) {
    //         submitRef.current?.click();
    //     }
    // };

    return (
        <>
            <STIModal open={openEmail} onClose={() => setOpenEmail(false)}>
                <EmailNotesModal onClose={() => setOpenEmail(false)} />
            </STIModal>
            <form onSubmit={saveNote} style={{ width: '100%' }}>
                {/* {anchorEl && <CollabMenu open={true} anchorEl={anchorEl} onClose={closeMenu} onSelect={onSelect} txt={potentialTag} />} */}
                <Grid container alignItems="center">
                    <GridGrow>
                        {/* <TextField label="Add Note" fullWidth multiline rowsMax={3} value={newNote} onChange={updateNewNote} onKeyPress={enterSubmit} /> */}
                        <NoteInput newNote={newNote} setNewNote={setNewNote} addTag={addTag} />
                    </GridGrow>
                    <GridMin container direction="column" justify="space-between">
                        <Grid item container spacing={1} wrap="nowrap">
                            <Grid item>
                                <STITooltip title="Submit">
                                    <ExtendedIconButton type="submit" size="small" loading={loading}>
                                        <NotePlusOutline />
                                    </ExtendedIconButton>
                                </STITooltip>
                            </Grid>
                            <Grid item>
                                <CollabButton />
                            </Grid>
                            <Grid item>
                                <STITooltip title="Email Notes">
                                    <ExtendedIconButton size="small" onClick={() => setOpenEmail(true)}>
                                        <EmailSendOutline />
                                    </ExtendedIconButton>
                                </STITooltip>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classes.charCount}
                                color={newNote.length > 250 ? 'error' : 'textPrimary'}
                                variant="caption"
                            >{`${newNote.length}/250`}</Typography>
                        </Grid>
                    </GridMin>
                </Grid>
            </form>
        </>
    );
};
