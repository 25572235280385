import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import TableSearch from 'mdi-material-ui/TableSearch';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../contexts/app.context';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { Version } from '../../../pages/login.page';
import { SearchType, AppActions } from '../../../types/contexts/app-context.type';
import { STIButtonbase } from '../../generic';
import { ISearchOption } from './searchoption.component';

export const DrawerMenu = () => {
    const [isOpen, setOpen] = useState(false);
    const checkPermission = usePermission();
    return (
        <>
            <STIButtonbase color="inherit" onClick={() => setOpen(true)}>
                <TableSearch color="inherit" />
            </STIButtonbase>
            <Drawer anchor="left" open={isOpen} onClose={() => setOpen(false)}>
                <List>
                    <SearchOptionList option={SearchType.division}>Client/Carrier</SearchOptionList>
                    <SearchOptionList option={SearchType.invoice}>Invoice Numbers</SearchOptionList>
                    <SearchOptionList option={SearchType.dateAmt}>Date/Amount</SearchOptionList>
                    {checkPermission() && (
                        <>
                            <SearchOptionList option={SearchType.remittance}>Remittance Number</SearchOptionList>
                            <SearchOptionList option={SearchType.disbursement}>Disbursement Number</SearchOptionList>
                            <SearchOptionList option={SearchType.paymentConfirmation}>Payment Confirmation Number</SearchOptionList>
                        </>
                    )}
                </List>
                <Version color="dark" />
            </Drawer>
        </>
    );
};

const SearchOptionList: React.FC<ISearchOption> = ({ children, option, buttonColor = 'primary', color = 'inherit', ...props }) => {
    const appDispatch = useAppDispatch();

    const handleClick = () => {
        appDispatch({ type: AppActions.searchType, payload: option });
    };
    return (
        <ListItem button onClick={handleClick} {...props}>
            <ListItemText primary={children} />
        </ListItem>
    );
};
