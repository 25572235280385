import React, { useMemo } from 'react';
import { useAuditState } from './audit.context';
import { Grid, Box, styled, Table, fade } from '@material-ui/core';
import { GridMin, GridGrow, Scroller } from '../../generic';
import { useInputStyle } from '../../../styles/stiinput.style';
import { BillingEntitySelect } from './gl/billingentityselector.component';
import { useGLStyles } from './gl/common';
import { GLHeader } from './gl/glheader.component';
import { GLRows } from './gl/glrows.component';
import { GLTotalRow } from './gl/gltotalRow.component';
import { Caps } from '../../generic/display/typocaps.component';
import { useAuditStyles } from './audit.component';
import { Permission } from '../../../types/contexts/user-context.type';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { GLHistoryModal } from '../modals/glhistorymodal.component';
import { Models } from 'shipmenttrackers-domain/dist';

export const GLComponent: React.FC = () => {
    const classes = useGLStyles();
    const inputClasses = useInputStyle();
    const auditClasses = useAuditStyles();
    const checkPermission = usePermission();
    const auditState = useAuditState();
    const { glcoding: codings, availableCodes } = auditState;

    const availableCodesByPosition = useMemo(() => {
        const codes = availableCodes.reduce((acc: string[][], c: Models.AllowedGLCodings) => {
            const zeroIndex = c.glPosition - 1;
            if (!acc[zeroIndex]) {
                acc[zeroIndex] = [];
            }
            if (c.glCode) {
                acc[zeroIndex].push(c.glCode);
            }
            return acc;
        }, []);

        for (let i = 0; i < codes.length; i++) {
            if (!codes[i]) {
                codes[i] = [];
            } // 'No Code',
            codes[i] = [
                ...codes[i].sort((a, b) => {
                    return a.localeCompare(b);
                })
            ];
        }
        return codes;
        // return codes;
    }, [availableCodes]);

    const max: number = useMemo(() => {
        let m = 3;
        for (let i = 0; i < codings.length; i++) {
            for (let j = 10; j > m; j--) {
                const c = codings[i][`gl${j}`];
                if (c !== null && c !== undefined) {
                    m = j;
                }
            }
        }
        return Math.max(availableCodesByPosition.length, m);
    }, [codings, availableCodesByPosition]);

    return (
        <Grid item container direction="column" className={`${classes.container} ${auditClasses.bgPrimary}`} wrap="nowrap">
            {/* <> */}
            <GridMin container alignItems="flex-end" className={`${auditClasses.pagePadding}`}>
                <GridGrow>
                    <BillingEntitySelect label="Billing Entity" variant="filled" className={inputClasses.input} fullWidth title="Billing Entity" />
                </GridGrow>
                {checkPermission(Permission.viewGlCodes) && (
                    <GridGrow container justify="flex-end" alignItems="center">
                        <Box color="white">
                            <GLCountLabel />
                        </Box>
                        <GLHistoryModal invoice={auditState.invoice as Models.CarrierInvoices} />
                    </GridGrow>
                )}
            </GridMin>
            {checkPermission(Permission.viewGlCodes) && (
                <>
                    <Grid className={`${auditClasses.pagePadding} ${classes.glScrollArea}`}>
                        <Scroller>
                            <StyledTable stickyHeader>
                                <GLHeader max={max} />
                                <GLRows max={max} availableCodesByPosition={availableCodesByPosition} />
                            </StyledTable>
                        </Scroller>
                    </Grid>
                    <GridMin className={`${auditClasses.bgDark} ${auditClasses.pagePadding}`}>
                        <GLTotalRow />
                    </GridMin>
                </>
            )}
            {/* </> */}
        </Grid>
    );
};

const GLCountLabel = () => {
    const auditState = useAuditState();
    return (
        <Caps noWrap color="inherit">
            {`GL Rows: ${auditState.glcoding.length}`}
        </Caps>
    );
};

// const GLHistory = () => {
//     const [open, setOpen] = useState(false);
//     const auditState = useAuditState();
//     const checkPermission = usePermission();
//     return checkPermission(Permission.viewGlCodes) ? (
//         <>
//             <ExtendedIconButton></ExtendedIconButton>
//             <ExtendedButton onClick={() => setOpen(!open)}>History</ExtendedButton>
//             <GLHistoryModal open={open} onClose={() => setOpen(false)} carrierInvoiceID={auditState.invoice?.carrierInvoiceID} />
//         </>
//     ) : null;
// };

const StyledTable = styled(Table)(({ theme }) => ({
    '& th, & td': {
        padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
        borderBottom: 'none'
    },
    '& th': {
        background: theme.palette.primary.main,
        borderBottom: `1px solid ${fade(theme.palette.common.white, 0.5)}`,
        borderTop: `1px solid ${fade(theme.palette.common.white, 0.5)}`
    }
}));
