import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { TextField, Box, Typography, useTheme, Switch, withStyles, Button, makeStyles } from '@material-ui/core';
import { DateSelect } from '../../generic';
import { AppFilterActions } from '../../../types/contexts/app-context.type';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { startOfDay, endOfDay } from 'date-fns';
import { useInputStyle } from '../../../styles/stiinput.style';
import { SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { Models } from 'shipmenttrackers-domain/dist';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { usePermission } from '../../../hooks/permission/permissions.hook';

interface AmountOption {
    text: string;
    value: Models.AmountType;
}

const stiAmountTypes: AmountOption[] = [
    {
        text: 'Remittance Amount',
        value: Models.AmountType.REMITTANCE_AMOUNT
    },
    {
        text: 'Disbursement Amount',
        value: Models.AmountType.DISBURSEMENT_AMOUNT
    }
];

const allAmountTypes: AmountOption[] = [
    {
        text: 'Original Amount',
        value: Models.AmountType.ORIGINAL_AMOUNT
    },
    {
        text: 'Current Amount',
        value: Models.AmountType.CURRENT_AMOUNT
    },
    {
        text: 'Paid Amount',
        value: Models.AmountType.PAID_AMOUNT
    },
    {
        text: 'Amount Due',
        value: Models.AmountType.AMOUNT_DUE
    }
];

interface DateAmt {
    [AppFilterActions.startInvDate]?: Date;
    [AppFilterActions.endInvDate]?: Date;
    [AppFilterActions.startDueDate]?: Date;
    [AppFilterActions.endDueDate]?: Date;
    [AppFilterActions.minInv]?: string;
    [AppFilterActions.maxInv]?: string;
    [AppFilterActions.amountType]?: AmountOption;
    [index: string]: any;
}

const reducer = (state: Partial<DateAmt>, update: Partial<DateAmt>) => ({ ...state, ...update });

const useRadioStyles = makeStyles((theme) => ({
    radioControl: {
        color: theme.palette.common.white
    },
    root: {
        color: theme.palette.common.white
    }
}));

const useStyles = makeStyles((theme) => ({
    controlGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr auto',
        width: 'fit-content',
        minWidth: '75%',
        columnGap: theme.spacing(1),
        rowGap: `${theme.spacing(1)}px`,
        '& > *': {
            margin: 0
        }
    },
    buttonWrapper: {
        color: theme.palette.common.white,
        alignSelf: 'center'
    }
}));

export const DateAmountSearch = () => {
    const checkPermission = usePermission();
    const amountTypes = useMemo(() => (checkPermission() ? [...allAmountTypes, ...stiAmountTypes] : allAmountTypes), [checkPermission]);

    const appDispatch = useAppDispatch();
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const classes = useStyles();
    const theme = useTheme();
    const [type, setType] = useState<'date' | 'dueDate'>('date');
    const appState = useAppState();
    const [state, update] = useReducer(reducer, () => ({
        [AppFilterActions.startInvDate]: appState.filter[AppFilterActions.startInvDate],
        [AppFilterActions.endInvDate]: appState.filter[AppFilterActions.endInvDate],
        [AppFilterActions.startDueDate]: appState.filter[AppFilterActions.startDueDate],
        [AppFilterActions.endDueDate]: appState.filter[AppFilterActions.endDueDate],
        [AppFilterActions.minInv]: appState.filter[AppFilterActions.minInv],
        [AppFilterActions.maxInv]: appState.filter[AppFilterActions.maxInv],
        [AppFilterActions.amountType]: appState.filter[AppFilterActions.amountType]
            ? amountTypes.find((o) => o.value === appState.filter[AppFilterActions.amountType])
            : amountTypes.find((o) => o.value === Models.AmountType.CURRENT_AMOUNT)
    }));

    useEffect(() => {
        update({
            [AppFilterActions.startInvDate]: appState.filter[AppFilterActions.startInvDate],
            [AppFilterActions.endInvDate]: appState.filter[AppFilterActions.endInvDate],
            [AppFilterActions.startDueDate]: appState.filter[AppFilterActions.startDueDate],
            [AppFilterActions.endDueDate]: appState.filter[AppFilterActions.endDueDate],
            [AppFilterActions.minInv]: appState.filter[AppFilterActions.minInv],
            [AppFilterActions.maxInv]: appState.filter[AppFilterActions.maxInv],
            [AppFilterActions.amountType]: appState.filter[AppFilterActions.amountType]
                ? amountTypes.find((o) => o.value === appState.filter[AppFilterActions.amountType])
                : amountTypes.find((o) => o.value === Models.AmountType.CURRENT_AMOUNT)
        });
    }, [amountTypes, appState.filter]);

    const onChangeMin = useCallback((date: Date, value?: string | null, name?: string | undefined) => {
        const d = startOfDay(date);
        if (name) {
            update({ [name]: d });
            // appDispatch({ type: name, payload: d });
        }
    }, []);

    const onChangeMax = useCallback((date: Date, value?: string | null, name?: string | undefined) => {
        const d = endOfDay(date);
        if (name) {
            update({ [name]: d });
            // name && appDispatch({ type: name, payload: d });
        }
    }, []);

    const onTypeChange = (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const t = checked ? 'dueDate' : 'date';
        appDispatch({
            type: AppFilterActions.filterBulk,
            payload:
                t === 'date'
                    ? { ...state, [AppFilterActions.startDueDate]: undefined, [AppFilterActions.endDueDate]: undefined }
                    : t === 'dueDate'
                    ? { ...state, [AppFilterActions.startInvDate]: undefined, [AppFilterActions.endInvDate]: undefined }
                    : {}
        });
        setType(t);
    };

    const search = () => {
        appDispatch({
            type: AppFilterActions.filterBulk,
            payload: { ...state, amountType: state.amountType?.value }
        });
    };

    const clear = () => {
        update({
            [AppFilterActions.startInvDate]: undefined,
            [AppFilterActions.endInvDate]: undefined,
            [AppFilterActions.startDueDate]: undefined,
            [AppFilterActions.endDueDate]: undefined,
            [AppFilterActions.minInv]: undefined,
            [AppFilterActions.maxInv]: undefined,
            [AppFilterActions.amountType]: amountTypes.find((o) => o.value === Models.AmountType.CURRENT_AMOUNT)
        });
        appDispatch({
            type: AppFilterActions.filterBulk,
            payload: {
                [AppFilterActions.startInvDate]: undefined,
                [AppFilterActions.endInvDate]: undefined,
                [AppFilterActions.startDueDate]: undefined,
                [AppFilterActions.endDueDate]: undefined,
                [AppFilterActions.minInv]: null,
                [AppFilterActions.maxInv]: null,
                [AppFilterActions.amountType]: Models.AmountType.CURRENT_AMOUNT
            }
        });
    };

    return (
        <div className={classes.controlGrid}>
            <SingleSelectAuto<AmountOption>
                className={inputClasses.input}
                menuClasses={menuClasses}
                value={state[AppFilterActions.amountType]}
                onValChange={(evt, value) => update({ [AppFilterActions.amountType]: value })}
                options={amountTypes}
                getText={(o) => o.text || ''}
                getValue={(o) => o.value}
                label="Invoice Amount Type"
                variant="filled"
            />
            <Box color={theme.palette.common.white} display="flex" alignItems="center" justifyContent="center">
                <Typography variant="overline" color="inherit">
                    Invoice:&nbsp;
                </Typography>
                <Typography variant="overline" color="inherit">
                    Date
                </Typography>
                <Box height="0px" display="flex" alignItems="center">
                    <StyledSwitch checked={type === 'dueDate'} onChange={onTypeChange} name="checkedC" />
                </Box>
                <Typography variant="overline" color="inherit">
                    Due Date
                </Typography>
            </Box>
            <div />
            <TextField
                className={inputClasses.input}
                value={state[AppFilterActions.minInv] || ''}
                variant="filled"
                type="number"
                label="Minimum Amount"
                name={AppFilterActions.minInv}
                onChange={(evt) => update({ [AppFilterActions.minInv]: evt.target.value })}
            />
            {type === 'date' ? (
                <DateSelect
                    inputVariant="filled"
                    className={inputClasses.input}
                    label="Date Starting"
                    name={AppFilterActions.startInvDate}
                    onDateChange={onChangeMin}
                    value={state[AppFilterActions.startInvDate] || null}
                    disabled={appState.disabledFilters.startInvDate}
                />
            ) : type === 'dueDate' ? (
                <DateSelect
                    inputVariant="filled"
                    className={inputClasses.input}
                    label="Due Date Starting"
                    name={AppFilterActions.startDueDate}
                    onDateChange={onChangeMin}
                    value={state[AppFilterActions.startDueDate] || null}
                    disabled={appState.disabledFilters.startDueDate}
                />
            ) : null}
            <div className={classes.buttonWrapper}>
                <Button variant="outlined" color="inherit" onClick={search}>
                    Search
                </Button>
            </div>
            <TextField
                className={inputClasses.input}
                value={state[AppFilterActions.maxInv] || ''}
                variant="filled"
                type="number"
                label="Maximum Amount"
                name={AppFilterActions.maxInv}
                onChange={(evt) => update({ [AppFilterActions.maxInv]: evt.target.value })}
            />

            {type === 'date' ? (
                <DateSelect
                    inputVariant="filled"
                    className={inputClasses.input}
                    label="Date Ending"
                    name={AppFilterActions.endInvDate}
                    onDateChange={onChangeMax}
                    value={state[AppFilterActions.endInvDate] || null}
                    disabled={appState.disabledFilters.endInvDate}
                />
            ) : type === 'dueDate' ? (
                <DateSelect
                    inputVariant="filled"
                    className={inputClasses.input}
                    label="Due Date Ending"
                    name={AppFilterActions.endDueDate}
                    onDateChange={onChangeMax}
                    value={state[AppFilterActions.endDueDate] || null}
                    disabled={appState.disabledFilters.endDueDate}
                />
            ) : null}

            <div className={classes.buttonWrapper}>
                <Button fullWidth variant="outlined" color="inherit" onClick={clear}>
                    Reset
                </Button>
            </div>
        </div>
    );
};

const StyledSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.secondary.main
    },
    checked: {},
    track: {
        backgroundColor: theme.palette.secondary.main
    }
}))(Switch);
