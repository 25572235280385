import React, { useEffect, useRef } from 'react';
import { useAppDispatch } from '../contexts/app.context';
import { useCurrentUser, useUserState } from '../contexts/user.context';

import socketIOClient from 'socket.io-client';
import { useConnectToMessages, useSetMessageRead } from '../hooks/stiapi.hook';
import { AppActions } from '../types/contexts/app-context.type';
import { Models } from 'shipmenttrackers-domain/dist';
import { useSnackbar } from 'notistack';
import { Button, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    snackButton: {
        color: theme.palette.common.white
    }
}));

export const MessageListener: React.FC = () => {
    const userState = useUserState();
    const appDispatch = useAppDispatch();
    const { connectToMessages } = useConnectToMessages();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const { setMessageRead } = useSetMessageRead();
    const classes = useStyles();
    const location = useLocation();

    const [user] = useCurrentUser();

    const socketRef = useRef<SocketIOClient.Socket>();

    const started = useRef(false);

    useEffect(() => {
        if (location.pathname.includes('/pdf/')) return;
        if (!socketRef.current) {
            socketRef.current = socketIOClient(`${process.env.REACT_APP_SOCKET}`);
        }

        if (process.env.NODE_ENV !== 'production') {
            socketRef.current.on('disconnect', () => {
                console.log('lose connection');
            });
        }

        const beforeunload = (ev?: BeforeUnloadEvent) => {
            // ev?.preventDefault();
            socketRef.current!.emit('userDisconnect', user);
            socketRef.current && socketRef.current.disconnect();
            if (ev) ev.returnValue = false;
            return; // do socketRef.current things
        };
        window.addEventListener('beforeunload', beforeunload);
        return () => {
            beforeunload();
            window.removeEventListener('beforeunload', beforeunload);
        };
    }, [location.pathname, socketRef, user]);

    useEffect(() => {
        if (location.pathname.includes('/pdf/')) return;
        if (!socketRef.current) {
            socketRef.current = socketIOClient(`${process.env.REACT_APP_SOCKET}`);
        }
        //const socketRef.current = socketRef.current;
        if (user && appDispatch && !started.current) {
            const listener = (data: Partial<Models.User>) => {
                appDispatch({ type: AppActions.loggedInUsers, payload: data });
                // console.log('Chat Data: ' + data);
            };
            socketRef.current.on('chat', listener);
            socketRef.current.emit('chat', user);
            started.current = true;
            // console.log('listeners: ');
            // console.log(socketRef.current.listeners);
        }
    }, [userState, appDispatch, socketRef, started, user, location.pathname]);

    useEffect(() => {
        if (location.pathname.includes('/pdf/')) return;
        if (user && appDispatch) {
            // console.log('NOTIFICATIONS ');
            const markRead = async (m: Models.Messages) => {
                const res = await setMessageRead(m.rowID);
                if (res.status) {
                    appDispatch({ type: AppActions.updateMessages, payload: [{ ...m, seen: true }] });
                }
            };

            const goToInvoice = (m: Models.Messages) => (snackKey: React.ReactText) => async () => {
                await markRead(m);
                closeSnackbar(snackKey);
                history.push(`/audit/${m.carrierInvoiceID}`);
            };

            (async () => {
                if (!socketRef.current) {
                    socketRef.current = socketIOClient(`${process.env.REACT_APP_SOCKET}`);
                }
                //const socketRef.current = socketRef.current;
                socketRef.current.on(`notifications-${user?.userID}`, (data: Models.Messages[]) => {
                    if (data.length === 0) return;
                    if (data.length === undefined || data.length === 1) {
                        const msg = Array.isArray(data) ? data[0] : data;
                        const str = msg.content;
                        let display = str;
                        if (str?.length > 23) {
                            display = str.substring(0, 20) + '...';
                        }
                        appDispatch({ type: AppActions.newMessages, payload: Array.isArray(data) ? data : [data] });
                        const k = enqueueSnackbar(display, {
                            variant: 'info',
                            autoHideDuration: 10000,
                            action: (key) => (
                                <>
                                    <Button className={classes.snackButton} onClick={goToInvoice(data as any)(key)}>
                                        View
                                    </Button>
                                    <Button className={classes.snackButton} onClick={() => closeSnackbar(key)}>
                                        Dismiss
                                    </Button>
                                </>
                            ),
                            onClick: () => closeSnackbar(k)
                        });
                    } else {
                        appDispatch({ type: AppActions.newMessages, payload: data });
                        const k = enqueueSnackbar(`${data.length} New Messages`, {
                            variant: 'info',
                            autoHideDuration: 10000,
                            action: (key) => (
                                <Button className={classes.snackButton} onClick={() => closeSnackbar(key)}>
                                    Dismiss
                                </Button>
                            ),
                            onClick: () => closeSnackbar(k)
                        });
                    }
                });
                try {
                    const res = await connectToMessages();
                    if (res.status === 200) {
                    }
                } catch (E) {
                    console.log(E);
                }
            })();
        }
    }, [user, appDispatch, closeSnackbar, history, enqueueSnackbar, setMessageRead, connectToMessages, classes, socketRef, location.pathname]);
    return null;
};
