import React, { useReducer, useRef, useEffect, Dispatch } from 'react';

export function useReducerWithRef<T, A>(reducer: (state: T, action: A) => T, initial: T): [T | undefined, Dispatch<A>, React.MutableRefObject<T | undefined>] {
    const [state, dispatch] = useReducer(reducer, initial);
    const stateRef = useRef<T | undefined>(initial);

    stateRef.current = state;

    return [state, dispatch, stateRef];
}
