import React, { useState, useReducer, useEffect, memo } from 'react';
import { Grid, TextField, Button, styled, TextFieldProps, Box, Typography } from '@material-ui/core';
import { UnderlineTypography, Scroller, STIModal, CheckControl } from '../../generic';
import { UserActions, useUserState, useUserDispatch, useCurrentUser } from '../../../contexts/user.context';
import { Models } from 'shipmenttrackers-domain/dist';
import { useInfoModal, MODAL_TYPE, CONFIRM_TYPES } from '../../../hooks/info-modal.hook';
import { ResetPassword } from '../modals/resetpassword.component';
import { useRefreshToken, useUpdateUser } from '../../../hooks/stiapi.hook';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';

interface IUserDetails extends Partial<Models.User> {
    [index: string]: any;
}

const detailsReducer = (state: IUserDetails, values: Partial<IUserDetails>): IUserDetails => {
    return Object.assign({}, state, values);
};

export const UserDetails: React.FC = () => {
    const userState = useUserState();
    const userDispatch = useUserDispatch();
    const [state, dispatch] = useReducer(detailsReducer, {});
    const [ConfirmationModal, runConfirmationModal] = useInfoModal();
    const { updateUser, loading } = useUpdateUser();
    const { refreshToken, loading: refreshLoading } = useRefreshToken();
    const [user] = useCurrentUser();

    const [resetOpen, setResetOpen] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            dispatch(user);
        }
    }, [user]);

    const inputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ [evt.target.name]: evt.target.value });
    };

    const logout = () => {
        userDispatch({ type: UserActions.LOGOUT });
    };

    const clear = () => {
        if (user) {
            dispatch(user);
        }
    };

    const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const resp = await runConfirmationModal({ title: 'Are you sure?', desc: 'Apply all changes to your user?', modalType: MODAL_TYPE.QUESTION });
        if (resp === CONFIRM_TYPES.YES) {
            const res = await updateUser(state);
            if (res.data.User) {
                const resToken = await refreshToken();
                if (resToken.status === 200) {
                    userDispatch({ type: UserActions.LOGIN, payload: resToken.data.Data.token });
                }
            }
        }
    };

    const canEmailChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let resp;
        if (checked === false) {
            resp = await runConfirmationModal({
                title: 'Are you sure?',
                desc: (
                    <div>
                        <Typography>Disabling this option will turn off emails generated by @mentions from the audit page.</Typography>
                        <Typography>STI employees will still be permitted to manually send emails to the associated address.</Typography>
                    </div>
                ),
                modalType: MODAL_TYPE.QUESTION
            });
        }

        if (resp === undefined || resp === CONFIRM_TYPES.YES) {
            dispatch({ canEmail: checked });
        }
    };

    return (
        <Scroller vertical>
            <STIModal open={resetOpen} onClose={() => setResetOpen(false)}>
                <ResetPassword setOpen={setResetOpen} />
            </STIModal>
            <form onSubmit={onSubmit}>
                <Container container direction="column">
                    <UnderlineTypography>My Profile</UnderlineTypography>
                    <MemoTextField value={state.firstName || ''} type="string" label="First Name" name="firstName" onChange={inputChange}></MemoTextField>
                    <MemoTextField value={state.lastName || ''} type="string" label="Last Name" name="lastName" onChange={inputChange}></MemoTextField>
                    <MemoTextField value={state.email || ''} type="email" label="Email" name="email" onChange={inputChange} disabled></MemoTextField>
                    <Box paddingLeft={2}>
                        <CheckControl onChange={canEmailChange} name="canEmail" checked={state.canEmail || false}>
                            Send Collaborate Emails
                        </CheckControl>
                    </Box>

                    <MemoTextField value={state.username || ''} type="string" label="Username" name="username" onChange={inputChange} disabled></MemoTextField>
                    <Row item container wrap="nowrap">
                        <MemoTextField disabled value={state.userID ? '1234567890' : ''} fullWidth type="password" label="Password"></MemoTextField>
                        <Button variant="outlined" onClick={() => setResetOpen(true)}>
                            Reset
                        </Button>
                    </Row>
                    <MemoTextField
                        value={state.signature ?? ''}
                        type="string"
                        label="Signature"
                        multiline
                        rowsMax={4}
                        name="signature"
                        onChange={inputChange}
                    ></MemoTextField>
                    <Row item container justify="flex-end">
                        <ExtendedButton variant="outlined" onClick={logout}>
                            Log Out
                        </ExtendedButton>
                        <ExtendedButton variant="outlined" onClick={clear}>
                            Clear Changes
                        </ExtendedButton>
                        <ExtendedButton variant="outlined" type="submit" loading={!!loading || !!refreshLoading}>
                            Save Changes
                        </ExtendedButton>
                        <ConfirmationModal />
                    </Row>
                </Container>
            </form>
        </Scroller>
    );
};

const Container = styled(Grid)(({ theme }) => ({
    '& > *': {
        marginTop: theme.spacing(1.5)
    }
}));

const Row = styled(Grid)(({ theme }) => ({
    '& > *:not(:first-child)': {
        marginLeft: theme.spacing(1)
    }
}));

const MemoTextField: React.FC<TextFieldProps> = memo((props) => {
    return <TextField {...props} />;
});
