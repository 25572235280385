import React, { useMemo } from 'react';

import ChatOutline from 'mdi-material-ui/ChatOutline';
import { useAppState } from '../../../contexts/app.context';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: theme.spacing(3)
    },
    unread: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        borderRadius: '99999px',
        top: theme.spacing(-0.75),
        right: theme.spacing(-0.75),
        position: 'absolute',
        width: theme.spacing(2),
        height: theme.spacing(2),
        textAlign: 'center',
        lineHeight: `${theme.spacing(2)}px`
    }
}));

export const MessageIcon: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const appState = useAppState();
    const classes = useStyles();

    const unread = useMemo(() => appState.messages.reduce((acc: number, m) => acc + (m.seen ? 0 : 1), 0), [appState.messages]);

    return (
        <div {...props} className={classes.root}>
            <ChatOutline color="inherit" />
            {unread ? (
                <Typography className={classes.unread} variant="caption">
                    {unread}
                </Typography>
            ) : null}
        </div>
    );
};
