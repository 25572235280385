import React, { useMemo, useState, useEffect } from 'react';
import { ISingleSelectAuto, SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { IAuditState, useAuditState, useAuditStateRef, useAuditDispatch, AuditActions } from './audit.context';
import { useSelector } from '../../../contexts/store.helpers';
import { Models } from 'shipmenttrackers-domain/dist';
import { useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { IAppState } from '../../../types/contexts/app-context.type';
import { useAppState } from '../../../contexts/app.context';
import { makeStyles } from '@material-ui/core';
import { STIModal } from '../../generic';
import { VoidModal } from '../modals/voidmodal.component';
import { useCurrentUser } from '../../../contexts/user.context';

const statusSelector = (state: IAuditState): [Models.CarrierInvoices | undefined] => [state.invoice];
const optionsSelector = (state: IAppState): [Models.IpInvoiceStatus[]] => [state.data.invoiceStatusOptions];

const useStyles = makeStyles((theme) => ({
    override: {
        '& .MuiFormLabel-root.Mui-disabled, & input.Mui-disabled': {
            color: theme.palette.text.primary
        }
    }
}));

export const InvoiceStatusSelect: React.FC<ISingleSelectAuto<Partial<Models.IpInvoiceStatus>>> = ({ ...props }) => {
    const classes = useStyles();
    const auditStateRef = useAuditStateRef();
    const auditDispatch = useAuditDispatch();
    const [invoice] = useSelector(useAuditState, statusSelector);
    const [statusOptions] = useSelector(useAppState, optionsSelector);
    const { updateInvoices, loading: updatingInvoices } = useUpdateInvoices();
    // const [InfoRequestModal, runRequestModal] = useInfoRequestModal();
    const [val, setVal] = useState<Partial<Models.IpInvoiceStatus>>();
    const [openVoid, setOpenVoid] = useState(false);
    // const userState = useUserState();
    const [user] = useCurrentUser();
    // useEffect(() => setVal(statusOptions.find((o) => o.invoiceStatus === initialStatus)), [initialStatus, statusOptions]);

    const options = useMemo<Partial<Models.IpInvoiceStatus>[]>(
        () => [{ invoiceStatus: 'No Status' }, ...statusOptions.sort((a, b) => ((a as any).appOrder || 0) - ((b as any).appOrder || 0))],
        [statusOptions]
    );

    const onChange = async (event: any, status?: Partial<Models.IpInvoiceStatus>) => {
        const inv = auditStateRef.current.invoice;
        if (!inv) return;
        if (status) {
            if (status.invoiceStatus === 'Void') {
                setOpenVoid(true);
            } else {
                setVal(status);
                const invoiceRes = await updateInvoices([{ carrierInvoiceID: inv.carrierInvoiceID, invoiceStatus: status.invoiceStatus }]);
                if (invoiceRes.status === 200) {
                    // invoiceRes.data.invoices[0].actions?.forEach((a) => {
                    //     if (a.userID === user?.userID) {
                    //         a.user = user as Models.User;
                    //     }
                    // });
                    auditDispatch({ type: AuditActions.invoice, payload: invoiceRes.data.invoices[0] });
                }
            }
        }
    };

    useEffect(() => {
        setVal(options.find((o) => o.invoiceStatus === invoice?.invoiceStatus));
    }, [setVal, options, invoice]);

    return (
        <>
            {/* <InfoRequestModal /> */}
            <STIModal open={openVoid} onClose={() => setOpenVoid(false)}>
                <VoidModal invoice={invoice} close={() => setOpenVoid(false)} />
            </STIModal>
            <SingleSelectAuto
                disabled={!!updatingInvoices}
                {...props}
                className={`${props.className} ${classes.override}`}
                value={val || options[0]}
                options={statusOptions}
                // nullString="No Status"
                loading={!!updatingInvoices}
                onValChange={onChange}
                getText={(s) => s.invoiceStatus || ''}
                getSubtext={(s) => s.invoiceStatusDescription || ''}
                getValue={(s) => s.invoiceStatus}
            />
        </>
    );
};
