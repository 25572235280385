import { makeStyles, fade } from '@material-ui/core';

export const useGLStyles = makeStyles((theme) => ({
    container: {
        flexShrink: 1,
        flexGrow: 1,
        minHeight: '0px',
        '& *::-webkit-scrollbar-thumb': {
            backgroundColor: fade(theme.palette.common.white, 0.5)
        },
        '& > *': {
            flexShrink: 0
        }
    },
    glScrollArea: {
        flexShrink: 1,
        minHeight: '0px'
    },
    input: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        overflow: 'hidden',
        '& > *': {
            margin: `0px ${theme.spacing(1)}px`,
            display: 'block'
        },
        '& > .MuiAutocomplete-root > .MuiInputBase-root': {
            padding: '6px 0 7px',
            paddingRight: '0px !important'
        }
    }
}));
