import React, { useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { UserDetails, IUserDetails } from './manageuserdetails.component';
import { useManageDispatch, ManageActions, IManageState, useManageState } from '../manage.context';
import { useStateWithRef } from '../../../../hooks/statewithref.hook';
import { Models } from 'shipmenttrackers-domain/dist';
import { useSelector } from '../../../../contexts/store.helpers';
import { useUpdateUser } from '../../../../hooks/stiapi.hook';
import { useState } from 'react';
import { useInfoModal, MODAL_TYPE } from '../../../../hooks/info-modal.hook';

interface IUserInfo {
    submitRef?: React.RefObject<HTMLButtonElement>;
}

type IUserSelector = [Models.User | undefined];
const selector = (state: IManageState): IUserSelector => [state.userToManage];

export const UserDetailsControl: React.FC<IUserInfo> = ({ submitRef }) => {
    const manageDispatch = useManageDispatch();
    const [, /*updateUser*/ setUpdateUser, updateUserRef] = useStateWithRef<IUserDetails>({});
    const { updateUser } = useUpdateUser();
    const [error, setError] = useState('');
    const [ConfirmationModal, runConfirmation] = useInfoModal();
    const [user] = useSelector(useManageState, selector);

    useEffect(
        () => () => {
            const toStore = { ...updateUserRef.current, password: '', confirmEmail: '', confirmPassword: '' };
            manageDispatch({ type: ManageActions.updateUser, payload: toStore, storeOnly: true });
        },
        [manageDispatch, updateUserRef]
    );

    const somethingChanged = useCallback(
        (updateUser) => {
            setUpdateUser(updateUser);
        },
        [setUpdateUser]
    );

    const onSubmit = useCallback(
        (evt: React.FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            if (updateUserRef.current) {
                (async () => {
                    const { confirmEmail, confirmPassword, manager, approvalLimit, approvalLimitString, ...req } = updateUserRef.current;

                    req.managerUserID = manager?.userID;
                    req.approvalLimit =
                        parseFloat(approvalLimitString?.replace('$', '').replace(',', '').replace('€', '').replace('CAD', '').trim() ?? '0') || null;
                    const res = await updateUser(req);
                    if (res.status === 201) {
                        manageDispatch({ type: ManageActions.updateUser, payload: {} });
                        manageDispatch({ type: ManageActions.userToManage, payload: res.data.User });
                        manageDispatch({ type: ManageActions.updateUserList, payload: res.data.User });
                        await runConfirmation({ title: 'Success', yesTxt: 'OK', desc: 'User successfully updated', no: false, modalType: MODAL_TYPE.INFO });
                    } else {
                        setError((res as any)?.response?.data?.Message || 'There was a problem updating this user.');
                        setTimeout(() => setError(''), 10000);
                    }
                })();
            }
        },
        [updateUserRef, updateUser, manageDispatch, runConfirmation]
    );

    return (
        <form onSubmit={onSubmit} autoComplete="off" style={{ height: '100%', width: '100%' }}>
            <ConfirmationModal />
            <UserDetails mode="edit" user={user} requireUser onSomethingChanged={somethingChanged} requireEmail={false} requirePassword={false} error={error} />
            <Box display="none">
                <button ref={submitRef} type="submit" />
            </Box>
        </form>
    );
};
