import React from 'react';
import { styled, Grid, Box } from '@material-ui/core';
import { ModeSelect } from './modeselect.component';
import { InvoiceStatusSelect } from './invoicestatusselect.component';
import { AuditStatusSelect } from './auditstatusselect.component';
import { LoggedOnUsers } from './loggedon.component';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { useAppState } from '../../../contexts/app.context';

export const Footer: React.FC = () => {
    const checkPermission = usePermission();
    const appState = useAppState();
    // const appDispatch = useAppDispatch();

    return (
        <FooterContainer container alignItems="center">
            <Box flexBasis={0} flexGrow={1}>
                <img src={`${process.env.PUBLIC_URL}/img/sti_logo.png`} alt="sti" height="48px" />
            </Box>
            <Box flexBasis={0} flexGrow={0} display="flex" justifyContent="center">
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Box width="160px">
                            <ModeSelect />
                        </Box>
                    </Grid>
                    {(checkPermission() || appState.homeTableMode === 'default') && (
                        <>
                            <Grid item>
                                <Box width="160px">
                                    <InvoiceStatusSelect />
                                </Box>
                            </Grid>
                        </>
                    )}
                    {checkPermission() && (
                        <Grid item>
                            <Box width="160px">
                                <AuditStatusSelect />
                            </Box>
                        </Grid>
                    )}
                    {/* <Grid item>
                        <CheckControl
                            title="Audited"
                            checked={appState.filter.audited === false ? true : false}
                            onChange={(evt, checked) => appDispatch({ type: AppFilterActions.audited, payload: checked ? false : undefined })}
                            color="#FFFFFF"
                        >
                            Unaudited Only
                        </CheckControl>
                    </Grid> */}
                </Grid>
            </Box>
            <Box flexBasis={0} flexGrow={1} display="flex" justifyContent="flex-end" paddingRight={1}>
                <LoggedOnUsers />
            </Box>
        </FooterContainer>
    );
};

const FooterContainer = styled(Grid)(({ theme }) => ({
    background: theme.palette.common.black,
    color: theme.palette.grey[600],
    padding: theme.spacing(1)
}));
