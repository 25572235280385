import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DataTable, DataTableInstance } from '../../datatable/datatablevirtual.component';
import { useInvoiceState, useInvoiceDispatch, useInvoiceStateRef, useGetInvoiceFromState } from '../../../contexts/invoice.context';
import { IColumn, IRow, IDataTableState } from '../../datatable/datatable.types';
import { useTableClasses } from './common';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { useHistory } from 'react-router-dom';
import { Models } from 'shipmenttrackers-domain/dist';
import { AppActions } from '../../../types/contexts/app-context.type';
import { useAppState, useAppDispatch, useAppStateRef } from '../../../contexts/app.context';
import { InvoiceActions } from '../../../types/contexts/invoice-context.type';
import { tableHeaderMap } from '../../../helpers/mapping';
import { date, SIMPLE_FORMAT } from '../../../helpers/pipes';
import { Box } from '@material-ui/core';
import { STILoading } from '../../generic';

import { FailureObject, useGetInvoiceRemittance, useGetInvoiceRemittances, useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { CONFIRM_TYPES, useInfoModal } from '../../../hooks/info-modal.hook';
import {
    ApproveCheck,
    BillingEntityComponent,
    CImg,
    DisburseInput,
    DisputeCheck,
    EntityComponent,
    getBillingEntity,
    getEntityName,
    InvoiceButton,
    InvoiceColumns,
    CurrencyAmount,
    PDFButton,
    ReceiveInput,
    TotalToDisburse,
    TotalToReceive,
    DisburseCheck,
    ReceiveCheck
} from './columncomponents';
import { toCurrencyDecimal } from '../../../helpers';
import { ApprovalFailed } from '../modals/approveallfailed.component';
import { DisburseAllFailed, DisburseFailureTypes } from '../modals/disburseallfailed';
import { SearchType } from '../../../types/contexts/app-context.type';
import { HomeTableColumnWarning } from './hometablecolumnwarning.component';

export const Component: React.FC = () => {
    const tableClasses = useTableClasses();
    const invoiceState = useInvoiceState();
    const invoiceStateRef = useInvoiceStateRef();
    const menuClasses = useMenuStyles();
    const history = useHistory();
    const invoiceDispatch = useInvoiceDispatch();
    const appDispatch = useAppDispatch();
    const appStateRef = useAppStateRef();
    const { updateInvoices, loading } = useUpdateInvoices();

    const appState = useAppState();
    const tableState = appState.dataTableState;
    const tableDef = appState.data.tableDefs[appState.homeTableMode];
    const [ConfirmationModal, runConfirmation] = useInfoModal();
    const checkPermission = usePermission();
    const { getInvoiceRemittance } = useGetInvoiceRemittance();
    const { getInvoiceRemittances } = useGetInvoiceRemittances();

    const handleSelectAllClick = useCallback(
        (prop: string, checked: boolean) => async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            const dispurseFailure: FailureObject<DisburseFailureTypes> = {};
            const filteredInvoices = invoiceStateRef.current.invoices.filter((inv) => {
                if (!checkPermission(prop, inv)) return false;
                if ((inv.updates[prop] ?? inv[prop]) === checked) return false;
                if (inv.invoiceStatus === 'Client Payable') return false;
                if (inv.invoiceStatus === 'Client Paid' && prop !== 'receive') return false;
                if (prop === 'disburse') {
                    let fails = false;
                    if (inv.invoiceStatus === 'Void') {
                        dispurseFailure[inv.invoiceNumber] = { voided: true };
                        fails = true;
                    }
                    if (inv.dispute) {
                        dispurseFailure[inv.invoiceNumber] = { disputed: true };
                        fails = true;
                    }
                    return !fails;
                }
                return true;
            });

            let updates: Partial<Models.CarrierInvoices>[] = [];
            if (prop === 'receive' && checked && appStateRef.current.searchType === SearchType.remittance) {
                const remittances = (
                    await getInvoiceRemittances({
                        carrierInvoiceIds: filteredInvoices.map((inv) => inv.carrierInvoiceID),
                        remittanceNumbers: appStateRef.current.filter.remittanceNumbers.map((r) => parseInt(r))
                    })
                ).data;
                if (remittances?.length) {
                    const remittanceMap = remittances.reduce((acc: Record<string, number>, r) => {
                        if (!acc[r.carrierInvoiceID]) acc[r.carrierInvoiceID] = 0;
                        acc[r.carrierInvoiceID] += r.requestedAmount;
                        return acc;
                    }, {});
                    updates = Object.entries(remittanceMap).map(([id, amount]) => ({
                        carrierInvoiceID: id,
                        receive: true,
                        invoiceToReceive: toCurrencyDecimal(amount)
                    }));
                } else {
                    runConfirmation({
                        title: 'Unable to fetch Remittance',
                        desc: 'There was an issue fetching remittance information for one or more invoices.',
                        no: false,
                        yesTxt: 'OK'
                    });
                }
            } else {
                updates = filteredInvoices.map((inv) => ({ carrierInvoiceID: inv.carrierInvoiceID, [prop]: checked }));
            }

            invoiceDispatch({ type: InvoiceActions.addUpdateBulk, payload: updates });
            if (Object.keys(dispurseFailure).length) {
                runConfirmation({
                    title: 'Cannot disburse some invoices',
                    desc: (
                        <>
                            <DisburseAllFailed fails={dispurseFailure} />
                        </>
                    ),
                    no: false,
                    yesTxt: 'OK'
                });
            }
        },
        [appStateRef, checkPermission, getInvoiceRemittances, invoiceDispatch, invoiceStateRef, runConfirmation]
    );

    // const handleAllocateAll = useCallback(
    //     (checked) => async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    //         const toUpdate = invoiceStateRef.current.invoices
    //             // .filter((inv) => checkPermission('approve', inv.carrierAccount))
    //             .filter((inv) => inv.allocate !== !!checked)
    //             .map((inv) => {
    //                 return { carrierInvoiceID: inv.carrierInvoiceID, allocate: checked };
    //             });
    //         if (!toUpdate.length) return;

    //         const confirmRes = await runConfirmation({
    //             title: checked ? 'Allocate All?' : 'Allocatee All?',
    //             desc: (
    //                 <>
    //                     <p>{`Are you sure you want to ${checked ? 'allocate' : 'unallocte'} all invoices?`}</p>
    //                 </>
    //             )
    //         });

    //         if (confirmRes === CONFIRM_TYPES.YES) {
    //             const res = await updateInvoices(toUpdate);
    //             if (res.status === 200) {
    //                 invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: res.data.invoices });
    //             }
    //         }
    //     },
    //     [invoiceDispatch, invoiceStateRef, runConfirmation, updateInvoices]
    // );

    const handleApproveAll = useCallback(
        (checked) => async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            let disputed = 0;
            const toUpdate = invoiceStateRef.current.invoices
                .filter((inv) => checkPermission('approve', inv))
                .filter((inv) => inv.approve !== checked)
                .map((inv) => {
                    if (inv.dispute) {
                        disputed++;
                    }
                    return { carrierInvoiceID: inv.carrierInvoiceID, approve: checked };
                });
            if (!toUpdate.length) return;
            if (disputed) {
                await runConfirmation({
                    title: 'Invoices In Dispute',
                    desc: (
                        <>
                            <p>{`${disputed} invoices are in dispute. Please resolve these first.`}</p>
                        </>
                    ),
                    no: false,
                    yesTxt: 'OK'
                });
            } else {
                const confirmRes = await runConfirmation({
                    title: checked ? 'Approve All?' : 'Unapprove All?',
                    desc: (
                        <>
                            <p>{`Are you sure you want to ${checked ? 'approve' : 'unapprove'} all invoices?`}</p>
                        </>
                    )
                });

                if (confirmRes === CONFIRM_TYPES.YES) {
                    const res = await updateInvoices(toUpdate);
                    if (res.status === 200) {
                        invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: res.data.invoices });
                        const failed = res.data.failed;
                        if (Object.entries(failed).length) {
                            const confirmRes = await runConfirmation({
                                title: 'Approve all incomplete',
                                desc: (
                                    <>
                                        <ApprovalFailed fails={failed} />
                                    </>
                                ),
                                no: false,
                                yesTxt: 'OK'
                            });
                            if (confirmRes === CONFIRM_TYPES.YES) {
                                const newState = { ...appStateRef.current.dataTableState };
                                newState.sort = [{ key: 'approve', direction: 'desc' }, ...(newState.sort ?? [])];
                                appDispatch({ type: AppActions.dataTableState, payload: newState });
                            }
                        }
                    }
                }
            }
        },
        [checkPermission, invoiceDispatch, invoiceStateRef, runConfirmation, updateInvoices, appDispatch, appStateRef]
    );

    // const displayColumns = useMemo<IColumn[]>(() => {
    useEffect(() => {
        const createColumn = (key: InvoiceColumns): IColumn => {
            const col: IColumn = {
                key,
                label: tableHeaderMap[key] || key
            };

            switch (key) {
                case 'carrierCode':
                    col.Component = CImg;
                    break;
                case 'pdf':
                    col.Component = PDFButton;
                    break;
                case 'dispute':
                    col.Component = DisputeCheck;
                    break;
                case 'approve':
                    col.menu = [
                        { text: 'Approve All', onClick: handleApproveAll(true) },
                        { text: 'Unapprove all', onClick: handleApproveAll(false) }
                    ];
                    col.Component = ApproveCheck;
                    break;
                case 'receive':
                    col.menu = [
                        { text: 'Receive All', onClick: handleSelectAllClick('receive', true) },
                        { text: 'Receive None', onClick: handleSelectAllClick('receive', false) }
                    ];
                    col.Component = ReceiveCheck;
                    break;
                // case 'allocate':
                //     col.menu = [
                //         { text: 'Allocate All', onClick: handleAllocateAll(true) },
                //         { text: 'Allocate None', onClick: handleAllocateAll(false) }
                //     ];
                //     col.Component = TableCheck;
                //     break;
                case 'disburse':
                    col.menu = [
                        { text: 'Disbure All', onClick: handleSelectAllClick('disburse', true) },
                        { text: 'Disburse None', onClick: handleSelectAllClick('disburse', false) }
                    ];
                    col.Component = DisburseCheck;
                    break;
                case 'invoiceOriginalAmt':
                case 'currentAmt':
                case 'invoiceAmtDue':
                case 'invoicePaidAmt':
                    col.Component = CurrencyAmount;
                    col.className = tableClasses.justifyRight;
                    break;
                case 'invoiceTotalReceived':
                    col.Component = TotalToReceive;
                    col.className = tableClasses.justifyRight;
                    break;
                case 'invoiceTotalDisbursed':
                    col.Component = TotalToDisburse;
                    col.className = tableClasses.justifyRight;
                    break;
                case 'invoiceToReceive':
                    col.Component = ReceiveInput;
                    break;
                case 'invoiceToDisburse':
                    col.Component = DisburseInput;
                    break;
                case 'invoiceNumber':
                    col.Component = InvoiceButton;
                    break;
                case 'invoiceDate':
                case 'invoiceDueDate':
                    col.pipe = date(SIMPLE_FORMAT);
                    break;
                case 'billingEntity':
                    col.value = (r) => getBillingEntity(appStateRef.current, r);
                    col.Component = BillingEntityComponent;
                    break;
                case 'clientID':
                case 'companyID':
                case 'divisionID':
                case 'locationID':
                    col.value = (r) => getEntityName(appStateRef.current, r, key);
                    col.Component = EntityComponent;
                    break;
                default:
                    break;
            }
            return col;
        };

        const keys = tableDef.columns;
        const others = Object.keys(tableHeaderMap)
            .filter((k) => !keys.includes(k))
            .sort((a, b) => a.localeCompare(b));

        const cols = [...keys, ...others].reduce((acc: IColumn[], key) => {
            const col = createColumn(key as InvoiceColumns);
            col.active = tableDef.columns.includes(key);
            acc.push(col);
            return acc;
        }, []);

        if (tableDef.auditStatus?.order) {
            const auditStatus = cols.find((c) => c.key === 'auditStatus');
            if (auditStatus && tableDef.auditStatus?.order.length) {
                const orderObj = tableDef.auditStatus?.order.reduce((acc: { [index: string]: number }, s, i) => {
                    acc[s] = i;
                    return acc;
                }, {});
                auditStatus.sortFunc = (a: string, b: string) => orderObj[a] - orderObj[b];
            }
        }

        if (tableDef.invoiceStatus?.order) {
            const invoiceStatus = cols.find((c) => c.key === 'invoiceStatus');
            if (invoiceStatus && tableDef.invoiceStatus?.order.length) {
                const orderObj = tableDef.invoiceStatus?.order.reduce((acc: { [index: string]: number }, s, i) => {
                    acc[s] = i;
                    return acc;
                }, {});
                invoiceStatus.sortFunc = (a: string, b: string) => orderObj[a] - orderObj[b];
            }
        }
        appDispatch({
            type: AppActions.updateDataTableState,
            payload: {
                sort: tableDef.sort,
                colWidths: Object.values(tableDef.widths || {}).find((c) => c.width !== undefined) ? tableDef.widths : undefined,
                columns: cols
            }
        });
        // return cols;
    }, [tableDef, appStateRef, handleSelectAllClick, appDispatch, handleApproveAll, tableClasses]);

    const onRowClick = useCallback(
        async (evt: any, invoice: IRow<Models.CarrierInvoices>) => {
            appDispatch({ type: AppActions.storeDataTableState });
            const name = evt.target.name || evt.target.dataset.name;
            // console.log(`target ${rowNum} ${evt.target.name} ${evt.target.checked}`);
            // console.log('currenttarget' + evt.currentTarget.value);

            // const invoice = invoiceStateRef.current.invoices[rowNum];
            switch (name) {
                case 'pdf':
                    window.open(`${window.location.origin}/pdf/${invoice.carrierCode}/${invoice.invoiceNumber}`, 'STI: PDF', 'height=960,width=960');
                    break;
                case 'invoice':
                    const click = evt as React.MouseEvent<HTMLButtonElement, MouseEvent>;
                    if (click.altKey) {
                        evt.preventDefault();
                        navigator.clipboard.writeText(invoice.invoiceNumber).then(
                            () => {},
                            () => alert('There was a problem copying automatically.')
                        );
                    }

                    break;
                case 'receive':
                    const { checked } = evt.target;
                    if (appStateRef.current.searchType === SearchType.remittance && checked) {
                        const amount = (
                            await getInvoiceRemittance(
                                invoice.carrierInvoiceID,
                                appStateRef.current.filter.remittanceNumbers.map((r) => parseInt(r))
                            )
                        ).data.reduce((acc, r) => acc + r.requestedAmount, 0);
                        invoiceDispatch({
                            type: InvoiceActions.addUpdateBulk,
                            payload: [{ carrierInvoiceID: invoice.carrierInvoiceID, receive: checked, invoiceToReceive: toCurrencyDecimal(amount) }]
                        });
                    } else {
                        invoiceDispatch({ type: InvoiceActions.addUpdate, id: invoice.carrierInvoiceID, prop: 'receive', payload: checked });
                    }
                    break;
                case 'disburse':
                    invoiceDispatch({ type: InvoiceActions.addUpdate, id: invoice.carrierInvoiceID, prop: evt.target.name, payload: evt.target.checked });
                    break;
                default:
                    break;
            }
        },
        [appDispatch, appStateRef, invoiceDispatch, getInvoiceRemittance]
    );

    const onRowDataUpdate = useCallback(
        (data: IRow<Models.CarrierInvoices>[]) => {
            invoiceDispatch({ type: InvoiceActions.invoices, payload: data });
        },
        [invoiceDispatch]
    );

    const [hasActiveColumns, setHasActiveColumns] = useState(() => tableState.columns?.some((c) => c.active));

    const onDataTableStateChange = useCallback(
        (state: IDataTableState) => {
            setHasActiveColumns(state.columns?.some((c) => c.active));
            appDispatch({ type: AppActions.dataTableState, payload: state, storeOnly: true });
        },
        [appDispatch]
    );

    const instanceRef = useRef<DataTableInstance>();

    return (
        <Box position="relative" width="100%" height="100%" className={tableClasses.overrides}>
            <STILoading loading={invoiceState.loading || !!loading} />
            <ConfirmationModal />
            <DataTable
                key={tableDef.title}
                onRowClick={onRowClick}
                headerRowHeight={32}
                bodyRowHeight={50}
                classes={tableClasses}
                menuClasses={menuClasses}
                rows={invoiceState.invoices}
                // columns={t}
                defaultColWidths={tableDef.widths}
                onRowsDataUpdate={onRowDataUpdate}
                onStateChange={onDataTableStateChange}
                initialState={tableState}
                useGetRowData={useGetInvoiceFromState}
                instanceRef={instanceRef}
            />

            {!hasActiveColumns ? (
                <Box position="absolute" top="0px" left="0px" width="100%" height="100%">
                    <HomeTableColumnWarning tableInstance={instanceRef} />
                </Box>
            ) : null}
        </Box>
    );
};

export const HomeTable = memo(Component);
