import React, { useState, memo } from 'react';
import { Grid, styled, fade, Hidden, Box, IconButton, useTheme, Theme, Typography } from '@material-ui/core';
import { STIButtonbase } from '../../generic/display/stibuttonbase.component';

import CardAccountDetailsOutline from 'mdi-material-ui/CardAccountDetailsOutline';
import ShieldAccountOutline from 'mdi-material-ui/ShieldAccountOutline';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import AccountGroupOutline from 'mdi-material-ui/AccountGroupOutline';
import Incognito from 'mdi-material-ui/Incognito';
import { Slideout, ISlideoutId } from '../../generic/containers/slideout.component';
import { Panel } from '../../generic/containers/panel.component';
import { TabPanel, STITooltip } from '../../generic';
import { UserDetails } from '../settings/userdetails.component';
import { UserPermissions } from '../settings/userpermissions.component';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { AppFilterActions, SearchType } from '../../../types/contexts/app-context.type';
import { CleanLink } from '../../generic/display/cleanlink.component';
import { SearchOption } from './searchoption.component';
import { DrawerMenu } from './drawermenu.component';
import { MessageIcon } from './messageicon.component';
import { DrawerMessages } from './drawermessages.component';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { useUserState } from '../../../contexts/user.context';
import Icon from '@mdi/react';
import { mdiLink, mdiLinkOff } from '@mdi/js';
import { ExportButton } from './export.component';
import { UserAlias } from '../settings/useralias.component';

export const Component: React.FC = () => {
    const [openMenu, setOpenMenu] = useState<ISlideoutId>();
    const [openMessages, setOpenmessages] = useState(false);
    const checkPermission = usePermission();
    const userState = useUserState();
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const theme = useTheme();

    // const openModal = (type: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //     appDispatch({ type, payload: true });
    // };

    const handleLink = () => {
        appDispatch({ type: AppFilterActions.linkIds, payload: !appState.filter.linkIds });
    };

    const alias = userState.alias?.user;

    return (
        <HeaderContainer container className={alias ? 'aliased' : undefined}>
            <DrawerMessages open={openMessages} onClose={() => setOpenmessages(false)} />
            <FilterToggleContainer color="inherit" item>
                <DrawerMenu />
            </FilterToggleContainer>
            <Hidden mdDown>
                <FilterSelectionContainer color="inherit" item>
                    <SearchOption option={SearchType.division}>Client/Carrier</SearchOption>
                    <SearchOption option={SearchType.dateAmt}>Date/Amount</SearchOption>
                    <IconButton onClick={handleLink} color="inherit">
                        <Icon path={appState.filter.linkIds ? mdiLink : mdiLinkOff} size={1} color="currentColor" />
                    </IconButton>
                    <SearchOption option={SearchType.invoice}>Invoice Numbers</SearchOption>
                    {checkPermission() && (
                        <>
                            <VL className="line" />
                            <SearchOption option={SearchType.remittance}>Remittance</SearchOption>
                            <SearchOption option={SearchType.disbursement}>Disbursement</SearchOption>
                            <SearchOption option={SearchType.paymentConfirmation}>Payment Confirmation</SearchOption>
                        </>
                    )}
                </FilterSelectionContainer>
            </Hidden>

            <MenuContainer>
                {alias ? (
                    <Typography color="inherit" variant="button">
                        {`Viewing as ${alias.firstName} ${alias.lastName}`}
                    </Typography>
                ) : null}
                {/* <Slideout
                    trigger={<TableArrowRight />}
                    open={openMenu === 'export'}
                    onOpen={setOpenMenu}
                    slideId="export"
                    placement="bottom-end"
                    tooltipText="Export"
                    tooltipPlacement="bottom"
                    transformOrigin="100% 0 0"
                    color="inherit"
                >
                    <Panel>
                        <Button onClick={handleExport}>Export</Button>
                    </Panel>
                </Slideout> */}
                <ExportButton />
                <STITooltip title="Messages">
                    <STIButtonbase color="inherit">
                        <MessageIcon onClick={() => setOpenmessages(true)} />
                    </STIButtonbase>
                </STITooltip>

                {checkPermission() && (
                    <STITooltip title="Admin">
                        <STIButtonbase color="inherit">
                            <Box marginBottom="-5px">
                                <CleanLink to="/users">
                                    <Box padding={1} margin={-1}>
                                        <AccountGroupOutline />
                                    </Box>
                                </CleanLink>
                            </Box>
                        </STIButtonbase>
                    </STITooltip>
                )}

                <Slideout
                    open={openMenu === 'user'}
                    onOpen={setOpenMenu}
                    slideId="user"
                    trigger={userState.alias?.user ? <Incognito /> : <AccountOutline />}
                    placement="bottom-end"
                    tooltipText={
                        <div>
                            <div>Settings</div>
                            {userState.alias?.user ? <div>{`Currently logged in as ${alias?.firstName} ${alias?.lastName}`}</div> : null}
                        </div>
                    }
                    tooltipPlacement="bottom"
                    transformOrigin="100% 0 0"
                    color="inherit"
                    style={{ backgroundColor: userState.alias?.user ? theme.palette.error.main : undefined }}
                >
                    <SettingsPanel>
                        <TabPanel
                            labels={[
                                <STITooltip title="User Details">
                                    <CardAccountDetailsOutline />
                                </STITooltip>,
                                <STITooltip title="User Permissions">
                                    <ShieldAccountOutline />
                                </STITooltip>,
                                ...(checkPermission(undefined, undefined, true)
                                    ? [
                                          <STITooltip title="Use App As">
                                              <Incognito />
                                          </STITooltip>
                                      ]
                                    : [])
                            ]}
                        >
                            <UserDetails />
                            <UserPermissions />
                            {checkPermission(undefined, undefined, true) ? <UserAlias /> : null}
                        </TabPanel>
                    </SettingsPanel>
                </Slideout>

                {/* <STIButtonbase color="inherit" onClick={openModal(AppActions.showCart)}>
                    <Cart color="inherit" />
                </STIButtonbase> */}
                {/* <STIButtonbase color="inherit" onClick={openModal(AppActions.showInvConf)}>
                    <CurrencyUsd color="inherit" />
                </STIButtonbase> */}
            </MenuContainer>
        </HeaderContainer>
    );
};

export const Header = memo(Component);

const VL = styled('div')(({ theme }) => ({
    width: '1px',
    height: '100%',
    background: fade(theme.palette.common.white, 0.25),
    alignSelf: 'center'
}));

const HeaderContainer = styled(Grid)<Theme, { aliased?: boolean }>(({ theme, aliased }) => ({
    background: theme.palette.primary.main,
    color: fade(theme.palette.common.white, 0.87),
    '&.aliased': {
        background: theme.palette.error.main
    }
}));

const FilterToggleContainer = styled(Grid)(({ theme }) => ({
    '& > *': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

const FilterSelectionContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    '& > *:not(.line)': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
        // '&:not(:first-child)': {
        //     borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.25)}`
        // }
    }
}));

const MenuContainer = styled(Grid)(({ theme }) => ({
    marginLeft: 'auto',
    color: theme.palette.common.white,
    '& > *': {
        height: '100%',
        padding: theme.spacing(1),
        '&:not(:first-child)': {
            borderLeft: `1px solid ${fade(theme.palette.common.white, 0.25)}`
        }
    }
}));

const SettingsPanel = styled(Panel)(({ theme }) => ({
    maxHeight: theme.spacing(90),
    overflow: 'hidden',
    '& > *': {
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    border: `1px solid ${theme.palette.text.primary}AA`
}));
