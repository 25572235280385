import { Button, fade, makeStyles, Typography } from '@material-ui/core';
import { mdiHistory } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { Models } from '../../../../../shipmenttrackers-domain/dist';
import { currency, date, DATE_TIME } from '../../../helpers/pipes';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { useGetGLHistory } from '../../../hooks/stiapi.hook';
import { Permission } from '../../../types/contexts/user-context.type';
import { IIdx } from '../../../types/general.type';
import { GridMin, STIModal } from '../../generic';
import { ExtendedIconButton } from '../../generic/controls/extendediconbutton.component';
import { ModalContainer, Header, BodyContainer, ButtonContainer } from './common.component';

interface GLHistoryProps {
    invoice: Models.CarrierInvoices;
}

interface GLHistory {
    maxGL?: number;
    entries?: {
        date?: Date;
        user?: Models.User;
        rows: Models.GLCodingHistory[];
        total: number;
    }[];
}

const useStyles = makeStyles((theme) => ({
    body: {
        flexWrap: 'nowrap'
    },
    gridWrapper: {
        flex: 1,
        overflow: 'auto'
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(var(--colCount), 1fr)',
        '& > *': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        justifyContent: 'stretch',
        alignItems: 'stretch',
        paddingRight: theme.spacing(0.25)
    },
    titleRow: {
        gridColumn: '1 / calc(var(--colCount) + 1)',
        display: 'flex',
        paddingBottom: theme.spacing(0.5)
    },
    totalRow: {
        paddingTop: theme.spacing(0.5),
        gridColumn: '1 / calc(var(--colCount) + 1)',
        textAlign: 'right'
    },
    space: {
        paddingTop: theme.spacing(2)
    },
    historyEntry: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    cellWrapper: {
        padding: theme.spacing(0.5)
    },
    rowStart: {
        paddingLeft: theme.spacing(1)
    },
    rowEnd: {
        paddingRight: theme.spacing(1)
    },
    sectionStart: {
        paddingTop: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1)
    },
    sectionEnd: {
        paddingBottom: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1)
    },
    margin: {
        gridColumn: '1 / calc(var(--colCount) + 1)',
        padding: theme.spacing(1),
        background: theme.palette.common.white
    },
    cell: {
        background: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        color: 'black',
        padding: theme.spacing(0.75),
        width: '100%',
        height: '100%',
        minWidth: theme.spacing(8)
    },
    headerItem: {
        color: fade(theme.palette.common.white, 0.5),
        borderColor: 'currentColor',
        borderStyle: 'solid',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        textAlign: 'center'
    }
}));

export const GLHistoryModal: React.FC<GLHistoryProps> = ({ invoice }) => {
    const { carrierInvoiceID } = invoice;
    const classes = useStyles();
    const [history, setHistory] = useState<GLHistory>({});
    const { getGLHistory, loading } = useGetGLHistory();
    const [open, setOpen] = useState(false);
    const checkPermission = usePermission();
    const denomination = invoice.currency;

    useEffect(() => {
        (async () => {
            if (!open || !carrierInvoiceID) return;

            const res = await getGLHistory(carrierInvoiceID);
            let max = 3;
            const entries: { [index: string]: Models.GLCodingHistory[] } = {};

            res.data?.forEach((h) => {
                const k = h.createdAt.toString();
                if (!entries[k]) {
                    entries[k] = [];
                }
                entries[k].push(h);
                for (let i = 10; i > 0; i--) {
                    if ((h as IIdx)[`gl${i}`] !== null) {
                        max = Math.max(max, i);
                        break;
                    }
                }
            });

            setHistory({
                maxGL: max,
                entries: Object.values(entries)
                    .map((e) => ({
                        user: (e[0] as any).user,
                        date: e[0].createdAt,
                        rows: e,
                        total: e.reduce((acc: number, item) => {
                            acc += item.glAmount;
                            return acc;
                        }, 0)
                    }))
                    .reverse()
            });
        })();
    }, [open, carrierInvoiceID, setHistory, getGLHistory]);

    if (!checkPermission(Permission.viewGlCodes)) return null;

    const close = () => setOpen(false);
    return (
        <>
            <ExtendedIconButton loading={!!loading} title="GL History" onClick={() => setOpen(true)}>
                <Icon path={mdiHistory} size={1} />
            </ExtendedIconButton>
            <STIModal open={open && !loading} onClose={close}>
                <ModalContainer>
                    <GridMin>
                        <Header variant="subtitle1">GL History</Header>
                    </GridMin>
                    <BodyContainer container direction="column" className={classes.body}>
                        <div className={classes.gridWrapper}>
                            {history.entries?.length ? (
                                <div className={classes.grid} style={{ '--colCount': (history.maxGL ?? 3) + 2 } as any}>
                                    {history.entries?.map((h, i) => (
                                        <>
                                            {i > 0 && <div className={classes.margin} />}
                                            <div className={`${classes.titleRow} ${classes.sectionStart} ${classes.rowStart} ${classes.rowEnd}`}>
                                                <Typography>{`${h.user?.firstName} ${h.user?.lastName} - ${date(DATE_TIME)(h.date)}`}</Typography>
                                            </div>
                                            {new Array(history.maxGL ?? 3).fill(null).map((n, i) => (
                                                <div className={`${classes.rowStart} ${classes.headerItem}`}>{`GL${i + 1}`}</div>
                                            ))}
                                            <div className={`${classes.headerItem}`}>%</div>
                                            <div className={`${classes.rowEnd} ${classes.headerItem}`}>AMOUNT</div>
                                            {h.rows.map((r) => (
                                                <>
                                                    {new Array(history.maxGL ?? 3).fill(null).map((n, i) => (
                                                        <div className={`${classes.cellWrapper} ${i === 0 ? classes.rowStart : ''}`}>
                                                            <div className={`${classes.cell}`}>{(r as IIdx)[`gl${i + 1}`]}</div>
                                                        </div>
                                                    ))}
                                                    <div className={classes.cellWrapper}>
                                                        <div className={classes.cell}>{((r.glAmount / h.total) * 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className={`${classes.cellWrapper} ${classes.rowEnd}`}>
                                                        <div className={`${classes.cell}`}>{currency(r.glAmount, denomination)}</div>
                                                    </div>
                                                </>
                                            ))}
                                            <div className={`${classes.totalRow} ${classes.sectionEnd} ${classes.rowStart} ${classes.rowEnd}`}>
                                                <Typography>{`Total: ${currency(h.total, denomination)}`}</Typography>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            ) : (
                                <Typography variant="h6">No history to show.</Typography>
                            )}
                        </div>
                        <ButtonContainer container wrap="nowrap" justify="center">
                            <Button variant="contained" color="primary" onClick={close}>
                                Close
                            </Button>
                        </ButtonContainer>
                    </BodyContainer>
                </ModalContainer>
            </STIModal>
        </>
    );
};
