import { useAuditStateRef, useAuditDispatch, AuditActions } from '../audit.context';
import { useCreateGLCodings, useDeleteGLCoding, ICreateCodingResponse } from '../../../../hooks/stiapi.hook';
import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useCurrentUser } from '../../../../contexts/user.context';

export const useSaveGLChanges = (): [() => Promise<AxiosResponse<ICreateCodingResponse> | void>, boolean, string, () => void] => {
    const auditStateRef = useAuditStateRef();
    const { createGLCodings, loading: createLoading } = useCreateGLCodings();
    const { deleteGLCoding, loading: deleteLoading } = useDeleteGLCoding();
    const auditDispatch = useAuditDispatch();
    const [error, setError] = useState('');
    // const userState = useUserState();
    const [user] = useCurrentUser();

    const handleSave = useCallback(async () => {
        if (auditStateRef.current?.invoice?.carrierInvoiceID === undefined) return;
        const deleteCodes = auditStateRef.current.glsToDelete;
        const deleteSuccess = await Promise.all(
            deleteCodes.map(async (c) => {
                if (c.glCodeID === undefined) return;
                const res = await deleteGLCoding(c.glCodeID, auditStateRef.current.invoice?.carrierAccount || '');
                return res.status === 200;
            })
        );
        const codes = auditStateRef.current.glcoding
            .filter((c) => c.updated)
            .map((c) => ({ ...c, carrierInvoiceID: auditStateRef.current.invoice!.carrierInvoiceID.toString() }));

        if (codes.length) {
            const res = await createGLCodings(codes, auditStateRef.current.invoice?.carrierAccount || '');
            if (res.status === 201) {
                auditDispatch({ type: AuditActions.setGL, payload: res.data.codings });
                if (res.data.actions) {
                    // res.data.actions.forEach((a) => (a.user = user as Models.User));
                    auditDispatch({ type: AuditActions.addAction, payload: res.data.actions });
                }
            } else {
                setError('There was a problem saving GL Codes.');
            }
        }

        auditDispatch({ type: AuditActions.clearDeletes, payload: deleteSuccess });
    }, [auditDispatch, auditStateRef, createGLCodings, deleteGLCoding]);

    const clearError = useCallback(() => {
        setError('');
    }, [setError]);

    return [handleSave, !!createLoading || !!deleteLoading, error, clearError];
};
