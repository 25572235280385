import React from 'react';
import { FormControlLabel, Checkbox, CheckboxProps, CircularProgressProps, CircularProgress, makeStyles, Box } from '@material-ui/core';
import { mdiCheckboxBlankOutline } from '@mdi/js';
import Icon, { Stack } from '@mdi/react';

const checkboxOutline = 'M19,19H5V5h10V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-8h-2s';
const check = 'M7.9,10.1l-1.4,1.4L11,16L21,6l-1.4-1.4L11,13.2L7.9,10.1z';

export interface ICheckOnly extends Omit<CheckboxProps, 'color'> {
    loading?: boolean;
    progressProps?: CircularProgressProps;
    color?: 'primary' | 'secondary' | string;
    checkSize?: number;
    checkColor?: string;
}

export interface ICheckControl extends ICheckOnly {
    labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
}

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: '999px',
        position: 'relative'
        // width: theme.spacing(5.25),
        // height: theme.spacing(5.25)
    },
    progress: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        opacity: '75'
    }
}));

export const CheckControl: React.FC<Partial<ICheckControl>> = ({ children, labelPlacement, className, ...props }) => {
    return (
        <Box color={props.color} display="inline-block" className={className}>
            <FormControlLabel labelPlacement={labelPlacement} color="inherit" control={<CheckOnly {...props} />} label={children} />
        </Box>
    );
};

export const CheckOnly: React.FC<ICheckOnly> = ({ loading, progressProps, color, checkSize = 1, checkColor, ...props }) => {
    const classes = useStyles();

    return (
        <Box className={classes.button} color={color}>
            <Checkbox
                {...props}
                icon={<Icon path={mdiCheckboxBlankOutline} size={checkSize} color={color} />}
                checkedIcon={
                    <Stack size={checkSize}>
                        <Icon path={checkboxOutline} color={color} />
                        <Icon path={check} color={checkColor || color} />
                    </Stack>
                }
            />
            {loading && (
                <CircularProgress
                    className={classes.progress}
                    variant="indeterminate"
                    size={24 * checkSize + 18}
                    thickness={2}
                    color="inherit"
                    {...progressProps}
                />
            )}
        </Box>
    );
};
