import React, { memo, forwardRef } from 'react';

import { Box, Typography, BoxProps, styled } from '@material-ui/core';

interface IPanel {
    title: string;
}

const Component: React.FC<Partial<IPanel> & React.HTMLAttributes<HTMLDivElement>> = forwardRef<
    HTMLDivElement,
    Partial<IPanel> & React.HTMLAttributes<HTMLDivElement>
>(({ title, className, children, ...props }, ref) => {
    return (
        <Container ref={ref} className={`${className}`} {...props}>
            {title && (
                <Title color="primary" variant="subtitle1">
                    {title}
                </Title>
            )}
            <GrowDiv>{children}</GrowDiv>
        </Container>
    );
});

export const Panel = memo(Component);

const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    margin: theme.spacing(1),

    display: 'flex',
    flexDirection: 'column'
}));

const Title = styled(Typography)({
    fontWeight: 'bold',
    flexGrow: 0
});

const GrowDiv = styled('div')({
    flexGrow: 1
});
