import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { TextField, Grid, Button, Box, useTheme, Typography, makeStyles } from '@material-ui/core';
import { TextWrapper, ButtonWrapper } from './common.components';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { useStateWithRef } from '../../../hooks/statewithref.hook';
import { AppFilterActions } from '../../../types/contexts/app-context.type';
import { splitIDs } from '../../../helpers/pipes';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useInvoiceState } from '../../../contexts/invoice.context';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';
import { summaryToExcel } from '../../../helpers/excelexport';

export interface IInvoiceNumberSearch {
    action:
        | AppFilterActions.invoiceIDS
        | AppFilterActions.remittanceNumbers
        | AppFilterActions.disbursementInvoiceIDS
        | AppFilterActions.paymentConfirmationIDS;
    label: string;
    placeholder: string;
    summary?: boolean;
}

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        flexBasis: 0,
        flexGrow: 1,
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(1),
        '& *': {
            color: theme.palette.common.white
        }
    },
    summaryTitle: {
        flexGrow: 0
    },
    statContainer: {
        flexGrow: 1,
        display: 'flex'
    },
    statColumn: {
        display: 'grid',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        gridTemplateColumns: 'repeat(2, auto)',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        '&:first-child': {
            borderRight: '1px solid #FFFFFF44'
        }
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',

        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(2)
    }
}));

export const InvoiceNumberSearch: React.FC<IInvoiceNumberSearch> = ({ action, label, placeholder, summary }) => {
    const appDispatch = useAppDispatch();
    // const userState = useUserState();
    const invoiceState = useInvoiceState();
    const appState = useAppState();
    const inputClasses = useInputStyle();
    const theme = useTheme();
    const [val, setVal, valRef] = useStateWithRef<string>('');
    const classes = useStyles();
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [user] = useCurrentUser();

    // on tab away, store the input
    useEffect(
        () => () => {
            appDispatch({ type: action, payload: splitIDs(valRef.current) });
        },
        [action, appDispatch, valRef]
    );

    useEffect(() => {
        setVal(appState.filter[action] ? appState.filter[action].join(', ') : '');
    }, [action, appState.filter, setVal]);

    const setNumbers = useCallback(() => {
        appDispatch({ type: action, payload: splitIDs(valRef.current) });
    }, [action, appDispatch, valRef]);

    const clear = useCallback(() => {
        appDispatch({ type: action, payload: [] });
    }, [action, appDispatch]);

    const handleTextChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setVal(evt.target.value);
        },
        [setVal]
    );

    // useEffect(() => {}, [invoiceState.invoices]);

    const doExport = () => {
        summaryToExcel(user, appState, appState.filterIDQuery[action], invoiceState.invoices);
    };

    const onPaste: React.ClipboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = (evt) => {
        evt.preventDefault();
        let data = evt.clipboardData.getData('text');

        const input = inputRef.current;
        const start = input?.selectionStart;
        const end = input?.selectionEnd;

        let newVal = val;

        if (start === end && end === val.length) {
            if (newVal.trim() && data) {
                newVal += ', ';
            }
            if (data) {
                newVal += data;
            }
        } else {
            if (data && end !== undefined) {
                newVal = val.substr(0, start) + data + val.substr(end);
            }
        }

        setVal(newVal);
    };

    return (
        <Grid container wrap="nowrap" spacing={1}>
            <TextWrapper item>
                <TextField
                    inputRef={inputRef}
                    value={val}
                    className={inputClasses.input}
                    variant="filled"
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={3}
                    onChange={handleTextChange}
                    label={label}
                    placeholder={placeholder}
                    inputProps={{
                        onPaste
                    }}
                ></TextField>
            </TextWrapper>
            <ButtonWrapper item container direction="column" spacing={1} justify="center">
                <Grid item>
                    <Box color={theme.palette.common.white} width="100%">
                        <Button variant="outlined" color="inherit" onClick={setNumbers}>
                            Search
                        </Button>
                    </Box>
                </Grid>
                <Grid item>
                    <Box color={theme.palette.common.white} width="100%">
                        <Button fullWidth variant="outlined" color="inherit" onClick={clear}>
                            Reset
                        </Button>
                    </Box>
                </Grid>
            </ButtonWrapper>
            {summary && (
                <div className={classes.summaryContainer}>
                    {/* <Typography className={classes.summaryTitle} variant="body1" color="inherit">
                    {`Fetch Results: ${appState.filterIDQuery[action]?.results ?? 0}`}
                </Typography> */}
                    <div className={classes.statContainer}>
                        <div className={classes.statColumn}>
                            <Typography color="inherit" variant="body2" noWrap align="right">
                                {`Searched`}
                            </Typography>
                            <Typography color="inherit" variant="body2" noWrap>
                                {`: ${appState.filterIDQuery[action]?.queries?.length ?? 0}`}
                            </Typography>
                            <Typography color="inherit" variant="body2" noWrap align="right">
                                {`Exact Match`}
                            </Typography>
                            <Typography color="inherit" variant="body2" noWrap>
                                {`: ${appState.filterIDQuery[action]?.exact?.length ?? 0}`}
                            </Typography>
                        </div>
                        <div className={classes.statColumn}>
                            <Typography color="inherit" variant="body2" noWrap align="right">
                                {`No Match`}
                            </Typography>
                            <Typography color="inherit" variant="body2" noWrap>
                                {`: ${appState.filterIDQuery[action]?.none?.length ?? 0}`}
                            </Typography>
                            <Typography color="inherit" variant="body2" noWrap align="right">
                                {`Partial Match`}
                            </Typography>
                            <Typography color="inherit" variant="body2" noWrap>
                                {`: ${appState.filterIDQuery[action]?.fuzzy?.length ?? 0}`}
                            </Typography>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button fullWidth variant="outlined" color="inherit" onClick={doExport}>
                                Export
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Grid>
    );
};
