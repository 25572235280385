import { Box, fade, Grid, List, ListItem, makeStyles, Typography, useTheme, darken } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import React, { useMemo, useState } from 'react';
import { SortEndHandler, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useMenuStyles } from '../../../../styles/stimenu.style';
import { GridGrow, GridMin, ISingleSelect, Scroller, SingleSelect } from '../../../generic';
import { IMultiSelectOption } from '../../../generic/controls/controls.types';
import { ExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';
import { useDataTableState, useDataTableDispatch } from '../../datatable.context';
import { ISortOption, DataTableActions } from '../../datatable.types';

const useStyles = makeStyles((theme) => ({
    menu: {
        height: '100%'
    },
    input: {
        width: '120px',
        '& .MuiInputBase-root': {
            marginTop: '0px'
        },
        '& .MuiFormLabel-root': {
            transform: `translate(0, ${theme.spacing(3.66667)}px) scale(1)`
        },
        '& .MuiInputLabel-shrink': {
            transform: `translate(0, ${theme.spacing(1.25)}px) scale(.75)`
        }
    },
    sortableHelper: {
        zIndex: 9999,
        background: theme.palette.background.default,
        borderRadius: theme.spacing(2)
    },
    sortList: {
        boxShadow: theme.shadows[1],
        '& > :nth-child(2n)': {
            backgroundColor: theme.palette.background.paper
        },
        '& > :nth-child(2n-1)': {
            backgroundColor: darken(theme.palette.background.paper, 0.05)
        }
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${fade(theme.palette.common.black, 0.5)}`,
        marginTop: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        boxShadow: theme.shadows[1]
    }
}));

export const SortMenu: React.FC = () => {
    const dataTableState = useDataTableState();
    const classes = useStyles();
    const { sort, columns } = dataTableState;
    const menuClasses = useMenuStyles();
    const dataTableDispatch = useDataTableDispatch();
    const columnOptions: IMultiSelectOption[] = useMemo(() => columns.map((c) => ({ text: c.label, value: c.key })), [columns]);

    const onChange = (index: number | undefined, opt: ISortOption) => {
        const newSort = [...sort];
        if (index !== undefined) {
            newSort[index] = opt;
        } else {
            newSort.push(opt);
        }

        dataTableDispatch({ type: DataTableActions.sort, payload: newSort });
    };

    const onDelete = (index: number) => {
        const newSort = [...sort];
        newSort.splice(index, 1);
        dataTableDispatch({ type: DataTableActions.sort, payload: newSort });
    };

    const onReorderEnd: SortEndHandler = ({ oldIndex, newIndex }, evt) => {
        const newSort = [...sort];
        newSort.splice(newIndex, 0, ...newSort.splice(oldIndex, 1));
        dataTableDispatch({ type: DataTableActions.sort, payload: newSort });
    };

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <GridGrow>
                <Scroller paddingTop={1}>
                    <SortableList axis="y" useDragHandle onSortEnd={onReorderEnd} helperClass={classes.sortableHelper}>
                        <List className={classes.sortList} disablePadding>
                            {sort.map((s, i) => (
                                <SortableListItem index={i} key={s.key || i}>
                                    <SortItem
                                        key={s.key || i}
                                        index={i}
                                        onOptionChange={onChange}
                                        columnOptions={columnOptions}
                                        sort={s}
                                        selectOptions={{ className: classes.input, menuClasses: menuClasses }}
                                        onDelete={onDelete}
                                    />
                                </SortableListItem>
                            ))}
                        </List>
                    </SortableList>
                </Scroller>
            </GridGrow>
            <GridMin className={classes.footer}>
                <List disablePadding>
                    <ListItem key={-1}>
                        <SortItem
                            onOptionChange={onChange}
                            columnOptions={columnOptions}
                            selectOptions={{ className: classes.input, menuClasses: menuClasses }}
                            onDelete={onDelete}
                        />
                    </ListItem>
                </List>
            </GridMin>
        </Box>
    );
};

const SortableList = SortableContainer(({ children }: { children: any }) => <>{children}</>);

const SortableListItem = SortableElement(({ children, ...props }: { children: any }) => <ListItem {...props}>{children}</ListItem>);

const Handle = SortableHandle(({ children }: { children: any }) => (
    <Grid item className="handle">
        {children}
    </Grid>
));

interface ISortItem {
    columnOptions: IMultiSelectOption[];
    sort?: ISortOption;
    index?: number;
    onDelete: (index: number) => void;
    onOptionChange: (index: number | undefined, opt: ISortOption) => void;
    selectOptions?: Partial<ISingleSelect>;
}

const initialS: Partial<ISortOption> = { direction: 'asc' };

const SortItem: React.FC<ISortItem> = ({ sort, columnOptions, index, onDelete, onOptionChange, selectOptions }) => {
    const [s, setS] = useState<Partial<ISortOption>>(sort || { ...initialS });
    const theme = useTheme();
    const onChange = (evt: React.ChangeEvent<any>) => {
        const newS: Partial<ISortOption> = { ...s, [evt.target.name]: evt.target.value };
        setS(newS);

        if (newS.direction && newS.key) {
            onOptionChange(index, newS as ISortOption);
            if (index === undefined) {
                setS({ ...initialS });
            }
        }
    };

    const handleDelete = () => {
        if (index !== undefined) {
            onDelete(index);
        } else {
            setS({ ...initialS });
        }
    };

    return (
        <Grid container wrap="nowrap" spacing={1} alignItems="center">
            {index !== undefined ? (
                <Handle>
                    <Box paddingLeft={1} paddingRight={1}>
                        <Typography variant="h6">{index + 1}</Typography>
                    </Box>
                </Handle>
            ) : (
                <Grid item>
                    <Box paddingLeft={1} paddingRight={1}>
                        <Typography variant="h6">+</Typography>
                    </Box>
                </Grid>
            )}

            <Grid item>
                <SingleSelect value={s.key || ''} onChange={onChange} label="Column" options={columnOptions} name="key" {...selectOptions} />
            </Grid>
            <Grid item>
                <SingleSelect value={s.direction || ''} onChange={onChange} label="Direction" options={dirOptions} name="direction" {...selectOptions} />
            </Grid>
            {index !== undefined && (
                <Grid item>
                    <ExtendedIconButton
                        size="small"
                        onClick={handleDelete}
                        bgColor={fade(theme.palette.common.black, 0.1)}
                        hoverColor={fade(theme.palette.common.black, 0.25)}
                    >
                        <DeleteOutline />
                    </ExtendedIconButton>
                </Grid>
            )}
        </Grid>
    );
};

const dirOptions = [
    { text: 'Ascending', value: 'asc' },
    { text: 'Descending', value: 'desc' }
];
