import { IconButton, Input, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useInvoiceDispatch } from '../../../contexts/invoice.context';
import { currency } from '../../../helpers/pipes';
import { useInfoModal, MODAL_TYPE, CONFIRM_TYPES } from '../../../hooks/info-modal.hook';
import { useInfoRequestModal } from '../../../hooks/inforequestmodal.hook';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { FailureObject, FailureType, useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { IInvoicePlus, InvoiceActions } from '../../../types/contexts/invoice-context.type';
import { IIdx } from '../../../types/general.type';
import { IColumnComponent } from '../../datatable/datatable.types';
import { CarrierImg } from '../../generic/display/carrierimg.component';
import FilePdfOutline from 'mdi-material-ui/FilePdfOutline';
import { CheckOnly, STITooltip } from '../../generic';
import { useAppState } from '../../../contexts/app.context';
import { IAppState } from '../../../types/contexts/app-context.type';
import { useTableClasses } from './common';
import { useStateWithRef } from '../../../hooks/statewithref.hook';
import { CleanLink } from '../../generic/display/cleanlink.component';
import { GrowUnderline } from '../../generic/feedback/growunderline.component';
import { AxiosResponse } from 'axios';
import { ApproveFailed } from '../modals/approvefailed.component';

const useCellInputStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'center'
    },
    changed: {
        color: theme.palette.warning.main
    },
    error: {
        color: theme.palette.error.main
    },
    exact: {
        color: theme.palette.secondary.main
    }
}));

export const CImg: IColumnComponent<string, IIdx<Models.CarrierInvoices>> = ({ value }) => (
    <CarrierImg key={value} style={{ maxHeight: '100%', paddingTop: '1px', paddingBottom: '1px' }} carrierCode={value} />
);

export const CurrencyAmount: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const denomination = invoice?.currency;
    return <div className={column.className}>{currency(invoice[column.key], denomination)}</div>;
};

export const ReceiveInput: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const val = invoice.invoiceToReceive ?? 0;
    const updateVal = invoice?.updates?.invoiceToReceive ?? 0;
    const denomination = invoice?.currency;

    const checkPermission = usePermission(invoice);
    const classes = useCellInputStyles();

    const [temp, setTemp, tempRef] = useStateWithRef<string | undefined>(undefined);
    const [error, setError] = useState(false);

    const invoiceDispatch = useInvoiceDispatch();

    // useEffect(() => {
    //     if (tempRef.current === undefined) {
    //         setTemp(currency(updateVal ?? val, denomination));
    //     }
    // }, [setTemp, val, updateVal, tempRef, denomination]);

    const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let val: string = evt.target.value;
        setTemp(val);
        if (val === '') {
            invoiceDispatch({ type: InvoiceActions.addUpdate, id: invoice.carrierInvoiceID, prop: column.key, payload: 0 });
            setError(false);
        }
        const num = parseFloat(val.replaceAll(',', '').replaceAll('$', ''));
        // const hasError = !val.match(dollarRegex) && isNaN(num);

        if (isNaN(num)) {
            setError(true);
        } else {
            setError(false);
            invoiceDispatch({ type: InvoiceActions.addUpdate, id: invoice.carrierInvoiceID, prop: column.key, payload: num });
        }
    };

    useEffect(() => {
        if (!invoice.updates.receive) setTemp(undefined);
    }, [invoice.updates.receive, setTemp]);

    const updates = invoice.updates;
    let displayNum = undefined;

    if (val !== undefined) displayNum = val;
    if (updateVal !== undefined) displayNum = updateVal;
    if (temp !== undefined) displayNum = parseFloat(temp.replaceAll(/[$,]/g, ''));

    const amtDue = updates.invoiceAmtDue !== undefined ? updates.invoiceAmtDue : invoice.invoiceAmtDue;
    const totalRec = (updates.invoiceTotalReceived !== undefined ? updates.invoiceTotalReceived : invoice.invoiceTotalReceived) || 0;

    const onBlur = () => {
        if (temp !== undefined) {
            const num = parseFloat(temp.replaceAll(/\$|,/g, ''));
            if (isNaN(num)) {
                setError(true);
            } else {
                setError(false);
                setTemp(undefined);
            }
        } else {
            setError(false);
            setTemp(undefined);
        }
    };

    let className = '';

    if (displayNum !== undefined) {
        if (displayNum > amtDue - totalRec) className = classes.error;
        if (displayNum < amtDue - totalRec) className = classes.changed;
        if (displayNum === amtDue - totalRec) className = classes.exact;
    }
    if (error) className = classes.error;

    return (
        <Input
            key={invoice.carrierInvoiceID + column.key}
            className={className}
            onBlur={onBlur}
            disabled={!updates.receive || (!!invoice && !checkPermission(column.key))}
            // disableUnderline
            inputProps={{ className: `${classes.input}` }}
            value={temp || (displayNum ? currency(displayNum, denomination) : '$')}
            onChange={onChange}
        />
    );
};

export const DisburseInput: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const val = invoice.invoiceToDisburse ?? 0;
    const updateVal = invoice.updates?.invoiceToDisburse ?? 0;
    const denomination = invoice?.currency;

    const checkPermission = usePermission(invoice);
    const classes = useCellInputStyles();
    const [temp, setTemp, tempRef] = useStateWithRef<string | undefined>(undefined);
    const [error, setError] = useState(false);

    const invoiceDispatch = useInvoiceDispatch();

    // useEffect(() => {
    //     if (tempRef.current === undefined) {
    //         setTemp(currency(updateVal ?? val, denomination));
    //     }
    // }, [setTemp, val, updateVal, tempRef, denomination]);

    const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let val: string = evt.target.value;
        let num = parseFloat(val.replaceAll(',', '').replaceAll('$', ''));
        if (num > invoice.invoiceAmtDue) {
            val = currency(invoice.invoiceAmtDue, denomination);
            num = invoice.invoiceAmtDue;
        }
        setTemp(val);
        if (val === '') {
            invoiceDispatch({ type: InvoiceActions.addUpdate, id: invoice.carrierInvoiceID, prop: column.key, payload: 0 });
            setError(false);
        }
        // const hasError = !val.match(dollarRegex) && isNaN(num);

        if (isNaN(num)) {
            setError(true);
        } else {
            setError(false);
            invoiceDispatch({ type: InvoiceActions.addUpdate, id: invoice.carrierInvoiceID, prop: column.key, payload: num });
        }
    };

    useEffect(() => {
        if (!invoice.updates.disburse) setTemp(undefined);
    }, [invoice.updates.disburse, setTemp]);

    const updates = invoice.updates;
    let displayNum = undefined;
    if (val !== undefined) displayNum = val;
    if (updateVal !== undefined) displayNum = updateVal;
    if (temp !== undefined) displayNum = parseFloat(temp.replace('$', ''));

    const totalRec =
        (updates.invoiceToReceive || 0) + ((updates.invoiceTotalReceived !== undefined ? updates.invoiceTotalReceived : invoice.invoiceTotalReceived) || 0);

    const onBlur = () => {
        if (temp !== undefined) {
            const num = parseFloat(temp.replaceAll(/\$|,/g, ''));
            if (isNaN(num)) {
                setError(true);
            } else {
                setError(false);
                setTemp(undefined);
            }
        } else {
            setError(false);
            setTemp(undefined);
        }
    };

    let className = '';

    if (displayNum !== undefined) {
        if (displayNum > totalRec) className = classes.error;
        if (displayNum < totalRec) className = classes.changed;
        if (displayNum === totalRec) className = classes.exact;
    }
    if (error) className = classes.error;

    const incompatibleStatus = invoice.dispute || invoice.invoiceStatus === 'Void';
    const noPermission = !!invoice && !checkPermission(column.key);

    return (
        <STITooltip
            enterDelay={300}
            title={
                invoice.dispute
                    ? 'Cannot disburse a disputed invoice'
                    : invoice.invoiceStatus === 'Void'
                    ? 'Cannot disburse a voided invoice'
                    : noPermission
                    ? 'Insufficnent Permissions to disburse invoice'
                    : ''
            }
        >
            <Input
                key={invoice.carrierInvoiceID + column.key}
                className={className}
                onBlur={onBlur}
                disabled={incompatibleStatus || !updates.disburse || (!!invoice && !checkPermission(column.key))}
                // disableUnderline
                inputProps={{ className: `${classes.input}` }}
                value={temp || (displayNum ? currency(displayNum, denomination) : '$')}
                onChange={onChange}
            />
        </STITooltip>
    );
};

export const TotalToReceive: IColumnComponent<any, IInvoicePlus> = ({ row: invoice }) => {
    const updates = invoice.updates;
    const val = parseFloat(invoice && (invoice as IIdx).invoiceTotalReceived) || 0;
    const updateVal = parseFloat(updates && (updates as IIdx).invoiceToReceive) || 0;
    const classes = useCellInputStyles();
    const displayVal = updateVal !== undefined ? updateVal + val : val;
    const currAmt = updates.currentAmt !== undefined ? updates.currentAmt : invoice.currentAmt;
    const tableClasses = useTableClasses();
    // const totalRec = (updates.invoiceTotalReceived !== undefined ? updates.invoiceTotalReceived : invoice.invoiceTotalReceived) || 0;
    // const vsDue = amtDue - totalRec;

    const denomination = invoice?.currency;
    return (
        <div
            className={`${tableClasses.justifyRight} ${
                displayVal ? (displayVal > currAmt ? classes.error : displayVal < currAmt ? classes.changed : displayVal === currAmt ? classes.exact : '') : ''
            }`}
        >
            {currency(displayVal, denomination)}
        </div>
    );
};

export const TotalToDisburse: IColumnComponent<any, IInvoicePlus> = ({ row: invoice }) => {
    const updates = invoice.updates;
    const val = (invoice && invoice.invoiceTotalDisbursed) || 0;
    const updateVal = (updates && updates.invoiceToDisburse) || 0;
    const displayVal = updateVal !== undefined ? updateVal + val : val;
    const classes = useCellInputStyles();
    const tableClasses = useTableClasses();
    const totalRec =
        (updates.invoiceToReceive || 0) + ((updates.invoiceTotalReceived !== undefined ? updates.invoiceTotalReceived : invoice.invoiceTotalReceived) || 0);

    const denomination = invoice?.currency;
    return (
        <div
            className={`${tableClasses.justifyRight} ${
                displayVal
                    ? displayVal > totalRec
                        ? classes.error
                        : displayVal < totalRec
                        ? classes.changed
                        : displayVal === totalRec
                        ? classes.exact
                        : ''
                    : ''
            }`}
        >
            {currency(displayVal, denomination)}
        </div>
    );
};

export const InvoiceButton: IColumnComponent<string, IIdx<Models.CarrierInvoices>> = ({ value, row }) => (
    <CleanLink className="invoiceLink" to={`/audit/${row.carrierInvoiceID}`} data-name="invoice">
        <GrowUnderline data-name="invoice" className="buttonText" onHover>
            <div>{value.replace(/^000000/, '')}</div>
        </GrowUnderline>
    </CleanLink>
    // <Button className="fixButtonName buttonText" name="invoice">
    //     {value.replace(/^000000/, '')}
    // </Button>
);

export const PDFButton: IColumnComponent<any, IIdx<Models.CarrierInvoices>> = ({ row }) => (
    <IconButton name="pdf" className="fixButtonName">
        <FilePdfOutline />
    </IconButton>
);

export const TableCheck: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const checkPermission = usePermission(invoice);

    const val = invoice && (invoice as IIdx)[column.key];
    const updateVal = invoice?.updates && (invoice.updates as IIdx)[column.key];
    const theme = useTheme();

    return (
        <CheckOnly
            disableRipple
            disabled={!!invoice && !checkPermission(column.key)}
            name={column.key}
            checked={updateVal !== undefined ? updateVal : val || false}
            inputProps={{ name: column.key }}
            // onChange={onChange}
            color={updateVal !== undefined ? theme.palette.warning.main : undefined}
        />
    );
};

export const ReceiveCheck: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const checkPermission = usePermission(invoice);

    const val = invoice && (invoice as IIdx)[column.key];
    const updateVal = invoice?.updates && (invoice.updates as IIdx)[column.key];
    const theme = useTheme();

    return (
        <CheckOnly
            disableRipple
            disabled={(!!invoice && !checkPermission(column.key)) || invoice.invoiceStatus === 'Client Payable'}
            name={column.key}
            checked={updateVal !== undefined ? updateVal : val || false}
            inputProps={{ name: column.key }}
            // onChange={onChange}
            color={updateVal !== undefined ? theme.palette.warning.main : undefined}
        />
    );
};

export const DisburseCheck: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const checkPermission = usePermission(invoice);

    const val = invoice && (invoice as IIdx)[column.key];
    const updateVal = invoice?.updates && (invoice.updates as IIdx)[column.key];
    const theme = useTheme();

    const incompatibleStatus = invoice.dispute || invoice.invoiceStatus === 'Void';
    const noPermission = !!invoice && !checkPermission(column.key);

    return (
        <STITooltip
            enterDelay={300}
            title={
                invoice.dispute
                    ? 'Cannot disburse a disputed invoice'
                    : invoice.invoiceStatus === 'Void'
                    ? 'Cannot disburse a voided invoice'
                    : invoice.invoiceStatus === 'Client Payable' || invoice.invoiceStatus === 'Client Paid'
                    ? 'Cannot disburse a client payable/paid invoice'
                    : noPermission
                    ? 'Insufficnent Permissions to disburse invoice'
                    : ''
            }
        >
            <CheckOnly
                disableRipple
                disabled={
                    incompatibleStatus ||
                    (!!invoice && !checkPermission(column.key)) ||
                    invoice.invoiceStatus === 'Client Payable' ||
                    invoice.invoiceStatus === 'Client Paid'
                }
                name={column.key}
                checked={updateVal !== undefined ? updateVal : val || false}
                inputProps={{ name: column.key }}
                // onChange={onChange}
                color={updateVal !== undefined ? theme.palette.warning.main : undefined}
            />
        </STITooltip>
    );
};

export const ApproveCheck: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const checkPermission = usePermission(invoice);

    const { updateInvoices, loading } = useUpdateInvoices();
    const invoiceDispatch = useInvoiceDispatch();
    const val = invoice && (invoice as IIdx)[column.key];
    const updateVal = invoice?.updates && (invoice.updates as IIdx)[column.key];
    const runRequestModal = useInfoRequestModal();
    const [ConfirmationModal, runConfirmation] = useInfoModal();

    const theme = useTheme();

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let res:
            | AxiosResponse<{
                  invoices: Models.CarrierInvoices[];
                  disburseNumber?: string | undefined;
                  failed: FailureObject;
              }>
            | undefined = undefined;
        if (invoice.dispute) {
            if (checkPermission('dispute')) {
                const { type, value } = await runRequestModal({
                    title: 'Invoice In Dispute',
                    desc: (
                        <>
                            <p>This invoice is currently disputed and the dispute will be resolved with this change.</p>
                            <p>Please provide a desciption of the resolution.</p>
                        </>
                    ),
                    collabInvoice: invoice.carrierInvoiceID,
                    modalType: MODAL_TYPE.WARNING,
                    no: false,
                    cancel: true
                });
                if (type === CONFIRM_TYPES.YES) {
                    res = await updateInvoices([{ carrierInvoiceID: invoice.carrierInvoiceID, approve: checked, dispute: false, reason: value }]);
                }
            } else {
                await runConfirmation({
                    title: 'Cannot Dispute',
                    desc: (
                        <>
                            <p>This invoice is currently in dispute.</p>
                            <p>Please coordinate with someone who is able to resolve this dispute before proceeding</p>
                        </>
                    ),
                    no: false,
                    cancel: true,
                    yes: false
                });
            }
        } else {
            res = await updateInvoices([{ carrierInvoiceID: invoice.carrierInvoiceID, approve: checked }]);
        }

        if (res && res.status === 200) {
            invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: res.data.invoices });
            if (res.data.failed) {
                const failed = Object.entries(res.data.failed)[0];
                if (failed) {
                    const [id, reason] = failed;
                    const [type] = Object.entries(reason)[0];
                    await runConfirmation({
                        title: 'Cannot Approve',
                        desc: (
                            <>
                                <ApproveFailed type={type as FailureType} invoice={invoice.invoiceNumber} />
                            </>
                        ),
                        yesTxt: 'OK',
                        no: false
                    });
                }
            }
        } else if (process.env.NODE_ENV === 'development') {
            await runConfirmation({
                title: 'Cannot Approve',
                desc: (
                    <>
                        <p>There was a problem with the request.</p>
                        {(res as any)?.message && <p>{(res as any).message}</p>}
                    </>
                ),
                yesTxt: 'OK',
                no: false
            });
        }
    };

    return (
        <>
            <ConfirmationModal />
            {/* <InfoRequestModal /> */}
            <CheckOnly
                disableRipple
                disabled={!!invoice && !checkPermission(column.key)}
                size="small"
                loading={!!loading}
                name={column.key}
                checked={updateVal !== undefined ? updateVal : val || false}
                inputProps={{ name: column.key }}
                onChange={onChange}
                color={updateVal !== undefined || !!loading ? theme.palette.warning.main : undefined}
            />
        </>
    );
};

export const DisputeCheck: IColumnComponent<any, IInvoicePlus> = ({ row: invoice, column }) => {
    const checkPermission = usePermission(invoice);
    const [ConfirmationModal, runConfirmation] = useInfoModal();

    const { updateInvoices, loading } = useUpdateInvoices();
    const invoiceDispatch = useInvoiceDispatch();
    const val = invoice && (invoice as IIdx)[column.key];
    const updateVal = invoice?.updates && (invoice.updates as IIdx)[column.key];
    const runRequestModal = useInfoRequestModal();

    const theme = useTheme();

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const { type, value } = await runRequestModal({
            title: 'Dispute?',
            desc: (
                <>
                    {invoice.approve ? (
                        <>
                            <p>This invoice is currently approved.</p>
                            <p>Disputing this invoice will remove its approved status.</p>
                        </>
                    ) : null}
                    <p>Please provide a reason for disputing this invoice</p>
                </>
            ),
            modalType: MODAL_TYPE.WARNING,
            collabInvoice: invoice.carrierInvoiceID,
            no: false,
            cancel: true
        });
        if (type === CONFIRM_TYPES.YES) {
            const changeApprove = checked ? { approve: false } : {};
            const res = await updateInvoices([
                { carrierInvoiceID: invoice.carrierInvoiceID, dispute: checked, ...changeApprove, ...(value ? { reason: value } : {}) }
            ]);
            if (res.status === 200) {
                invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: res.data.invoices });
            } else if (process.env.NODE_ENV === 'development') {
                await runConfirmation({
                    title: 'Cannot Dispute',
                    desc: (
                        <>
                            <p>There was a problem with the request.</p>
                            {(res as any)?.message && <p>{(res as any).message}</p>}
                        </>
                    ),
                    yesTxt: 'OK',
                    no: false
                });
            }
        }
    };

    return (
        <>
            <ConfirmationModal />
            {/* <InfoRequestModal /> */}
            <CheckOnly
                disableRipple
                disabled={!!invoice && !checkPermission(column.key)}
                size="small"
                loading={!!loading}
                name={column.key}
                checked={updateVal !== undefined ? updateVal : val || false}
                inputProps={{ name: column.key }}
                onChange={onChange}
                color={updateVal !== undefined || !!loading ? theme.palette.warning.main : undefined}
            />
        </>
    );
};

export const BillingEntityComponent: IColumnComponent<any, IInvoicePlus> = ({ value, row: invoice, column, className }) => {
    const appState = useAppState();
    return <div className={className}>{getBillingEntity(appState, invoice)}</div>;
};

export const getBillingEntity = (state: IAppState, invoice: Models.CarrierInvoices) => {
    switch (invoice.clientBillingLevel) {
        case 'Client':
            return state.data.entitiesById.clients[invoice.clientID]?.clientName;
        // return state.data.clients.find((c) => c.clientID === invoice.clientID)?.clientName;
        case 'Company':
            return state.data.entitiesById.companies[invoice.companyID]?.companyName;
        // return state.data.clients.find((c) => c.clientID === invoice.clientID)?.companies.find((c) => c.companyID === invoice.companyID)?.companyName;
        case 'Division':
            return state.data.entitiesById.divisions[invoice.divisionID]?.divisionName;
        case 'Location':
            return state.data.entitiesById.locations[invoice.locationID]?.locationName;
        // return state.data.clients
        //     .find((c) => c.clientID === invoice.clientID)
        //     ?.companies.find((c) => c.companyID === invoice.companyID)
        //     ?.divisions.find((d) => d.divisionID === invoice.divisionID)?.divisionName;
        default:
            return 'Unknown';
    }
};

export const EntityComponent: IColumnComponent<any, IInvoicePlus> = ({ value, row: invoice, column, className }) => {
    // So, theres a race condition with loading the clients vs invoices that sometimes shows billing entity as blank if invoices load first.
    // the value is there but the component doesnt rerender on that change as a side effect to some table performance things.
    // just telling this cell to rerender based on a hook change of the appstate is sufficient without needing to go and find the billing entity in the list again.
    const appState = useAppState();
    return <div className={className}>{getEntityName(appState, invoice, column.key)}</div>;
};

export const getEntityName = (state: IAppState, invoice: Models.CarrierInvoices, type: string) => {
    switch (type) {
        case 'clientID':
            return state.data.entitiesById.clients[invoice.clientID]?.clientName;
        case 'companyID':
            return state.data.entitiesById.companies[invoice.companyID]?.companyName;
        case 'divisionID':
            return state.data.entitiesById.divisions[invoice.divisionID]?.divisionName;
        case 'locationID':
            return state.data.entitiesById.locations[invoice.locationID]?.locationName;
        default:
            return 'Unknown';
    }
};

export type InvoiceColumns = keyof Models.CarrierInvoices | 'pdf' | 'billingEntity' | (string & {});

export const defaultHomeTableColumns: InvoiceColumns[] = [
    'carrierCode',
    'billingEntity',
    'carrierAccount',
    'payer',
    'invoiceNumber',
    'pdf',
    'invoiceOriginalAmt',
    'currentAmt',
    'invoiceAmtDue',
    'invoiceDate',
    'invoiceDueDate',
    'dispute',
    'approve',
    // 'allocate',
    'invoiceStatus',
    'invoicePaidAmt'
];

export const auditColumns: string[] = ['carrierCode', 'billingEntity', 'invoiceNumber', 'invoiceDate', 'currentAmt', 'auditStatus'];

export const payColumns: string[] = [
    'carrierCode',
    'billingEntity',
    'invoiceNumber',
    'invoiceDate',
    'invoiceDueDate',
    'currentAmt',
    'invoiceAmtDue',
    'receive',
    'invoiceToReceive',
    'invoiceTotalReceived',
    'disburse',
    'invoiceToDisburse',
    'invoiceTotalDisbursed',
    'invoiceStatus'
];

export const reviewColumns: string[] = [
    'carrierCode',
    'carrierAccount',
    'payer',
    'invoiceNumber',
    'invoiceDate',
    'invoiceDueDate',
    'currentAmt',
    'invoiceAmtDue',
    'invoiceStatus',
    'dispute',
    'approve'
];
