import { styled, Grid } from '@material-ui/core';

export const TextWrapper = styled(Grid)({
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '800px',
    width: '100%',
    '& > *': {
        maxHeight: '100%'
    }
});

export const ButtonWrapper = styled(Grid)({
    flexBasis: 0,
    flexGrow: 0
});
