import { createMuiTheme } from '@material-ui/core';

export const mainTheme = createMuiTheme({
    palette: {
        common: {
            black: '#393939'
        },
        primary: {
            main: '#006686',
            dark: '#3B3E53'
        },
        secondary: {
            main: '#48B252',
            contrastText: '#FFF'
        },
        success: {
            main: '#48B252'
        },
        error: {
            main: '#E04848',
            dark: '#bc2020',
            contrastText: '#FFF'
        },
        warning: {
            main: '#ff8300',
            contrastText: '#FFF'
        },
        background: {},
        text: {}
    },
    typography: {},
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '15px',
                    height: '15px'
                },

                '*::-webkit-scrollbar-track': {
                    background: 'transparent'
                },

                '*::-webkit-scrollbar-thumb': {
                    boxSizing: 'unset',
                    backgroundColor: '#006686',
                    borderRadius: '999px',
                    border: '5px solid transparent'
                },

                '*::-webkit-scrollbar-corner': {
                    backgroundColor: 'transparent'
                },
                '.MuiFormLabel-root': {
                    textTransform: 'uppercase'
                }
            }
        }
    }
});
