import React from 'react';

const folder = `${process.env.PUBLIC_URL}/img/`;

export const CarrierImg: React.FC<React.HTMLAttributes<HTMLImageElement> & { carrierCode: string | undefined }> = ({ carrierCode, ...props }) => {
    return (
        <img
            {...props}
            src={`${folder}${carrierCode}.png`}
            onError={(e) => {
                (e.target as HTMLImageElement).onerror = null;
                (e.target as HTMLImageElement).src = `${folder}STI_ImgNotFound.png`;
            }}
            alt="carrier"
        />
    );
};
