// npm i react-sortable-hoc @material-ui/core @material-ui/icons react-resizable

import React, { useCallback, useEffect, useRef, forwardRef, memo } from 'react';

import { Grid, styled, makeStyles } from '@material-ui/core';

import { IColumn, DataTableActions, IRow, IDataTableState, IColWidth, ISortOption } from './datatable.types';
import { AutoSizer } from 'react-virtualized';
import { useDataTableState, useDataTableDispatch, useDataTableStateRef, DataTableProvider } from './datatable.context';
import { VariableSizeList } from 'react-window';
import { HeaderRow } from './layout/headerrow.component';
import { BodyRow, RowDataHook } from './layout/bodyrow.component';
import { killEvt } from '../../helpers';
import { DataTableMenu } from './layout/menu/menu.component';

export type DataTableClasses = Partial<{
    headerRow: string;
    headerCell: string;
    bodyRow: string;
    bodyRowEven: string;
    bodyRowOdd: string;
}>;

interface IDataTable {
    // columns?: IColumn[];
    rows: IRow[];
    onSelectRow?: <T>(row: T) => void;
    currentKeys?: (string | number)[];
    selectableRows?: boolean;
    fullWidth?: boolean;
    // colOrder?: ColOrderItem[];
    defaultColWidths?: { [index: string]: IColWidth };
    classes?: DataTableClasses;
    headerRowHeight?: number;
    bodyRowHeight?: number;
    sortable?: boolean;
    resizable?: boolean;
    menuClasses?: any;
    onRowClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, rowData: IRow<any>) => void;
    onRowsDataUpdate?: (rowData: IRow<any>) => void;
    onStateChange?: (state: IDataTableState) => void;
    // initialState?: Partial<IDataTableState>;
    useGetRowData: RowDataHook;
    instanceRef?: React.MutableRefObject<DataTableInstance>;
}

export interface DataTableInstance {
    setMenuOpen?: (action: React.SetStateAction<boolean>, tab?: number) => void;
}

export type DataTableInstanceRef = React.MutableRefObject<DataTableInstance>;

const useRowStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        width: 'auto',
        '& > *': {
            padding: '0px 8px',
            minWidth: '16px'
        }
    },
    bodyRow: {
        alignItems: 'center',
        width: 'auto',
        '& > *': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            flexGrow: 0,
            flexShrink: 0,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '100%',
            height: '100%',
            borderTopWidth: '0px !important',
            borderBottomWidth: '0px !important'
        },
        '& .inner': {
            justifyContent: 'left',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    },
    headerRow: {
        width: 'fit-content',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 250,
        '& > *': {
            width: 'fit-content',
            position: 'relative'
        }
    },
    rowStaticSize: {
        width: '100%',
        '& > *': {
            flexBasis: 0,
            flexGrow: 1
        }
    }
}));

// onRowsDataUpdate is meant to allow the data to function as a "controlled" component.
// If this function is provided, data is expected to be controlled outside the table component and any changes will be sent out as the entire data set,
// outside state should be updated or changes will not be seen.

export const Component: React.FC<IDataTable & React.HTMLAttributes<Element>> = ({
    // columns,
    rows,
    onSelectRow,
    selectableRows,
    currentKeys,
    fullWidth,
    // colOrder,
    defaultColWidths,
    classes,
    headerRowHeight = 40,
    bodyRowHeight = 40,
    sortable = true,
    resizable = true,
    menuClasses,
    onRowClick,
    onRowsDataUpdate,
    onStateChange,
    useGetRowData,
    instanceRef,
    ...props
}) => {
    const dataTableState = useDataTableState();
    const dataTableStateRef = useDataTableStateRef();
    const rowClasses = useRowStyles();
    const windowRef = useRef<HTMLDivElement>();
    const lastExported = useRef<IRow[] | undefined>();

    useEffect(() => {
        onStateChange && onStateChange(dataTableState);
    }, [dataTableState, onStateChange]);

    // useEffect(() => {
    //     const order: ColOrderItem[] = colOrder || columns.map((c) => ({ key: c.key, active: true, label: c.label }));
    //     if (order !== dataTableStateRef.current.order) {
    //         dataTableDispatch({ type: DataTableActions.order, payload: order });
    //     }
    // }, [columns, dataTableDispatch, dataTableStateRef]);

    useEffect(() => {
        // console.log('row change');
        if (!rows.length || lastExported.current === rows) return;
        const sortData = doSort(rows, dataTableStateRef.current.columns, dataTableStateRef.current.sort);
        if (onRowsDataUpdate) {
            lastExported.current = sortData;
            onRowsDataUpdate(sortData);
        }
    }, [rows, onRowsDataUpdate, dataTableStateRef, lastExported]);

    const rowsRef = useRef(rows);
    rowsRef.current = rows;
    useEffect(() => {
        // console.log('sort change');
        if (!rowsRef.current.length) return;
        const sortData = doSort(rowsRef.current, dataTableStateRef.current.columns, dataTableState.sort);
        if (onRowsDataUpdate) {
            onRowsDataUpdate(sortData);
            lastExported.current = sortData;
        }
    }, [rowsRef, dataTableState.sort, onRowsDataUpdate, lastExported, dataTableStateRef]);

    const getRow = useCallback(
        ({ index, style }) => {
            const { width, ...styleNoWidth } = style;
            return (
                <BodyRow
                    onRowClick={onRowClick}
                    index={index}
                    key={rowsRef.current[index]?.carrierInvoiceID ?? index}
                    style={{ ...styleNoWidth, top: style.top + headerRowHeight }}
                    className={`${rowClasses.row} ${rowClasses.bodyRow} ${index % 2 ? classes?.bodyRowOdd : classes?.bodyRowEven} ${
                        resizable ? '' : rowClasses.rowStaticSize
                    }`}
                    // rowData={displayData![index]}
                    useGetRowData={useGetRowData}
                    // columnsData={orderedColumns}
                />
            );
        },
        [onRowClick, headerRowHeight, rowClasses.row, rowClasses.bodyRow, rowClasses.rowStaticSize, classes, resizable, useGetRowData]
    );

    const getItemSize = useCallback(
        (index) => {
            return bodyRowHeight;
        },
        [bodyRowHeight]
    );

    const innerElementType = useCallback(
        forwardRef<HTMLDivElement>(({ children, ...rest }, ref) => (
            <div ref={ref} {...rest}>
                <HeaderRow
                    sortable={sortable}
                    resizable={resizable}
                    height={headerRowHeight}
                    className={`${rowClasses.row} ${rowClasses.headerRow} ${classes?.headerRow} ${resizable ? '' : rowClasses.rowStaticSize}`}
                    // columns={orderedColumns}
                    tableRef={windowRef}
                    key={'h-row'}
                    menuClasses={menuClasses}
                    cellClassName={`${classes?.headerCell}`}
                    // colWidths={colWidths}
                    onTouchStart={killEvt}
                />
                {children}
            </div>
        )),
        [headerRowHeight, rowClasses, windowRef, sortable, resizable, menuClasses]
    );

    return (
        <TableContainer container direction="column">
            <AutoSizer className="autosizer">
                {({ height, width }) => (
                    <WidthHelper width={width} initialColWidths={defaultColWidths}>
                        <div style={{ width: `${width}px`, height: `${height}px` }}>
                            <VariableSizeList
                                innerElementType={innerElementType}
                                // outerElementType={outerElementType}
                                height={height}
                                itemCount={rows?.length || 0}
                                itemSize={getItemSize}
                                width="auto"
                                outerRef={windowRef}
                                // overscanCount={15}
                            >
                                {getRow}
                            </VariableSizeList>
                        </div>
                    </WidthHelper>
                )}
            </AutoSizer>
            <DataTableMenu instanceRef={instanceRef} />
        </TableContainer>
    );
};

const TableContainer = styled(Grid)({
    width: '100%',
    height: '100%',
    position: 'relative'
});

export const DataTable: React.FC<
    Omit<IDataTable, 'instanceRef'> & {
        initialState?: Partial<IDataTableState>;
        instanceRef?: React.MutableRefObject<DataTableInstance | undefined>;
    } & React.HTMLAttributes<Element>
> = ({ initialState, instanceRef, ...props }) => {
    if (instanceRef && !instanceRef.current) instanceRef.current = {};
    return (
        <DataTableProvider savedState={initialState}>
            <Component {...props} instanceRef={instanceRef as React.MutableRefObject<DataTableInstance>} />
        </DataTableProvider>
    );
};

const WidthHelper: React.FC<{ width: number; initialColWidths: { [index: string]: IColWidth } | undefined }> = ({ initialColWidths, width, children }) => {
    const { colWidths, columns } = useDataTableState();
    const dataTableDispatch = useDataTableDispatch();
    const dataTableStateRef = useDataTableStateRef();

    if (!colWidths && initialColWidths && width) {
        //See if the initial widths are actually a reload
        if (Object.values(initialColWidths).find((c) => c.width !== undefined)) {
            dataTableDispatch({ type: DataTableActions.colWidths, payload: initialColWidths });
        } else {
            let totalWidth = 0;
            let expandCount = 0;
            columns.forEach((col: IColumn) => {
                if (!col.active) return;
                const c = initialColWidths[col.key] || { initialWidth: 90, initialExpand: true };
                if (c.initialExpand) expandCount++;
                totalWidth += c.initialWidth;
            });
            const dif = width - totalWidth;
            if (dif > 0) {
                const pad = dif / expandCount;
                const newWidths = Object.keys(initialColWidths).reduce((acc: { [index: string]: IColWidth }, k: string) => {
                    const c = initialColWidths[k];
                    if (c?.initialExpand) {
                        acc[k] = { ...c, width: c.initialWidth + pad };
                    } else {
                        acc[k] = { ...c };
                    }
                    return acc;
                }, {});
                dataTableDispatch({ type: DataTableActions.colWidths, payload: newWidths });
            } else {
                dataTableDispatch({ type: DataTableActions.colWidths, payload: initialColWidths });
            }
        }
    }

    useEffect(() => {
        const newWidths = { ...dataTableStateRef.current.colWidths };
        let updated = false;

        columns.forEach((c) => {
            if (!newWidths[c.key] && c.active) {
                newWidths[c.key] = { initialWidth: 90, initialExpand: true };
                updated = true;
            }
        });
        if (updated) {
            dataTableDispatch({ type: DataTableActions.colWidths, payload: newWidths });
        }
    }, [dataTableStateRef, columns, dataTableDispatch]);

    return (
        <WidthStyle colWidths={colWidths || {}} columns={columns}>
            {children}
        </WidthStyle>
    );
};

const WidthStyle: React.ComponentType<{ colWidths: { [index: string]: IColWidth }; columns: IColumn<any>[] }> = memo(
    styled(({ children, colWidths, columns, ...props }) => <div {...props}>{children}</div>)(
        ({ theme, colWidths, columns }) =>
            colWidths &&
            columns.reduce(
                (acc: { [index: string]: any }, col, i, arr) => {
                    if (!col.active) return acc;
                    const k = col.key;
                    acc[`& .control_body_width_${k}`] = { width: `${colWidths[k]?.width || colWidths[k]?.initialWidth || 147}px`, zIndex: arr.length - i };
                    if (colWidths[k]?.sticky !== undefined) {
                        Object.assign(acc[`& .control_body_width_${k}`], {
                            position: 'sticky',
                            left: colWidths[k].sticky + 'px',
                            borderStyle: 'solid',
                            borderColor: 'rgba(0,0,0,.1)',
                            borderLeftWidth: '1px',
                            borderRightWidth: '1px'
                        });
                    } else {
                        Object.assign(acc[`& .control_body_width_${k}`], { position: 'relative', left: 'unset', border: 'none' });
                    }
                    return acc;
                },

                {}
            )
    )
);

const doSort = (rows: IRow<any>, columns: IColumn[], sort: ISortOption[]) => {
    if (!rows.length) return rows;
    let sortData = [...rows];

    sortData.sort((a, b) => {
        for (let i = 0; i < sort.length; i++) {
            const s = sort[i];
            const col = columns.find((c) => c.key === s.key);
            if (!col) continue;

            let desc = s.direction !== 'asc';
            desc = col?.revSort ? !desc : desc;

            const aVal = col.value ? col.value(a) : a[col.key];
            const bVal = col.value ? col.value(b) : b[col.key];
            let comp = 0;
            if (col.sortFunc) {
                comp = col.sortFunc(aVal, bVal);
            }
            if (typeof aVal === 'string') {
                comp = aVal.localeCompare(bVal);
            } else {
                comp = aVal < bVal ? 1 : bVal < aVal ? -1 : 0;
            }
            if (comp) return comp * (desc ? -1 : 1);
        }
        return 0;
    });
    return sortData;
};
