import React from 'react';
import { ICheckControl, CheckControl } from '../../generic';
import { useTheme, Box, makeStyles } from '@material-ui/core';
import { useAuditState, useAuditDispatch, AuditActions } from './audit.context';
import { IIdx } from '../../../types/general.type';
import { useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { Caps } from '../../generic/display/typocaps.component';
import { useInfoRequestModal, CONFIRM_TYPES } from '../../../hooks/inforequestmodal.hook';
import { MODAL_TYPE, useInfoModal } from '../../../hooks/info-modal.hook';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { useCurrentUser } from '../../../contexts/user.context';

interface IAuditCheck extends ICheckControl {}

const useStyles = makeStyles((theme) => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.33,
        cursor: 'default'
    }
}));

export const AuditDisputeCheck: React.FC<IAuditCheck> = ({ ...props }) => {
    const theme = useTheme();
    const classes = useStyles();
    const auditState = useAuditState();
    const checkPermission = usePermission(auditState.invoice);
    const auditDispatch = useAuditDispatch();
    // const userState = useUserState();
    const [ConfirmationModal, runConfirmation] = useInfoModal();
    // const selector = useCallback((state: IAuditState): [boolean] => [], [prop]);
    // const [ val ] = useSelector(useAuditState, selector);
    const val = auditState.invoice && (auditState.invoice as IIdx).dispute;
    const runRequestModal = useInfoRequestModal();
    const [user] = useCurrentUser();

    const { updateInvoices, loading } = useUpdateInvoices();

    const toggleValue = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const inv = auditState.invoice;
        if (!inv) return;

        let val = '';
        let confirm = false;
        const { type, value } = await runRequestModal({
            title: 'Dispute Invoice?',
            desc: (
                <>
                    <p>This invoice is currently approved.</p>
                    <p>Disputing this invoice will remove its approved status.</p>
                    <p>This action requires a reason for this change.</p>
                </>
            ),
            modalType: MODAL_TYPE.WARNING,
            no: false,
            cancel: true,
            collabInvoice: auditState.invoice?.carrierInvoiceID
        });
        if (type === CONFIRM_TYPES.YES) {
            confirm = true;
            val = value;
        }

        if (confirm) {
            const res = await updateInvoices([
                {
                    carrierInvoiceID: inv.carrierInvoiceID,
                    dispute: !(inv as IIdx).dispute,
                    ...(inv.approve ? { approve: false } : {}),
                    ...(val ? { reason: val } : {})
                }
            ]);
            if (res.status === 200) {
                // res.data.invoices[0].actions?.forEach((a: any) => {
                //     if (a.userID === user?.userID) {
                //         a.user = user as Models.User;
                //     }
                // });
                auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
            } else if (process.env.NODE_ENV === 'development') {
                await runConfirmation({
                    title: 'Cannot Dispute',
                    desc: (
                        <>
                            <p>There was a problem with the request.</p>
                            {(res as any)?.message && <p>{(res as any).message}</p>}
                        </>
                    ),
                    yesTxt: 'OK',
                    no: false
                });
            }
        }
    };

    return (
        <Box marginBottom={-1} display="inline-block" className={auditState.invoice && !checkPermission('dispute') ? classes.disabled : undefined}>
            {/* <InfoRequestModal /> */}
            <ConfirmationModal />
            <CheckControl
                {...props}
                labelPlacement="top"
                onClick={toggleValue}
                loading={!!loading}
                checked={!!val}
                checkSize={1.5}
                color={theme.palette.common.white}
                checkColor={theme.palette.secondary.main}
            >
                <Box marginBottom={-1}>
                    <Caps variant="body2">Dispute</Caps>
                </Box>
            </CheckControl>
        </Box>
    );
};
