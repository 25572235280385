import React, { useCallback } from 'react';
import { useAuditStateRef, useAuditState, useAuditDispatch, AuditActions } from './audit.context';
import { STIButtonbase } from '../../generic';
import { Box, Typography } from '@material-ui/core';

export const PDFToggle = () => {
    const auditState = useAuditState();
    const auditStateRef = useAuditStateRef();
    const auditDispatch = useAuditDispatch();

    const togglePDF = () => {
        auditDispatch({ type: AuditActions.attachPDF, payload: !auditStateRef.current?.attachPDF });
    };

    return (
        <STIButtonbase onClick={togglePDF}>
            <Box height="100%" padding={1.5}>
                <Typography noWrap>{auditState.attachPDF ? 'Detach PDF' : 'Attach PDF'}</Typography>
            </Box>
        </STIButtonbase>
    );
};
