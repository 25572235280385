import { IIdx } from '../../types/general.type';

export interface IDataTableAction {
    type: string;
    payload: any;
    colKey?: string;
}

export enum DataTableActions {
    sort = 'sort',
    colWidth = 'colWidth',
    colWidths = 'colWidths',
    columns = 'columns',
    replace = 'replace',
    toggleSticky = 'toggleSticky'
}

// export type ColKey = number | string;

export interface IColWidth {
    initialWidth: number;
    width?: number;
    min?: number;
    max?: number;
    initialExpand?: boolean;
    sticky?: number;
}

export interface ISortOption {
    key: string;
    direction: 'asc' | 'desc';
}

// export interface ColOrderItem {
//     key: string;
//     active: boolean;
//     label: string;
// }

export interface IDataTableState extends IIdx {
    sort: ISortOption[];
    colWidths?: { _default: IColWidth; [index: string]: IColWidth };
    columns: IColumn[];
}

//Row value is any object. the column definitions define how to get the data from the object.
// export interface IRow {
//     key: number | string;
//     value: { [index: string]: unknown; [index: number]: unknown };
//     expansionComponent?: ReactNode;
// }
export type IRow<T = any> = IIdx<T>;

export type IColumnComponent<V = any, R = any> = React.ComponentType<{ value: V; row: R; index: number; column: IColumn<R>; className?: string }>;

// if value function is not provided, the key will be used as an index on the row value object
export interface IColumn<R = any> {
    label: string;
    key: string;
    pipe?: (value: any, row: R) => any;
    revSort?: boolean;
    value?: (row: R) => any;
    style?: React.CSSProperties;
    Component?: IColumnComponent;
    sortFunc?: (a: R, b: R) => number;
    menu?: {
        text: string;
        onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    }[];
    active?: boolean;
    className?: string;
}
