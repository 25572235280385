import React from 'react';
import { useUserState } from '../../../contexts/user.context';
import { currency } from '../../../helpers/pipes';
import { FailureType, useGetUsersByClient } from '../../../hooks/stiapi.hook';
import { failureTitles } from './approveallfailed.component';

interface ApproveFailedProps {
    type: FailureType;
    invoice: string;
    message?: string;
}
export const ApproveFailed: React.FC<ApproveFailedProps> = ({ type, invoice, message }) => {
    const { user } = useUserState();
    return (
        <div>
            <p>{`Failed to approve invoice ${invoice} due to ${failureTitles[type]}.`}</p>
            {message ? <p>{`Response Message: ${message}`}</p> : null}
            {type === 'failedApprovalLimit' && user?.approvalLimit ? <p>Your approval limit: ${currency(user?.approvalLimit)}</p> : null}
        </div>
    );
};
