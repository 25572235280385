import React, { useContext, useReducer, useRef, useEffect, Dispatch } from 'react';

import { IAction } from '../../../types/contexts/context.type';
import { loggedReducer } from '../../../contexts/store.helpers';
import { useGetPermissions, useGetUsers, useGetUsersByClient } from '../../../hooks/stiapi.hook';
import { IIdx } from '../../../types/general.type';
import { Models } from 'shipmenttrackers-domain/dist';

export enum ManageType {
    'createUsers' = 'createUsers',
    'manageAccess' = 'manageAccess',
    'manageUsers' = 'manageUsers',
    'manageTemplates' = 'manageTemplates'
}

export enum ManageActions {
    manageType = 'manageType',
    permissions = 'permissions',
    userToManage = 'userToManage',
    newUser = 'newUser',
    updateUser = 'updateUser',
    clientToManage = 'clientToManage',
    clientUsers = 'clientUsers',
    allUsers = 'allUsers',
    updateUserList = 'updateUserList'
}

export interface IManageState extends IIdx {
    manageType: ManageType;
    permissions: Models.Permissions[];
    userToManage?: Models.User;
    clientToManage?: Models.Client;
    clientUsers: Models.User[];
    newUser?: Partial<Models.User>;
    allUsers: Models.User[];
}

const initialState: IManageState = {
    manageType: ManageType.createUsers,
    permissions: [],
    userToManage: undefined,
    clientUsers: [],
    allUsers: []
};

const reducer = (state: IManageState, action: IAction) => {
    const newState = action.storeOnly ? state : { ...state };

    switch (action.type) {
        case ManageActions.updateUserList:
            const update: Models.User = action.payload;
            if (newState.clientToManage && update.clientID === newState.clientToManage?.clientID) {
                const clientUserIndex = newState.clientUsers.findIndex((u) => u.userID === update.userID);
                newState.clientUsers = [...newState.clientUsers];
                if (clientUserIndex > -1) {
                    newState.clientUsers.splice(clientUserIndex, 1, update);
                } else {
                    newState.clientUsers.push(update);
                }
            }

            const allIndex = newState.allUsers.findIndex((u) => u.userID === update.userID);
            newState.allUsers = [...newState.allUsers];
            if (allIndex > -1) {
                newState.allUsers.splice(allIndex, 1, update);
            } else {
                newState.allUsers.push(update);
            }

            break;
        case ManageActions.manageType:
        case ManageActions.permissions:
        case ManageActions.userToManage:
        case ManageActions.newUser:
        case ManageActions.updateUser:
        case ManageActions.clientUsers:
        case ManageActions.allUsers:
        case ManageActions.clientToManage:
            newState[action.type] = action.payload;
            break;
        default:
            break;
    }
    return newState;
};

const logReducer = loggedReducer<IManageState>(reducer, 'Manage');

export const ManageStateContext: any = React.createContext({});
export const ManageDispatchContext: any = React.createContext({});
export const ManageStateRefContext: any = React.createContext({});

export const useManageState = (): IManageState => useContext(ManageStateContext);
export const useManageDispatch = (): Dispatch<IAction> => useContext(ManageDispatchContext);
export const useManageStateRef = (): React.MutableRefObject<IManageState> => useContext(ManageStateRefContext);

export const ManageProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(logReducer, initialState);
    const stateRef = useRef<IManageState>(state);
    const { getPermissions } = useGetPermissions();
    const { getUsersByClient } = useGetUsersByClient();
    const { getUsers } = useGetUsers();

    useEffect(() => {
        (async () => {
            const res = await getUsers();
            if (res.status === 200) {
                dispatch({ type: ManageActions.allUsers, payload: res.data });
            }
        })();
    }, [getUsers, dispatch]);

    useEffect(() => {
        stateRef.current = state;
    }, [state, stateRef]);

    useEffect(() => {
        (async () => {
            const perm = await getPermissions();
            if (perm.status === 200) {
                dispatch({ type: ManageActions.permissions, payload: perm.data });
            }
        })();
    }, [dispatch, getPermissions]);

    useEffect(() => {
        (async () => {
            if (!state.clientToManage) return;
            const res = await getUsersByClient(state.clientToManage.clientID);
            if (res.status === 200) {
                dispatch({ type: ManageActions.clientUsers, payload: res.data });
            }
        })();
    }, [getUsersByClient, state.clientToManage]);

    return (
        <ManageStateContext.Provider value={state}>
            <ManageDispatchContext.Provider value={dispatch}>
                <ManageStateRefContext.Provider value={stateRef}>{children}</ManageStateRefContext.Provider>
            </ManageDispatchContext.Provider>
        </ManageStateContext.Provider>
    );
};
