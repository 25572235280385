import React, { useReducer, useState, useRef } from 'react';
import { styled, Grid, Typography, TextField, Box, Button } from '@material-ui/core';
import { useCreatePassword, useResetForgottenPassword, useResetPassword } from '../hooks/stiapi.hook';
import { ExtendedButton } from '../components/generic/controls/extendedbutton.component';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { UserActions, useUserDispatch } from '../contexts/user.context';
import { toQueryParams } from '../helpers';

interface FormState {
    code: string;
    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
}

const formState: FormState = {
    code: '',
    email: '',
    password: '',
    newPassword: '',
    confirmPassword: ''
};

interface ResetLoginPageProps {
    useOldPassword?: boolean;
    useCode?: boolean;
    useToken?: boolean;
    verb?: string;
}

const reducer = (state: FormState, update: Partial<FormState>) => ({ ...state, ...update });

export const ResetLoginPage: React.FC<ResetLoginPageProps> = ({ useCode, useOldPassword, useToken, verb = 'Reset' }) => {
    const [state, dispatch] = useReducer(reducer, formState);
    const { resetForgottenPassword: reset, loading } = useResetForgottenPassword();
    const { createPassword } = useCreatePassword();
    // const [status, setStatus] = useState<number | undefined>();
    const history = useHistory();
    const { resetPassword } = useResetPassword();
    const userDispatch = useUserDispatch();
    const location = useLocation();

    const timeoutRef = useRef<NodeJS.Timeout>();
    const [status, setStatus] = useState<'success' | 'error'>();

    const submitLogin = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        let res: AxiosResponse<any> | undefined;
        if (useCode) {
            res = await reset(state.code, state.email, state.newPassword);
        } else if (useOldPassword) {
            res = await resetPassword(state.email, state.password, state.newPassword);
        } else if (useToken) {
            const { token } = toQueryParams(location.search);

            res = await createPassword(token, state.newPassword);
        }

        const status = res?.status === 201 ? res.data?.Status || res.status : res?.status;

        if (status === 200 || status === 201) {
            setStatus('success');
            timeoutRef.current = setTimeout(login, 3000);
        } else setStatus('error');
    };

    const onChange = (evt: React.ChangeEvent<any>) => {
        dispatch({ [evt.target.name]: evt.target.value });
    };
    const login = () => {
        userDispatch({ type: UserActions.LOGOUT });
        history.push('/login');
    };

    return (
        <Main container alignItems="center" justify="center">
            <Center item container direction="column" justify="center">
                <LogoContainer item container justify="center">
                    <img src={`${process.env.PUBLIC_URL}/img/sti_logo.png`} alt="sti logo" />
                </LogoContainer>
                <LoginCard item container direction="column" justify="center">
                    <LoginForm item container direction="column">
                        <LoginHeader fontWeight="fontWeightLight" textAlign="center">
                            <Typography variant="h4">
                                {status === 'success' ? (
                                    <>
                                        Password {verb}
                                        <br></br>Success
                                    </>
                                ) : (
                                    <>
                                        {verb} Your<br></br>
                                        Password
                                    </>
                                )}
                            </Typography>
                        </LoginHeader>
                        <form onSubmit={submitLogin}>
                            <Grid container direction="column" spacing={2} justify="center">
                                {status !== 'success' && (
                                    <>
                                        {useCode ? (
                                            <Grid item>
                                                <TextField
                                                    value={state.code}
                                                    name="code"
                                                    required
                                                    type="string"
                                                    label="Reset Code"
                                                    fullWidth
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        ) : null}
                                        {!useToken ? (
                                            <Grid item>
                                                <TextField
                                                    value={state.email}
                                                    name="email"
                                                    required
                                                    type="string"
                                                    label="Email"
                                                    fullWidth
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        ) : null}
                                        {useOldPassword ? (
                                            <Grid item>
                                                <TextField
                                                    value={state.password}
                                                    name="password"
                                                    required
                                                    type="password"
                                                    label="Old Password"
                                                    fullWidth
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        ) : null}
                                        <Grid item>
                                            <TextField
                                                value={state.newPassword}
                                                name="newPassword"
                                                required
                                                type="password"
                                                label="New Password"
                                                fullWidth
                                                onChange={onChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                value={state.confirmPassword}
                                                name="confirmPassword"
                                                error={state.newPassword !== state.confirmPassword}
                                                helperText={state.newPassword !== state.confirmPassword ? 'Passwords must match.' : ''}
                                                inputProps={{
                                                    pattern: state.newPassword
                                                }}
                                                required
                                                type="password"
                                                label="Confirm New Password"
                                                fullWidth
                                                onChange={onChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {useOldPassword && state.password === state.newPassword ? (
                                                'New password must be different'
                                            ) : state.newPassword !== state.confirmPassword ? (
                                                'Passwords must match.'
                                            ) : status === 'error' ? (
                                                <Typography color="error">There was a problem resetting your password.</Typography>
                                            ) : null}
                                        </Grid>
                                    </>
                                )}

                                <Grid item>
                                    {status !== 'success' ? (
                                        <ExtendedButton
                                            disabled={!state.newPassword || state.newPassword !== state.confirmPassword}
                                            loading={!!loading}
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                        >
                                            {verb.toUpperCase()}
                                        </ExtendedButton>
                                    ) : (
                                        <Button variant="contained" fullWidth onClick={login}>
                                            LOGIN
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </LoginForm>
                </LoginCard>
            </Center>
        </Main>
    );
};

const Main = styled(Grid)(({ theme }) => ({
    width: '100%',
    height: '100%',
    background: `url(${process.env.PUBLIC_URL}/img/bkgrdlogin.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
}));

const Center = styled(Grid)(({ theme }) => ({
    position: 'relative',
    width: 'auto',
    minWidth: theme.spacing(45)
}));

const LogoContainer = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    bottom: `calc(100% + ${theme.spacing(4)}px)`
}));

const LoginCard = styled(Grid)(({ theme }) => ({
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5)
}));

const LoginHeader = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& *': {
        fontWeight: 'inherit'
    }
}));

const LoginForm = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4)
}));

// const LoginFooter = styled(Grid)(({ theme }) => ({
//     background: 'lightgray',
//     borderBottomRightRadius: theme.spacing(1.5),
//     borderBottomLeftRadius: theme.spacing(1.5),
//     paddingTop: theme.spacing(2),
//     paddingLeft: theme.spacing(2),
//     paddingBottom: theme.spacing(2),
//     paddingRight: theme.spacing(2)
// }));

// const CleanedLink = styled('a')(({ theme }) => ({
//     textDecoration: 'none',
//     color: 'inherit',
//     cursor: 'pointer',
//     '&:hover': {
//         textDecoration: 'underline'
//     }
// }));
