import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { ISingleSelectAuto, SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { IAuditState, useAuditState, useAuditStateRef, useAuditDispatch, AuditActions } from './audit.context';
import { useSelector } from '../../../contexts/store.helpers';
import { Models } from 'shipmenttrackers-domain/dist';
import { useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { useInfoRequestModal, MODAL_TYPE, CONFIRM_TYPES } from '../../../hooks/inforequestmodal.hook';
import { IAppState } from '../../../types/contexts/app-context.type';
import { useAppState } from '../../../contexts/app.context';
import { makeStyles } from '@material-ui/core';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';

const statusSelector = (state: IAuditState): [Models.CarrierInvoices | undefined] => [state.invoice];
const optionsSelector = (state: IAppState): [Models.IpAuditStatus[]] => [state.data.auditStatusOptions];

const useStyles = makeStyles((theme) => ({
    override: {
        '& .MuiFormLabel-root.Mui-disabled, & input.Mui-disabled': {
            color: theme.palette.text.primary
        }
    }
}));

export const AuditStatusSelect: React.FC<ISingleSelectAuto<Partial<Models.IpAuditStatus>>> = ({ ...props }) => {
    const classes = useStyles();
    const auditStateRef = useAuditStateRef();
    const auditDispatch = useAuditDispatch();
    const [invoice] = useSelector(useAuditState, statusSelector);
    const [statusOptions] = useSelector(useAppState, optionsSelector);
    const { updateInvoices, loading: updatingInvoices } = useUpdateInvoices();
    const runRequestModal = useInfoRequestModal();
    const [val, setVal] = useState<Partial<Models.IpAuditStatus>>();
    // const userState = useUserState();
    const [user] = useCurrentUser();

    const options = useMemo<Partial<Models.IpAuditStatus>[]>(
        () => [{ auditStatus: 'No Status' }, ...statusOptions.sort((a, b) => ((a as any).appOrder || 0) - ((b as any).appOrder || 0))],
        [statusOptions]
    );

    const onChange = useCallback(
        async (event: any, status?: Partial<Models.IpAuditStatus>) => {
            const inv = auditStateRef.current.invoice;
            if (!inv) return;
            if (status) {
                let confirm = inv.auditStatus === 'Unaudited' && status.auditStatus === 'Audited';
                let reason = '';
                if (!confirm) {
                    const { type, value } = await runRequestModal({
                        title: `Change to ${status.auditStatus}`,
                        desc: `Updating the status from ${inv.auditStatus} to ${status.auditStatus} requires a reason for the change.`,
                        modalType: MODAL_TYPE.WARNING,
                        no: false,
                        cancel: true,
                        collabInvoice: inv?.carrierInvoiceID
                    });
                    reason = value;
                    confirm = type === CONFIRM_TYPES.YES;
                }

                if (confirm) {
                    setVal(status);
                    const invoiceRes = await updateInvoices([
                        { carrierInvoiceID: inv.carrierInvoiceID, auditStatus: status?.auditStatus, ...(reason ? { reason: reason } : {}) }
                    ]);
                    if (invoiceRes.status === 200) {
                        // invoiceRes.data.invoices[0].actions?.forEach((a) => {
                        //     if (a.userID === user?.userID) {
                        //         a.user = user as Models.User;
                        //     }
                        // });
                        auditDispatch({ type: AuditActions.invoice, payload: invoiceRes.data.invoices[0] });
                    }
                }
            }
        },
        [auditStateRef, runRequestModal, updateInvoices, auditDispatch]
    );

    useEffect(() => {
        setVal(options.find((o) => o.auditStatus === invoice?.auditStatus));
    }, [setVal, options, invoice]);

    return (
        <>
            {/* <InfoRequestModal /> */}

            <SingleSelectAuto
                disabled={!!updatingInvoices}
                {...props}
                className={`${props.className} ${classes.override}`}
                value={val || options[0]}
                options={options}
                // nullString="No Status"
                loading={!!updatingInvoices}
                onValChange={onChange}
                getText={(s) => s.auditStatus || ''}
                getSubtext={(s) => s.auditStatusDescription || ''}
                getValue={(s) => s.auditStatus}
            />
        </>
    );
};
