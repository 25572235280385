import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useAppState } from '../../../contexts/app.context';
import { currencyParts, prettyNum } from '../../../helpers/pipes';
import { useFonts } from '../../../styles/font.style';
import { SummaryContainer } from './common';

export const Component: React.FC = () => {
    const appState = useAppState();
    const filterCurrencyParts = currencyParts(appState.invoiceSumFiltered || 0);
    const totalCurrencyParts = currencyParts(appState.invoiceSumAll);
    const fontClasses = useFonts();

    return (
        <SummaryContainer container justify="flex-end">
            <Grid item>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="subtitle2" noWrap>
                        {'Filtered Invoices / '}
                    </Typography>
                    <Typography color="secondary" variant="subtitle2" component="span">
                        Amount
                    </Typography>
                </Grid>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="h5" noWrap className={`${fontClasses.openSansSemiBold}`}>
                        {`${prettyNum(appState.invoiceCountFiltered || 0)} / `}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        $
                    </Typography>
                    <Typography component="span" variant="h5" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {filterCurrencyParts[0]}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {`.${filterCurrencyParts[1]}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="subtitle2" noWrap>
                        {'Total Invoices / '}
                    </Typography>
                    <Typography component="span" variant="subtitle2" color="secondary">
                        Amount
                    </Typography>
                </Grid>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="h5" noWrap className={`${fontClasses.openSansSemiBold}`}>
                        {`${prettyNum(appState.invoiceCountAll)} / `}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        $
                    </Typography>
                    <Typography component="span" variant="h5" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {totalCurrencyParts[0]}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {`.${totalCurrencyParts[1]}`}
                    </Typography>
                </Grid>
            </Grid>
        </SummaryContainer>
    );
};

export const SummaryPanel = memo(Component);
