import React, { useState, useRef, useMemo } from 'react';
import { makeStyles, TextField, Popper, ClickAwayListener, TextFieldProps } from '@material-ui/core';
import { Models } from 'shipmenttrackers-domain/dist';
import { IAuditState, useAuditState } from '../audit.context';
import { Autocomplete, FilterOptionsState, AutocompleteProps } from '@material-ui/lab';

import { UserListItem } from '../userlistitem.component';
import { useCollabOptions } from '../../../../hooks/usecollaboptions.hook';

const useNoteInputStyles = makeStyles((theme) => ({
    textField: {
        fontFamily: "'Open Sans', sans-serif"
    },
    listItem: {
        position: 'relative',
        width: '100%'
    },
    emailOff: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.error.main
    },
    dot: {
        borderRadius: '999px',
        position: 'absolute',
        top: '12%',
        left: theme.spacing(-1.25),
        width: theme.spacing(1),
        height: theme.spacing(1)
    },
    popper: {
        boxShadow: theme.shadows[3],
        '& .MuiPaper-root': {
            margin: 0
        }
    }
}));

type INoteInput = Partial<AutocompleteProps<Partial<Models.User>, false, true, false>> &
    Pick<TextFieldProps, 'autoFocus' | 'variant' | 'label' | 'multiline' | 'rows' | 'rowsMax' | 'required'> & {
        newNote: string;
        setNewNote: (n: string) => void;
        addTag: (t: Models.User) => void;
        collabOptions?: Models.User[];
    };

export const NoteInput: React.FC<INoteInput> = ({
    newNote,
    setNewNote,
    collabOptions,
    addTag,
    autoFocus,
    variant,
    label,
    multiline,
    rows,
    rowsMax,
    required,
    ...props
}) => {
    const auditState: IAuditState | undefined = useAuditState();
    const classes = useNoteInputStyles();
    const [potentialTag, setPotentialTag] = useState('');
    const [open, setOpen] = useState(false);
    const posRef = useRef<number>(0);
    const inputRef = useRef<HTMLTextAreaElement>();

    const sortedOptions = useMemo(() => {
        return (collabOptions ?? auditState.collabOptions ?? []).sort(
            (a, b) => a.accountType - b.accountType || `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
        );
    }, [auditState.collabOptions, collabOptions]);

    const updateNewNote = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const val = evt.target.value;
        posRef.current = evt.target.selectionEnd !== null ? evt.target.selectionEnd : val.length;
        if (val.endsWith('@ ')) setOpen(false);
        if (val.charAt(val.length - 1) === '@') {
            setOpen(true);
            setPotentialTag('');
        } else if (newNote === val + '@') {
            setOpen(false);
            setPotentialTag('');
        }
        setNewNote(val);
        if (open) {
            let start = val.slice(0, posRef.current);
            const parts = start.split('@');
            setPotentialTag(parts[parts.length - 1]);
        }
    };

    const onSelect = (event: React.ChangeEvent<{}>, u: Partial<Models.User>) => {
        let start = newNote.slice(0, posRef.current);
        let end = newNote.slice(posRef.current);
        const parts = start.split('@');
        parts[parts.length - 1] = `${u.firstName} ${u.lastName}`;
        const newPart = parts.join('@');
        setNewNote(newPart + end);
        setOpen(false);
        addTag(u as Models.User);
    };

    const onKey = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === '@') setOpen(true);
        if (event.key === 'Escape') setOpen(false);
    };

    return (
        <Autocomplete<Partial<Models.User>, false, true, false>
            disableCloseOnSelect
            disableClearable
            autoHighlight
            fullWidth
            clearOnBlur={false}
            clearOnEscape={false}
            popupIcon={null}
            {...props}
            options={sortedOptions}
            open={open}
            value={{}}
            groupBy={groupBy}
            onChange={onSelect}
            filterOptions={filterOptions(potentialTag)}
            getOptionLabel={(u) => `${u.firstName} ${u.lastName}`}
            PopperComponent={({ style, className, ...props }) => (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Popper
                        placement="top-start"
                        {...props}
                        style={{ ...style, width: 'auto', maxWidth: '360px' }}
                        className={`${className} ${classes.popper}`}
                    />
                </ClickAwayListener>
            )}
            renderOption={(u, i) => <UserListItem user={u} />}
            renderInput={({ InputProps, inputProps, ...params }) => {
                inputRef.current = (inputProps as any).ref.current;
                return (
                    <TextField
                        autoFocus={autoFocus}
                        onChange={updateNewNote}
                        className={`${classes.textField}`}
                        InputProps={{ ...InputProps }}
                        multiline={multiline ?? true}
                        rows={rows}
                        rowsMax={rowsMax}
                        variant={variant}
                        required={required}
                        {...params}
                        inputProps={{ ...inputProps, value: newNote, onKeyDown: onKey }}
                        label={label ?? 'ADD NOTE'}
                        // value={newNote}
                    />
                );
            }}
        />
    );
};

const filterOptions = (val: string) => (options: Partial<Models.User>[], state: FilterOptionsState<Models.User>) => {
    if (!val) return options;
    val = val.toLocaleLowerCase();
    return options.filter(
        (o) =>
            `${o.firstName} ${o.lastName}`.toLocaleLowerCase().includes(val) ||
            o.username?.toLocaleLowerCase().includes(val) ||
            o.email?.toLocaleLowerCase().includes(val)
    );
};

const groupBy = (o: Partial<Models.User>) => (o.accountType === 1 ? 'STI' : 'More Users');
