import React, { useState, useCallback, useRef } from 'react';
import { Modal, Grid, Typography, Button, makeStyles, Box, Checkbox, SvgIconProps } from '@material-ui/core';
import { Panel, GridMin, GridGrow } from '../components/generic';
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline';
import InformationOutline from 'mdi-material-ui/InformationOutline';
import AlertOutline from 'mdi-material-ui/AlertOutline';

// EXAMPLE:
// const [ConfirmationModal, runConfirmationModal] = useInfoModal();
//
// runConfirmationModal({ title: 'Are you sure?', desc: 'Really?', modalType: MODAL_TYPE.QUESTION, actionTypes: ACTION_TYPES.YN }).then(
//     (resp: string) => {
//         if (resp === CONFIRM_TYPES.YES) {
//             *do the things
//         }
//     }
// );
//
// return (<>
//             <ConfirmationModal />
//             ...
//         </>
// )

export enum CONFIRM_TYPES {
    YES = 'YES',
    NO = 'NO',
    CANCEL = 'CANCEL'
}

export enum MODAL_TYPE {
    QUESTION = 'QUESTION',
    INFO = 'INFO',
    WARNING = 'WARNING'
}

interface IModalOptions {
    title?: string;
    desc?: React.ReactChild;
    modalType?: MODAL_TYPE;
    yes?: boolean;
    yesTxt?: string;
    no?: boolean;
    cancel?: boolean;
    rememberOptionKey?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    panel: {
        maxWidth: '50%',
        maxHeight: '90%',
        overflow: 'auto'
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    },
    iconWrapper: {
        color: theme.palette.common.white,
        width: '100%',
        height: '100%',
        padding: `0px ${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        fontSize: theme.spacing(6)
    },
    question: {
        backgroundColor: theme.palette.primary.main
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: theme.palette.error.main
    }
}));

interface IModalState extends IModalOptions {
    resolver: { (val: any): void };
}

const GetIcon: React.FC<SvgIconProps & { type?: MODAL_TYPE }> = ({ type, ...props }) => {
    switch (type) {
        case MODAL_TYPE.QUESTION:
            return <HelpCircleOutline {...props} />;
        case MODAL_TYPE.INFO:
            return <InformationOutline {...props} />;
        case MODAL_TYPE.WARNING:
            return <AlertOutline {...props} />;
        default:
            return <HelpCircleOutline {...props} />;
    }
};

export const useInfoModal = (): [React.ComponentType, (options: IModalOptions) => any] => {
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState<IModalState>({
        title: '',
        desc: '',
        yes: true,
        no: true,
        modalType: MODAL_TYPE.QUESTION,
        resolver: (val?: void) => {}
    });

    const rememberOption = useRef(false);

    const classes = useStyles();

    const run = useCallback(
        (type) => (evt: any) => {
            if (rememberOption.current && state.rememberOptionKey && type !== CONFIRM_TYPES.CANCEL) {
                sessionStorage.setItem(state.rememberOptionKey, type);
            }
            state.resolver(type);
            setShowModal(false);
        },
        [state, setShowModal, rememberOption]
    );

    const closeModal = useCallback(() => setShowModal(false), []);

    const handleRememberOption = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            rememberOption.current = checked;
        },
        [rememberOption]
    );

    const InfoModal = () => (
        <Modal open={showModal} onClose={closeModal} className={classes.root}>
            <Panel className={classes.panel}>
                <Grid container spacing={2} wrap="nowrap">
                    <GridMin container wrap="nowrap" alignItems="center" spacing={2}>
                        <Box className={`${classes.iconWrapper} ${state.modalType === MODAL_TYPE.WARNING ? classes.warning : classes.info}`}>
                            <GetIcon type={state.modalType} className={classes.icon} />
                        </Box>
                    </GridMin>
                    <GridGrow container direction="column" spacing={2}>
                        <Grid item container direction="column">
                            <Typography color="primary" variant="h6">
                                {state.title}
                            </Typography>
                            <Typography variant="body2">{state.desc}</Typography>
                            {state.rememberOptionKey && (
                                <Box marginTop={2}>
                                    <Grid container alignItems="center">
                                        <Checkbox onChange={handleRememberOption} />
                                        <Typography variant="body2">
                                            <p>Remember this option and do not show this dialog again.</p>
                                        </Typography>
                                    </Grid>
                                </Box>
                            )}
                        </Grid>
                        <Grid item container wrap="nowrap" spacing={2} justify="flex-end">
                            {state.yes && (
                                <Grid item>
                                    <Button color="secondary" variant="contained" onClick={run(CONFIRM_TYPES.YES)}>
                                        {state.yesTxt || 'Yes'}
                                    </Button>
                                </Grid>
                            )}
                            {state.no && (
                                <Grid item>
                                    <Button variant="contained" onClick={run(CONFIRM_TYPES.NO)} className={classes.error}>
                                        No
                                    </Button>
                                </Grid>
                            )}
                            {state.cancel && (
                                <Grid item>
                                    <Button variant="contained" onClick={run(CONFIRM_TYPES.CANCEL)}>
                                        Cancel
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </GridGrow>
                </Grid>
            </Panel>
        </Modal>
    );

    const runModal = useCallback(
        async (options: IModalOptions): Promise<any> => {
            const res = await new Promise((resolve, reject) => {
                const storedVal = options.rememberOptionKey && sessionStorage.getItem(options.rememberOptionKey);
                if (storedVal) {
                    resolve(storedVal);
                } else {
                    setState({ yes: true, no: true, ...options, resolver: resolve });
                    setShowModal(true);
                }
            });
            return res;
        },
        [setState, setShowModal]
    );

    return [InfoModal, runModal];
};
