import { StorageKeys } from './store.helpers';

export const getStoredItem = (key: StorageKeys) => {
    try {
        const item = window.localStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        } else {
            return item;
        }
    } catch (err) {
        console.log('Error getting stored item ', err);
    }
    return null;
};

export const setStoredItem = (key: StorageKeys, data: unknown) => {
    try {
        return window.localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
        console.log('Error setting stored item');
    }
    return;
};

export const clearStoredItem = (key: StorageKeys) => {
    try {
        return window.localStorage.removeItem(key);
    } catch (error) {
        console.log('Error removing stored item');
    }
    return;
};
