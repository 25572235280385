import React from 'react';
import { ModalProps, styled, Modal, Backdrop, Slide } from '@material-ui/core';

export const STIModal: React.FC<ModalProps> = ({ children, ...props }) => {
    return (
        <FlexModal
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            {...props}
        >
            <Slide direction="down" in={props.open} unmountOnExit>
                <div>{children}</div>
            </Slide>
        </FlexModal>
    );
};

const FlexModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
        outline: 'none'
    },
    maxWidth: '50%',
    margin: 'auto'
});
