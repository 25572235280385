import React, { useMemo } from 'react';
import { AppActions, IViewMode } from '../../../types/contexts/app-context.type';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { usePermission } from '../../../hooks/permission/permissions.hook';

// const modeselector = (state: IAppState): [IMultiSelectOption[], string] => {
//     const mode = state.homeTableMode;
//     const modeOptions: IMultiSelectOption[] = Object.keys(state.data.tableDefs).map((k) => ({
//         text: state.data.tableDefs[k].title,
//         value: k,
//         subText: state.data.tableDefs[k].desc
//     }));
//     return [modeOptions, mode];
// };

export const ModeSelect = () => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const appState = useAppState();
    const mode = appState.homeTableMode;
    const appDispatch = useAppDispatch();
    const checkPermission = usePermission();

    const modeOptions = useMemo(() => Object.values(appState.data.tableDefs).filter((d) => (checkPermission() ? true : !d.sti)), [
        appState.data.tableDefs,
        checkPermission
    ]);

    const onModeChange = (evt: React.ChangeEvent<any>, val?: IViewMode) => {
        appDispatch({ type: AppActions.homeTableMode, payload: val?.key });
    };

    return (
        <SingleSelectAuto<IViewMode>
            fullWidth
            variant="filled"
            className={inputClasses.input}
            menuClasses={menuClasses}
            initialVal={appState.data.tableDefs[mode]}
            options={modeOptions}
            label="Mode"
            onValChange={onModeChange}
            getText={(m) => m.title}
            getSubtext={(m) => m.desc}
        />
    );
};
