import React, { useEffect, FormEvent } from 'react';

import { TextField, makeStyles, Popper, fade, ListItemText, CircularProgress, useTheme } from '@material-ui/core';

import { Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails, AutocompleteProps } from '@material-ui/lab';
import { useFonts } from '../../../styles/font.style';
import { useStateWithRef } from '../../../hooks/statewithref.hook';
import { ReactNode } from 'react';

export type OnValChange<T> = (
    event: React.ChangeEvent<{}> | FormEvent<HTMLDivElement>,
    value: T[],
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
) => void;

export interface IMultiSelectAuto<T> extends Partial<Omit<AutocompleteProps<T, true, boolean | undefined, false>, 'getOptionSelected' | 'getOptionLabel'>> {
    label?: string;
    onValChange?: OnValChange<T>;
    options?: T[];
    menuClasses?: any;
    initialVal?: T[];
    variant?: 'filled' | 'standard' | 'outlined' | undefined;
    disableNull?: boolean;
    nullString?: string;
    value?: T[];
    getText?: (opt: T) => string;
    getSubtext?: (opt: T) => string;
    getValue?: (opt: T) => any;
    getTag?: (opt: T) => ReactNode;
    getDotColor?: (opt: T) => string;
}

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        '& fieldset': {
            display: 'none'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(12px, 10px) scale(0.75)'
        },
        '& .MuiInputBase-root': {
            paddingTop: '27px',
            paddingBottom: '10px',
            paddingRight: '9px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            flexWrap: 'nowrap'
        },
        '& .MuiAutocomplete-input': {
            paddingBottom: '0px !important',
            paddingTop: '0px !important'
        }
    },
    list: {
        '& .MuiAutocomplete-option[data-focus="true"]': {
            backgroundColor: fade(theme.palette.primary.main, 0.33)
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: fade(theme.palette.primary.main, 0.15)
        }
    },
    listItem: {
        position: 'relative',
        width: '100%'
    },
    emailOff: {
        position: 'absolute',
        top: theme.spacing(-0.5),
        right: theme.spacing(-0.5),
        color: theme.palette.error.main
    },
    dot: {
        borderRadius: '999px',
        position: 'absolute',
        top: '12%',
        left: theme.spacing(-1.25),
        width: theme.spacing(1),
        height: theme.spacing(1)
    },
    selection: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

export function MultiSelectAuto<T>({
    label,
    onValChange,
    options = [],
    menuClasses,
    initialVal,
    className,
    variant,
    renderInput,
    loading,
    disableNull,
    nullString,
    getText,
    getSubtext,
    getValue,
    getTag,
    getDotColor,
    ...props
}: IMultiSelectAuto<T>) {
    const theme = useTheme();
    const [val, setVal] = useStateWithRef<T[]>([]);
    const classes = useStyles();
    const fontClasses = useFonts();

    useEffect(() => {
        setVal(initialVal || []);
    }, [initialVal, setVal]);

    const handleChange = (
        evt: React.ChangeEvent<{}> | FormEvent<HTMLDivElement>,
        value: T[],
        reason?: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<T> | undefined
    ) => {
        let v = value;
        if ((evt as any).ctrlKey && (reason === 'select-option' || reason === 'remove-option') && details?.option) {
            v = [details.option];
        }
        setVal(v);
        onValChange && v && onValChange(evt, v, reason, details);
    };

    return (
        <Autocomplete
            {...props}
            className={`${className} ${classes.autocomplete}`}
            disableCloseOnSelect
            disableClearable
            openOnFocus
            includeInputInList
            onChange={handleChange as any}
            value={props.value || val}
            options={options}
            // getOptionSelected={(o) => !!val.find((v) => (getValue ? getValue(v) === getValue(o) : v === o))}
            getOptionLabel={getText}
            getOptionDisabled={(option) => (disableNull && (getValue ? getValue(option) : option) === null) || false}
            popupIcon={null}
            PopperComponent={({ style, className, ...props }) => (
                <Popper placement="bottom-start" {...props} style={{ ...style, width: 'auto', maxWidth: '360px' }} className={`${className} ${classes.list}`} />
            )}
            renderOption={
                props.renderOption ||
                ((option, i) => {
                    const tag = getTag && getTag(option);
                    const color = getDotColor && getDotColor(option);
                    return (
                        <div className={classes.listItem}>
                            {color && <div className={classes.dot} style={{ backgroundColor: color }} />}
                            <ListItemText primary={getText ? getText(option) : option} secondary={getSubtext ? getSubtext(option) : ''} />
                            {tag || null}
                        </div>
                    );
                })
            }
            renderInput={
                renderInput
                    ? renderInput
                    : ({ InputProps, inputProps, ...params }) => (
                          <TextField
                              className={`${fontClasses.openSans}`}
                              InputProps={{ ...InputProps, endAdornment: loading && <CircularProgress size={theme.spacing(2.25)} /> }}
                              {...params}
                              inputProps={{ ...inputProps, 'data-lpignore': 'true' }}
                              label={label}
                              variant={variant}
                          />
                      )
            }
            renderTags={
                (value: T[], getTagProps) => (
                    <div className={classes.selection}>{value.length === 1 ? `${getText ? getText(value[0]) : value[0]}` : `${value.length} Selected`}</div>
                )
                // value.map((option: IMultiSelectOption, index: number) => <Chip variant="outlined" label={'label'} {...getTagProps({ index })} />)
            }
        />
    );
}
