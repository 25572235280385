import React from 'react';
import { SortableContainer, SortEvent } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core';
import { IColumn, DataTableActions } from '../datatable.types';
import { memo, useCallback } from 'react';
import { useDataTableStateRef, useDataTableDispatch, useDataTableState } from '../datatable.context';
import { HeaderCell } from './headercell.component';

interface IHeaderRow {
    sortable?: boolean;
    resizable?: boolean;
    height: number;
    columns?: IColumn[];
    tableRef: React.MutableRefObject<HTMLDivElement | undefined>;
    menuClasses?: any;
    cellClassName?: string;
    // colWidths?: { [index: string]: IColWidth };
}

const SortableWrapper = SortableContainer(({ children }: { children: any }) => children);

const useSortStyles = makeStyles((theme) => ({
    header: {
        zIndex: 10
    },
    sortHelper: {
        cursor: 'grabbing',
        '& *': { cursor: 'grabbing' }
    }
}));

const SortableHeaderRow: React.FC<React.HTMLAttributes<HTMLDivElement> & IHeaderRow> = memo((props) => {
    const dataTableStateRef = useDataTableStateRef();
    const dataTableDispatch = useDataTableDispatch();

    const sortClasses = useSortStyles();

    const { tableRef } = props;

    const onReorderEnd = useCallback(
        ({ oldIndex, newIndex, collection, isKeySorting }, evt: SortEvent) => {
            const dragOut = 64;

            const columns = dataTableStateRef.current.columns;

            const newOrder = [...columns];

            if (tableRef?.current) {
                const tableWindow = tableRef.current.getBoundingClientRect();
                const e = evt instanceof MouseEvent ? evt : evt instanceof TouchEvent ? evt.touches.item(0) : undefined;
                if (
                    e &&
                    (e.clientX < tableWindow.left - dragOut ||
                        e.clientX > tableWindow.right + dragOut ||
                        e.clientY < tableWindow.top - dragOut ||
                        e.clientY > tableWindow.bottom + dragOut)
                ) {
                    newOrder[oldIndex].active = false;
                    return dataTableDispatch({ type: DataTableActions.columns, payload: newOrder });
                }
            }
            const moved = newOrder.splice(oldIndex, 1);
            newOrder.splice(newIndex, 0, moved[0]);
            dataTableDispatch({ type: DataTableActions.columns, payload: newOrder });
        },
        [dataTableStateRef, dataTableDispatch, tableRef]
    );

    return (
        <SortableWrapper axis="x" useDragHandle onSortEnd={onReorderEnd} distance={5} helperClass={`${sortClasses.sortHelper} ${props.cellClassName}`}>
            <SimpleHeaderRow {...props} className={`${props.className} ${sortClasses.header}`} />
        </SortableWrapper>
    );
});

const SimpleHeaderRow: React.FC<React.HTMLAttributes<HTMLDivElement> & IHeaderRow> = memo(({ columns, className, cellClassName, ...props }) => {
    // const dataTableStateRef = useDataTableStateRef();

    return (
        <div className={className}>
            {columns?.reduce((acc: React.ReactNode[], c, i) => {
                if (c.active) {
                    acc.push(
                        <HeaderCell
                            // initialWidth={dataTableStateRef.current.colWidths[c.key]?.initialWidth || (colWidths && colWidths[c.key])?.initialWidth || 120}
                            // minWidth={dataTableStateRef.current.colWidths[c.key]?.min || (colWidths && colWidths[c.key])?.min || 4}
                            // maxWidth={dataTableStateRef.current.colWidths[c.key]?.max || (colWidths && colWidths[c.key])?.max}
                            cellClassName={cellClassName}
                            key={`h ${c.key}`}
                            column={c}
                            colIdx={i}
                            {...props}
                        />
                    );
                }
                return acc;
            }, [])}
        </div>
    );
});

export const HeaderRow: React.FC<React.HTMLAttributes<HTMLDivElement> & IHeaderRow> = (props) => {
    const { columns } = useDataTableState();
    return props.sortable ? <SortableHeaderRow columns={columns} {...props} /> : <SimpleHeaderRow columns={columns} {...props} />;
};
