import React from 'react';

import { useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { ExtendedIconButton } from '../../generic/controls/extendediconbutton.component';
import { AuditActions, useAuditDispatch, useAuditState } from './audit.context';
import { useInvoiceDispatch } from '../../../contexts/invoice.context';
import { InvoiceActions } from '../../../types/contexts/invoice-context.type';
import { Box, useTheme } from '@material-ui/core';
import { STITooltip } from '../../generic';

export const AuditGo: React.FC<{ beforeChange: () => Promise<boolean> }> = ({ beforeChange }) => {
    const { updateInvoices, loading: updatingInvoices } = useUpdateInvoices();
    const auditState = useAuditState();
    const auditDispatch = useAuditDispatch();
    const invoiceDispatch = useInvoiceDispatch();
    const theme = useTheme();

    const handleGo = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!(await beforeChange())) return;

        const inv = auditState.invoice;
        const setAuditResp = await updateInvoices([{ carrierInvoiceID: inv!.carrierInvoiceID, auditStatus: 'Audited' }]);
        if (setAuditResp.status === 200) {
            invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: setAuditResp.data.invoices });
            auditDispatch({ type: AuditActions.invoiceAppIdx, payload: auditState.invoiceAppIdx + 1 });
        }
    };

    let img;
    if (auditState.invoice?.auditStatus === 'Audited') {
        img = `${process.env.PUBLIC_URL}/img/icon_shield_grn@2x.png`;
    } else {
        img = `${process.env.PUBLIC_URL}/img/icon_shield_gry@2x.png`;
    }

    return (
        <ExtendedIconButton onClick={handleGo} loading={!!updatingInvoices} color="white" progressColor={theme.palette.secondary.main}>
            <STITooltip title="Set Audited and Open Next Invoice">
                <Box display="flex" width="70px" height="70px" justifyContent="center">
                    <img src={img} alt="audit and go" height="70px" />
                </Box>
            </STITooltip>
        </ExtendedIconButton>
    );
};
