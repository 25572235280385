import React from 'react';

import { FormControl, InputLabel, Select, MenuItem, styled, FormControlProps, SelectProps, ListItemText } from '@material-ui/core';
import { IMultiSelectOption } from './controls.types';

export interface ISingleSelect extends SelectProps {
    options: IMultiSelectOption[];
    menuClasses?: any;
    formProps?: FormControlProps;
}

export const SingleSelect: React.FC<ISingleSelect> = ({ label, menuClasses, options, formProps, className, variant, fullWidth, ...props }) => {
    return (
        <FormControl {...formProps} className={className} variant={variant} fullWidth={fullWidth}>
            {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
            <StyledSelect
                fullWidth
                color={props.color}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <div></div>}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }}
                {...props}
                // renderValue={(v: any) =>
                //     v.text
                //     // <MenuItem className={menuClasses.menuItem} value={v.value}>
                //     //     {v.text}
                //     // </MenuItem>
                // }
            >
                {options &&
                    options.map((v, i) => (
                        <MenuItem className={menuClasses.menuItem} key={v.text + i} value={v.value}>
                            <ListItemText className={menuClasses.menuItem} key={v.text + i} primary={v.text} secondary={v.subText} />
                        </MenuItem>
                    ))}
            </StyledSelect>
        </FormControl>
    );
};

const StyledSelect = styled(Select)({
    '& .MuiSelect-root': {
        paddingTop: '27px',
        paddingBottom: '10px',
        paddingRight: '9px',
        height: '24px'
    }
});
