import React, { memo, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { currencyParts, prettyNum } from '../../../helpers/pipes';
import { useFonts } from '../../../styles/font.style';
import { SummaryContainer } from './common';
import { useInvoiceState } from '../../../contexts/invoice.context';

export const Component: React.FC = () => {
    const fontClasses = useFonts();
    const invoiceState = useInvoiceState();
    const [{ toReceiveCount, toReceiveAmt, toDisburseCount, toDisburseAmt }, setValues] = useState<{
        toReceiveCount: number;
        toReceiveAmt: number;
        toDisburseCount: number;
        toDisburseAmt: number;
    }>({ toReceiveCount: 0, toReceiveAmt: 0, toDisburseCount: 0, toDisburseAmt: 0 });

    useEffect(() => {
        (async () => {
            let toReceiveCount = 0;
            let toReceiveAmt = 0;
            let toDisburseCount = 0;
            let toDisburseAmt = 0;

            invoiceState.invoices.forEach(({ updates: u }) => {
                if (u.receive && u.invoiceToReceive) {
                    toReceiveCount++;
                    toReceiveAmt += u.invoiceToReceive;
                }
                if (u.disburse && u.invoiceToDisburse) {
                    toDisburseCount++;
                    toDisburseAmt += u.invoiceToDisburse;
                }
            });
            setValues({ toReceiveCount, toReceiveAmt, toDisburseCount, toDisburseAmt });
        })();
    }, [invoiceState]);

    const recParts = currencyParts(toReceiveAmt);
    const disParts = currencyParts(toDisburseAmt);

    return (
        <SummaryContainer container justify="flex-end">
            <Grid item>
                <Grid container justify="center" wrap="nowrap">
                    <Typography variant="subtitle2" component="span" noWrap>
                        To Receive
                    </Typography>
                </Grid>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="h5" noWrap className={`${fontClasses.openSansSemiBold}`}>
                        {`${prettyNum(toReceiveCount)} / `}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        $
                    </Typography>
                    <Typography component="span" variant="h5" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {recParts[0]}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {`.${recParts[1]}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="subtitle2" noWrap>
                        To Disburse
                    </Typography>
                </Grid>
                <Grid container justify="center" wrap="nowrap">
                    <Typography component="span" variant="h5" noWrap className={`${fontClasses.openSansSemiBold}`}>
                        {`${prettyNum(toDisburseCount)} / `}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        $
                    </Typography>
                    <Typography component="span" variant="h5" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {disParts[0]}
                    </Typography>
                    <Typography component="span" variant="overline" color="secondary" className={`${fontClasses.openSansSemiBold}`}>
                        {`.${disParts[1]}`}
                    </Typography>
                </Grid>
            </Grid>
        </SummaryContainer>
    );
};

export const PaySummaryPanel = memo(Component);
