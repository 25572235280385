import { Button, Grid, ListItemText, makeStyles, TextField, Typography } from '@material-ui/core';
import { AutocompleteProps, Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import React, { useMemo, useReducer, Suspense, memo } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';
import { date } from '../../../helpers/pipes';
import { MODAL_TYPE, useInfoModal, CONFIRM_TYPES } from '../../../hooks/info-modal.hook';
import { useSendNotes } from '../../../hooks/stiapi.hook';
import { CheckControl, CheckOnly, GridGrow, GridMin, Scroller } from '../../generic';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { AuditActions, useAuditDispatch, useAuditState } from '../audit/audit.context';
import { BodyContainer, Header, ModalContainer } from './common.component';

const useStyles = makeStyles((theme) => ({
    bodyContainer: {
        maxWidth: '60vw',
        '& > *': {
            flexGrow: 0,
            flexShrink: 0
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 1,
        minHeight: '0px',
        '& > *': {
            flexBasis: 0,
            flexGrow: 1
        }
    },
    notesContainer: {
        '& > *:not(first-child)': {
            marginLeft: theme.spacing(1)
        }
    },
    noteContainer: {
        padding: theme.spacing(0.5),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:nth-of-type(2n-1)': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    notesHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    note: {},
    buttons: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-evenly'
    },
    checkbox: {
        '& *': {
            whiteSpace: 'nowrap'
        }
    },
    message: {
        width: '100%',
        height: '100%',
        minWidth: theme.spacing(45),
        minHeight: theme.spacing(45),
        '& > .MuiInputBase-root': {
            width: '100%',
            height: '100%',
            '& > .MuiInput-input': {
                width: '100%',
                height: '100% !important'
            }
        }
    }
}));

interface EmailNotesModalProps {
    onClose?: () => void;
}

interface EmailForm extends Omit<Partial<Models.Email>, 'toUsers' | 'ccUsers' | 'notes'> {
    toUsers?: Partial<Models.User>[];
    ccUsers?: Partial<Models.User>[];
    notes?: { [index: string]: boolean };
    [index: string]: any;
}

const reducer = (state: EmailForm, updates: EmailForm) => ({ ...state, ...updates });

export const EmailNotesModal: React.FC<EmailNotesModalProps> = ({ onClose }) => {
    const classes = useStyles();
    const auditState = useAuditState();
    const auditDispatch = useAuditDispatch();
    const userState = useUserState();
    const [state, update] = useReducer(reducer, { toUsers: [], ccUsers: [], notes: {} });
    const { sendNotes, loading } = useSendNotes();
    const [ConfirmationModal, runConfirmationModal] = useInfoModal();
    const [user] = useCurrentUser();

    const onSubmit = async () => {
        if (!state.toUsers?.length) {
            await runConfirmationModal({
                title: 'Cannot Send',
                desc: 'You have not selected any recipients.',
                modalType: MODAL_TYPE.WARNING,
                yesTxt: 'OK',
                no: false
            });
            return;
        }
        const errors: string[] = [];
        ['subject', 'header', 'body'].forEach((k) => {
            if (!state[k]) errors.push(k);
        });
        if (errors.length) {
            const res = await runConfirmationModal({
                title: 'Did you forget something?',
                desc: `Are you sure you want to send without a ${errors
                    .slice(0, errors.length - 1)
                    .map((e) => `${e}${errors.length > 2 ? ',' : ''} `)
                    .join('')}${errors.length > 1 ? 'or ' : ''}${errors[errors.length - 1]}`,
                modalType: MODAL_TYPE.WARNING,
                yesTxt: 'Send',
                cancel: true,
                no: false
            });
            if (res !== CONFIRM_TYPES.YES) {
                return;
            }
        }

        const email: Partial<Models.Email> = {
            ...state,
            toUsers:
                state.toUsers?.reduce((acc: string[], u) => {
                    if (u.email) acc.push(u.email);
                    return acc;
                }, []) ?? [],
            ccUsers:
                state.ccUsers?.reduce((acc: string[], u) => {
                    if (u.email) acc.push(u.email);
                    return acc;
                }, []) ?? [],
            notes: Object.entries(state.notes ?? {}).reduce((acc: string[], [k, v]) => {
                if (v) acc.push(k);
                return acc;
            }, []),
            invoiceNumber: auditState.invoice?.invoiceNumber,
            carrierInvoiceID: auditState.invoice?.carrierInvoiceID
        };
        const res = await sendNotes(email);
        if (res.status === 200) {
            auditDispatch({ type: AuditActions.addAction, payload: { ...res.data.action, user: user } });
            onClose?.();
        } else {
            const res = await runConfirmationModal({
                title: 'Could not send.',
                desc: (
                    <>
                        <p>There was an issue sending this email</p>
                        <p>If this continues to be a problem, please try without attaching the invoice pdf.</p>
                    </>
                ),
                modalType: MODAL_TYPE.WARNING,
                yesTxt: 'OK',
                no: false
            });
            if (res !== CONFIRM_TYPES.YES) {
                return;
            }
        }
    };

    return (
        <ModalContainer>
            <ConfirmationModal />
            <Header variant="subtitle1">Email Notes</Header>
            <BodyContainer item container direction="column" className={classes.bodyContainer} wrap="nowrap">
                <Grid container spacing={1}>
                    <GridGrow item container direction="column">
                        <TextField label="Subject" value={state.subject ?? ''} onChange={(evt) => update({ subject: evt.target.value })} />
                        <TextField label="Header" value={state.header ?? ''} onChange={(evt) => update({ header: evt.target.value })} />
                    </GridGrow>
                    <GridMin item container direction="column" justify="space-evenly" alignItems="center">
                        <div className={classes.buttons}>
                            <ExtendedButton variant="contained" color="primary" loading={!!loading} onClick={onSubmit}>
                                Send
                            </ExtendedButton>
                            <ExtendedButton variant="contained" color="error" onClick={onClose}>
                                Cancel
                            </ExtendedButton>
                        </div>
                        <CheckControl onChange={(evt, checked) => update({ pdf: checked })} className={classes.checkbox}>
                            Attach Invoice PDF
                        </CheckControl>
                    </GridMin>
                </Grid>
                <Grid container spacing={1} direction="column">
                    <Grid item>
                        <UserSelect title="To:" value={state.toUsers} onChange={(evt, val) => update({ toUsers: val as Partial<Models.User>[] })} />
                    </Grid>
                    <Grid item>
                        <UserSelect title="CC:" value={state.ccUsers} onChange={(evt, val) => update({ ccUsers: val as Partial<Models.User>[] })} />
                    </Grid>
                </Grid>
                <div className={classes.contentContainer}>
                    <Grid container direction="column">
                        <Grid>
                            <TextField
                                className={classes.message}
                                placeholder="Message Body"
                                multiline
                                value={state.body ?? ''}
                                onChange={(evt) => update({ body: evt.target.value })}
                            />
                        </Grid>
                        <GridMin container direction="column" alignItems="flex-end">
                            <Typography>{user?.signature}</Typography>
                            <Typography noWrap>{`${user?.firstName} ${user?.lastName}`}</Typography>
                        </GridMin>
                    </Grid>
                    <NotesPicker classes={classes} selectedNotes={state.notes} update={update} />
                </div>
            </BodyContainer>
        </ModalContainer>
    );
};

const UserSelect: React.FC<Partial<AutocompleteProps<Partial<Models.User>, true, boolean, true>>> = memo((props) => {
    // const [userOptions, setUserOptions] = useState<Partial<Models.User>[]>([]);
    // const [users, setUsers] = useState<Partial<Models.User>[]>([]);
    // const userState = useUserState();
    const auditState = useAuditState();

    // const options = useMemo(() => {
    //     return auditState.collabOptions.filter((u) => u.userID !== userState.user?.userID);
    // }, [auditState.collabOptions, userState.user]);

    const onChange = (
        event: React.ChangeEvent<{}>,
        value: (string | Partial<Models.User>)[] | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails
    ) => {
        const v = reason === 'create-option' ? [...(props.value ?? []), { email: details?.option }] : (value as Partial<Models.User>[]);
        props.onChange?.(event, v, reason);
    };

    return (
        <Autocomplete<Partial<Models.User>, true, boolean, true>
            multiple
            freeSolo
            autoComplete
            autoHighlight
            {...props}
            value={props.value ?? []}
            onChange={onChange as any}
            options={auditState.collabOptions}
            getOptionLabel={(u) => `${u.firstName ? u.firstName : ''} ${u.lastName ? `${u.firstName ? ' ' : ''}${u.lastName}` : ''}<${u.email}>`}
            renderOption={(u, i) => <ListItemText primary={`${u.firstName} ${u.lastName}`} secondary={u.email || ''} />}
            renderInput={(params) => {
                return <TextField label={props.title} {...params} />;
            }}
        />
    );
});

const noteDate = date(`h:mm a - MM/dd/yy`);

const NotesPicker: React.FC<{
    classes: Record<string, string>;
    selectedNotes?: { [index: string]: boolean };
    update: (update: EmailForm) => void;
}> = memo(({ classes, update, selectedNotes = {} }) => {
    const auditState = useAuditState();
    const display = useMemo(() => {
        const n = [...auditState.actions];
        n.reverse();
        return n;
    }, [auditState.actions]);
    const onSelectAll = () => {
        update({
            notes: display.reduce((acc: { [index: string]: boolean }, a) => {
                acc[a.ID] = true;
                return acc;
            }, {})
        });
    };
    return (
        <div className={classes.notesContainer}>
            <Suspense fallback="loading">
                <Scroller>
                    <div className={`${classes.notesHeader} ${classes.noteContainer}`}>
                        <Button onClick={onSelectAll}>Select All</Button>
                        <Button onClick={() => update({ notes: {} })}>Deselect All</Button>
                    </div>
                    {display.length ? (
                        display.map((n) => (
                            <div className={classes.noteContainer}>
                                <CheckOnly
                                    onChange={(evt, checked) => update({ notes: { ...selectedNotes, [n.ID]: checked } })}
                                    checked={!!selectedNotes?.[n.ID]}
                                />
                                <div className={classes.note}>
                                    <Typography variant="subtitle2" color="primary">
                                        {`${n.user.firstName} ${n.user.lastName} - ${n.createdAt && noteDate(n.createdAt)}`}
                                    </Typography>
                                    <Typography variant="body2" color="textPrimary">
                                        {n.action}
                                    </Typography>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={classes.noteContainer}>No Available Notes</div>
                    )}
                </Scroller>
            </Suspense>
        </div>
    );
});
