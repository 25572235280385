import { Typography, Grid, Box } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { CompanyInvoiceType } from 'shipmenttrackers-domain/dist/models';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';
import { useReducerWithRef } from '../../../hooks/reducerwithref.hook';
import { useCreateGLCodings, useDeleteGLCoding, useGetInvoiceSum, useMakeAdjustment, useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { useSearchHandler } from '../../../mediators/appsearchinvoice.mediator';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { AppActions } from '../../../types/contexts/app-context.type';
import { IAction } from '../../../types/contexts/context.type';
import { GridMin } from '../../generic';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { AuditAmountInput } from '../audit/amountinput.component';
import { AuditActions, useAuditDispatch, useAuditStateRef } from '../audit/audit.context';
import { ModalContainer, Header, BodyContainer } from './common.component';
import { NoteInput } from '../audit/noteinput/noteinput.component';
import { useHandleAtMentions } from '../audit/noteinput/useHandleAtMentions.hook';

interface VoidModal {
    invoice?: Models.CarrierInvoices;
    close: () => void;
}

interface IVoidState {
    billAmt?: string;
    billType?: Models.AdjustmentTypes;
    billCat?: Models.AdjustmentCategory;
    nonAmt?: string;
    nonType?: Models.AdjustmentTypes;
    nonCat?: Models.AdjustmentCategory;
    amount?: string;
    billable?: boolean;
    type?: Models.AdjustmentTypes;
    category?: Models.AdjustmentCategory;
    note: string;
    total: number;
}

const reducer = (state: IVoidState, action: IAction) => {
    const newState = { ...state };
    switch (action.type as keyof IVoidState) {
        case 'billAmt': {
            const amt = parseFloat(action.payload.replace('$', '').replace(',', ''));
            newState.nonAmt = (state.total - amt).toFixed(2);
            break;
        }
        case 'nonAmt': {
            const amt = parseFloat(action.payload.replace('$', '').replace(',', ''));
            newState.billAmt = (state.total - amt).toFixed(2);
            break;
        }

        default:
            break;
    }
    return { ...newState, [action.type]: action.payload };
};

export const VoidModal: React.FC<VoidModal> = ({ invoice, close }) => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const appState = useAppState();
    const [state, dispatch, stateRef] = useReducerWithRef(reducer, { note: '', total: 0 });
    const { makeAdjustment, loading: adjustmentLoading } = useMakeAdjustment();
    const [adjError, setAdjError] = useState<ReactNode>(null);
    const auditDispatch = useAuditDispatch();
    const { getInvoiceSum, loading: loadingSum } = useGetInvoiceSum();
    const appDispatch = useAppDispatch();
    const { fetchSum } = useSearchHandler();
    const { updateInvoices, loading: updatingInvoices } = useUpdateInvoices();
    const { createGLCodings, loading: createLoading } = useCreateGLCodings();
    const { deleteGLCoding, loading: deleteGLLoading } = useDeleteGLCoding();
    const auditStateRef = useAuditStateRef();
    const userState = useUserState();
    const { addTag, handleNoteTags } = useHandleAtMentions();
    const [user] = useCurrentUser();

    useEffect(() => {
        dispatch({ type: 'total', payload: invoice?.currentAmt });
    }, [invoice, dispatch]);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const state = stateRef.current!;
        const inv = invoice;
        if (!inv) return;

        const billRes = await makeAdjustment({
            adjustmentTypeID: state.billType?.adjustmentTypeID,
            adjustmentCategoryID: state.billCat?.adjustmentCategoryID,
            billable: true,
            carrierInvoiceID: inv.carrierInvoiceID.toString(),
            amount: parseFloat(state.nonAmt!.replace('$', '').replace(',', '')),
            notes: state.note
        });

        const nonBillRes = await makeAdjustment({
            adjustmentTypeID: state.nonType?.adjustmentTypeID,
            adjustmentCategoryID: state.nonCat?.adjustmentCategoryID,
            billable: false,
            carrierInvoiceID: inv.carrierInvoiceID.toString(),
            amount: 0,
            notes: state.note
        });

        if (billRes.status === 201 && nonBillRes.status === 201) {
            const invoiceRes = await updateInvoices([
                { carrierInvoiceID: inv.carrierInvoiceID, invoiceStatus: 'Void', ...(state.note ? { reason: state.note } : {}) }
            ]);
            if (invoiceRes.status === 200) {
                // invoiceRes.data.invoices[0].actions?.forEach((a) => {
                //     if (a.userID === user?.userID) {
                //         a.user = user as Models.User;
                //     }
                // });
                auditDispatch({ type: AuditActions.invoice, payload: invoiceRes.data.invoices[0] });
            }
            setAdjError(null);

            await handleNoteTags(state.note);

            const glRes = await Promise.all(
                auditStateRef.current.glcoding.reduce((acc: Promise<AxiosResponse<Models.GLCoding>>[], g) => {
                    if (g.glCodeID !== undefined) {
                        acc.push(deleteGLCoding(g.glCodeID, auditStateRef.current.invoice?.carrierAccount || ''));
                    }
                    return acc;
                }, [])
            );

            const codes = auditStateRef.current.glcoding;
            if (codes.length) {
                const res = await createGLCodings(
                    codes.map((c) => ({ ...c, glAmount: 0 })),
                    auditStateRef.current.invoice?.carrierAccount || ''
                );
                if (res.status === 201) {
                    auditDispatch({ type: AuditActions.setGL, payload: res.data.codings });
                    if (res.data.actions) {
                        // res.data.actions.forEach((a) => (a.user = user as Models.User));
                        auditDispatch({ type: AuditActions.addAction, payload: res.data.actions });
                    }
                }
            }

            // refetch sums on adjustment
            const sumRes = await getInvoiceSum(CompanyInvoiceType.Division, {});
            if (sumRes.status === 201) {
                appDispatch({ type: AppActions.invoiceSumAll, payload: sumRes.data.sum });
            } else {
                appDispatch({ type: AppActions.invoiceSumAll, payload: 0 });
            }
            await fetchSum();
            close();
        } else {
            setAdjError(
                <>
                    <p>There was a problem saving the adjustment.</p>
                    <p>Invoice amount has not been changed.</p>
                </>
            );
        }
    };

    const typeOptions = useMemo(() => appState.data.adjustmentTypes.filter((t) => !!t.appViewable).sort((a, b) => a.appOrder - b.appOrder), [
        appState.data.adjustmentTypes
    ]);

    const catOptions = useMemo(() => appState.data.adjustmentCategories.filter((c) => !!c.appViewable).sort((a, b) => a.appOrder - b.appOrder), [
        appState.data.adjustmentCategories
    ]);

    const onChange = useCallback(
        (evt) => {
            dispatch({ type: evt.target.name, payload: evt.target.value });
        },
        [dispatch]
    );

    const loading = !!adjustmentLoading || !!updatingInvoices || !!deleteGLLoading || !!createLoading;

    return (
        <ModalContainer>
            <GridMin>
                <Header variant="subtitle1">Void Invoice</Header>
            </GridMin>
            <BodyContainer item container>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2} direction="column">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item>
                                <AuditAmountInput
                                    autoFocus
                                    onFocus={(evt) => evt.target.select()}
                                    onChange={onChange}
                                    value={state?.billAmt || ''}
                                    required
                                    name="billAmt"
                                    label="Billable Amount"
                                    errorNonEmptyOnly
                                />
                            </Grid>
                            <Grid item>
                                <Box minWidth="180px">
                                    <SingleSelectAuto
                                        required
                                        onValChange={(evt, val) => dispatch({ type: 'billType', payload: val })}
                                        fullWidth
                                        variant="filled"
                                        options={typeOptions}
                                        className={inputClasses.input}
                                        menuClasses={menuClasses}
                                        label="Type"
                                        name="billType"
                                        getText={(o) => o.adjustmentCode}
                                        getSubtext={(o) => o.adjustmentDescription}
                                    />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box minWidth="180px">
                                    <SingleSelectAuto
                                        required
                                        onValChange={(evt, val) => dispatch({ type: 'billCat', payload: val })}
                                        fullWidth
                                        variant="filled"
                                        options={catOptions}
                                        className={inputClasses.input}
                                        menuClasses={menuClasses}
                                        label="Category"
                                        name="billCat"
                                        getText={(o) => o.adjustmentCategoryCode}
                                        getSubtext={(o) => o.adjustmentCategoryDescription}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item>
                                <AuditAmountInput
                                    onChange={onChange}
                                    value={state?.nonAmt || ''}
                                    required
                                    name="nonAmt"
                                    label="Non-Billable Amount"
                                    errorNonEmptyOnly
                                />
                            </Grid>
                            <Grid item>
                                <Box minWidth="180px">
                                    <SingleSelectAuto
                                        required
                                        onValChange={(evt, val) => dispatch({ type: 'nonType', payload: val })}
                                        fullWidth
                                        variant="filled"
                                        options={typeOptions}
                                        className={inputClasses.input}
                                        menuClasses={menuClasses}
                                        label="Type"
                                        name="nonType"
                                        getText={(o) => o.adjustmentCode}
                                        getSubtext={(o) => o.adjustmentDescription}
                                    />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box minWidth="180px">
                                    <SingleSelectAuto
                                        required
                                        onValChange={(evt, val) => dispatch({ type: 'nonCat', payload: val })}
                                        fullWidth
                                        variant="filled"
                                        options={catOptions}
                                        className={inputClasses.input}
                                        menuClasses={menuClasses}
                                        label="Category"
                                        name="nonCat"
                                        getText={(o) => o.adjustmentCategoryCode}
                                        getSubtext={(o) => o.adjustmentCategoryDescription}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <NoteInput
                                newNote={state?.note ?? ''}
                                setNewNote={(v) => dispatch({ type: 'note', payload: v })}
                                variant="filled"
                                className={inputClasses.input}
                                label="Notes"
                                multiline
                                rows={1}
                                rowsMax={4}
                                fullWidth
                                required
                                addTag={addTag}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                        <Grid item>
                            <Typography color="error">{adjError}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={inputClasses.charCount}
                                color={(state?.note.length || 0) > 250 ? 'error' : 'textPrimary'}
                                variant="caption"
                            >{`${state?.note.length}/250`}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item container wrap="nowrap" spacing={2} justify="flex-end">
                        <Grid item>
                            <ExtendedButton loading={loading} color="secondary" variant="contained" type="submit">
                                Save
                            </ExtendedButton>
                        </Grid>
                        <Grid item>
                            <ExtendedButton variant="contained" onClick={close} color="error" disabled={loading}>
                                Cancel
                            </ExtendedButton>
                        </Grid>
                    </Grid>
                </form>
            </BodyContainer>
        </ModalContainer>
    );
};
