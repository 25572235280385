import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInvoiceDispatch, useInvoiceStateRef } from '../contexts/invoice.context';
import { useAppState, useAppDispatch, useAppStateRef } from '../contexts/app.context';
import { useGetInvoices, useGetInvoiceCount, useGetInvoiceSum, useGetCarriers } from '../hooks/stiapi.hook';
import { SearchType, IAppFilter, AppActions, AppDataActions, AppFilterActions, IDFetchSummary } from '../types/contexts/app-context.type';
import { Models } from 'shipmenttrackers-domain/dist';
import { useSnackbar } from 'notistack';
import { InvoiceActions } from '../types/contexts/invoice-context.type';
import { prettyNum } from '../helpers/pipes';
import Axios, { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { usePermission } from '../hooks/permission/permissions.hook';
import { useUserState } from '../contexts/user.context';

const SearchContext: any = React.createContext({});

export interface SearchHandler {
    fetchInvoices: () => Promise<void>;
    fetchCount: () => Promise<void>;
    fetchSum: () => Promise<void>;
    getFilterQuery: () => {
        query: {
            [index: string]: any;
        };
        body: Partial<Models.InvoiceNumberSearchBody>;
        type: Models.CompanyInvoiceType;
    };
}

export const useSearchHandler = (): SearchHandler => useContext(SearchContext);

export const AppSearchInvoiceHandler: React.FC = ({ children }) => {
    const appState = useAppState();
    const appStateRef = useAppStateRef();
    const appDispatch = useAppDispatch();
    const invoiceDispatch = useInvoiceDispatch();
    const { getInvoices, apiDataRef, canceler: invoiceCanceler } = useGetInvoices();
    const { getInvoiceCount } = useGetInvoiceCount();
    const { getInvoiceSum } = useGetInvoiceSum();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { getCarriers } = useGetCarriers();
    const lastSearch = useRef<{ success?: boolean; query: string }>({ query: '' });
    let location = useLocation();
    const lastCarrier = useRef<string>('');

    const checkPermission = usePermission();

    useEffect(() => {
        lastSearch.current = { query: '' };
    }, [getInvoices]);

    const getFilterQuery = useCallback(
        () => ({
            ...createInvoiceQueryObj(appStateRef.current.searchType, appStateRef.current.filter, checkPermission()),
            type: getCompanyInvoiceType(appStateRef.current.searchType)
        }),
        [appStateRef, checkPermission]
    );

    const fetchInvoices = useCallback(
        async (
            t?: Models.CompanyInvoiceType,
            q?: {
                [index: string]: any;
            } | null,
            b?: Partial<Models.InvoiceNumberSearchBody>,
            onSuccess?: () => void
        ) => {
            const type = t ?? getCompanyInvoiceType(appStateRef.current.searchType);
            const { query: queryFromFilter, body: bodyFromFilter } = createInvoiceQueryObj(
                appStateRef.current.searchType,
                appStateRef.current.filter,
                checkPermission()
            );
            const query = q ?? queryFromFilter ?? {};
            const body = b ?? bodyFromFilter ?? {};
            invoiceDispatch({ type: InvoiceActions.loading, payload: true });
            if (apiDataRef.current.loading) invoiceCanceler();

            const fetching = enqueueSnackbar(`Fetching Invoices`, {
                persist: true,
                variant: 'info'
            });
            try {
                let res: Partial<AxiosResponse<Models.InvoiceSearchResponse>> = {};
                if (!shouldAttemptFetch(type, body, appStateRef.current.filter.linkIds)) {
                    res.status = 201;
                    res.data = { invoices: [], count: 0, sum: 0 };
                } else {
                    res = await getInvoices(type, query, body);
                }

                if (res.status === 201) {
                    console.time('invoice');
                    if (appStateRef.current.filter.invoiceIDS.length) {
                        appDispatch({ type: AppActions.filterIDQuery, payload: { [AppFilterActions.invoiceIDS]: [...appStateRef.current.filter.invoiceIDS] } });
                    }
                    onSuccess?.();
                    invoiceDispatch({
                        type: InvoiceActions.invoices,
                        payload: res.data!.invoices
                    });
                    appDispatch({ type: AppActions.invoiceSumFiltered, payload: res.data!.sum });
                    appDispatch({ type: AppActions.invoiceCountFiltered, payload: res.data!.count });

                    const sendSummary = (type: string) => (payload: IDFetchSummary) => {
                        appDispatch({ type: AppActions.filterIDQuery, payload: { [type]: payload } });
                    };

                    if (
                        (appStateRef.current.searchType === SearchType.invoice ||
                            appStateRef.current.searchType === SearchType.division ||
                            appStateRef.current.searchType === SearchType.dateAmt) &&
                        appStateRef.current.filter.invoiceIDS.length
                    ) {
                        createIDSummary(appStateRef.current.filter.invoiceIDS, res.data!.invoices, sendSummary(AppFilterActions.invoiceIDS));
                    } else if (appStateRef.current.searchType === SearchType.remittance && appStateRef.current.filter.remittanceNumbers.length) {
                        createIDSummary(appStateRef.current.filter.remittanceNumbers, res.data!.invoices, sendSummary(AppFilterActions.remittanceNumbers));
                    } else if (appStateRef.current.searchType === SearchType.disbursement && appStateRef.current.filter.disbursementInvoiceIDS.length) {
                        createIDSummary(
                            appStateRef.current.filter.disbursementInvoiceIDS,
                            res.data!.invoices,
                            sendSummary(AppFilterActions.disbursementInvoiceIDS)
                        );
                    } else if (appStateRef.current.searchType === SearchType.paymentConfirmation && appStateRef.current.filter.paymentConfirmationIDS.length) {
                        createIDSummary(
                            appStateRef.current.filter.paymentConfirmationIDS,
                            res.data!.invoices,
                            sendSummary(AppFilterActions.paymentConfirmationIDS)
                        );
                    } else {
                        appDispatch({ type: AppActions.filterIDQuery, payload: {} });
                    }

                    closeSnackbar(fetching);
                    const k = enqueueSnackbar(`Loaded ${prettyNum(res.data!.count)} Invoices`, {
                        variant: 'info',
                        onClick: () => closeSnackbar(k)
                    });
                    console.timeEnd('invoice');
                } else {
                    closeSnackbar(fetching);
                    if (!Axios.isCancel(res)) {
                        const k = enqueueSnackbar(`Error Fetching Invoices`, {
                            variant: 'error',
                            onClick: () => closeSnackbar(k)
                        });
                    }
                }
            } catch (err) {
                closeSnackbar(fetching);
                if (!Axios.isCancel(err)) {
                    const k = enqueueSnackbar(`Error Fetching Invoices`, {
                        variant: 'error',
                        onClick: () => closeSnackbar(k)
                    });
                }
            }
            invoiceDispatch({ type: InvoiceActions.loading, payload: false });
        },
        [appStateRef, checkPermission, invoiceDispatch, apiDataRef, invoiceCanceler, enqueueSnackbar, getInvoices, closeSnackbar, appDispatch]
    );

    const fetchCount = useCallback(
        async (
            t?: Models.CompanyInvoiceType,
            q?: {
                [index: string]: any;
            } | null,
            b?: Partial<Models.InvoiceNumberSearchBody>
        ) => {
            const type = t ?? getCompanyInvoiceType(appStateRef.current.searchType);
            const { query: queryFromFilter, body: bodyFromFilter } = createInvoiceQueryObj(
                appStateRef.current.searchType,
                appStateRef.current.filter,
                checkPermission()
            );

            const query = q ?? queryFromFilter ?? {};
            const body = b ?? bodyFromFilter ?? {};
            let countRes: Partial<AxiosResponse<{
                count: number;
            }>> = {};
            if (!shouldAttemptFetch(type, body, appStateRef.current.filter.linkIds)) {
                countRes.status = 201;
                countRes.data = { count: 0 };
            } else {
                countRes = await getInvoiceCount(type, query, body);
            }

            if (countRes.status === 201) {
                appDispatch({ type: AppActions.invoiceCountFiltered, payload: countRes.data!.count });
            } else {
                appDispatch({ type: AppActions.invoiceCountFiltered, payload: 0 });
            }
        },
        [appDispatch, appStateRef, checkPermission, getInvoiceCount]
    );

    const fetchSum = useCallback(
        async (
            t?: Models.CompanyInvoiceType,
            q?: {
                [index: string]: any;
            } | null,
            b?: Partial<Models.InvoiceNumberSearchBody>
        ) => {
            const type = t ?? getCompanyInvoiceType(appStateRef.current.searchType);
            const { query: queryFromFilter, body: bodyFromFilter } = createInvoiceQueryObj(
                appStateRef.current.searchType,
                appStateRef.current.filter,
                checkPermission()
            );
            const query = q ?? queryFromFilter ?? {};
            const body = b ?? bodyFromFilter ?? {};
            let sumRes: Partial<AxiosResponse<{
                sum: number;
            }>> = {};
            if (!shouldAttemptFetch(type, body, appStateRef.current.filter.linkIds)) {
                sumRes.status = 201;
                sumRes.data = { sum: 0 };
            } else {
                sumRes = await getInvoiceSum(type, query, body);
            }
            if (sumRes.status === 201) {
                appDispatch({ type: AppActions.invoiceSumFiltered, payload: sumRes.data!.sum });
            } else {
                appDispatch({ type: AppActions.invoiceSumFiltered, payload: 0 });
            }
        },
        [appDispatch, appStateRef, checkPermission, getInvoiceSum]
    );

    useEffect(() => {
        (async () => {
            const f = appState.filter;
            const query: Partial<Models.CarrierInvoices> = {
                clientID: f.client?.clientID,
                companyID: f.company?.companyID,
                divisionID: f.division?.divisionID,
                locationID: f.location?.locationID,
                carrierAccount: f.carrierAccount?.carrierAccount
            };
            const str = appState.filter.autofill ? JSON.stringify(query) : '';
            if (lastCarrier.current === str) return;
            lastCarrier.current = str;
            const res = await getCarriers(query);
            if (res.status === 201) {
                appDispatch({ type: AppDataActions.carriers, payload: res.data });
            }
        })();
    }, [getCarriers, appState.filter, appDispatch]);

    useEffect(() => {
        // (async () => {
        const type = getCompanyInvoiceType(appState.searchType);
        const fetch = createInvoiceQueryObj(appState.searchType, appState.filter, checkPermission());
        //lets not rerun, set the check value down after a successful
        const hasDif = JSON.stringify(fetch) !== lastSearch.current.query;

        const { query, body } = fetch;

        if (!query || location.pathname !== '/' || !hasDif) return;

        lastSearch.current.query = JSON.stringify(fetch);
        lastSearch.current.success = false;
        fetchInvoices(type, query, body, () => (lastSearch.current.success = true));
        // fetchCount(type, query, body);
        // fetchSum(type, query, body);
        // })();
    }, [appState.filter, appState.searchType, appState.homeTableMode, fetchInvoices, location.pathname, checkPermission]);

    const value: SearchHandler = useMemo(() => ({ fetchInvoices, fetchSum, fetchCount, getFilterQuery }), [
        fetchInvoices,
        fetchSum,
        fetchCount,
        getFilterQuery
    ]);

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

const getCompanyInvoiceType = (t: SearchType): Models.CompanyInvoiceType => {
    switch (t) {
        // case SearchType.dateAmt:
        //     return Models.CompanyInvoiceType.Date;
        case SearchType.disbursement:
            return Models.CompanyInvoiceType.Disbursement;
        // case SearchType.division:
        //     return Models.CompanyInvoiceType.Division;
        case SearchType.invoice:
            return Models.CompanyInvoiceType.Invoice;
        case SearchType.remittance:
            return Models.CompanyInvoiceType.Remittance;
        case SearchType.paymentConfirmation:
            return Models.CompanyInvoiceType.PaymentConfirmation;
        default:
            return Models.CompanyInvoiceType.Division;
    }
};

const createDivisionQuery = (query: { [index: string]: any }, filter: IAppFilter, isSTI: boolean) => {
    Object.assign(query, filter.otherfilters);
    query.type = SearchType.division;
    query.auditStatus = (isSTI ? filter.auditStatus.map((s) => s.auditStatus) : ['Audited', 'Non-Audit', 'Pending Client Review']).join(',');
    query.invoiceStatus = filter.invoiceStatus.map((s) => s.invoiceStatus).join(',');
    // Entities
    query.clientID = filter.client?.clientID;
    query.companyID = filter.company?.companyID;
    query.divisionID = filter.division?.divisionID;
    query.locationID = filter.location?.locationID;
    query.carrierID = filter.carrier?.carrierID;
    query.carrierAccountID = filter.carrierAccount?.carrierAccountID.toString();

    // Date & Amt
    query.minInv = filter.minInv || undefined;
    query.maxInv = filter.maxInv || undefined;
    query.startInvDate = filter.startInvDate;
    query.endInvDate = filter.endInvDate;
    query.startDueDate = filter.startDueDate;
    query.endDueDate = filter.endDueDate;

    if (!(query.minInv ?? 1) && (query.maxInv ?? 1)) {
        query.maxInv = Number.MAX_SAFE_INTEGER;
    }
    if (!(query.maxInv ?? 1) && (query.minInv ?? 1)) {
        query.minInv = Number.MIN_SAFE_INTEGER;
    }
    if (query.minInv !== undefined || query.maxInv !== undefined) {
        query.amountType = filter.amountType || Models.AmountType.CURRENT_AMOUNT;
    }
    return query;
};

const createInvoiceNumberQuery = (body: Partial<Models.InvoiceNumberSearchBody>, filter: IAppFilter) => {
    body.invoiceNumbers = filter.invoiceIDS;
    body.exact = !!filter.exactIds;
    return body;
};

const createInvoiceQueryObj = (type: SearchType, filter: IAppFilter, isSTI: boolean) => {
    let query: { [index: string]: any } = {};
    let body: Partial<Models.InvoiceNumberSearchBody> = {};

    if ([SearchType.division, SearchType.dateAmt].includes(type)) {
        createDivisionQuery(query, filter, isSTI);
        if (filter.linkIds) createInvoiceNumberQuery(body, filter);
    }

    if (type === SearchType.invoice) {
        createInvoiceNumberQuery(body, filter);
        if (filter.linkIds) query = createDivisionQuery(query, filter, isSTI);
    }

    if (type === SearchType.remittance) body.remittanceNumbers = filter.remittanceNumbers;
    if (type === SearchType.disbursement) body.disbursementNumbers = filter.disbursementInvoiceIDS;
    if (type === SearchType.paymentConfirmation) body.paymentConfirmationNumbers = filter.paymentConfirmationIDS;

    return { query, body };
};

const shouldAttemptFetch = (type: Models.CompanyInvoiceType, body: Partial<Models.InvoiceNumberSearchBody>, linkedWithFilter: boolean | undefined) => {
    switch (type) {
        case Models.CompanyInvoiceType.Invoice:
            return !!body.invoiceNumbers?.length || linkedWithFilter;
        case Models.CompanyInvoiceType.Remittance:
            return !!body.remittanceNumbers?.length;
        case Models.CompanyInvoiceType.Disbursement:
            return !!body.disbursementNumbers?.length;
        case Models.CompanyInvoiceType.PaymentConfirmation:
            return !!body.paymentConfirmationNumbers?.length;
        default:
            return true;
    }
};

const createIDSummary = async (ids: string[], results: Models.CarrierInvoices[], callback: (summary: IDFetchSummary) => void) => {
    const exact: string[] = [];
    const fuzzy: string[] = [];
    const fuzzyIds = new Set<string>();
    const none: string[] = [];

    const strMap = ids.reduce((acc: { [index: string]: { count: number; text: string } }, id) => {
        acc[id.toLocaleLowerCase().replace(/^000000/, '')] = { count: 0, text: id };
        return acc;
    }, {});

    results.forEach((inv) => {
        const resNum = inv.invoiceNumber.toLocaleLowerCase().replace(/^000000/, '');
        if (strMap[resNum] !== undefined) {
            exact.push(inv.invoiceNumber);
            strMap[resNum].count += 1;
            return;
        } else if (strMap[resNum] !== undefined) {
            const less = resNum;
            exact.push(less);
            strMap[less].count += 1;
            return;
        } else {
            const found = ids.find((id) => resNum.includes(id.toLocaleLowerCase()));
            if (found) {
                fuzzy.push(inv.invoiceNumber);
                fuzzyIds.add(found);
                strMap[found.toLocaleLowerCase()].count += 1;
                return;
            }
        }
    });
    ids.forEach((id) => {
        const lId = id.toLocaleLowerCase().replace(/^000000/, '');
        if (strMap[lId].count === 0) {
            none.push(strMap[lId].text);
        }
    });

    callback({ exact, none, fuzzy, queries: ids, results: results.length });
};
