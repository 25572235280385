import React, { useCallback, memo, useRef, useEffect } from 'react';
import { Box, useTheme, Grid, ButtonBase, styled } from '@material-ui/core';
import { UserAccessControl } from '../components/layout/manage/control/useraccesscontrol.component';
import { useManageState, ManageType, useManageDispatch, ManageActions, ManageProvider } from '../components/layout/manage/manage.context';
import { UserAccessFilter } from '../components/layout/manage/filter/useraccessfilter.component';
import { CreateUserFilter } from '../components/layout/manage/filter/createuserfilter.component';
import { UserDetailsFilter } from '../components/layout/manage/filter/userdetailsfilter.component';
import { CleanLink } from '../components/generic/display/cleanlink.component';
import { NewUserControl } from '../components/layout/manage/control/newusercontrol.component';
import { UserDetailsControl } from '../components/layout/manage/control/userdetailscontrol.component';
import { GrowUnderline } from '../components/generic/feedback/growunderline.component';
import { GridGrow, GridMin } from '../components/generic';
import { ExtendedButton } from '../components/generic/controls/extendedbutton.component';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Caps } from '../components/generic/display/typocaps.component';

export const Component: React.FC = () => {
    const theme = useTheme();
    const manageDispatch = useManageDispatch();

    useEffect(() => {
        document.title = 'STI : Management';
    }, []);

    const submitRef = useRef<HTMLButtonElement>(null);

    const setManageType = useCallback(
        (evt) => {
            manageDispatch({ type: ManageActions.manageType, payload: evt.target.name });
        },
        [manageDispatch]
    );
    return (
        <Container>
            <Box bgcolor={theme.palette.primary.dark} padding={2} display="flex" alignItems="center">
                <img src={`${process.env.PUBLIC_URL}/img/sti_logo.png`} alt="sti" width="100%" />
            </Box>
            <div>
                <Box bgcolor={theme.palette.primary.main} width="100%" padding={2} color="white" minHeight="88px" display="flex" justifyContent="center">
                    <Grid container alignItems="center" spacing={2}>
                        <GridGrow>
                            <ManageTypeFilterComponent submitRef={submitRef} />
                        </GridGrow>
                        <GridMin>
                            <CleanLink to="/">
                                <ExtendedButton variant="outlined" color="inherit" wrap="nowrap">
                                    Back To Grid
                                </ExtendedButton>
                            </CleanLink>
                        </GridMin>
                    </Grid>
                </Box>
                {/* <Box bgcolor={theme.palette.primary.light} width="100%" padding={2} color="white">
                    <ManageTypeFilterComponent submitRef={submitRef} />
                </Box> */}
            </div>
            <Box bgcolor={theme.palette.primary.main} width="100%" paddingTop={4}>
                <Grid container direction="column" justify="space-between">
                    {[ManageType.createUsers, ManageType.manageUsers, ManageType.manageAccess].map((t) => (
                        <ManageTypeMenuOption key={t} setType={setManageType} type={t} />
                    ))}
                </Grid>
            </Box>
            <Box bgcolor={theme.palette.grey.A100}>
                <ManageTypeComponent submitRef={submitRef} />
            </Box>
        </Container>
    );
};

export const ManageUsers = memo(() => (
    <ManageProvider>
        <Component />
    </ManageProvider>
));

const Container = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'min-content minmax(20%, 1fr)',
    gridTemplateRows: 'min-content minmax(20%, 1fr)',
    height: '100%',
    width: '100%'
});

const ManageTypeMenuOption: React.FC<{ type: ManageType; setType: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }> = ({ type, setType }) => {
    const theme = useTheme();
    const state = useManageState();
    const currentType = state.manageType;

    const current = currentType === type;
    return (
        <ButtonBase key={type} name={type} onClick={setType} className="fixButtonName">
            <Box
                marginTop={1}
                marginBottom={1}
                paddingTop={2}
                paddingBottom={2}
                paddingLeft={8}
                paddingRight={8}
                color={current ? theme.palette.common.white : fade(theme.palette.common.white, 0.5)}
            >
                <GrowUnderline show={current} lineProps={{ bgcolor: theme.palette.common.white }}>
                    <Caps variant="h6" color="inherit" noWrap>
                        {typeTitles[type]}
                    </Caps>
                </GrowUnderline>
            </Box>
        </ButtonBase>
    );
};

const ManageTypeComponent: React.FC<{ submitRef: React.RefObject<HTMLButtonElement> }> = ({ submitRef }) => {
    const manageState = useManageState();
    return (
        <>
            {manageState.manageType === ManageType.createUsers ? (
                <NewUserControl submitRef={submitRef} />
            ) : manageState.manageType === ManageType.manageAccess ? (
                <UserAccessControl submitRef={submitRef} />
            ) : manageState.manageType === ManageType.manageUsers ? (
                <UserDetailsControl submitRef={submitRef} />
            ) : null}
        </>
    );
};

const ManageTypeFilterComponent: React.FC<{ submitRef: React.RefObject<HTMLButtonElement> }> = ({ submitRef }) => {
    const manageState = useManageState();
    return (
        <>
            {manageState.manageType === ManageType.createUsers ? (
                <CreateUserFilter submitRef={submitRef} />
            ) : manageState.manageType === ManageType.manageAccess ? (
                <UserAccessFilter submitRef={submitRef} />
            ) : manageState.manageType === ManageType.manageUsers ? (
                <UserDetailsFilter submitRef={submitRef} />
            ) : null}
        </>
    );
};

const typeTitles = {
    [ManageType.createUsers]: 'Create Users',
    [ManageType.manageAccess]: 'User Access',
    [ManageType.manageUsers]: 'User Details',
    [ManageType.manageTemplates]: 'Templates'
};
