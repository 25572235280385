import { Accordion, AccordionDetails, AccordionSummary, IconButton, makeStyles } from '@material-ui/core';
import { mdiChevronDown, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { CleanLink } from '../../generic/display/cleanlink.component';
import { GrowUnderline } from '../../generic/feedback/growunderline.component';

interface FailureSectionProps {
    invoices: string[];
    title: string;
    message?: string;
}

const useSectionStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderColor: 'rgba(0,0,0,0)',
        transitionProperty: 'all',
        '&.Mui-expanded': {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        }
    },
    summary: {
        textTransform: 'capitalize',
        '& .MuiAccordionSummary-content': {
            flexDirection: 'column'
        }
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    list: {
        paddingLeft: theme.spacing(3),
        '& span': {
            display: 'inline-block'
        }
    },
    copy: {
        marginTop: '-12px'
    },
    error: {
        color: theme.palette.error.main
    }
}));

export const FailureSection: React.FC<FailureSectionProps> = ({ title, message, invoices }) => {
    const classes = useSectionStyles();

    const copy = () => {
        navigator.clipboard.writeText(invoices.join(', ')).then(
            () => {},
            () => alert('There was a problem copying automatically.')
        );
    };
    return (
        <Accordion className={classes.root}>
            <AccordionSummary className={classes.summary} expandIcon={<Icon size={1} path={mdiChevronDown} />}>
                <div>{`${title}: ${invoices.length}`}</div>
                {message ? <div>{message}</div> : null}
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.body}>
                    <div className={classes.list}>
                        {invoices.map((inv, i) => (
                            <React.Fragment key={inv}>
                                <span>
                                    <CleanLink className="invoiceLink" to={`/audit/${inv}`}>
                                        <GrowUnderline data-name="invoice" className="buttonText" onHover>
                                            <div>{inv.replace(/^000000/, '')}</div>
                                        </GrowUnderline>
                                    </CleanLink>
                                </span>
                                {i < invoices.length - 1 ? <span>,&nbsp;</span> : null}
                            </React.Fragment>
                        ))}
                    </div>
                    <IconButton onClick={copy} className={classes.copy}>
                        <Icon size={0.75} path={mdiContentCopy} />
                    </IconButton>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
