import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from '../../../contexts/store.helpers';
import { Models } from 'shipmenttrackers-domain/dist';
import { IAppState, AppFilterActions } from '../../../types/contexts/app-context.type';
import { useAppState, useAppDispatch } from '../../../contexts/app.context';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { MultiSelectAuto, IMultiSelectAuto } from '../../generic/controls/multi-selectauto.component';

const selector = (state: IAppState): [Models.IpAuditStatus[], Models.IpAuditStatus[]] => [state.data.auditStatusOptions, state.filter.auditStatus];

const allVal: Models.IpAuditStatus = {
    auditStatus: 'All',
    auditStatusDescription: 'Do not limit by Audit Status.',
    auditMode: true,
    reviewMode: true,
    payMode: true,
    appOrder: -1
};

export const AuditStatusSelect: React.FC<IMultiSelectAuto<Models.IpAuditStatus>> = () => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const appDispatch = useAppDispatch();
    const [statusOptions, currentStatus] = useSelector(useAppState, selector);
    const lastWasAll = useRef<boolean>(!currentStatus.length);

    useEffect(() => {
        if (currentStatus.length === 0 || (currentStatus.length === 1 && currentStatus[0].auditStatus === allVal.auditStatus)) {
            lastWasAll.current = true;
        } else {
            lastWasAll.current = false;
        }
    }, [lastWasAll, currentStatus]);

    const options = useMemo<Models.IpAuditStatus[]>(() => [allVal, ...statusOptions.sort((a, b) => ((a as any).appOrder || 0) - ((b as any).appOrder || 0))], [
        statusOptions
    ]);

    const onChange = (event: React.ChangeEvent<{}>, value: Models.IpAuditStatus[]) => {
        let v = value;
        const hasAll = value.find((s) => s.auditStatus === allVal.auditStatus);
        if (hasAll) {
            if (lastWasAll.current && value.length > 1) {
                v = value.filter((s) => s.auditStatus !== allVal.auditStatus);
                lastWasAll.current = false;
            } else if (!lastWasAll.current) {
                v = [];
                lastWasAll.current = true;
            }
        }
        appDispatch({ type: AppFilterActions.auditStatus, payload: v });
    };

    return (
        <MultiSelectAuto<Models.IpAuditStatus>
            fullWidth
            variant="filled"
            className={inputClasses.input}
            menuClasses={menuClasses}
            initialVal={currentStatus.length ? currentStatus : [allVal]}
            options={options}
            label="Audit Status"
            onValChange={onChange}
            multiple
            getText={(o) => o.auditStatus}
            getSubtext={(o) => o.auditStatusDescription}
        />
    );
};
