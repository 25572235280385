import React, { useContext, useReducer, useRef, useEffect, Dispatch, useCallback } from 'react';
import { IAction } from '../types/contexts/context.type';
import { IUserState, PermissionsMap } from '../types/contexts/user-context.type';
import { loggedReducer } from './store.helpers';

import jwtdecode from 'jwt-decode';
import { Models } from 'shipmenttrackers-domain/dist';

import { useSnackbar } from 'notistack';
import { Disallows, useGetUserPermissions } from '../hooks/stiapi.hook';
import IdleTimer from 'react-idle-timer';

export const UserActions = {
    LOGIN: 'LOGIN',
    LOGIN_SESSION: 'LOGIN_SESSION',
    LOGOUT: 'LOGOUT',
    loginAlias: 'loginAlias',
    logoutAlias: 'logoutAlias',
    updatePermission: 'updatePermission',
    permissions: 'permissions',
    user: 'user',
    permissionFetching: 'permissionFetching'
    // summary: 'summary'
};

const initialState: IUserState = {
    token: null,
    user: null,
    permissions: { allowed: undefined, disallowed: {} },
    permissionFetching: {},
    // summary: {},
    alias: {
        user: null,
        token: null
    }
};

export enum TokenValidation {
    valid = 'valid',
    invalid = 'invalid',
    exp = 'exp'
}

const findToken = () => {
    let token = window.localStorage.getItem('token');
    if (!token) {
        token = window.sessionStorage.getItem('token');
    }
    return token;
};

const findUser = () => {
    let user = window.localStorage.getItem('user');
    if (!user) {
        user = window.sessionStorage.getItem('user');
    }
    return user;
};

const initializer = (): IUserState => {
    if (checkTokenValid() === TokenValidation.valid) {
        const tokenStr = findToken();
        return {
            ...initialState,
            token: tokenStr,
            user: JSON.parse(findUser() || '')
        };
    } else {
        return initialState;
    }
};

export const checkTokenValid = (tkn?: string | null) => {
    const tokenStr = tkn ?? findToken();
    let token: IToken | undefined = undefined;
    if (tokenStr) {
        token = jwtdecode(tokenStr);
    }
    if (!token) return TokenValidation.invalid;
    if (token.exp < Date.now() / 1000) return TokenValidation.exp;
    return TokenValidation.valid;
};

interface IToken {
    exp: number;
    iat: number;
    user: Models.User;
}

const reducer = (state: IUserState, action: IAction): IUserState => {
    switch (action.type) {
        case UserActions.LOGIN:
        case UserActions.LOGIN_SESSION:
        case UserActions.LOGOUT:
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('user');
            window.sessionStorage.removeItem('token');
            window.sessionStorage.removeItem('user');
            if (action.payload) {
                try {
                    const token: IToken = jwtdecode(action.payload);
                    if (action.type === UserActions.LOGIN || token.user.accountType === 1) {
                        window.localStorage.setItem('token', action.payload);
                        window.localStorage.setItem('user', JSON.stringify(token.user));
                    } else if (action.type === UserActions.LOGIN_SESSION) {
                        window.sessionStorage.setItem('token', action.payload);
                        window.sessionStorage.setItem('user', JSON.stringify(token.user));
                    }

                    return {
                        ...state,
                        alias: undefined,
                        token: action.payload,
                        user: token.user
                    };
                } catch (err) {
                    console.error(`[Error] Error logging in: ${err}`);
                }
            }

            return {
                ...state,
                alias: undefined,
                token: null,
                user: null
            };

        case UserActions.user:
            // case UserActions.summary:
            return { ...state, [action.type]: action.payload };
        case UserActions.permissions: {
            const perm: Models.UserEntities[] = action.payload.permissions;
            const dis: Disallows = action.payload.disallowed;
            return {
                ...state,

                permissions: perm.reduce(
                    (acc: PermissionsMap, p) => {
                        if (p.isAllowed) {
                            acc.allowed = p;
                        } else {
                            if (!acc.disallowed[p.entityLevel]) {
                                acc.disallowed[p.entityLevel] = {};
                            }
                            const [level, id, name] = idField[p.entityLevel];
                            const model = dis[level].find((d) => d[id] === p.entityID);
                            acc.disallowed[p.entityLevel][p.entityID] = { ...p, model, name: model[name] };
                        }
                        return acc;
                    },
                    { allowed: undefined, disallowed: {} }
                )
            };
        }
        case UserActions.updatePermission:
            return { ...state, permissions: { ...state.permissions, [action.payload.carrierAccount]: action.payload } };
        case UserActions.permissionFetching:
            const [key, is] = Object.entries(action.payload as { [index: string]: boolean })[0];
            if (is) {
                state.permissionFetching[key] = true;
            } else {
                delete state.permissionFetching[key];
            }
            return state;
        case UserActions.loginAlias:
            const token: IToken = jwtdecode(action.payload);
            return {
                ...state,
                alias: {
                    token: action.payload,
                    user: token.user
                }
            };
        case UserActions.logoutAlias:
            return { ...state, alias: undefined };
        default:
            return state;
    }
};

const logReducer = loggedReducer<IUserState>(reducer, 'User');

export const UserStateContext: any = React.createContext({});
export const UserDispatchContext: any = React.createContext({});
export const UserStateRefContext: any = React.createContext({});

export const useUserState = (): IUserState => useContext(UserStateContext);
export const useUserDispatch = (): Dispatch<IAction> => useContext(UserDispatchContext);
export const useUserStateRef = (): React.MutableRefObject<IUserState> => useContext(UserStateRefContext);

export const UserProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(logReducer, initialState, initializer);
    const lastUser = useRef<Models.User>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const userStateRef = useRef<IUserState>(state);
    userStateRef.current = state;

    useEffect(() => {
        const lastU = lastUser.current;
        const u = state.user;
        if (u?.userID && lastU?.userID !== u.userID) {
            const k = enqueueSnackbar(`Welcome, ${u.firstName || u.username}`, { variant: 'success', onClick: () => closeSnackbar(k) });
            lastUser.current = u;
        }
    }, [lastUser, state, enqueueSnackbar, closeSnackbar]);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                <UserStateRefContext.Provider value={userStateRef}>
                    <IdleTimer
                        timeout={state.user?.accountType === 1 ? 12 * 60 * 60 * 1000 : 30 * 60 * 1000}
                        onIdle={() => dispatch({ type: UserActions.LOGOUT })}
                    >
                        <UserWatcher />
                        {children}
                    </IdleTimer>
                </UserStateRefContext.Provider>
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};

export const useCurrentUser = () => {
    const userState = useUserState();
    return [userState.alias?.user ?? userState.user, userState.user];
};

const UserWatcher: React.FC = () => {
    const { getPermissions } = useGetUserPermissions();
    // const { getPermissionStatus } = useGetPermissionStatus();
    const dispatch = useUserDispatch();
    // const state = useUserState();
    const [user] = useCurrentUser();

    useEffect(() => {
        (async () => {
            if (!user) return;
            const res = await getPermissions(user?.userID);
            if (res.status === 200) {
                dispatch({
                    type: UserActions.permissions,
                    payload: res.data
                });
            }
        })();
        // (async () => {
        //     if (!user) return;
        //     const res = await getPermissionStatus();
        //     if (res.status === 200) {
        //         dispatch({
        //             type: UserActions.summary,
        //             payload: res.data
        //         });
        //     }
        // })();
    }, [user, dispatch, getPermissions]);
    return null;
};

export const useCheckTokenValid = (): [() => TokenValidation, (valid?: TokenValidation | undefined) => void] => {
    const userDispatch = useUserDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userState = useUserState();
    const token = userState.alias?.token ?? userState.token;

    const handleLogCheck = useCallback(
        (valid?: TokenValidation) => {
            if (!valid) {
                valid = checkTokenValid(token);
            }
            if (valid === TokenValidation.exp) {
                const k = enqueueSnackbar('Login Session Expired', { variant: 'error', onClick: () => closeSnackbar(k) });
                userDispatch({ type: UserActions.LOGOUT });
            }
            // if (valid === TokenValidation.invalid) {
            //     enqueueSnackbar('Logging Out', { variant: 'info' });
            //     userDispatch({ type: UserActions.LOGOUT });
            // }
        },
        [closeSnackbar, enqueueSnackbar, userDispatch, token]
    );
    return [checkTokenValid, handleLogCheck];
};

const idField: { [index: string]: string[] } = {
    Client: ['clients', 'clientID', 'clientName'],
    Company: ['companies', 'companyID', 'companyName'],
    Division: ['divisions', 'divisionID', 'divisionName'],
    Location: ['locations', 'locationID', 'locationName'],
    Account: ['accounts', 'carrierAccountID', 'accountName'],
    Carrier: ['carriers', 'carrierID', 'carrierName']
};

// export const usePermission = () => {
//     const user = useUserState();
//     const checkPermission = useCallback(
//         (carrierAccount: string, permission: string) => {
//             if (user.user?.accountType === 1) {
//                 return true;
//             }
//             switch (permission) {
//                 case 'invoiceToReceive':
//                 case 'invoiceToDisburse':
//                 default:
//                     break;
//             }
//             return !!(user.permissions[carrierAccount] && user.permissions[carrierAccount][permission]);
//         },
//         [user]
//     );
//     return checkPermission;
// };
