import React, { useState, useEffect } from 'react';
import { useGetCollabOptions } from './stiapi.hook';
import { Models } from 'shipmenttrackers-domain/dist';

/**
 *
 * @param invoice carrierInvoiceID
 */
export const useCollabOptions = (invoice?: string) => {
    const [options, setOptions] = useState<Models.User[]>([]);
    const { getCollabOptions } = useGetCollabOptions();
    useEffect(() => {
        if (invoice) {
            getCollabOptions(invoice).then((res) => {
                if (res.status === 200) setOptions(res.data);
            });
        } else {
            setOptions([]);
        }
    }, [getCollabOptions, invoice]);
    return { collabOptions: options };
};
