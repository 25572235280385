import React from 'react';

import { Backdrop, CircularProgress, fade, CircularProgressProps, styled } from '@material-ui/core';

interface ILoading {
    loading?: boolean;
}

export const STILoading: React.FC<ILoading & CircularProgressProps> = ({ loading, children, ...props }) => {
    return loading ? (
        <StyledBackdrop open={loading}>
            <CircularProgress color="primary" {...props} />
            {children}
        </StyledBackdrop>
    ) : null;
};

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: fade(theme.palette.background.default, 0.5),
    overflow: 'hidden'
}));
