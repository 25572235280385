import { IAction } from '../types/contexts/context.type';
import { useMemo } from 'react';

export const loggedReducer = <S, A extends IAction = IAction>(reducer: (state: S, action: A) => S, title: string) => {
    if (process.env.NODE_ENV === 'production') {
        return reducer;
    } else {
        return (state: S, action: A): S => {
            console.group(title, action.type);
            console.groupCollapsed('Previous State');
            console.log(state);
            console.groupEnd();
            console.groupCollapsed('Action');
            console.log(action);
            console.groupEnd();
            const newState = reducer(state, action);
            console.groupCollapsed('Next State');
            if (newState === state) {
                console.log('state passthrough');
            }
            console.log(newState);
            console.groupEnd();
            console.groupEnd();
            return newState;
        };
    }
};

export function useSelector<S, T extends any[]>(useContextState: () => S, select: (state: S) => T) {
    const state: S = useContextState();
    const selections = select(state);
    return useMemo(() => {
        return selections;
    }, [selections]);
}

export enum StorageKeys {
    layouts = 'STI_LAYOUTS'
}
