import React from 'react';
import { BoxProps, makeStyles, Box, useTheme } from '@material-ui/core';

const useGrowStyles = makeStyles((theme) => ({
    grow: {
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
        transformOrigin: 'center'
    },
    open: {
        width: '100%'
    },
    closed: {
        width: '0%'
    },
    onHover: {
        '&:hover': {
            '& $grow': {
                width: '100%'
            }
        }
    },
    pointer: {
        cursor: 'pointer'
    }
}));

interface IGrowUnderline extends BoxProps {
    lineProps?: BoxProps;
    show?: boolean;
    onHover?: boolean;
    pointer?: boolean;
}

export const GrowUnderline: React.FC<IGrowUnderline> = ({ show, children, lineProps, onHover, pointer, ...props }) => {
    const growClasses = useGrowStyles();
    const theme = useTheme();
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="fit-content"
            {...props}
            className={`${onHover ? growClasses.onHover : ''} ${pointer ? growClasses.pointer : ''} ${props.className}`}
        >
            {children}
            <Box
                {...lineProps}
                bgcolor={lineProps?.bgcolor || theme.palette.primary.main}
                height="1px"
                className={`${growClasses.grow} ${show ? growClasses.open : growClasses.closed} ${lineProps?.className}`}
            />
        </Box>
    );
};
