import { Models } from 'shipmenttrackers-domain/dist';
import { IIdx } from '../general.type';
import { IAction } from './context.type';

export enum InvoiceActions {
    invoices = 'invoices',
    updateInvoices = 'updateInvoices',
    checkAll = 'checkAll',
    unCheckAll = 'unCheckAll',
    addUpdate = 'addUpdate',
    addUpdates = 'addUpdates',
    addUpdateBulk = 'addUpdateBulk',
    loading = 'loading'
}

export interface IInvoicePlus extends IIdx<Models.CarrierInvoices> {
    updates: IIdx<Partial<Models.CarrierInvoices>>;
}

export interface IInvoiceState extends IIdx {
    invoices: IInvoicePlus[];
    loading: boolean;
}

export interface IInvoiceAction extends IAction {
    id?: string | number;
    prop?: string;
}
