import React from 'react';
import { ICheckControl, CheckControl } from '../../generic';
import { useTheme, Box, makeStyles } from '@material-ui/core';
import { useAuditState, useAuditDispatch, AuditActions } from './audit.context';
import { FailureType, useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { Caps } from '../../generic/display/typocaps.component';
import { useInfoRequestModal, CONFIRM_TYPES } from '../../../hooks/inforequestmodal.hook';
import { MODAL_TYPE, useInfoModal } from '../../../hooks/info-modal.hook';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { Models } from '../../../../../shipmenttrackers-domain/dist';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';
import { ApproveFailed } from '../modals/approvefailed.component';

interface IAuditCheck extends ICheckControl {}

const useStyles = makeStyles((theme) => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.33,
        cursor: 'default'
    }
}));

export const AuditApproveCheck: React.FC<IAuditCheck> = ({ ...props }) => {
    const theme = useTheme();
    const classes = useStyles();
    const auditState = useAuditState();
    const checkPermission = usePermission(auditState.invoice);
    const auditDispatch = useAuditDispatch();
    const userState = useUserState();
    // const selector = useCallback((state: IAuditState): [boolean] => [state.invoice && (state.invoice as IIdx).approve], []]);
    // const [ val ] = useSelector(useAuditState, selector);
    const val = auditState.invoice?.approve;
    const runRequestModal = useInfoRequestModal();
    const [ConfirmationModal, runConfirmation] = useInfoModal();
    const [user] = useCurrentUser();

    const { updateInvoices, loading } = useUpdateInvoices();

    const toggleValue = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const inv = auditState.invoice;
        if (!inv) return;

        const req: Partial<Models.CarrierInvoices> = { carrierInvoiceID: inv.carrierInvoiceID };

        if (inv.dispute) {
            if (checkPermission('dispute')) {
                const { type, value } = await runRequestModal({
                    title: 'Invoice In Dispute',
                    desc: (
                        <>
                            <p>This invoice is currently disputed and the dispute will be resolved with this change.</p>
                            <p>Please provide a desciption of the resolution.</p>
                        </>
                    ),
                    modalType: MODAL_TYPE.WARNING,
                    no: false,
                    cancel: true,
                    collabInvoice: inv.carrierInvoiceID
                });
                if (type === CONFIRM_TYPES.YES) {
                    req.approve = checked;
                    req.dispute = false;
                    req.reason = value;
                    // const res = await updateInvoices([{ carrierInvoiceID: inv.carrierInvoiceID, approve: checked, dispute: false }]);
                    // if (res.status === 200) {
                    //     auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
                    // }
                } else {
                    return;
                }
            }
        } else {
            req.approve = checked;
            // const res = await updateInvoices([{ carrierInvoiceID: inv.carrierInvoiceID, approve: checked }]);
            // if (res.status === 200) {
            //     auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
            // }
        }

        const res = await updateInvoices([req]);
        if (res && res.status === 200) {
            // res.data.invoices[0].actions?.forEach((a) => {
            //     if (a.userID === user?.userID) {
            //         a.user = user as Models.User;
            //     }
            // });
            auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
            if (res.data.failed) {
                const failed = Object.entries(res.data.failed)[0];
                if (failed) {
                    const [id, reason] = failed;
                    const [type] = Object.entries(reason)[0];
                    await runConfirmation({
                        title: 'Cannot Approve',
                        desc: (
                            <>
                                <ApproveFailed type={type as FailureType} invoice={inv.invoiceNumber} />
                            </>
                        ),
                        yesTxt: 'OK',
                        no: false
                    });
                }
            }
        } else if (process.env.NODE_ENV === 'development') {
            await runConfirmation({
                title: 'Cannot Approve',
                desc: (
                    <>
                        <p>There was a problem with the request.</p>
                        {(res as any)?.message && <p>{(res as any).message}</p>}
                    </>
                ),
                yesTxt: 'OK',
                no: false
            });
        }
        // if (res.status === 200) {
        //     auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
        // }
    };

    return (
        <Box marginBottom={-1} display="inline-block" className={auditState.invoice && !checkPermission('approve') ? classes.disabled : undefined}>
            {/* <InfoRequestModal /> */}
            <ConfirmationModal />
            <CheckControl
                {...props}
                labelPlacement="top"
                onChange={toggleValue}
                loading={!!loading}
                checked={!!val}
                checkSize={1.5}
                color={theme.palette.common.white}
                checkColor={theme.palette.secondary.main}
            >
                <Box marginBottom={-1}>
                    <Caps variant="body2">Approve</Caps>
                </Box>
            </CheckControl>
        </Box>
    );
};
