import React from 'react';
import { useInputStyle } from '../../../../styles/stiinput.style';
import { Grid, Button } from '@material-ui/core';
import { useMenuStyles } from '../../../../styles/stimenu.style';
import { useManageStyles, UserSelect } from './common';

export const UserAccessFilter: React.FC<{ submitRef: React.RefObject<HTMLButtonElement> }> = ({ submitRef }) => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const classes = useManageStyles();

    const onGrant = () => {
        submitRef.current?.click();
    };

    return (
        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
            <Grid item container spacing={2}>
                <Grid item>
                    <UserSelect className={`${classes.input} ${inputClasses.input}`} menuClasses={menuClasses} color="primary" variant="filled" label="User" />
                </Grid>
                {/* <Grid item>
                    <SingleSelectAuto
                        className={`${classes.input} ${inputClasses.input}`}
                        menuClasses={menuClasses}
                        color="primary"
                        variant="filled"
                        label="Template"
                        onValChange={() => {}}
                        options={[]}
                    />
                </Grid> */}
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" className={classes.filterButton} onClick={onGrant}>
                    Grant
                </Button>
            </Grid>
        </Grid>
    );
};
