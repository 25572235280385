import { styled, Typography, Grid, Box } from '@material-ui/core';

export const ModalContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    // height: '40vh',
    maxHeight: '80vh',
    // minHeight: '480px',
    minWidth: '480px'
});

export const BodyContainer = styled(Grid)(({ theme }) => ({
    height: 'auto',
    minHeight: '0px',
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
    background: theme.palette.background.default,
    padding: theme.spacing(2)
}));

export const Header = styled(Typography)(({ theme }) => ({
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    flexGrow: 0,
    flexShrink: 0
}));

export const SectionHeader = styled(Typography)({
    textAlign: 'center'
});

export const TableWrapper = styled('div')({
    // flexBasis: 0,
    // flexGrow: 1,
    height: 'auto',
    width: '100%'
});

export const ButtonContainer = styled(Grid)(({ theme }) => ({
    flexBasis: 0,
    flexGrow: 0,
    '& > *': {
        margin: theme.spacing(1)
    }
}));
