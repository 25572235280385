import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useManageStyles } from './common';

export const CreateUserFilter: React.FC<{ submitRef: React.RefObject<HTMLButtonElement> }> = ({ submitRef }) => {
    const classes = useManageStyles();
    const onCreate = () => {
        submitRef.current?.click();
    };

    return (
        <Grid container spacing={2} alignItems="center" justify="flex-end" wrap="nowrap">
            <Grid item>
                <Button variant="contained" color="secondary" className={classes.filterButton} onClick={onCreate}>
                    Create
                </Button>
            </Grid>
        </Grid>
    );
};
