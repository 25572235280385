import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { STITooltip } from '../../generic';
import { CollabModal } from '../modals/collab.component';
import AccountQuestionOutline from 'mdi-material-ui/AccountQuestionOutline';
import { ExtendedIconButton } from '../../generic/controls/extendediconbutton.component';

export const CollabButton: React.FC = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <CollabModal open={open} setOpen={setOpen} />
            <STITooltip title="Collaborate">
                <ExtendedIconButton size="small" onClick={() => setOpen(true)}>
                    <AccountQuestionOutline />
                </ExtendedIconButton>
            </STITooltip>
        </>
    );
};
