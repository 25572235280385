import React, { memo, useReducer, useState } from 'react';
import { Button, TextField, Grid, makeStyles, Typography } from '@material-ui/core';
import { GridMin } from '../../generic';
import { Header, BodyContainer, ModalContainer } from './common.component';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';
import { useResetPassword } from '../../../hooks/stiapi.hook';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';

interface IPWState {
    oldPW: string;
    newPW: string;
    cNewPW: string;
}

const initialState: IPWState = {
    oldPW: '',
    newPW: '',
    cNewPW: ''
};

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    }
}));

const reducer = (state: IPWState, value: Partial<IPWState>) => {
    return Object.assign({}, state, value);
};

interface IResetModal {
    setOpen: (open: boolean) => void;
}

export const Component: React.FC<IResetModal> = ({ setOpen }) => {
    const classes = useStyles();
    const userState = useUserState();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { resetPassword, loading } = useResetPassword();
    const [status, setStatus] = useState<number | undefined>();
    const [user] = useCurrentUser();

    const close = () => {
        setOpen(false);
    };

    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ [evt.target.name]: evt.target.value });
    };

    const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (user?.email) {
            const res = await resetPassword(user.email, state.oldPW, state.newPW);
            const status = res.status === 201 ? res.data?.Status || res.status : res.status;
            setStatus(status);
        }
    };

    return (
        <ModalContainer>
            <GridMin>
                <Header variant="subtitle1">Reset Password</Header>
            </GridMin>
            <form onSubmit={onSubmit}>
                <BodyContainer item container>
                    <Grid item container direction="column">
                        <TextField required name="oldPW" onChange={onChange} type="password" value={state.oldPW} label="Old Password" />
                        <TextField required name="newPW" onChange={onChange} type="password" value={state.newPW} label="New Password" />
                        <TextField
                            required
                            name="cNewPW"
                            onChange={onChange}
                            type="password"
                            value={state.cNewPW}
                            label="Confirm New Password"
                            inputProps={{
                                pattern: state.newPW
                            }}
                            error={state.newPW !== state.cNewPW}
                            helperText={state.newPW !== state.cNewPW ? 'Passwords Do Not Match' : ' '}
                        />
                        {status !== undefined && status !== 200 && <Typography color="error">There was a problem changing your password.</Typography>}
                    </Grid>
                    <Grid item container wrap="nowrap" spacing={2} justify="flex-end">
                        {status !== 200 && (
                            <Grid item>
                                <ExtendedButton loading={!!loading} color="secondary" variant="contained" type="submit">
                                    Save
                                </ExtendedButton>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant="contained" onClick={close} className={classes.error}>
                                {status === 200 ? 'Close' : 'Cancel'}
                            </Button>
                        </Grid>
                    </Grid>
                </BodyContainer>
            </form>
        </ModalContainer>
    );
};

export const ResetPassword = memo(Component);
