import { useReducer, useRef, useCallback, MutableRefObject, useEffect } from 'react';

import axios, { AxiosInstance, Canceler, CancelToken, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

import { IAction } from '../types/contexts/context.type';

export interface IApiState {
    loading: number;
    error: any;
}

export const API_TYPES = {
    ADD_LOADING: 'ADD_LOADING',
    REMOVE_LOADING: 'REMOVE_LOADING',
    SET_ERROR: 'SET_ERROR'
};

const initialState = { loading: 0, error: undefined };

const reducer = (state: IApiState, action: IAction): IApiState => {
    switch (action.type) {
        case API_TYPES.ADD_LOADING:
            return {
                ...state,
                loading: state.loading + 1
            };
        case API_TYPES.REMOVE_LOADING:
            return { ...state, loading: state.loading - 1 };
        case API_TYPES.SET_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};
interface IApiDataRef {
    loading?: number;
    error?: any;
}

export interface IApiHelpers {
    loading: number;
    canceler: Canceler;
    getCancelToken: () => CancelToken;
    error: any;
    apiDataRef: MutableRefObject<IApiDataRef>;
}

export type IApiBase = { getAxios: (apiConfig?: AxiosRequestConfig, retryConfig?: IAxiosRetryConfig) => AxiosInstance } & IApiHelpers;

export const useApi = (): IApiBase => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const apiDataRef = useRef<IApiDataRef>({});
    useEffect(() => {
        apiDataRef.current.error = state.error;
        apiDataRef.current.loading = state.loading;
    }, [state.error, state.loading]);

    const canceler = useRef<Canceler>();

    // const setCancelRequest = useCallback(
    //     (req: Canceler) => {
    //         canceler.current = req;
    //     },
    //     [canceler]
    // );

    const getAxios = useCallback((apiConfig?: AxiosRequestConfig, retryConfig?: IAxiosRetryConfig) => {
        const api = axios.create(apiConfig);

        if (axiosRetry !== undefined) {
            axiosRetry(api, retryConfig);
        }

        api.interceptors.request.use((config) => {
            dispatch({ type: API_TYPES.ADD_LOADING });
            dispatch({ type: API_TYPES.SET_ERROR, payload: undefined });
            return config;
        });
        api.interceptors.response.use(
            (response) => {
                dispatch({ type: API_TYPES.REMOVE_LOADING });
                dispatch({ type: API_TYPES.SET_ERROR, payload: undefined });
                return response;
            },
            (error) => {
                dispatch({ type: API_TYPES.SET_ERROR, payload: error });
                dispatch({ type: API_TYPES.REMOVE_LOADING });

                return error;
            }
        );
        return api;
    }, []);

    const getCancelToken = useCallback(() => {
        return new axios.CancelToken((c) => (canceler.current = c));
    }, []);

    const cancelRequest = useCallback((message?: string) => {
        if (canceler.current) {
            canceler.current(message);
        }
    }, []);
    return { getAxios, loading: state.loading, canceler: cancelRequest, getCancelToken, error: state.error, apiDataRef };
};

export const success = (res?: AxiosResponse<any>) => res?.status === 200 || res?.status === 201;
