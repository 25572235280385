import React, { useState, useCallback, useRef } from 'react';
import { Modal, Grid, Typography, Button, makeStyles, Box, TextField, SvgIconProps } from '@material-ui/core';
import { Panel, GridMin, GridGrow } from '../components/generic';
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline';
import InformationOutline from 'mdi-material-ui/InformationOutline';
import AlertOutline from 'mdi-material-ui/AlertOutline';
import { useInputStyle } from '../styles/stiinput.style';
import { useHandleAtMentions } from '../components/layout/audit/noteinput/useHandleAtMentions.hook';
import { Models } from 'shipmenttrackers-domain/dist';
import { NoteInput } from '../components/layout/audit/noteinput/noteinput.component';
import { killEvt } from '../helpers';
import { useCollabOptions } from './usecollaboptions.hook';

// EXAMPLE:
// const [ConfirmationModal, runConfirmationModal] = useInfoModal();
//
// runConfirmationModal({ title: 'Are you sure?', desc: 'Really?', modalType: MODAL_TYPE.QUESTION }).then(
//     (resp: string) => {
//         if (resp === CONFIRM_TYPES.YES) {
//             *do the things
//         }
//     }
// );
//
// return (<>
//             <ConfirmationModal />
//             ...
//         </>
// )

const InfoRequestContext = React.createContext<(options: IModalOptions) => Promise<{ type: CONFIRM_TYPES; value: string }>>(() => ({} as any));

export enum CONFIRM_TYPES {
    YES = 'YES',
    NO = 'NO',
    CANCEL = 'CANCEL'
}

export enum MODAL_TYPE {
    QUESTION = 'QUESTION',
    INFO = 'INFO',
    WARNING = 'WARNING'
}

interface IModalOptions {
    title?: string;
    desc?: React.ReactChild;
    modalType?: MODAL_TYPE;
    yes?: boolean;
    no?: boolean;
    cancel?: boolean;
    doNotShowOption?: boolean;
    rememberOptionKey?: string;
    collabInvoice?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    iconWrapper: {
        color: theme.palette.common.white,
        width: '100%',
        height: '100%',
        padding: `0px ${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        fontSize: theme.spacing(6)
    },
    question: {
        backgroundColor: theme.palette.primary.main
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: theme.palette.error.main
    }
}));

interface IModalState extends IModalOptions {
    resolver: { (val: any): void };
}

const GetIcon: React.FC<SvgIconProps & { type?: MODAL_TYPE }> = ({ type, ...props }) => {
    switch (type) {
        case MODAL_TYPE.QUESTION:
            return <HelpCircleOutline {...props} />;
        case MODAL_TYPE.INFO:
            return <InformationOutline {...props} />;
        case MODAL_TYPE.WARNING:
            return <AlertOutline {...props} />;
        default:
            return <HelpCircleOutline {...props} />;
    }
};

// export const useInfoRequestModal = (): [React.ComponentType, (options: IModalOptions) => Promise<{ type: CONFIRM_TYPES; value: string }>] => {
//     const [showModal, setShowModal] = useState(false);
//     // const inputClasses = useInputStyle();
//     const [state, setState] = useState<IModalState>({
//         title: '',
//         desc: '',
//         yes: true,
//         no: true,
//         modalType: MODAL_TYPE.QUESTION,
//         collabInvoice: undefined,
//         resolver: (val?: void) => {}
//     });
//     const { addTag, handleNoteTags, collabOptions } = useHandleAtMentions(state.collabInvoice);

//     const valRef = useRef<string>('');

//     const onChange = useCallback(
//         (val: string) => {
//             valRef.current = val;
//         },
//         [valRef]
//     );

//     // const classes = useStyles();

//     const run = useCallback(
//         (type) => async (evt: any) => {
//             killEvt(evt);
//             await handleNoteTags(valRef.current);
//             state.resolver({ type, value: valRef.current });
//             setShowModal(false);
//         },
//         [handleNoteTags, state]
//     );

//     const closeModal = useCallback(() => setShowModal(false), []);

//     const runModal = useCallback(
//         async (options: IModalOptions): Promise<{ type: CONFIRM_TYPES; value: string }> => {
//             const res = await new Promise<{ type: CONFIRM_TYPES; value: string }>((resolve, reject) => {
//                 setState({ yes: true, no: true, ...options, resolver: resolve });
//                 valRef.current = '';
//                 setShowModal(true);
//             });
//             return res;
//         },
//         [setState, valRef, setShowModal]
//     );

//     return [
//         () =>
//             showModal ? (
//                 <ModalComponent
//                     collabOptions={collabOptions}
//                     addTag={addTag}
//                     showModal={showModal}
//                     closeModal={closeModal}
//                     state={state}
//                     run={run}
//                     onChange={onChange}
//                 />
//             ) : null,
//         runModal
//     ];
// };

export const useInfoRequestModal = () => React.useContext(InfoRequestContext);

export const InfoRequestProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [showModal, setShowModal] = useState(false);
    // const inputClasses = useInputStyle();
    const [state, setState] = useState<IModalState>({
        title: '',
        desc: '',
        yes: true,
        no: true,
        modalType: MODAL_TYPE.QUESTION,
        collabInvoice: undefined,
        resolver: (val?: void) => {}
    });
    const { addTag, handleNoteTags, collabOptions } = useHandleAtMentions(state.collabInvoice);

    const valRef = useRef<string>('');

    const onChange = (val: string) => {
        valRef.current = val;
    };

    // const classes = useStyles();

    const run = (type: CONFIRM_TYPES) => async (evt: any) => {
        killEvt(evt);
        await handleNoteTags(valRef.current);
        state.resolver({ type, value: valRef.current });
        setShowModal(false);
    };

    const closeModal = () => setShowModal(false);

    const runModal = useCallback(
        async (options: IModalOptions): Promise<{ type: CONFIRM_TYPES; value: string }> => {
            const res = await new Promise<{ type: CONFIRM_TYPES; value: string }>((resolve, reject) => {
                setState({ yes: true, no: true, ...options, resolver: resolve });
                valRef.current = '';
                setShowModal(true);
            });
            return res;
        },
        [setState, valRef, setShowModal]
    );

    return (
        <InfoRequestContext.Provider value={runModal}>
            {showModal ? (
                <ModalComponent
                    collabOptions={collabOptions}
                    addTag={addTag}
                    showModal={showModal}
                    closeModal={closeModal}
                    state={state}
                    run={run}
                    onChange={onChange}
                />
            ) : null}
            {children}
        </InfoRequestContext.Provider>
    );
};

interface IModalComponent {
    showModal: boolean;
    closeModal: () => void;
    state: IModalState;
    run: (type: any) => (evt: any) => void;
    onChange: (val: string) => void;
    addTag: (t: Models.User) => void;
    collabOptions?: Models.User[];
}

const ModalComponent: React.FC<IModalComponent> = ({ showModal, closeModal, state, run, addTag, onChange, collabOptions }) => {
    const [text, setText] = useState('');
    const inputClasses = useInputStyle();
    const classes = useStyles();

    const handleChange = (val: string) => {
        const t = val.slice(0, 250);
        setText(t);
        onChange(t);
    };

    return (
        <Modal open={showModal} onClose={closeModal} className={classes.root}>
            <Panel>
                <Grid container spacing={2} wrap="nowrap">
                    <GridMin container wrap="nowrap" alignItems="center" spacing={2}>
                        <Box className={`${classes.iconWrapper} ${state.modalType === MODAL_TYPE.WARNING ? classes.warning : classes.info}`}>
                            <GetIcon type={state.modalType} className={classes.icon} />
                        </Box>
                    </GridMin>
                    <GridGrow>
                        <form onSubmit={run(CONFIRM_TYPES.YES)}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item container wrap="nowrap" alignItems="center" spacing={2}>
                                    <Grid item container direction="column">
                                        <Typography color="primary" variant="h6">
                                            {state.title}
                                        </Typography>
                                        <Typography component="div" variant="body2">
                                            {state.desc}
                                        </Typography>
                                        <Box marginTop={2}>
                                            <Grid container alignItems="center">
                                                <NoteInput
                                                    autoFocus
                                                    fullWidth
                                                    className={inputClasses.input}
                                                    variant="filled"
                                                    label="Reason"
                                                    newNote={text}
                                                    setNewNote={handleChange}
                                                    addTag={addTag}
                                                    collabOptions={collabOptions}
                                                />
                                            </Grid>
                                        </Box>
                                        <Grid container justify="flex-end">
                                            <Typography variant="caption">{`${text.length}/250`}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container wrap="nowrap" spacing={2} justify="flex-end">
                                    <Grid item>
                                        <Button color="secondary" variant="contained" type="submit">
                                            Confirm
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={run(CONFIRM_TYPES.CANCEL)}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </GridGrow>
                </Grid>
            </Panel>
        </Modal>
    );
};
