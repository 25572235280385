import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInputStyle } from '../../../styles/stiinput.style';
import { TextField, useTheme, fade, TextFieldProps, makeStyles, Box } from '@material-ui/core';
import { useAuditState } from './audit.context';
import { useInputState } from '../../../hooks/input.hook';
import { ExtendedIconButton } from '../../generic/controls/extendediconbutton.component';
import { currency } from '../../../helpers/pipes';
import { UpdateAmountModal } from '../modals/updateamount.component';
import { STIModal } from '../../generic';
import { dollarRegex } from '../../../helpers';
import { mdiContentSaveEditOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { AdjustmentHistory } from './adjustmentHistory/adjustmenthistory.component';

const useStyles = makeStyles((theme) => ({
    override: {
        '& .MuiFormLabel-root.Mui-disabled, & input.Mui-disabled': {
            color: theme.palette.text.primary
        },
        '& .MuiInputBase-root.MuiFilledInput-adornedEnd': {
            paddingRight: '6px'
        }
    }
}));

export const AuditAmountInputForm: React.FC<TextFieldProps> = ({ disabled = false, ...props }) => {
    const { invoice } = useAuditState();
    const currentAmt = invoice?.currentAmt;
    const theme = useTheme();
    const [val, setVal, onChange] = useInputState<string>(currentAmt?.toString() || '0.00');
    const hasError = useRef<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (invoice) setVal(currency(invoice.currentAmt, invoice.currency));
    }, [invoice, setVal]);

    const submit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setOpen(true);
        },
        [setOpen]
    );

    const closeModal = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onErrorUpdate = useCallback(
        (error: boolean) => {
            hasError.current = error;
        },
        [hasError]
    );

    const onBlur = () => {
        val && invoice && setVal(currency(val, invoice.currency));
    };

    return (
        <form onSubmit={submit}>
            <STIModal open={open} onClose={closeModal}>
                <UpdateAmountModal amount={val || ''} close={closeModal} />
            </STIModal>
            <AuditAmountInput
                value={val}
                onChange={onChange}
                onErrorUpdate={onErrorUpdate}
                fullWidth
                {...props}
                disabled={disabled}
                onBlur={onBlur}
                InputProps={{
                    endAdornment: !disabled ? (
                        <Box display="flex" flexWrap="nowrap">
                            <ExtendedIconButton
                                type="submit"
                                title="Edit Invoice Amount"
                                size="small"
                                color={fade(hasError.current ? theme.palette.error.main : theme.palette.text.primary, 0.75)}
                                // loading={!!loading}
                                overflow="visible"
                            >
                                <Icon path={mdiContentSaveEditOutline} size={1} color="inherit" />
                            </ExtendedIconButton>
                            <AdjustmentHistory invoice={invoice} />
                        </Box>
                    ) : null
                }}
            />
        </form>
    );
};

type AuditAmountInputProps = TextFieldProps & {
    onErrorUpdate?: (error: boolean) => void;
    disableErrorColor?: boolean;
    errorNonEmptyOnly?: boolean;
};

export const AuditAmountInput: React.FC<AuditAmountInputProps> = ({ onErrorUpdate, value, disableErrorColor, label, errorNonEmptyOnly, ...props }) => {
    const inputClasses = useInputStyle();
    const classes = useStyles();
    // const hasError = !val?.match(/^[+-]?[0-9]{1,3}(?:[0-9]*(?:[,.][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?|(?:\.[0-9]{3})*(?:,[0-9]{2})?)$/);
    const hasError = ((errorNonEmptyOnly && !(value === undefined || value === '')) || !errorNonEmptyOnly) && !(value as string)?.match(dollarRegex);
    // console.log(value, hasError);
    return (
        <TextField
            data-lpignore="true"
            inputProps={{ 'data-lpignore': 'true' }}
            error={hasError}
            variant="filled"
            className={`${inputClasses.input} ${classes.override}`}
            label={hasError ? 'Invalid Amount' : label ?? 'Current Amount'}
            value={value}
            {...props}
        />
    );
};
