import { DrawerProps, Drawer, makeStyles, Grid, Typography, darken } from '@material-ui/core';
import { parseJSON } from 'date-fns';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Models } from 'shipmenttrackers-domain/dist';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { date } from '../../../helpers/pipes';
import { useSetMessageRead } from '../../../hooks/stiapi.hook';
import { AppActions } from '../../../types/contexts/app-context.type';
import { GridGrow, GridMin, Scroller } from '../../generic';

const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.spacing(45),
        maxWidth: '100%',
        height: '100%',
        background: theme.palette.grey.A100
    },
    header: {
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.secondary.main,
        '& > *': {
            padding: theme.spacing(2),
            color: theme.palette.secondary.contrastText,
            textTransform: 'uppercase'
        }
    },
    messageContainer: {
        padding: theme.spacing(2),
        position: 'relative',
        '&:nth-child(2n)': {
            backgroundColor: theme.palette.background.paper
        },
        '&:nth-child(2n-1)': {
            backgroundColor: darken(theme.palette.background.paper, 0.05)
        }
    },
    messageDate: {
        lineHeight: 1
    },
    messageContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    links: {
        '& > *': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    unread: {
        '&:before': {
            position: 'absolute',
            top: 0,
            left: 0,
            content: '" "',
            backgroundColor: theme.palette.secondary.main,
            height: '100%',
            width: theme.spacing(0.5)
        }
    }
}));

const noteDate = date(`HH:mma - MM/dd/yy`);
export const DrawerMessages: React.FC<DrawerProps> = (props) => {
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const classes = useStyles();
    const { setMessageRead } = useSetMessageRead();
    const history = useHistory();

    const markRead = (m: Models.Messages) => async () => {
        const res = await setMessageRead(m.rowID);
        if (res.status) {
            appDispatch({ type: AppActions.updateMessages, payload: [{ ...m, seen: true }] });
        }
    };

    const goToInvoice = (m: Models.Messages) => async () => {
        await markRead(m)();
        history.push(`/audit/${m.carrierInvoiceID}`);
    };

    const sortedMessages = useMemo(() => appState.messages.sort((a, b) => parseJSON(b.createdAt).valueOf() - parseJSON(a.createdAt).valueOf()), [
        appState.messages
    ]);

    return (
        <Drawer {...props} anchor="right">
            <Grid container direction="column" className={classes.root}>
                <GridMin item container justify="center" className={classes.header}>
                    <Typography variant="h6">Messages</Typography>
                </GridMin>
                <GridGrow>
                    <Scroller>
                        <Grid container>
                            {sortedMessages.map((m) => (
                                <Grid key={m.rowID} item container className={`${classes.messageContainer} ${m.seen ? '' : classes.unread}`} direction="column">
                                    <Typography variant="body1">{`From: ${m.fromUser?.firstName} ${m.fromUser?.lastName}`}</Typography>
                                    <Typography variant="caption">{m.fromUser?.email}</Typography>
                                    <Typography variant="caption">{noteDate(m.createdAt)}</Typography>
                                    <Typography variant="body2" className={classes.messageContent}>
                                        {m.content}
                                    </Typography>
                                    <Grid container justify="space-between" className={classes.links}>
                                        <Typography variant="caption" onClick={markRead(m)}>
                                            Mark as Read
                                        </Typography>
                                        <Typography variant="caption" onClick={goToInvoice(m)}>
                                            View Invoice
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Scroller>
                </GridGrow>
            </Grid>
        </Drawer>
    );
};
