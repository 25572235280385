import React, { useRef, useEffect } from 'react';
import { GridMin, PageContainer, Pager, GridGrow } from '../components/generic';
import { useAuditStyles } from '../components/layout/audit/audit.component';
import { Box } from '@material-ui/core';
import { AuditActions, IAuditState } from '../components/layout/audit/audit.context';
import { usePostMessages } from '../hooks/postmessage.hook';
import { AuditMessageKey } from './audit.page';
import { useHistory, useParams } from 'react-router-dom';
import { PDF } from '../components/pdfjsreskin/pdf.component';

export interface IAuditPDFState extends IAuditState {
    totalCount?: number;
    carrierCode?: number | string;
    invoiceNumber?: number | string;
}

export const PDFAuditPage = () => {
    const auditClasses = useAuditStyles();
    const openerRef = useRef<Window>(window.opener);
    const [receivedMessages, postMessage] = usePostMessages<IAuditPDFState>([AuditMessageKey]);
    const history = useHistory();
    const params = useParams<{ carrierCode: string; invoiceNumber: string }>();

    const sendPage = (p: number) => {
        if (openerRef.current) {
            postMessage(openerRef.current, { type: AuditActions.invoiceAppIdx, payload: p }, AuditMessageKey);
        }
    };

    useEffect(() => {
        receivedMessages?.carrierCode &&
            receivedMessages?.invoiceNumber &&
            history.replace(`/audit/pdf/${receivedMessages?.carrierCode}/${receivedMessages?.invoiceNumber}`);
    }, [history, receivedMessages]);

    return (
        <PageContainer>
            {receivedMessages?.invoiceAppIdx !== undefined && receivedMessages?.invoiceAppIdx !== -1 && (
                <GridMin container className={auditClasses.bgGreen} justify="center">
                    <Box padding={1.5}>
                        <Pager
                            currentPage={receivedMessages?.invoiceAppIdx && receivedMessages.invoiceAppIdx !== -1 ? receivedMessages.invoiceAppIdx : 0}
                            totalPages={receivedMessages?.totalCount || 1}
                            onPageChange={sendPage}
                            hideControls={receivedMessages?.invoiceAppIdx === undefined || receivedMessages?.invoiceAppIdx === -1}
                        />
                    </Box>
                </GridMin>
            )}
            <GridGrow>
                <PDF carrierCode={params.carrierCode} invoiceNumber={params.invoiceNumber} />
            </GridGrow>
        </PageContainer>
    );
};
