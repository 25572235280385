import React, { useCallback, useMemo } from 'react';
import { useAuditState, useAuditDispatch, AuditActions } from './audit.context';
import { useInvoiceState } from '../../../contexts/invoice.context';
import { Pager } from '../../generic';

export const AuditPager: React.FC<{ beforeChange: () => Promise<boolean> }> = ({ beforeChange }) => {
    const auditState = useAuditState();
    const auditDispatch = useAuditDispatch();
    const invoiceState = useInvoiceState();

    const changePage = useCallback(
        async (p: number) => {
            if (!(await beforeChange())) return;
            auditDispatch({ type: AuditActions.invoiceAppIdx, payload: p });
        },
        [auditDispatch, beforeChange]
    );
    return useMemo(
        () => (
            <>
                {auditState.invoiceAppIdx !== -1 && invoiceState.invoices.length ? (
                    <Pager currentPage={auditState.invoiceAppIdx} totalPages={invoiceState.invoices.length} onPageChange={changePage} />
                ) : null}
            </>
        ),
        [changePage, auditState.invoiceAppIdx, invoiceState.invoices.length]
    );
};
