import { fade, FormControl, FormControlProps, InputLabel, makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useInputStyle } from '../../../styles/stiinput.style';

const useStyles = makeStyles((theme) => ({
    override: {
        background: theme.palette.background.paper,
        width: '100%',
        // height: theme.spacing(2.375),
        '& .MuiFormLabel-root': {
            transform: `translate(${theme.spacing(1.5)}px, ${theme.spacing(1.25)}px) scale(0.75)`
        },
        paddingTop: theme.spacing(3.375),
        paddingRight: theme.spacing(1.125),
        paddingBottom: theme.spacing(1.125),
        paddingLeft: theme.spacing(1),

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${fade('#000000', 0.42)}`,
        color: theme.palette.text.primary
    },
    fakeInput: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        height: theme.spacing(2.375)
    },
    endAdornment: {
        overflow: 'visible',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: 0,
        right: theme.spacing(1.5),
        height: '100%'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

export const FakeInput: React.FC<FormControlProps & { label?: string; endAdornment?: ReactNode; pointer?: boolean }> = ({
    label,
    children,
    pointer,
    endAdornment,
    ...props
}) => {
    const inputClasses = useInputStyle();
    const classes = useStyles();
    return (
        <FormControl {...props} className={`${inputClasses.input} ${classes.override} ${props.className} ${pointer ? classes.pointer : ''}`}>
            <InputLabel shrink>{label}</InputLabel>
            {typeof children === 'string' ? <Typography className={classes.fakeInput}>{children}</Typography> : children}
            <div className={classes.endAdornment}>{endAdornment}</div>
        </FormControl>
    );
};
