import React, { useMemo } from 'react';
import { useAuditState } from '../audit.context';
import { Grid, Box, Typography, useTheme, fade, makeStyles } from '@material-ui/core';
import { GridGrow } from '../../../generic';
import { ReloadGL } from './reloadgl.component';
import { SaveGL } from './savegl.component';
import { AddGl } from './addgl.component';
import { currency } from '../../../../helpers/pipes';
import { useAppStateRef } from '../../../../contexts/app.context';

const useStyles = makeStyles((theme) => ({
    total: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.palette.common.white,
        '&.hasDif': {
            backgroundColor: theme.palette.warning.main
        }
    }
}));

export const GLTotalRow = () => {
    const classes = useStyles();
    const auditState = useAuditState();
    const theme = useTheme();
    const denomination = auditState.invoice?.currency;

    const encodedDif = parseFloat(((auditState.invoice?.currentAmt || 0) - auditState.encoded).toFixed(2));
    return (
        <Grid container justify="flex-end" alignItems="center" spacing={1}>
            <Grid item>
                <Box color="white" display="flex">
                    <ReloadGL
                        size="small"
                        bgColor={theme.palette.primary.main}
                        hoverColor={fade(theme.palette.primary.dark, 0.5)}
                        rippleColor={theme.palette.primary.dark}
                    />
                </Box>
            </Grid>
            <GridGrow></GridGrow>
            {encodedDif ? (
                <Grid item>
                    <Box color="white">
                        <Typography variant="h6" color="inherit">
                            {`Encoded: ${currency(auditState.encoded, denomination)}`}
                        </Typography>
                    </Box>
                </Grid>
            ) : null}
            <Grid item>
                <div className={`${classes.total}${encodedDif ? ' hasDif' : ''}`}>
                    <Typography variant="h6" color={encodedDif !== 0 ? 'inherit' : 'secondary'}>
                        {`Remaining: ${currency(encodedDif, denomination)}`}
                    </Typography>
                </div>
            </Grid>
            <Grid item>
                <Box color="white">
                    <Grid container spacing={1}>
                        <Grid item>
                            <AddGl
                                size="small"
                                bgColor={theme.palette.primary.main}
                                hoverColor={fade(theme.palette.primary.dark, 0.5)}
                                rippleColor={theme.palette.primary.dark}
                            />
                        </Grid>
                        <Grid item>
                            <SaveGL
                                size="small"
                                bgColor={theme.palette.primary.main}
                                hoverColor={fade(theme.palette.primary.dark, 0.5)}
                                rippleColor={theme.palette.primary.dark}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
