import { makeStyles } from '@material-ui/core';

export const useFonts = makeStyles({
    openSans: {
        fontFamily: "'Open Sans', sans-serif",
    },
    openSansSemiBold: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
    },
    adelle: {
        fontFamily: 'Adelle, serif',
    },
    adelleSemiBold: {
        fontFamily: 'Adelle-semibold, serif',
    },
    adelleBold: {
        fontFamily: 'Adelle-bold, serif',
    },
});
