import React, { useReducer } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useAdjustmentTableStyles } from './adjustmenthistorytable.component';
import { DATE_TIME, currency, date } from '../../../../helpers/pipes';
import { CheckOnly } from '../../../generic';
import { useAppState } from '../../../../contexts/app.context';
import { SingleSelectAuto } from '../../../generic/controls/single-selectauto.component';
import { useInputStyle } from '../../../../styles/stiinput.style';
import { useMenuStyles } from '../../../../styles/stimenu.style';
import { IIdx } from '../../../../types/general.type';
import { Typography, Input, useTheme } from '@material-ui/core';
import { ExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';
import { mdiBackupRestore, mdiContentSaveOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useUpdateAdustment } from '../../../../hooks/stiapi.hook';
import { success } from '../../../../hooks/api.hook';

interface AdjustmentRowProps {
    adjustment: Models.AdjustedAmounts;
    index: number;
    onChange: (adjustment: Models.AdjustedAmounts, index: number) => void;
    invoice?: Models.CarrierInvoices;
}

interface UpdateState {
    base: Models.AdjustedAmounts;
    update: IIdx<Partial<Models.AdjustedAmounts>>;
    info?: {
        loading?: boolean;
        hasUpdates?: boolean;
    };
}

type Actions =
    | { type: 'update'; payload: Partial<Models.AdjustedAmounts> }
    | { type: 'info'; payload: UpdateState['info'] }
    | { type: 'reset'; payload?: Partial<UpdateState> };

const reducer = (state: UpdateState, action: Actions) => {
    switch (action.type) {
        case 'info':
            return { ...state, info: { ...state.info, ...action.payload } };
        case 'update':
            const newState = { ...state, update: { ...state.update } };
            const base = state.base as { [index: string]: any };

            Object.entries(action.payload).forEach(([key, val]) => {
                if (val !== base[key]) {
                    newState.update[key] = val;
                } else {
                    delete newState.update[key];
                }
            });
            newState.info = { ...newState.info, hasUpdates: Object.keys(newState.update).length !== 0 };
            return newState as UpdateState;
        case 'reset':
            return { ...state, update: {}, info: {}, ...action.payload };
        default:
            return state;
    }
};

export const AdjustmentRow: React.FC<AdjustmentRowProps> = ({ adjustment, onChange, index, invoice }) => {
    const theme = useTheme();
    const [state, dispatch] = useReducer(reducer, { base: adjustment, update: {}, info: {} });
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const appState = useAppState();
    const classes = useAdjustmentTableStyles();
    const { updateAdjustment, loading } = useUpdateAdustment();

    const typeOptions = appState.data.adjustmentTypes;
    const catOptions = appState.data.adjustmentCategories;

    const handleChange = (payload: Partial<Models.AdjustedAmounts>) => {
        dispatch({ type: 'update', payload });
    };

    const handleUpdate = async () => {
        const { user, adjustmentCategory, adjustmentType, ...update } = state.update;
        update.adjustmentCategoryID = adjustmentCategory?.adjustmentCategoryID;
        update.adjustmentTypeID = adjustmentType?.adjustmentTypeID;
        update.id = state.base.id;
        const res = await updateAdjustment(update);
        if (success(res)) {
            dispatch({ type: 'reset', payload: { base: res.data } });
            onChange?.(res.data, index);
        }
    };

    return (
        <>
            <div className={`${classes.titleRow} ${classes.sectionStart} ${classes.rowStart} ${classes.rowEnd}`}>
                <Typography>{`${adjustment.user?.firstName} ${adjustment.user?.lastName} - ${date(DATE_TIME)(adjustment.createdAt)}`}</Typography>
            </div>
            <div className={`${classes.headerItem} ${classes.rowStart}`}>Current Amount</div>
            <div className={`${classes.headerItem}`}>Billable</div>
            <div className={`${classes.headerItem}`}>Type</div>
            <div className={`${classes.headerItem} ${classes.rowEnd}`}>Category</div>
            <div className={`${classes.cellWrapper} ${classes.rowStart}`}>{currency(adjustment.amount, invoice?.currency)}</div>
            <div className={classes.cellWrapper}>
                <CheckOnly
                    color="currentColor"
                    checked={state.update.billable ?? state.base.billable}
                    onChange={(evt, checked) => handleChange({ billable: checked })}
                />
            </div>
            <div className={classes.cellWrapper}>
                <div className={classes.cell}>
                    <SingleSelectAuto
                        required
                        value={state.update.adjustmentType ?? state.base.adjustmentType}
                        onValChange={(evt, val) => handleChange({ adjustmentType: val })}
                        fullWidth
                        options={typeOptions}
                        menuClasses={menuClasses}
                        getOptionSelected={(o, v) => o.adjustmentTypeID === v.adjustmentTypeID}
                        getText={(o) => o.adjustmentCode}
                        getSubtext={(o) => o.adjustmentDescription}
                        renderInput={({ InputProps, InputLabelProps, ...params }) => {
                            return <Input disableUnderline {...InputProps} {...params} className={classes.inputFix} />;
                        }}
                    />
                </div>
            </div>
            <div className={`${classes.cellWrapper} ${classes.rowEnd}`}>
                <div className={classes.cell}>
                    <SingleSelectAuto
                        required
                        value={state.update.adjustmentCategory ?? state.base.adjustmentCategory}
                        onValChange={(evt, val) => handleChange({ adjustmentCategory: val })}
                        fullWidth
                        options={catOptions}
                        menuClasses={menuClasses}
                        getOptionSelected={(o, v) => o.adjustmentCategoryID === v.adjustmentCategoryID}
                        getText={(o) => o.adjustmentCategoryCode}
                        getSubtext={(o) => o.adjustmentCategoryDescription}
                        renderInput={({ InputProps, InputLabelProps, ...params }) => {
                            return <Input disableUnderline {...InputProps} {...params} className={classes.inputFix} />;
                        }}
                    />
                </div>
            </div>
            <div className={`${classes.sectionEnd} ${classes.footerRow} ${classes.rowStart} ${classes.rowEnd}`}>
                <div>Notes: {adjustment.notes}</div>
                {state.info?.hasUpdates ? (
                    <div className={classes.footerButtons}>
                        <ExtendedIconButton size="small" color="inherit" onClick={() => dispatch({ type: 'reset' })}>
                            <Icon size={1} path={mdiBackupRestore} />
                        </ExtendedIconButton>
                        <ExtendedIconButton size="small" color="inherit" onClick={handleUpdate} loading={!!loading}>
                            <Icon size={1} path={mdiContentSaveOutline} />
                        </ExtendedIconButton>
                    </div>
                ) : null}
            </div>
        </>
    );
};
