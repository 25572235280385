import { ButtonBaseProps, useTheme, fade, Box, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { useSelector } from '../../../contexts/store.helpers';
import { SearchType, IAppState, AppActions } from '../../../types/contexts/app-context.type';
import { STIButtonbase } from '../../generic';
import { GrowUnderline } from '../../generic/feedback/growunderline.component';

export interface ISearchOption {
    option: SearchType;
    buttonColor?: string;
    color?: 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error' | undefined;
}

const searchSelector = (state: IAppState) => [state.searchType];

export const SearchOption: React.FC<ISearchOption & ButtonBaseProps<'div'>> = ({ children, option, buttonColor = 'primary', color = 'inherit', ...props }) => {
    const theme = useTheme();
    const appDispatch = useAppDispatch();
    const [currentSearch] = useSelector(useAppState, searchSelector);

    const handleClick = useCallback(() => {
        appDispatch({ type: AppActions.searchType, payload: option });
    }, [appDispatch, option]);

    return (
        <STIButtonbase color={buttonColor} {...props} onClick={handleClick}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                color={currentSearch === option ? fade(theme.palette.common.white, 0.87) : fade(theme.palette.common.white, 0.5)}
            >
                <GrowUnderline show={currentSearch === option} lineProps={{ bgcolor: fade(theme.palette.common.white, 0.87) }} marginBottom="-1px">
                    <Typography color="inherit" variant="button">
                        {children}
                    </Typography>
                </GrowUnderline>
            </Box>
        </STIButtonbase>
    );
};
