import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { UserDetails, IUserDetails } from './manageuserdetails.component';
import { useManageDispatch, useManageStateRef, ManageActions, ManageType } from '../manage.context';
import { useStateWithRef } from '../../../../hooks/statewithref.hook';
import { useCreateUser, useGetUsers, useGetUsersByClient } from '../../../../hooks/stiapi.hook';
import { MODAL_TYPE, useInfoModal } from '../../../../hooks/info-modal.hook';

interface IUserInfo {
    submitRef?: React.RefObject<HTMLButtonElement>;
}

export const NewUserControl: React.FC<IUserInfo> = ({ submitRef }) => {
    const manageDispatch = useManageDispatch();
    const manageStateRef = useManageStateRef();
    const [, /*newUser*/ setNewUser, newUserRef] = useStateWithRef<IUserDetails>({});
    const [initialUser, setInitialUser] = useState<IUserDetails>();
    const { createUser } = useCreateUser();
    const { getUsersByClient } = useGetUsersByClient();
    const { getUsers } = useGetUsers();
    const [error, setError] = useState('');
    const [ConfirmationModal, runConfirmation] = useInfoModal();

    const onSubmit = useCallback(
        (evt: React.FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            if (newUserRef.current) {
                (async () => {
                    const { confirmEmail, confirmPassword, manager, approvalLimit, approvalLimitString, ...req } = newUserRef.current;
                    req.accountType = req.accountType ? 1 : 2;
                    req.managerUserID = manager?.userID;
                    req.approvalLimit =
                        parseFloat(approvalLimitString?.replace('$', '').replace(',', '').replace('€', '').replace('CAD', '').trim() ?? '0') || null;
                    const res = await createUser(req);
                    if (res.status === 201) {
                        manageDispatch({ type: ManageActions.newUser, payload: req });
                        manageDispatch({ type: ManageActions.userToManage, payload: res.data.User });
                        manageDispatch({ type: ManageActions.updateUserList, payload: res.data.User });
                        manageDispatch({ type: ManageActions.manageType, payload: ManageType.manageAccess });
                        if (manageStateRef.current.clientToManage) {
                            const data = await getUsersByClient(manageStateRef.current.clientToManage.clientID);
                            if (data.status === 200) {
                                manageDispatch({ type: ManageActions.clientUsers, payload: data.data });
                            }
                        }
                        const data = await getUsers();
                        if (data.status === 200) {
                            manageDispatch({ type: ManageActions.allUsers, payload: data.data });
                            await runConfirmation({ title: 'Success', yesTxt: 'OK', desc: 'User successfully created', no: false, modalType: MODAL_TYPE.INFO });
                        }
                    } else {
                        setError((res as any)?.response?.data?.Message || 'There was a problem updating this user.');
                        setTimeout(() => setError(''), 10000);
                    }
                })();
            }
        },
        [newUserRef, createUser, manageDispatch, manageStateRef, getUsers, getUsersByClient, runConfirmation]
    );

    useEffect(() => {
        setInitialUser(manageStateRef.current.newUser || {});
    }, [manageDispatch, newUserRef, setInitialUser, manageStateRef]);

    useEffect(
        () => () => {
            const toStore = { ...newUserRef.current, password: '', confirmEmail: '', confirmPassword: '' };
            manageDispatch({ type: ManageActions.newUser, payload: toStore, storeOnly: true });
        },
        [manageDispatch, newUserRef]
    );

    const somethingChanged = useCallback(
        (newUser) => {
            setNewUser(newUser);
        },
        [setNewUser]
    );

    return (
        <form onSubmit={onSubmit} style={{ height: '100%', width: '100%' }}>
            <ConfirmationModal />
            <UserDetails mode="create" user={initialUser} onSomethingChanged={somethingChanged} error={error} />
            <Box display="none">
                <button ref={submitRef} type="submit" />
            </Box>
        </form>
    );
};
