import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Tooltip, TooltipProps } from '@material-ui/core';

export const STITooltip = withStyles((theme) => ({
    tooltip: {
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        color: theme.palette.primary.main,
    },
}))(({ children, ...props }: TooltipProps) => <Tooltip {...props}>{children}</Tooltip>);
