import React, { memo, useRef, useMemo } from 'react';
import { Grid, Typography, styled, fade, Box, Paper } from '@material-ui/core';
import { GridMin, GridGrow, Scroller } from '../../generic';
import { IAuditState, useAuditState } from './audit.context';
import { useSelector } from '../../../contexts/store.helpers';
import { date } from '../../../helpers/pipes';
import { NewNoteInline } from './noteinput/newNoteInline.component';

export const NotesComponent: React.FC = memo(() => {
    return (
        <Grid item container direction="column">
            <GridMin>
                <NewNoteInline />
            </GridMin>
            <GridGrow container>
                <Box marginLeft={-1} height="100%" width="100%">
                    <Scroller>
                        <Paper>
                            <Notes />
                        </Paper>
                    </Scroller>
                </Box>
            </GridGrow>
        </Grid>
    );
});

const noteDate = date(`h:mm a - MM/dd/yy`);

const Notes: React.FC = () => {
    const auditState = useAuditState();
    const { actions: notes } = auditState;
    const lastNoteRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     lastNoteRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    // }, [notes.length]);

    const display = useMemo(() => {
        const n = [...notes];
        n.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf() || a.action.localeCompare(b.action));
        // n.reverse();
        return n;
    }, [notes]);

    return (
        <NoteContainer container>
            {display.map((n, i) => (
                <Grid item ref={i === notes.length - 1 ? lastNoteRef : undefined} key={n.ID}>
                    <Box padding={1.5} width="100%">
                        <Typography variant="subtitle2" color="primary">
                            {`${n.user?.firstName} ${n.user?.lastName} - ${n.createdAt && noteDate(n.createdAt)}`}
                        </Typography>
                        <Box paddingLeft={1.5}>
                            {n.action?.split('\n').map((s, i) => (
                                <Typography key={i} variant="body2" color="textPrimary">
                                    {s}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Grid>
            ))}
        </NoteContainer>
    );
};

const NoteContainer = styled(Grid)(({ theme }) => ({
    '& > *': {
        position: 'relative',
        width: '100%',
        '&:nth-of-type(2n-1)': {
            backgroundColor: fade(theme.palette.common.black, 0.05)
        }
    },
    '& > :not(:first-child)': {
        paddingTop: theme.spacing(1)
    },
    '& > :not(:last-child)': {
        paddingBottom: theme.spacing(1),
        '&::after': {
            content: '""',
            display: 'block',
            width: '33%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0
        }
    }
}));
