import React, { memo, useState } from 'react';
import { Button, TextField, Grid, makeStyles, Typography } from '@material-ui/core';
import { GridMin } from '../../generic';
import { Header, BodyContainer, ModalContainer } from './common.component';
import { useForgotPassword } from '../../../hooks/stiapi.hook';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    buttons: {
        paddingTop: theme.spacing(2)
    }
}));

interface IForgotModal {
    setOpen: (open: boolean) => void;
}

export const Component: React.FC<IForgotModal> = ({ setOpen }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const { forgotPassword, loading } = useForgotPassword();
    const [status, setStatus] = useState<number | undefined>();
    const history = useHistory();

    const close = () => {
        setOpen(false);
    };

    const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const res = await forgotPassword(email);
        const status = res.status === 201 ? res.data?.Status || res.status : res.status;
        setStatus(status);
        if (status === 201) {
            setTimeout(() => history.push('/resetlogin'), 3000);
        }
    };

    return (
        <ModalContainer>
            <GridMin>
                <Header variant="subtitle1">Forgot Password</Header>
            </GridMin>
            <form onSubmit={onSubmit}>
                <BodyContainer item container>
                    <Grid item container direction="column">
                        <Typography>
                            {status === undefined
                                ? 'Please enter the email used for this account.'
                                : status === 201
                                ? `A reset link has been sent to ${email}.`
                                : 'There was a problem finding this account. Please try again or contact your supervisor.'}
                        </Typography>
                        {status !== 201 && (
                            <TextField required name="oldPW" onChange={(evt) => setEmail(evt.target.value)} type="email" value={email} label="Email" />
                        )}
                    </Grid>
                    <Grid item container wrap="nowrap" spacing={2} justify="flex-end" className={classes.buttons}>
                        {status !== 201 && (
                            <Grid item>
                                <ExtendedButton loading={!!loading} color="secondary" variant="contained" type="submit">
                                    Send
                                </ExtendedButton>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant="contained" onClick={close} className={classes.error}>
                                {status === 201 ? 'Close' : 'Cancel'}
                            </Button>
                        </Grid>
                    </Grid>
                </BodyContainer>
            </form>
        </ModalContainer>
    );
};

export const ForgotPassword = memo(Component);
