import React, { memo } from 'react';
import { IGLCodingPlus, GLAuditActions } from '../audit.context';
import { TableRow, TableCell, Box } from '@material-ui/core';
import { GLInput } from './glinput.component';
import { GLDelete } from './gldelete.component';
import { GLInputControlled } from './glinputcontrolled.component';

interface IGLRow {
    rowNum: number;
    inputClass?: string;
    glcoding: IGLCodingPlus;
    onTab?: (evt: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    availableGLs: string[][];
    canEnter?: boolean;
    canEdit?: boolean;
    colCount: number;
}

export const GLRow: React.FC<IGLRow> = memo(({ rowNum, inputClass, glcoding, onTab, availableGLs, canEnter, canEdit, colCount }) => {
    const cells = [];

    for (let i = 0; i < colCount; i++) {
        cells.push(
            <TableCell key={`${glcoding.key} gl${i}`}>
                <GLInput
                    value={glcoding[`gl${i + 1}`] || null}
                    options={availableGLs[i] || []}
                    className={inputClass}
                    rowNum={rowNum}
                    name={`gl${i + 1}`}
                    canEnter={canEnter}
                    canEdit={canEdit}
                />
            </TableCell>
        );
    }

    return (
        <TableRow>
            {cells}
            <TableCell key={`${glcoding.key} per`}>
                <GLInputControlled className={inputClass} rowNum={rowNum} name={GLAuditActions.glPer} />
            </TableCell>
            <TableCell key={`${glcoding.key} amt`}>
                <GLInputControlled isCurrency className={inputClass} rowNum={rowNum} onKeyDown={onTab} name={GLAuditActions.glAmount} />
            </TableCell>
            <TableCell key={`${glcoding.key} trash`}>
                <Box color="white">
                    <GLDelete rowNum={rowNum} />
                </Box>
            </TableCell>
        </TableRow>
    );
});
