import { Typography, makeStyles } from '@material-ui/core';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { DataTableInstance, DataTableInstanceRef } from '../../datatable/datatablevirtual.component';
import React from 'react';
import { useAppDispatch, useAppStateRef, useGetTableDef } from '../../../contexts/app.context';
import { AppDataActions } from '../../../types/contexts/app-context.type';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none'
    },
    buttonRow: {
        display: 'flex',
        gap: theme.spacing(1.5) + 'px',
        pointerEvents: 'all',
        flexWrap: 'nowrap'
    }
}));

interface HomeTableColumnWarningProps {
    tableInstance: React.MutableRefObject<DataTableInstance | undefined>;
}

export const HomeTableColumnWarning: React.FC<HomeTableColumnWarningProps> = ({ tableInstance }) => {
    const classes = useStyles();
    const getDef = useGetTableDef();
    const appStateRef = useAppStateRef();
    const appDispatch = useAppDispatch();

    const reset = () => {
        const currentTable = appStateRef.current.homeTableMode;
        const resetDef = getDef(currentTable);
        appDispatch({ type: AppDataActions.tableDefs, payload: { [currentTable]: resetDef } });
    };
    return (
        <div className={classes.root}>
            <Typography variant="h4">There are no active columns to display.</Typography>
            <Typography variant="h6">Please make one of the following adjustments.</Typography>
            <div className={classes.buttonRow}>
                <ExtendedButton variant="contained" color="primary" onClick={() => tableInstance?.current?.setMenuOpen?.((o: boolean) => !o, 1)}>
                    Activate Columns
                </ExtendedButton>
                <ExtendedButton variant="contained" color="primary" onClick={reset}>
                    Reset to Default Table
                </ExtendedButton>
            </div>
        </div>
    );
};
