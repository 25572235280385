import React, { useReducer, useContext, useRef, useEffect } from 'react';
import { loggedReducer } from '../../contexts/store.helpers';
import { IDataTableState, DataTableActions, IDataTableAction } from './datatable.types';

const initialState: IDataTableState = {
    [DataTableActions.sort]: [],
    [DataTableActions.colWidths]: undefined,
    [DataTableActions.columns]: []
};

const adjustStickyColumns = (state: IDataTableState): IDataTableState => {
    if (!state.colWidths) return state;
    for (let i = 0, pos = 0; i < state.columns.length; i++) {
        const col = state.columns[i];
        if (!col.active) continue;
        if (state.colWidths[col.key]?.sticky !== undefined) {
            state.colWidths[col.key].sticky = pos;
            pos += state.colWidths[col.key].width ?? state.colWidths[col.key].initialWidth;
        }
    }
    return state;
};

const reducer = (state: IDataTableState, action: IDataTableAction) => {
    let newState = { ...state };
    switch (action.type) {
        case DataTableActions.colWidth: {
            if (!action.colKey) return state;
            const _default = newState.colWidths?._default || { initialWidth: 90, initialExpand: true };
            newState.colWidths = { ...newState.colWidths, _default };
            if (!newState.colWidths[action.colKey]) newState.colWidths[action.colKey] = _default;
            newState.colWidths[action.colKey].width = action.payload;

            return adjustStickyColumns(newState);
        }
        case DataTableActions.colWidths:
            newState.colWidths = { ...newState.colWidths, ...action.payload };
            return adjustStickyColumns(newState);
        case DataTableActions.sort:
            newState[action.type] = action.payload;
            return newState;
        case DataTableActions.columns:
            newState[action.type] = action.payload;
            return adjustStickyColumns(newState);
        case DataTableActions.replace:
            return adjustStickyColumns({ ...state, ...action.payload });
        case DataTableActions.toggleSticky: {
            if (!action.colKey) return state;
            const _default = newState.colWidths?._default || { initialWidth: 90, initialExpand: true };
            newState.colWidths = { ...newState.colWidths, _default };
            const col = newState.colWidths[action.colKey];

            if (col) col.sticky = col.sticky === undefined ? 0 : undefined;
            return adjustStickyColumns(newState);
        }
        default:
            return state;
    }
};

const DataTableState: any = React.createContext({});
const DataTableDispatch: any = React.createContext({});
const DataTableStateRef: any = React.createContext({});

export const useDataTableState = (): IDataTableState => useContext(DataTableState);
export const useDataTableDispatch = (): ((action: IDataTableAction) => IDataTableState) => useContext(DataTableDispatch);
export const useDataTableStateRef = (): React.MutableRefObject<IDataTableState> => useContext(DataTableStateRef);

const logReducer = loggedReducer<IDataTableState, IDataTableAction>(reducer, 'Data Table');

export const DataTableProvider: React.FC<{ savedState?: Partial<IDataTableState> }> = ({ savedState, children }) => {
    const [state, dispatch] = useReducer(logReducer, { ...initialState, ...savedState });
    const stateRef = useRef<IDataTableState>(state);

    stateRef.current = state;

    useEffect(() => {
        savedState !== stateRef.current && dispatch({ type: DataTableActions.replace, payload: savedState });
    }, [savedState, dispatch]);

    return (
        <DataTableStateRef.Provider value={stateRef}>
            <DataTableState.Provider value={state}>
                <DataTableDispatch.Provider value={dispatch}>{children}</DataTableDispatch.Provider>
            </DataTableState.Provider>
        </DataTableStateRef.Provider>
    );
};
