import React, { useEffect, useState } from 'react';
import { useGetAdjustmentsByInvoice } from '../../../../hooks/stiapi.hook';
import { AdjustmentHistoryProps } from './adjustmenthistory.component';
import { makeStyles, fade } from '@material-ui/core';
import { Models } from 'shipmenttrackers-domain/dist';
import { AdjustmentRow } from './adjustmentrow.component';
import { STILoading } from '../../../generic';

export const useAdjustmentTableStyles = makeStyles((theme) => ({
    body: {
        flexWrap: 'nowrap'
    },
    gridWrapper: {
        flex: 1,
        overflow: 'auto',
        maxWidth: theme.spacing(64)
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        '& > *': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        justifyContent: 'stretch',
        alignItems: 'stretch',
        paddingRight: theme.spacing(0.25)
    },
    titleRow: {
        gridColumn: '1 / -1',
        display: 'flex',
        paddingBottom: theme.spacing(0.5),
        borderBottom: '1px solid currentColor'
    },
    footerRow: {
        paddingTop: theme.spacing(0.5),
        gridColumn: '1 / -1',
        borderTop: `1px solid ${fade(theme.palette.common.white, 0.5)}`,
        display: 'flex',
        justifyContent: 'space-between'
    },
    footerButtons: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    space: {
        paddingTop: theme.spacing(2)
    },
    historyEntry: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    cellWrapper: {
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        '& svg': {
            color: theme.palette.common.white
        }
    },
    rowStart: {
        paddingLeft: theme.spacing(1)
    },
    rowEnd: {
        paddingRight: theme.spacing(1)
    },
    sectionStart: {
        paddingTop: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1)
    },
    sectionEnd: {
        paddingBottom: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    margin: {
        gridColumn: '1 / -1',
        padding: theme.spacing(1),
        background: theme.palette.common.white
    },
    cell: {
        background: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        color: 'black',
        padding: theme.spacing(0.75),
        width: '100%',
        height: '100%',
        minWidth: theme.spacing(8),
        display: 'flex',
        alignItems: 'center'
    },
    headerItem: {
        color: fade(theme.palette.common.white, 0.5),
        borderColor: 'currentColor',
        borderStyle: 'solid',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inputFix: {
        '&.MuiInputBase-root': {
            paddingTop: 0,
            paddingBottom: 0
        }
    }
}));

export const AdjustmentHistoryTable: React.FC<AdjustmentHistoryProps> = ({ invoice }) => {
    const carrierInvoiceID = invoice?.carrierInvoiceID;
    const { getAdjustmentsByInvoice, loading } = useGetAdjustmentsByInvoice();
    const classes = useAdjustmentTableStyles();

    const [entries, setEntries] = useState<Models.AdjustedAmounts[]>();
    const [error, setError] = useState('');

    useEffect(() => {
        if (carrierInvoiceID)
            getAdjustmentsByInvoice(carrierInvoiceID).then((res) => {
                if (res.status === 200) {
                    if (!res.data.length) {
                        setError('No Adjustment History');
                        setError('');
                    } else setEntries(res.data);
                } else {
                    setError('There was a probem fetching history for this invoice');
                }
            });
    }, [carrierInvoiceID, getAdjustmentsByInvoice]);

    const onChange = (adj: Models.AdjustedAmounts, index: number) => {
        entries?.splice(index, 1, adj);
        setEntries(entries);
    };

    return (
        <>
            {loading ? (
                <STILoading />
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className={classes.gridWrapper}>
                    <div className={classes.grid}>
                        {entries?.map((e, i) => (
                            <React.Fragment key={e.id}>
                                <AdjustmentRow invoice={invoice} adjustment={e} onChange={onChange} index={i} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
            {/* {history.entries?.length ? (
        <div className={classes.grid} style={{ '--colCount': (history.maxGL ?? 3) + 2 } as any}>
            {history.entries?.map((h, i) => (
                <>
                    {i > 0 && <div className={classes.margin} />}
                    <div className={`${classes.titleRow} ${classes.sectionStart} ${classes.rowStart} ${classes.rowEnd}`}>
                        <Typography>{`${h.user?.firstName} ${h.user?.lastName} - ${date(DATE_TIME)(h.date)}`}</Typography>
                    </div>
                    {new Array(history.maxGL ?? 3).fill(null).map((n, i) => (
                        <div className={`${classes.rowStart} ${classes.headerItem}`}>{`GL${i + 1}`}</div>
                    ))}
                    <div className={`${classes.headerItem}`}>%</div>
                    <div className={`${classes.rowEnd} ${classes.headerItem}`}>AMOUNT</div>
                    {h.rows.map((r) => (
                        <>
                            {new Array(history.maxGL ?? 3).fill(null).map((n, i) => (
                                <div className={`${classes.cellWrapper} ${i === 0 ? classes.rowStart : ''}`}>
                                    <div className={`${classes.cell}`}>{(r as IIdx)[`gl${i + 1}`]}</div>
                                </div>
                            ))}
                            <div className={classes.cellWrapper}>
                                <div className={classes.cell}>{((r.glAmount / h.total) * 100).toFixed(2)}</div>
                            </div>
                            <div className={`${classes.cellWrapper} ${classes.rowEnd}`}>
                                <div className={`${classes.cell}`}>{currency(r.glAmount, denomination)}</div>
                            </div>
                        </>
                    ))}
                    <div className={`${classes.totalRow} ${classes.sectionEnd} ${classes.rowStart} ${classes.rowEnd}`}>
                        <Typography>{`Total: ${currency(h.total, denomination)}`}</Typography>
                    </div>
                </>
            ))}
        </div>
            ) : (<Typography variant="h6">No history to show.</Typography>
            )} */}
        </>
    );
};
