import { fade, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    topRadius: {
        borderTopLeftRadius: theme.spacing(3),
        borderTopRightRadius: theme.spacing(3)
    },
    itemButton: {
        width: '100%',
        padding: theme.spacing(1),
        '& .MuiButton-label': {
            textAlign: 'start',
            display: 'block'
        },
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.25)
        }
    },
    toggleButton: {
        borderRadius: '999px',
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    buttonSelected: {
        backgroundColor: fade(theme.palette.primary.dark, 0.25)
    },
    childrenLabel: {
        '& > *': {
            pointerEvents: 'none'
        },
        '& > p': {
            transform: 'translateX(-50%)',
            color: fade(theme.palette.common.black, 0.25)
        },
        '& > span': {
            backgroundColor: fade(theme.palette.common.black, 0.25)
        }
    },

    rotate: {
        transform: 'rotate(90deg)'
    },
    fullArrow: {
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`
    },
    error: {
        color: theme.palette.error.main
    },
    fade: {
        color: fade(theme.palette.text.primary, 0.25)
    },
    labelContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        width: '100%',
        alignItems: 'center',
        '& button *': {
            pointerEvents: 'none'
        }
    }
}));

export interface Disallows {
    [index: string]: boolean;
}
