import { Box, IconButton } from '@material-ui/core';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Disallows, useStyles } from './common';
import { mdiCancel } from '@mdi/js';
import { useAppStateRef } from '../../../../../contexts/app.context';
import Icon from '@mdi/react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useGetCarriers } from '../../../../../hooks/stiapi.hook';

interface CarrierPermission {
    onDisallow?: (state: Disallows) => void;
    sectionColor?: string;
    disallowed: Disallows;
}

export const CarrierPermission: React.FC<CarrierPermission> = ({ onDisallow, sectionColor, disallowed }) => {
    const classes = useStyles();
    const appStateRef = useAppStateRef();
    const [state, setState] = useState<Disallows>(disallowed);

    const [carriers, setCarriers] = useState<Models.Carrier[]>([]);
    const { getCarriers } = useGetCarriers();

    useEffect(() => {
        setState(disallowed);
    }, [disallowed]);

    const handleDisallow: React.MouseEventHandler<HTMLButtonElement> = useCallback(
        (evt) => {
            setState((s) => {
                const n = (evt.target as any).name;
                const newState = { ...s };
                if (state[n]) {
                    delete newState[n];
                } else {
                    newState[n] = true;
                }
                onDisallow?.(newState);
                return newState;
            });
        },
        [state, onDisallow]
    );

    useEffect(() => {
        getCarriers().then((res) => setCarriers(res.data));
    }, [getCarriers, setCarriers]);

    return (
        <>
            {carriers.map((c) => {
                const id = `Carrier ${c.carrierID}`;
                const isDisallowed = state[id];
                return (
                    <CarrierPermissionComponent
                        sectionColor={sectionColor}
                        isDisallowed={isDisallowed}
                        classes={classes}
                        id={id}
                        item={c}
                        handleDisallow={handleDisallow}
                    />
                );
            })}
        </>
    );
};

interface CarrierPermissionComponent extends Omit<CarrierPermission, 'disallowed'> {
    isDisallowed: boolean;
    classes: { [index: string]: string };
    id: string;
    item: Models.Carrier;
    handleDisallow: React.MouseEventHandler<HTMLButtonElement>;
}

const CarrierPermissionComponent: React.FC<CarrierPermissionComponent> = memo(({ sectionColor, isDisallowed, classes, id, item, handleDisallow }) => {
    return (
        <Box bgcolor={sectionColor} paddingLeft={1}>
            <div
                className={classes.labelContainer}
                // onClick={onSelectionClick}
                // name={item!.uid}
            >
                <IconButton
                    size="small"
                    onClick={handleDisallow}
                    name={id}
                    // color={isDisallowed ? 'error' : 'inherit'}
                >
                    <Icon className={isDisallowed ? classes.error : classes.fade} path={mdiCancel} size={1} />
                </IconButton>
                <div className={classes.itemButton}>{`${item.carrierName} (${item.carrierSTICode})`}</div>
            </div>
        </Box>
    );
});
