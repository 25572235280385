import React, { useContext, useReducer, useRef, useEffect } from 'react';

import { IAction } from '../../../types/contexts/context.type';
import { loggedReducer } from '../../../contexts/store.helpers';
import { Models } from 'shipmenttrackers-domain/dist';
import { IIdx } from '../../../types/general.type';

const newGL = (): IGLCodingPlus => ({
    key: Date.now(),
    glAmount: 0,
    glPer: 0,
    toDelete: false
});

export enum AuditActions {
    invoice = 'invoice',
    invoiceAppIdx = 'invoiceAppIdx',
    attachPDF = 'attachPDF',
    setGL = 'setGL',
    availableCodes = 'availableCodes',
    addGL = 'addGL',
    addGLRemaining = 'addGLRemaining',
    deleteRow = 'deleteRow',
    billingEntities = 'billingEntities',
    actions = 'actions',
    addAction = 'addAction',
    clearDeletes = 'clearDeletes',
    addPDF = 'addPDF',
    setPDFCount = 'setPDFCount',
    collabOptions = 'collabOptions'
}

export type IGLCodingPlus = Partial<
    Models.GLCoding & {
        key?: number;
        glPer?: number;
        updated: boolean;
        toDelete: boolean;
        [index: string]: any;
    }
>;

export interface IBillingEntity {
    name: string;
    id: number;
}

export interface IAuditState extends IIdx {
    glcoding: IGLCodingPlus[];
    glsToDelete: IGLCodingPlus[];
    invoice?: IIdx<Models.CarrierInvoices>;
    invoiceAppIdx: number;
    attachPDF: boolean;
    fillLast: boolean;
    encoded: number;
    availableCodes: Models.AllowedGLCodings[];
    billingEntities: IBillingEntity[];
    actions: Models.Actions[];
    extraPDFs: number;
    collabOptions: Models.User[];
}

const initialState: IAuditState = {
    glcoding: [newGL()],
    glsToDelete: [],
    invoice: undefined,
    invoiceAppIdx: -1,
    attachPDF: true,
    fillLast: false,
    encoded: 0,
    availableCodes: [],
    billingEntities: [],
    actions: [],
    extraPDFs: 0,
    collabOptions: []
};

export enum GLAuditActions {
    gl1 = 'gl1',
    gl2 = 'gl2',
    gl3 = 'gl3',
    gl4 = 'gl4',
    gl5 = 'gl5',
    gl6 = 'gl6',
    gl7 = 'gl7',
    gl8 = 'gl8',
    gl9 = 'gl9',
    gl10 = 'gl10',
    glPer = 'glPer',
    glAmount = 'glAmount'
}

interface IAuditAction extends IAction {
    rowNum?: number;
}

const reducer = (state: IAuditState, action: IAuditAction) => {
    const newState = { ...state };

    if (action.rowNum === newState.glcoding.length - 1) {
        newState.fillLast = false;
    }

    switch (action.type) {
        case GLAuditActions.gl1:
        case GLAuditActions.gl2:
        case GLAuditActions.gl3:
        case GLAuditActions.gl4:
        case GLAuditActions.gl5:
        case GLAuditActions.gl6:
        case GLAuditActions.gl7:
        case GLAuditActions.gl8:
        case GLAuditActions.gl9:
        case GLAuditActions.gl10: {
            const old = newState.initialCodes && newState.initialCodes[action.rowNum!] ? newState.initialCodes[action.rowNum!][action.type] : null;
            newState.glcoding[action.rowNum!][action.type] = action.payload;
            newState.glcoding[action.rowNum!].updated = true;
            break;
        }
        case GLAuditActions.glAmount:
            newState.glcoding[action.rowNum!][action.type] = parseFloat(action.payload) || 0;
            if (!newState.invoice) break;
            newState.glcoding[action.rowNum!].glPer = parseFloat(((action.payload / newState.invoice.currentAmt) * 100).toFixed(2));
            newState.glcoding[action.rowNum!].updated = true;
            adjustTotal(newState);
            break;

        case GLAuditActions.glPer:
            newState.glcoding[action.rowNum!][action.type] = parseFloat(action.payload) || 0;
            if (!newState.invoice) break;
            newState.glcoding[action.rowNum!].glAmount = parseFloat((newState.invoice.currentAmt * (action.payload / 100)).toFixed(2));
            newState.glcoding[action.rowNum!].updated = true;
            adjustTotal(newState);
            break;

        case AuditActions.addGL:
            newState.glcoding = [...newState.glcoding, newGL()];
            break;

        case AuditActions.addGLRemaining: {
            if (!newState.invoice) break;
            const gl = newGL();
            const leftover = newState.invoice.currentAmt - newState.encoded;
            gl.glAmount = parseFloat(leftover.toFixed(2));
            gl.glPer = newState.invoice.currentAmt
                ? parseFloat(((leftover / newState.invoice.currentAmt) * 100).toFixed(2))
                : leftover === 0 && newState.invoice.currentAmt === 0
                ? 100
                : undefined;
            gl.updated = true;
            newState.glcoding = [...newState.glcoding, gl];
            adjustTotal(newState);
            break;
        }

        case AuditActions.deleteRow:
            if (action.rowNum === undefined) break;
            const toDel = newState.glcoding.splice(action.rowNum, 1);
            if (toDel[0].glCodeID !== undefined) {
                newState.glsToDelete.push(...toDel);
            }

            adjustTotal(newState);
            break;

        case AuditActions.clearDeletes:
            newState.glsToDelete = newState.glsToDelete.reduce((acc: Partial<Models.GLCoding>[], d, i) => {
                if (!action.payload[i]) {
                    acc.push(d);
                }
                return acc;
            }, []);
            break;
        case AuditActions.setGL: {
            if (!newState.invoice) break;
            const leftover = newState.invoice.currentAmt - newState.encoded;
            newState.glcoding = action.payload.map((gl: Models.GLCoding) => {
                const newGL: IGLCodingPlus = { ...gl, key: gl.glCodeID };
                if (newState.invoice && newGL.glAmount !== undefined) {
                    newGL.glPer = newState.invoice.currentAmt
                        ? parseFloat(((newGL.glAmount / newState.invoice.currentAmt) * 100).toFixed(2))
                        : leftover === 0 && newState.invoice.currentAmt === 0
                        ? 100
                        : undefined;
                }
                return newGL;
            });
            if (newState.glcoding.length === 0) {
                newState.glcoding = [newGL()];
            }
            adjustTotal(newState);
            break;
        }
        case AuditActions.addAction:
            if (Array.isArray(action.payload)) {
                newState.actions = [...newState.actions, ...action.payload];
            } else {
                newState.actions = [...newState.actions, action.payload];
            }

            break;

        case AuditActions.addPDF:
            newState.extraPDFs += 1;
            break;
        case AuditActions.setPDFCount:
            newState.extraPDFs = action.payload;
            break;
        case AuditActions.availableCodes:
        case AuditActions.billingEntities:
        case AuditActions.actions:
        case AuditActions.invoiceAppIdx:
        case AuditActions.collabOptions:
            newState[action.type] = action.payload;
            break;
        case AuditActions.invoice:
            const { actions, ...inv } = action.payload;
            newState.invoice = inv;
            newState.glcoding = newState.glcoding.map((g) => ({
                ...g,
                glPer:
                    g.glAmount !== undefined && newState.invoice?.currentAmt !== undefined && newState.invoice?.currentAmt > 0
                        ? parseFloat(((g.glAmount / newState.invoice.currentAmt) * 100).toFixed(2))
                        : 0
            }));
            if (actions) {
                newState.actions = [...newState.actions, ...actions];
            }

            break;
        case AuditActions.attachPDF:
            newState[action.type] = action.payload;
            break;

        default:
            break;
    }
    return newState;
};

const logReducer = loggedReducer<IAuditState, IAuditAction>(reducer, 'Audit');

export const AuditStateContext: any = React.createContext({});
export const AuditDispatchContext: any = React.createContext({});
export const AuditStateRefContext: any = React.createContext({});

export const useAuditState = (): IAuditState => useContext(AuditStateContext);
export const useAuditDispatch = (): ((action: IAuditAction) => IAuditState) => useContext(AuditDispatchContext);
export const useAuditStateRef = (): React.MutableRefObject<IAuditState> => useContext(AuditStateRefContext);

export const AuditProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(logReducer, initialState);
    const stateRef = useRef<IAuditState>(state);

    stateRef.current = state;

    return (
        <AuditStateContext.Provider value={state}>
            <AuditDispatchContext.Provider value={dispatch}>
                <AuditStateRefContext.Provider value={stateRef}>{children}</AuditStateRefContext.Provider>
            </AuditDispatchContext.Provider>
        </AuditStateContext.Provider>
    );
};

const countDec = (n: number) => {
    const split = n.toString().split('.');
    return split[1] ? split[1].length : 0;
};

const adjustTotal = (newState: IAuditState) => {
    let dec = Math.max(countDec(newState.invoice?.currentAmt || 0), 2);
    const total = newState.glcoding.reduce((acc, gl, i) => {
        acc += gl.glAmount || 0;
        dec = Math.max(dec, countDec(gl.glAmount || 0));
        return acc;
    }, 0);

    newState.encoded = total;

    return newState;
};
