import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { ISingleSelectAuto, SingleSelectAuto } from '../../../generic/controls/single-selectauto.component';
import { useAppState } from '../../../../contexts/app.context';
import { useManageDispatch, useManageState, ManageActions } from '../manage.context';
import { Models } from 'shipmenttrackers-domain/dist';
import { useCurrentUser, useUserState } from '../../../../contexts/user.context';

export const useManageStyles = makeStyles((theme) => ({
    input: {
        width: '8rem'
    },
    filterButton: {
        border: `1px solid ${theme.palette.common.white}`,
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8)
    }
}));

export const ClientSelect: React.FC<Partial<ISingleSelectAuto<Partial<Models.Client>>>> = (props) => {
    const appState = useAppState();
    const manageState = useManageState();
    const manageDispatch = useManageDispatch();

    const clientOptions = useMemo(() => {
        let all: Partial<Models.Client>[] = [{ clientName: 'All Clients', clientID: -1 }];
        if (appState.data.clients) {
            const clients = appState.data.clients.sort((a, b) => a.clientName.localeCompare(b.clientName));
            // .map((c) => ({ text: c.clientName, value: c.clientID }));
            all = all.concat(clients);
        }
        return all;
    }, [appState.data.clients]);

    const onChange = useCallback(
        (event, value) => {
            const payload = value && value.clientID !== -1 ? value : undefined;
            manageDispatch({ type: ManageActions.clientToManage, payload });
        },
        [manageDispatch]
    );

    return (
        <SingleSelectAuto
            {...props}
            disableNull={false}
            value={manageState.clientToManage || clientOptions[0]}
            onValChange={onChange}
            options={clientOptions}
            getText={(c) => c.clientName || ''}
            getValue={(c) => c.clientID}
        />
    );
};

interface UserSelectProps extends Partial<Omit<ISingleSelectAuto<Partial<Models.User>>, 'onChange'>> {
    useManagedUser?: boolean;
    showSelf?: boolean;
    onChange?: (event: React.FormEvent<HTMLDivElement> | React.ChangeEvent<{}>, value?: Partial<Models.User>) => void;
}

export const UserSelect: React.FC<UserSelectProps> = ({ useManagedUser = true, showSelf = false, onChange, ...props }) => {
    // const [userOptions, setUserOptions] = useState<Partial<Models.User>[]>([]);
    const [users, setUsers] = useState<Partial<Models.User>[]>([]);
    const manageState = useManageState();
    const user = manageState.userToManage;
    const manageDispatch = useManageDispatch();
    // const userState = useUserState();
    const [currentUser] = useCurrentUser();

    const onUserSelect = (event: React.FormEvent<HTMLDivElement> | React.ChangeEvent<{}>, value?: Partial<Models.User>) => {
        // const payload = value && value.clientID ? value : undefined;
        manageDispatch({ type: ManageActions.userToManage, payload: value });
    };

    useEffect(() => {
        const toUse = manageState.clientToManage ? manageState.clientUsers : manageState.allUsers;
        setUsers([{ username: 'No Selection' }, ...toUse.sort((a, b) => (a.username || '').localeCompare(b.username || ''))]);
    }, [manageState.allUsers, manageState.clientToManage, manageState.clientUsers, setUsers]);

    const options = useMemo(() => {
        const opts = manageState.clientToManage ? manageState.clientUsers : users;
        return showSelf ? opts : opts.filter((u) => u.userID !== currentUser?.userID);
    }, [manageState.clientToManage, manageState.clientUsers, showSelf, currentUser, users]);

    return (
        <SingleSelectAuto
            fullWidth
            value={(useManagedUser && user) || users[0]}
            {...props}
            onValChange={onChange ?? onUserSelect}
            options={options}
            getText={(u) => u.username || ''}
            getOptionSelected={(o) => (user?.userID ? user?.userID === o.userID : user?.email === o.email)}
        />
    );
};
