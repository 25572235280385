import { IIdx } from '../types/general.type';

export const tableHeaderMap: IIdx = {
    auditStatus: 'Audit Status',
    billingEntity: 'Billing Entity',
    carrierInvoiceID: 'Invoice ID',
    carrierID: 'Carrier ID',
    carrierAccountID: 'Carrier Acct ID',
    carrierAccount: 'Carrier Acct #',
    clientID: 'Client',
    companyID: 'Company',
    divisionID: 'Division',
    locationID: 'Location',
    invoiceNumber: 'Invoice #',
    invoiceDate: 'Date',
    invoiceDueDate: 'Due Date',
    shipperCompany: 'Shipper Company',
    shipperName: 'Shipper Name',
    shipperAddress1: 'Shipper Address 1',
    shipperAddress2: 'Shipper Address 2',
    shipperCity: 'Shipper City',
    shipperState: 'Shipper State',
    shipperZip: 'Shipper Zip',
    shipperCountry: 'Shipper Country',
    consigneeCompany: 'Consignee Company',
    consigneeName: 'Consignee Name',
    consigneeAddress1: 'Consignee Address 1',
    consigneeAddress2: 'Consignee Address 2',
    consigneeCity: 'Consignee City',
    consigneeState: 'Consignee State',
    consigneeZip: 'Consignee Zip',
    consigneeCountry: 'Consignee Country',
    invoiceOriginalAmt: 'Original Amt',
    currentAmt: 'Current Amt',
    invoiceAmtDue: 'Amt Due',
    invoiceStatus: 'Invoice Status',
    // invoiceAllocFundReqAmt: 'Alloc Fund Req Amt',
    // invoiceAllocFundReqDate: 'Alloc Fund Req Date',
    stiInvoiceNumber: 'STI Invoice #',
    invoiceTotalReceived: 'Total Received',
    invoiceToReceive: 'To Receive',
    invoiceLastReceivedDate: 'Last Received Date',
    invoiceTotalDisbursed: 'Total Disbursed',
    invoiceToDisburse: 'To Disburse',
    invoicePaidAmt: 'Paid Amt',
    invoiceLastPaidDate: 'Last Paid Date',
    payer: 'Payer',
    audited: 'Audited',
    dispute: 'Dispute',
    approve: 'Approve',
    // allocate: 'Allocate',
    receive: 'Receive',
    disburse: 'Disburse',
    filename: 'File Name',
    // notes: 'Notes',
    carrierCode: 'Carrier',
    pdf: 'PDF'
};
