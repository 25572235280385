import React from 'react';

import Plus from 'mdi-material-ui/Plus';
import { useAuditDispatch, AuditActions } from '../audit.context';
import { ExtendedIconButton, IExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';
import { STITooltip } from '../../../generic';

export const AddGl: React.FC<IExtendedIconButton> = (props) => {
    const auditDispatch = useAuditDispatch();
    const addRow = () => {
        auditDispatch({ type: AuditActions.addGLRemaining });
    };
    return (
        <ExtendedIconButton color="inherit" onClick={addRow} {...props}>
            <STITooltip title="New Row">
                <Plus />
            </STITooltip>
        </ExtendedIconButton>
    );
};
