import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useAppState } from '../../../../contexts/app.context';
import { ITransferTreeItem, IItemProp } from '../../../transfertree/transfertree.types';
import { styled, Box, makeStyles, useTheme, Typography, ButtonBase } from '@material-ui/core';
import { Scroller, GridGrow, STILoading } from '../../../generic';
import { useManageState } from '../manage.context';

import { useCreatePermissions, useGetUserPermissions } from '../../../../hooks/stiapi.hook';
import { PermissionTree } from './permissiontree/permissiontree.component';
import { PermissionTreeState } from './permissiontree/permissiontree.context';
import { CarrierPermission } from './permissiontree/carrierpermission.component';
import { Disallows } from './permissiontree/common';

interface IUserAccess {
    user?: Models.User;
    submitRef?: React.RefObject<HTMLButtonElement>;
}

enum PropGroup {
    admin = 'Admin',
    invoice = 'Invoice'
}

const useStyles = makeStyles((theme) => ({
    green: {
        color: theme.palette.secondary.main
    },
    red: {
        color: theme.palette.error.main
    },
    toggle: {
        backgroundColor: theme.palette.secondary.main
    }
}));

const MANAGER_ID = 'MANAGER_ID';

interface CurrentPermissionsMap {
    allowed?: Models.UserEntities;
    disallowed: { [index: string]: { [index: string]: Models.UserEntities } };
}
export const UserAccessControl: React.FC<IUserAccess> = ({ user, submitRef }) => {
    const theme = useTheme();
    const appState = useAppState();
    const manageState = useManageState();
    const [entityTree, setEntityTree] = useState<ITransferTreeItem[]>([]);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const treeState = useRef<PermissionTreeState>();
    const carrierState = useState<{ [index: string]: boolean }>({});

    const { createPermissions } = useCreatePermissions();
    const { getPermissions } = useGetUserPermissions();

    const [currentUserPermissions, setCurrentUserPermissions] = useState<Models.UserEntities[]>([]);
    const getBillingHierarchy = useGetBillingHierarchy();

    const { allowed, disallowed } = useMemo(() => {
        let all: Models.UserEntities | undefined = undefined;
        const dis: { [index: string]: boolean } = {};
        currentUserPermissions.forEach((c) => {
            if (c.isAllowed) {
                all = c;
            } else {
                dis[`${c.entityLevel} ${c.entityID}`] = true;
            }
        });
        return { allowed: all, disallowed: dis };
    }, [currentUserPermissions]);

    useEffect(() => {
        (async () => {
            if (!manageState.userToManage) return;
            setLoading(true);
            const res = await getPermissions(manageState.userToManage?.userID);
            if (res.status === 200) {
                setCurrentUserPermissions(res.data.permissions);
            } else {
                setLoading(false);
            }
        })();
    }, [getPermissions, manageState.userToManage, setCurrentUserPermissions, setLoading]);

    const permissionList = useCallback(
        (level: string, entityID: number): IItemProp[] => {
            const getPropGroup = (id: number) => {
                switch (id) {
                    case 2:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        return PropGroup.invoice;
                    case 3:
                    case 9:
                    case 10:
                        return PropGroup.admin;
                    default:
                        return undefined;
                }
            };

            const permissionMap = currentUserPermissions.reduce(
                (acc: CurrentPermissionsMap, p) => {
                    if (p.isAllowed) {
                        acc.allowed = p;
                    } else {
                        if (!acc.disallowed[p.entityLevel]) {
                            acc.disallowed[p.entityLevel] = {};
                        }
                        acc.disallowed[p.entityLevel][p.entityID] = p;
                    }
                    return acc;
                },
                { allowed: undefined, disallowed: {} }
            );
            const permission = (permissionMap.allowed?.entityLevel === level && permissionMap.allowed?.entityID === entityID
                ? permissionMap.allowed
                : permissionMap.disallowed[level]?.[entityID]) as { [index: string]: any } | undefined;

            const p: IItemProp[] = manageState.permissions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((p) => {
                    return {
                        checked: !!permission?.[permissionField[p.permissionID]],
                        text: p.name,
                        value: p.permissionID,
                        bubbles: { up: { onSelect: true, onDeselect: true }, down: { onSelect: true, onDeselect: true } },
                        uid: permissionField[p.permissionID],
                        propGroup: getPropGroup(p.permissionID)
                    };
                });
            p.push({
                text: 'Manager',
                checked: permission && !manageState.permissions.find((p) => !permission?.[permissionField[p.permissionID]]),
                value: MANAGER_ID,
                bubbles: { up: { onSelect: false, onDeselect: true }, down: { onSelect: true, onDeselect: true } },
                uid: MANAGER_ID,
                isMaster: true
            });
            return p;
        },
        [manageState.permissions, currentUserPermissions]
    );

    useEffect(() => {
        if (!permissionList) {
            setLoading(false);
            return;
        }
        const create = (): ITransferTreeItem[] => {
            let cl: number | undefined = undefined;
            let com: number | undefined = undefined;
            let div: number | undefined = undefined;
            let loc: number | undefined = undefined;

            const ald = currentUserPermissions.find((p) => p.isAllowed);

            if (ald) {
                const h = getBillingHierarchy(ald.entityLevel, ald.entityID);
                cl = h.Client;
                com = h.Company;
                div = h.Division;
                loc = h.Location;
            }

            return appState.data.clients
                .sort((a, b) => a.clientName.localeCompare(b.clientName))
                .map((c) => ({
                    text: c.clientName,
                    value: c.clientID,
                    uid: `Client ${c.clientID}`,
                    properties: permissionList('Client', c.clientID),
                    toggleIcon: <ToggleLetter>C</ToggleLetter>,
                    startOpen: cl === c.clientID,
                    children: c.companies
                        .sort((a, b) => a.companyName.localeCompare(b.companyName))
                        .map((co) => ({
                            text: co.companyName,
                            value: co.companyID,
                            uid: `Company ${co.companyID}`,
                            properties: permissionList('Company', co.companyID),
                            toggleIcon: <ToggleLetter>M</ToggleLetter>,
                            startOpen: com === co.companyID,
                            children: co.divisions
                                .sort((a, b) => a.divisionName.localeCompare(b.divisionName))
                                .map((d) => ({
                                    text: d.divisionName,
                                    value: d.divisionID,
                                    uid: `Division ${d.divisionID}`,
                                    properties: permissionList('Division', d.divisionID),
                                    toggleIcon: <ToggleLetter>D</ToggleLetter>,
                                    startOpen: div === d.divisionID,
                                    children: d.locations
                                        .sort((a, b) => a.locationName.localeCompare(b.locationName))
                                        .map((l) => ({
                                            text: l.locationName,
                                            value: l.locationID,
                                            uid: `Location ${l.locationID}`,
                                            properties: permissionList('Location', l.locationID),
                                            toggleIcon: <ToggleLetter>L</ToggleLetter>,
                                            startOpen: loc === l.locationID,
                                            children: l.accounts
                                                .sort((a, b) => (a.carrierAccountID + '').localeCompare(a.carrierAccountID + ''))
                                                .map((a) => {
                                                    return {
                                                        text: a.carrierAccount,
                                                        value: a,
                                                        uid: `Account ${a.carrierAccountID}`,
                                                        classes: {
                                                            label: a.ip ? classes.green : classes.red
                                                        }
                                                    };
                                                })
                                        }))
                                }))
                        }))
                }));
        };
        // }
        (async () => {
            const p: ITransferTreeItem[] = await new Promise((resolve) => {
                resolve(create());
            });
            setEntityTree(p);
            setLoading(false);
        })();
    }, [appState.data.clients, setEntityTree, permissionList, classes, currentUserPermissions, getBillingHierarchy]);

    const onStateChange = (state: PermissionTreeState) => {
        treeState.current = state;
    };

    const onCarrierChange = (disallows: Disallows) => {
        treeState.current && (treeState.current.disallowed = { ...disallows });
    };

    const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (!treeState.current) return;
        const s = treeState.current;
        const perm: Partial<Models.UserEntities>[] = [];
        if (s.allowed) {
            perm.push({ ...s.allowed, isAllowed: true });
        }
        Object.entries(s.disallowed).forEach(([key, dis]) => {
            if (dis) {
                const [level, id] = key.split(' ');
                perm.push({
                    entityID: (id as unknown) as number,
                    entityLevel: level as Models.EntityLevel,
                    isAllowed: false
                });
            }
        });

        const res = await createPermissions(manageState.userToManage?.userID || '', perm);
        if (res.status === 200) {
            // console.log(res.data);
        }
    };

    const currentPerm = useMemo(() => {
        let initialAllowed: string | undefined = undefined;
        const initialDisallowed: string[] = [];
        currentUserPermissions.forEach((p) => {
            if (p.isAllowed) {
                initialAllowed = `${p.entityLevel} ${p.entityID}`;
            } else {
                initialDisallowed.push(`${p.entityLevel} ${p.entityID}`);
            }
        });
        return { initialAllowed, initialDisallowed };
    }, [currentUserPermissions]);

    return (
        <Container onSubmit={onSubmit}>
            <STILoading loading={loading} />
            <GridGrow>
                <Box width="100%" height="100%" bgcolor={theme.palette.common.white} borderRadius={24} padding={2} paddingLeft={1}>
                    <Scroller>
                        <PermissionTree
                            items={entityTree}
                            allowed={allowed}
                            disallowed={disallowed}
                            // LeftComponent={TreeComponent}
                            // RightComponent={TreeComponent}
                            // ControlComponent={ControlComponent}
                            // hidePropsLeft
                            onStateChange={onStateChange}
                            sectionColor={theme.palette.common.white}
                            sectionColorAlt={'#F3F3F3'}
                            {...currentPerm}
                        />
                    </Scroller>
                </Box>
            </GridGrow>
            <GridGrow>
                <Box width="100%" height="100%" bgcolor={theme.palette.common.white} borderRadius={24} padding={2} paddingLeft={1}>
                    <Scroller>
                        <CarrierPermission sectionColor={theme.palette.common.white} disallowed={disallowed} onDisallow={onCarrierChange} />
                    </Scroller>
                </Box>
            </GridGrow>
            <Box display="none">
                <button ref={submitRef} type="submit" />
            </Box>
        </Container>
    );
};

const Container = styled('form')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    padding: theme.spacing(2),
    position: 'relative',
    '& > *': {
        height: '100%',
        '&:not(:first-child)': {
            marginLeft: theme.spacing(2)
        }
    }
}));

const permissionField: { [index: number]: string } = {
    2: 'approve',
    3: 'reassignDivision',
    4: 'dispute',
    5: 'pay',
    6: 'enterGlCodes',
    7: 'editGlCodes',
    8: 'viewGlCodes',
    9: 'createUser',
    10: 'manageUser'
};

const ToggleLetter: React.FC = ({ children }) => {
    const theme = useTheme();
    return (
        <Box borderRadius={999} bgcolor={theme.palette.secondary.main} overflow="hidden">
            <ButtonBase>
                <Box width={24} height={23} marginTop="1px" fontWeight="bold" color={theme.palette.common.white}>
                    <Typography color="inherit">{children}</Typography>
                </Box>
            </ButtonBase>
        </Box>
    );
};

export const useGetBillingHierarchy = () => {
    const appState = useAppState();
    const get = useCallback(
        (entityLevel?: string, entityID?: number) => {
            let cl: number | undefined = undefined;
            let com: number | undefined = undefined;
            let div: number | undefined = undefined;
            let loc: number | undefined = undefined;

            if (entityLevel !== undefined && entityID !== undefined) {
                cl = appState.data.clients.find((c) => {
                    if (entityLevel === 'Client') {
                        return c.clientID === entityID;
                    } else {
                        const company = c.companies.find((w) => {
                            if (entityLevel === 'Company') {
                                return w.companyID === entityID;
                            } else {
                                const division = w.divisions.find((d) => {
                                    if (entityLevel === 'Division') {
                                        return d.divisionID === entityID;
                                    } else {
                                        const location = d.locations.find((l) => entityLevel === 'Location' && l.locationID === entityID);
                                        loc = location?.locationID;
                                        return location?.divisionID === d.divisionID;
                                    }
                                });
                                div = division?.divisionID;
                                return division?.companyID === w.companyID;
                            }
                        });
                        com = company?.companyID;
                        return company?.clientID === c.clientID;
                    }
                })?.clientID;
            }
            return {
                Client: cl,
                Company: com,
                Division: div,
                Location: loc
            };
        },
        [appState.data.clients]
    );
    return get;
};
