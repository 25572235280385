import { format, parseJSON } from 'date-fns';

export const SIMPLE_FORMAT = 'MM/dd/yy';
export const URL_FORMAT = `yyyy-MM-dd'T'HH:mmXX`;
export const DATE_QUERY_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME = 'MMM d, yyyy h:mm';
export const date = (dateFormat: string) => (d?: Date): string => (d ? format(parseJSON(d), dateFormat) : '');

export type Denomination = 'CAD' | 'EUR' | 'USD';

export const currency = (n: number | string, denomination?: Denomination | string): string => {
    const num = typeof n === 'string' ? parseFloat(n.replace(/\$|,/g, '')) : n;
    const str = num !== undefined && num !== null && !isNaN(num) ? `${num.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}` : '';
    switch (denomination) {
        case 'CAD':
            return `$${str} CAD`;
        case 'EUR':
            return `€${str}`;
        case 'USD':
            return `$${str}`;
        default:
            return str;
    }
};

export const currencyParts = (n: number): string[] =>
    n
        .toFixed(2)
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
        .split('.');

export const splitIDs = (nums?: string): string[] => {
    if (nums === undefined) return [];
    const matches = nums.match(/[a-zA-Z0-9-]+/g);
    if (matches) {
        return Array.from(matches);
    } else {
        return [];
    }
};

export const prettyNum = (n: number | string): string => (typeof n === 'number' ? n.toString() : n).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
