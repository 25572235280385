import React, { memo, useMemo, useState } from 'react';
import { makeStyles, Grid, TextField, Typography } from '@material-ui/core';
import { GridMin, STIModal } from '../../generic';
import { Header, BodyContainer, ButtonContainer, ModalContainer } from './common.component';
import { Models } from 'shipmenttrackers-domain/dist';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { MultiSelectAuto } from '../../generic/controls/multi-selectauto.component';
import { useAuditState, useAuditDispatch, AuditActions } from '../audit/audit.context';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { useCreateActions, usePostMessagesBulk } from '../../../hooks/stiapi.hook';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import { useRef } from 'react';
import { UserListItem } from '../audit/userlistitem.component';

const useStyles = makeStyles((theme) => ({
    select: {
        flexBasis: 0,
        flexGrow: 1,
        minWidth: theme.spacing(18),
        '& .MuiInputBase-root': {
            padding: '6px 0 7px'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 1.5px) scale(0.75)'
        }
    },
    input: {
        flexBasis: 0,
        flexGrow: 3
    },
    charCount: {
        opacity: 0.67,
        marginLeft: theme.spacing(0.5),
        textAlign: 'right'
    },
    emailOff: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.error.main
    }
}));

interface ICollabModal {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const Component: React.FC<ICollabModal> = ({ open, setOpen }) => {
    const classes = useStyles();
    const auditState = useAuditState();
    const menuClasses = useMenuStyles();
    const [newNote, setNewNote] = useState<string>('');
    const [tags, setTags] = useState<Models.User[]>([]);
    const { postMessagesBulk, loading: messageLoading } = usePostMessagesBulk();
    const { createAction, loading: actionLoading } = useCreateActions();
    const auditDispatch = useAuditDispatch();
    const posRef = useRef<number>();

    const handleNewNote = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.preventDefault();
        if (auditState.invoice?.carrierInvoiceID === undefined || !newNote) return;

        if (tags.length) {
            const messageRes = await postMessagesBulk(
                auditState.invoice.carrierInvoiceID,
                tags.map((u) => ({ content: newNote, receiverUserID: u.userID }))
            );
            if (messageRes.status === 201) {
                setTags([]);
            }
        }

        const res = await createAction({ carrierInvoiceID: auditState.invoice.carrierInvoiceID, action: newNote });
        if (res.status === 201) {
            auditDispatch({ type: AuditActions.addAction, payload: res.data });
            setNewNote('');
        }
        setOpen(false);
    };

    const sortedOptions = useMemo(
        () =>
            auditState.collabOptions.sort(
                (a, b) => a.accountType - b.accountType || `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
            ),
        [auditState.collabOptions]
    );

    const updateNewNote = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setNewNote(evt.target.value);
        posRef.current = evt.target.selectionEnd !== null ? evt.target.selectionEnd : undefined;
    };

    const onTagChange = (
        event: React.ChangeEvent<{}> | React.FormEvent<HTMLDivElement>,
        value: Models.User[],
        reason?: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<Models.User>
    ) => {
        setTags(value);
        if (reason === 'select-option') {
            if (posRef.current === undefined) {
                posRef.current = newNote.length;
            }
            let start = newNote.slice(0, posRef.current).trim();
            let end = newNote.slice(posRef.current).trim();
            const sel = details?.option;
            const name = `@${sel?.firstName} ${sel?.lastName}`;
            setNewNote(`${start} ${name} ${end}`);
            posRef.current = start.length + 1 + name.length;
        } else if (reason === 'remove-option') {
            const sel = details?.option;
            const name = `@${sel?.firstName} ${sel?.lastName}`;
            const parts = newNote.split(name).map((p) => p.trim());
            if (posRef.current === undefined) {
                posRef.current = newNote.length;
            }
            if (posRef.current >= parts[0].length) {
                posRef.current = Math.max(posRef.current - name.length, parts[0].length);
            }
            setNewNote(parts.join(' '));
        }
    };

    const setCursorPos = (evt: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>) => {
        posRef.current = (evt.target as HTMLInputElement).selectionEnd !== null ? (evt.target as HTMLInputElement).selectionEnd! : undefined;
    };

    return (
        <STIModal open={open} onClose={() => setOpen(false)}>
            <ModalContainer>
                <GridMin>
                    <Header variant="subtitle1">Collaborate</Header>
                </GridMin>
                <BodyContainer container direction="column">
                    <Grid container spacing={1}>
                        <Grid item className={classes.select}>
                            <MultiSelectAuto<Models.User>
                                fullWidth
                                multiple
                                groupBy={groupBy}
                                menuClasses={menuClasses}
                                options={sortedOptions}
                                initialVal={tags}
                                getText={(u) => `${u.firstName} ${u.lastName}`}
                                getSubtext={(u) => u.email}
                                label="Select Users"
                                onValChange={onTagChange}
                                renderOption={(u, i) => <UserListItem user={u} />}
                                // getDotColor={() => theme.palette.secondary.main}
                                // getTag={() => <Icon path={mdiEmailOffOutline} size={0.5} color={theme.palette.error.main} className={classes.emailOff} />}
                            />
                        </Grid>
                        <Grid container item className={classes.input} direction="column" wrap="nowrap">
                            <TextField
                                inputProps={{ onMouseUp: setCursorPos }}
                                label="Add Note"
                                fullWidth
                                multiline
                                rowsMax={3}
                                value={newNote}
                                onChange={updateNewNote}
                            />
                            <Typography
                                className={classes.charCount}
                                color={newNote.length > 250 ? 'error' : 'textPrimary'}
                                variant="caption"
                            >{`${newNote.length}/250`}</Typography>
                        </Grid>
                    </Grid>

                    <ButtonContainer container wrap="nowrap" justify="center">
                        <ExtendedButton variant="contained" color="secondary" onClick={handleNewNote} loading={!!messageLoading || !!actionLoading}>
                            Confirm
                        </ExtendedButton>
                        <ExtendedButton variant="contained" color="error" onClick={() => setOpen(false)}>
                            Cancel
                        </ExtendedButton>
                    </ButtonContainer>
                </BodyContainer>
            </ModalContainer>
        </STIModal>
    );
};

export const CollabModal = memo(Component);

const groupBy = (u: Models.User) => (u.accountType === 1 ? 'STI' : 'More Users');
