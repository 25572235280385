import { Button, fade, makeStyles, useTheme } from '@material-ui/core';
import { mdiHistory } from '@mdi/js';
import React, { useState } from 'react';
import { ExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';
import Icon from '@mdi/react';

import { STIModal, GridMin } from '../../../generic';
import { ModalContainer, Header, BodyContainer, ButtonContainer } from '../../modals/common.component';
import { AdjustmentHistoryTable } from './adjustmenthistorytable.component';
import { killEvt } from '../../../../helpers';
import { Models } from 'shipmenttrackers-domain/dist';

const useStyles = makeStyles((theme) => ({
    body: {
        flexWrap: 'nowrap'
    }
}));

export interface AdjustmentHistoryProps {
    invoice?: Models.CarrierInvoices;
}

export const AdjustmentHistory: React.FC<AdjustmentHistoryProps> = ({ invoice }) => {
    const theme = useTheme();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const onClick: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
        killEvt(evt);
        setOpen((o) => !o);
    };

    const close = () => setOpen(false);

    return (
        <>
            <ExtendedIconButton
                type="submit"
                size="small"
                title="View Adjustment History"
                color={fade(theme.palette.text.primary, 0.75)}
                // loading={!!loading}
                overflow="visible"
                onClick={onClick}
            >
                <Icon path={mdiHistory} size={1} color="inherit" />
            </ExtendedIconButton>

            <STIModal open={open} onClose={close}>
                <ModalContainer>
                    <GridMin>
                        <Header variant="subtitle1">Adjustment History</Header>
                    </GridMin>
                    <BodyContainer container direction="column" className={classes.body}>
                        <AdjustmentHistoryTable invoice={invoice} />
                        <ButtonContainer container wrap="nowrap" justify="center">
                            <Button variant="contained" color="primary" onClick={close}>
                                Close
                            </Button>
                        </ButtonContainer>
                    </BodyContainer>
                </ModalContainer>
            </STIModal>
        </>
    );
};
