import React, { useState } from 'react';
import { Slide, Fab, makeStyles, useTheme, Box } from '@material-ui/core';
import Menu from 'mdi-material-ui/Menu';
import { STITooltip, TabPanel } from '../../../generic';

import { SortMenu } from './sort.component';
import Sort from 'mdi-material-ui/Sort';
import SwapHorizontal from 'mdi-material-ui/SwapHorizontal';
import { ColumnOrderMenu } from './columns.component';
import { DataTableInstanceRef } from '../../datatablevirtual.component';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1,
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        height: '100%',
        pointerEvents: 'none',
        '& > *': {
            pointerEvents: 'auto'
        },
        '& .handle': {
            cursor: 'move'
        }
    },
    button: {
        opacity: 1
    },
    hide: {
        opacity: 0
    }
}));

export const DataTableMenu: React.FC<{ instanceRef?: DataTableInstanceRef }> = ({ instanceRef }) => {
    const [open, setOpen] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const [tab, setTab] = useState<number>();

    if (instanceRef)
        instanceRef.current.setMenuOpen = (action: React.SetStateAction<boolean>, tab?: number) => {
            setOpen(action);
            setTab(tab);
        };

    return (
        <Box className={classes.root} display="flex" alignItems="flex-end">
            <Box
                paddingTop={2}
                paddingLeft={2}
                marginBottom={2}
                marginRight={2}
                onMouseEnter={() => setShowButton(true)}
                onMouseLeave={() => setShowButton(false)}
                height="fit-content"
            >
                <Fab size="medium" color="primary" className={`${classes.button} ${showButton || open ? '' : classes.hide}`} onClick={() => setOpen(!open)}>
                    <Menu />
                </Fab>
            </Box>
            <Slide direction="left" in={open} mountOnEnter unmountOnExit>
                <Box height="100%" padding={2}>
                    <Box bgcolor={theme.palette.grey.A100} height="100%" borderRadius={theme.spacing(1)} padding={1}>
                        <TabPanel
                            defaultTab={tab}
                            labels={[
                                <STITooltip title="Invoice Sorting">
                                    <Sort />
                                </STITooltip>,
                                <STITooltip title="Reorder Columns">
                                    <SwapHorizontal />
                                </STITooltip>
                            ]}
                        >
                            <SortMenu />
                            <ColumnOrderMenu />
                        </TabPanel>
                    </Box>
                </Box>
            </Slide>
        </Box>
    );
};
