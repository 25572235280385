import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useAppState } from '../../../contexts/app.context';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, .75)',
        zIndex: 10000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(4)
    }
}));

export const LoadingSplash: React.FC = () => {
    const classes = useStyles();
    const appState = useAppState();
    const initializing = !!appState.initializing.size;
    // console.log(appState.initializing);
    return initializing ? (
        <div className={classes.root}>
            <div className={classes.text}>Initializing Environment</div>
            <div>{`${appState.initializing.size} Item${appState.initializing.size > 1 ? 's' : ''} Remaining`}</div>
        </div>
    ) : null;
};
