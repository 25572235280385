import React from 'react';
import { styled, Grid, GridProps } from '@material-ui/core';

export const PageContainer: React.ComponentType<GridProps> = styled(({ children, ...props }) => (
    <Grid container direction="column" {...props}>
        {children}
    </Grid>
))({
    width: '100%',
    height: '100%'
});

export const GridMin: React.ComponentType<GridProps> = styled(({ children, ...props }) => (
    <Grid item {...props}>
        {children}
    </Grid>
))({
    flexBasis: 0,
    flexGrow: 0,
    width: '100%'
});

export const GridGrow: React.ComponentType<GridProps & { growWeight?: number; minHeightFit?: boolean; height100?: boolean }> = styled(
    ({ children, growWeight, height100, ...props }) => (
        <Grid item {...props}>
            {children}
        </Grid>
    )
)(({ theme, growWeight = 1, minHeightFit = false, height100 = false }) => ({
    minHeight: minHeightFit ? 'fit-content' : '0px',
    height: height100 ? '100%' : 'auto',
    flexBasis: 0,
    flexGrow: growWeight,
    width: '100%'
}));
