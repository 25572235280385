import React, { useMemo } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { UnderlineTypography, Scroller } from '../../generic';
import { useCurrentUser, useUserState } from '../../../contexts/user.context';
import { useEntityNames } from '../../../contexts/app.context';
import { IIdx } from '../../../types/general.type';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(0.75)
    },
    fade: {
        opacity: 0.4
    },
    permissionGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, auto)'
    }
}));

export const UserPermissions: React.FC = React.memo(() => {
    const userState = useUserState();
    const classes = useStyles();
    const getEntityName = useEntityNames();
    const [user] = useCurrentUser();

    return (
        <Scroller vertical className={classes.root}>
            <Grid container direction="column">
                <Grid item>
                    <UnderlineTypography variant="subtitle1">My Permissions</UnderlineTypography>
                </Grid>
                {user?.accountType === 1 ? (
                    <Grid item>
                        <Typography variant="overline">STI Employee: All Access</Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <UnderlineTypography variant="overline">{`Granted ${userState.permissions.allowed?.entityLevel}:`}</UnderlineTypography>
                            <Typography>
                                {userState.permissions.allowed
                                    ? getEntityName(userState.permissions.allowed?.entityLevel, userState.permissions.allowed?.entityID)
                                    : 'None'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <UnderlineTypography variant="overline">Granted Permissions:</UnderlineTypography>
                            <div className={classes.permissionGrid}>
                                {Object.keys(userState.permissions.allowed ?? []).map((k) =>
                                    permissionField[k] ? (
                                        <Typography
                                            key={permissionField[k]}
                                            variant="body2"
                                            component="span"
                                            className={!(userState.permissions.allowed as IIdx)?.[k] ? classes.fade : ''}
                                        >
                                            {permissionField[k]}{' '}
                                        </Typography>
                                    ) : null
                                )}
                            </div>
                        </Grid>
                        {['Client', 'Company', 'Division', 'Location', 'Account', 'Carrier'].map((l) => {
                            const d = userState.permissions.disallowed[l];
                            if (d) {
                                const v = Object.values(d);
                                return (
                                    <Grid item>
                                        <UnderlineTypography variant="overline">{`Disallowed ${l}${v.length > 1 ? 's' : ''}:`}</UnderlineTypography>
                                        <div className={classes.permissionGrid}>
                                            {v
                                                .map((d) => d.name ?? d.model.accountCompanyName)
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((n) => (
                                                    <Typography variant="body2" component="span">
                                                        {n}
                                                    </Typography>
                                                ))}
                                        </div>
                                    </Grid>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </>
                )}
            </Grid>
        </Scroller>
    );
});

const permissionField: { [index: string]: string } = {
    approve: 'Approve',
    reassignDivision: 'Reassign Divisions',
    dispute: 'Dispute',
    pay: 'Pay',
    enterGlCodes: 'Enter GL Codes',
    editGlCodes: 'Edit GL Codes',
    viewGlCodes: 'View GL Codes',
    createUser: 'Create User',
    manageUser: 'Manage User'
};
