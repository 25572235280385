import React, { useEffect } from 'react';
import ContentSaveOutline from 'mdi-material-ui/ContentSaveOutline';
import { ExtendedIconButton, IExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';

import { useSaveGLChanges } from './savegls.hook';
import { STITooltip } from '../../../generic';
import { useInfoModal } from '../../../../hooks/info-modal.hook';

export const SaveGL: React.FC<IExtendedIconButton> = (props) => {
    const [onSave, saving, error, clearError] = useSaveGLChanges();
    const [ConfirmationBox, runConfirmation] = useInfoModal();
    // const theme = useTheme();
    useEffect(() => {
        error &&
            runConfirmation({
                title: 'Coding Error',
                desc: error,
                yesTxt: 'OK',
                no: false,
                cancel: false
            }).then(clearError);
    }, [error, runConfirmation, clearError]);
    return (
        <>
            <ConfirmationBox />
            <ExtendedIconButton color="inherit" onClick={onSave} loading={saving} {...props}>
                <STITooltip title="Save Changes">
                    <ContentSaveOutline />
                </STITooltip>
            </ExtendedIconButton>
        </>
    );
};
