import { makeStyles, darken } from '@material-ui/core';
import { IColWidth } from '../../datatable/datatable.types';

export const useTableClasses = makeStyles((theme) => ({
    headerRow: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    headerCell: {
        '&.MuiTableCell-root': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderTop: `1px solid ${darken(theme.palette.primary.dark, 0.2)}`,
            borderBottom: `1px solid ${darken(theme.palette.primary.dark, 0.2)}`,
            borderLeft: `.5px solid ${darken(theme.palette.primary.dark, 0.2)}`,
            borderRight: `.5px solid ${darken(theme.palette.primary.dark, 0.2)}`
        }
    },
    bodyRow: {},
    bodyRowEven: {
        '& div': {
            backgroundColor: theme.palette.common.white
        }
    },
    bodyRowOdd: {
        '& div': {
            backgroundColor: darken(theme.palette.common.white, 0.05)
        }
    },
    overrides: {
        '& .invoiceLink': {
            height: '100%',

            '& .buttonText': {
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                '& *': {
                    pointerEvents: 'none'
                },
                '& > div': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    justifyContent: 'left',
                    color: theme.palette.primary.dark
                }
            }
        }
    },
    justifyRight: {
        '& *': {
            width: '100%',
            paddingRight: theme.spacing(2),
            textAlign: 'right'
        }
    }
}));

export const getDefaultColumnWidths = (): { [index: string]: IColWidth } => ({
    carrierCode: { initialWidth: 115, max: 115 },
    divisionID: { initialWidth: 72, initialExpand: true },
    carrierAccount: { initialWidth: 164, initialExpand: true },
    payer: { initialWidth: 80, initialExpand: true },
    invoiceNumber: { initialWidth: 102, initialExpand: true },
    pdf: { initialWidth: 60, max: 60 },
    currentAmt: { initialWidth: 112, initialExpand: true },
    invoiceOriginalAmt: { initialWidth: 112, initialExpand: true },
    invoiceAmtDue: { initialWidth: 90, initialExpand: true },
    adjustedAmt: { initialWidth: 90, initialExpand: true },
    invoiceDate: { initialWidth: 90 },
    invoiceDueDate: { initialWidth: 90 },
    dispute: { initialWidth: 90 },
    approve: { initialWidth: 90 },
    // allocate: { initialWidth: 90 },
    receive: { initialWidth: 90 },
    disburse: { initialWidth: 90 },
    invoiceStatus: { initialWidth: 136, initialExpand: true },
    invoicePaidAmt: { initialWidth: 102, initialExpand: true },
    auditStatus: { initialWidth: 136, initialExpand: true },
    billingEntity: { initialWidth: 120, initialExpand: true },
    invoiceTotalReceived: { initialWidth: 90, initialExpand: true },
    invoiceToReceive: { initialWidth: 136, initialExpand: true },
    invoiceTotalDisbursed: { initialWidth: 90, initialExpand: true },
    invoiceToDisburse: { initialWidth: 136, initialExpand: true }
});

// export const initialOrderItems = Object.keys(tableHeaderMap).reduce((acc: { [index: string]: ColOrderItem }, k) => {
//     acc[k] = { key: k, label: tableHeaderMap[k], active: false };
//     return acc;
// }, {});
