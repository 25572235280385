import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

interface IScroller extends BoxProps {
    vertical: boolean;
    horizontal: boolean;
}

export const Scroller: React.FC<Partial<IScroller>> = ({ children, vertical, horizontal, ...props }) => (
    <Box height="100%" overflow="hidden" {...props}>
        <Box height="100%" overflow="auto">
            {children}
        </Box>
    </Box>
);
