import axios, { AxiosError, AxiosResponse, Canceler } from 'axios';
import { IAxiosRetryConfig, isNetworkError, isNetworkOrIdempotentRequestError } from 'axios-retry';
import { useApi, IApiHelpers } from './api.hook';
import { Models } from 'shipmenttrackers-domain';
import { useCallback, useEffect } from 'react';
import { IIdx } from '../types/general.type';
import { date, DATE_QUERY_FORMAT } from '../helpers/pipes';
import { useCheckTokenValid, TokenValidation, useUserState } from '../contexts/user.context';

const retryDefaults: IAxiosRetryConfig = {
    retryCondition: (err: AxiosError<any>) => {
        return isNetworkError(err) || err.code === 'ECONNABORTED';
    }
};

const useAxiosConfig = (name?: string) => {
    const userState = useUserState();
    const { getAxios, canceler, ...api } = useApi();
    const [checkTokenValid, handleLogCheck] = useCheckTokenValid();

    useEffect(
        () => () => {
            canceler(name + 'canceled');
        },
        [canceler, name]
    );

    const createConnection = useCallback(
        (validate: boolean = true, retryTimeout?: number, ignoreAlias?: boolean) => {
            const retryConfig: IAxiosRetryConfig = { ...retryDefaults };

            const token = ignoreAlias ? userState.token : userState.alias?.token ?? userState.token;
            // if (!forceBaseUser && userRef.current.alias?.token) token = userRef.current.alias.token;

            if (retryTimeout) {
                retryConfig.retryDelay = (count, error) => {
                    return (retryTimeout * 3 ** count) / 2 ** count;
                };
                retryConfig.shouldResetTimeout = true;
            }
            const a = getAxios(
                {
                    baseURL: process.env.REACT_APP_APIS,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                },
                retryConfig
            );
            a.interceptors.request.use((config) => {
                if (validate) {
                    const valid = checkTokenValid();
                    if (valid !== TokenValidation.valid) {
                        if (canceler) {
                            canceler('Invalid Token');
                        } else {
                            config.cancelToken = new axios.CancelToken((c: Canceler) => c('Invlid Token'));
                        }
                        handleLogCheck(valid);
                    }
                }
                return config;
            });
            return a;
        },
        [userState.token, userState.alias, getAxios, checkTokenValid, canceler, handleLogCheck]
    );

    return { createConnection, canceler, ...api };
};

export const useLogin = (): IApiHelpers & {
    login: (
        username: string,
        password: string
    ) => Promise<
        AxiosResponse<{
            Status: number;
            Data: { token: string };
            Message?: string;
        }>
    >;
} => {
    const { createConnection, ...other } = useAxiosConfig('login');

    const login = useCallback(
        async (username: string, password: string) =>
            await createConnection(false).post('/login', {
                username,
                password
            }),
        [createConnection]
    );
    return { login, ...other };
};

export const useLoginAs = (): IApiHelpers & {
    loginAs: (
        userID: string
    ) => Promise<
        AxiosResponse<{
            Status: number;
            Data: { token: string };
        }>
    >;
} => {
    const { createConnection, ...other } = useAxiosConfig('login');

    const loginAs = useCallback(
        async (userID: string) =>
            await createConnection(false).post('/login/as', {
                id: userID
            }),
        [createConnection]
    );
    return { loginAs, ...other };
};

export const useForgotPassword = (): IApiHelpers & { forgotPassword: (email: string) => Promise<AxiosResponse> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('forgot password');
    const forgotPassword = useCallback(
        async (email: string) => await createConnection(false).post(`/login/forgotpassword`, { email }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { forgotPassword, getCancelToken, ...other };
};

export const useResetForgottenPassword = (): IApiHelpers & {
    resetForgottenPassword: (code: string, email: string, newPassword: string) => Promise<AxiosResponse>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('reset forgot password');
    const resetForgottenPassword = useCallback(
        async (code: string, email: string, newPassword: string) =>
            await createConnection(false).post(`/login/confirm/forgot`, { code, email, newPassword }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { resetForgottenPassword, getCancelToken, ...other };
};

export const useResetPassword = (): IApiHelpers & { resetPassword: (email: string, oldPassword: string, newPassword: string) => Promise<AxiosResponse> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('reset password');
    const resetPassword = useCallback(
        async (email: string, oldPassword: string, newPassword: string) =>
            await createConnection(false).post(`/login/changepassword`, { oldPassword, email, newPassword }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { resetPassword, getCancelToken, ...other };
};

export const useCreatePassword = (): IApiHelpers & {
    createPassword: (email: string, password: string) => Promise<AxiosResponse<ApiExtras<{ User: Models.User }>>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('create password');
    const createPassword = useCallback(
        async (token: string, password: string) =>
            await createConnection(false).post(`/login/createpassword`, { password, token }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { createPassword, getCancelToken, ...other };
};

export const useResendWelcomeEmail = (): IApiHelpers & { resendWelcomeEmail: (userID: string) => Promise<AxiosResponse> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('resend welcome');
    const resendWelcomeEmail = useCallback(
        async (userID: string) => await createConnection(false).post(`/user/resendwelcome`, { userID }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { resendWelcomeEmail, getCancelToken, ...other };
};

type ApiExtras<T> = { Code: string; Status: string } & T;

export const useGetInvoices = (): IApiHelpers & {
    getInvoices: (
        type: Models.CompanyInvoiceType,
        invoiceOptions: Partial<Models.CarrierInvoices>,
        invoiceNumbers: Partial<Models.InvoiceNumberSearchBody>
    ) => Promise<AxiosResponse<Models.InvoiceSearchResponse>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoices');
    const getInvoices = useCallback(
        async (type: Models.CompanyInvoiceType, invoiceOptions: Partial<Models.CarrierInvoices>, invoiceNumbers: Partial<Models.InvoiceNumberSearchBody>) => {
            const query = createInvoiceQuery(type, invoiceOptions);
            return await createConnection().post(`/companyinvoice?${query}`, invoiceNumbers, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { getInvoices, getCancelToken, ...other };
};

export const useGetInvoiceSummary = (): IApiHelpers & {
    getInvoiceSummary: () => Promise<AxiosResponse<Models.InvoiceSummary>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice summary');
    const getInvoiceSummary = useCallback(async () => {
        return await createConnection().get(`/companyinvoice/summary`, { cancelToken: getCancelToken() });
    }, [createConnection, getCancelToken]);
    return { getInvoiceSummary, getCancelToken, ...other };
};

export const useGetInvoice = (): IApiHelpers & { getInvoice: (carrierInvoiceID: string | number) => Promise<AxiosResponse<Models.CarrierInvoices>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice');
    const getInvoice = useCallback(
        async (carrierInvoiceID: string | number) =>
            await createConnection().get(`/invoices?carrierInvoiceID=${carrierInvoiceID}`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getInvoice, getCancelToken, ...other };
};

export const useGetInvoiceStatusOptions = (): IApiHelpers & { getInvoiceStatusOptions: () => Promise<AxiosResponse<Models.IpInvoiceStatus[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice statuses');
    const getInvoiceStatusOptions = useCallback(async () => await createConnection().get('/invoices/invoice-status', { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getInvoiceStatusOptions, getCancelToken, ...other };
};

export const useGetAuditStatusOptions = (): IApiHelpers & { getAuditStatusOptions: () => Promise<AxiosResponse<Models.IpAuditStatus[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('Audit statuses');
    const getAuditStatusOptions = useCallback(async () => await createConnection().get('/invoices/audit-status', { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getAuditStatusOptions, getCancelToken, ...other };
};

export type FailureType = 'failedGl' | 'failedStatus' | 'failedApprovalLimit' | 'userNonExistant';
export type FailureObject<T extends string = string> = Record<string, Partial<Record<T, true | undefined>>>;

export const useUpdateInvoices = (): IApiHelpers & {
    updateInvoices: (
        invoices: Partial<Models.CarrierInvoices>[]
    ) => Promise<AxiosResponse<{ invoices: Models.CarrierInvoices[]; disburseNumber?: string; failed: FailureObject }>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('update invoice');
    const updateInvoices = useCallback(
        async (invoices: Partial<Models.CarrierInvoices>[]) => await createConnection().put(`/invoices/bulk`, invoices, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { updateInvoices, getCancelToken, ...other };
};

export const useGetCompanies = (): IApiHelpers & { getCompanies: () => Promise<AxiosResponse<Models.Company[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('companies');
    const getCompanies = useCallback(async () => await createConnection().get(`/company`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getCompanies, getCancelToken, ...other };
};

export const useGetClients = (): IApiHelpers & { getClients: () => Promise<AxiosResponse<Models.ClientInfo[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('clients all');
    const getClients = useCallback(async () => await createConnection().get(`/client/list/ip`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getClients, getCancelToken, ...other };
};

export const useGetClientsAll = (): IApiHelpers & { getClientsAll: () => Promise<AxiosResponse<Models.Client[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('clients');
    const getClientsAll = useCallback(async () => await createConnection().get(`/client`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getClientsAll, getCancelToken, ...other };
};

export const useGetPermissions = (): IApiHelpers & { getPermissions: () => Promise<AxiosResponse<Models.Permissions[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('permissions');
    const getPermissions: any = useCallback(
        async () =>
            await {
                status: 200,
                data: [
                    {
                        permissionID: 2,
                        name: 'Approve'
                    },
                    {
                        permissionID: 3,
                        name: 'Reassign Divisions'
                    },
                    {
                        permissionID: 4,
                        name: 'Dispute'
                    },
                    {
                        permissionID: 5,
                        name: 'Pay'
                    },
                    {
                        permissionID: 6,
                        name: 'Enter GL Codes'
                    },
                    {
                        permissionID: 7,
                        name: 'Edit GL Codes'
                    },
                    {
                        permissionID: 8,
                        name: 'View GL Codes'
                    },
                    {
                        permissionID: 9,
                        name: 'Create User'
                    },
                    {
                        permissionID: 10,
                        name: 'Manage User'
                    }
                ]
            },
        []
    );
    return { getPermissions, getCancelToken, ...other };
};

export const useCreateUser = (): IApiHelpers & { createUser: (user: Partial<Models.User>) => Promise<AxiosResponse<{ User: Models.User }>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('user');
    const createUser = useCallback(async (user: Partial<Models.User>) => await createConnection().post(`/user`, user, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { createUser, getCancelToken, ...other };
};

export const useUpdateUser = (): IApiHelpers & { updateUser: (user: Partial<Models.User>) => Promise<AxiosResponse<ApiExtras<{ User: Models.User }>>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('update user');
    const updateUser = useCallback(async (user: Partial<Models.User>) => await createConnection().put(`/user`, user, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { updateUser, getCancelToken, ...other };
};

export const useGetUsers = (): IApiHelpers & { getUsers: (ignoreAlias?: boolean) => Promise<AxiosResponse<Models.User[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('users');
    const getUsers = useCallback(
        async (ignoreAlias?: boolean) => await createConnection(true, undefined, ignoreAlias).get(`/user`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getUsers, getCancelToken, ...other };
};

export const useGetUser = (): IApiHelpers & { getUser: (userID: string) => Promise<AxiosResponse<Models.User>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('users');
    const getUser = useCallback(async (userID: string) => await createConnection().get(`/${userID}`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getUser, getCancelToken, ...other };
};

export const useGetGLCodings = (): IApiHelpers & {
    getGLCoding: (carrierInvoiceID: string | number, carrierAccount: string) => Promise<AxiosResponse<Models.GLCoding[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('gl');
    const getGLCoding = useCallback(
        async (carrierInvoiceID: string | number, carrierAccount: string) =>
            await createConnection().get(`/codings/${carrierInvoiceID}`, { cancelToken: getCancelToken(), headers: { carrierAccount } }),
        [createConnection, getCancelToken]
    );
    return { getGLCoding, getCancelToken, ...other };
};

export const useGetGLCodingsByIds = (): IApiHelpers & {
    getGLCodingsByIds: (carrierInvoiceIDs: string[]) => Promise<AxiosResponse<Record<string, Models.GLCoding[]>>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('glsById');
    const getGLCodingsByIds = useCallback(
        async (carrierInvoiceIDs: string[]) => await createConnection().post(`/codings/byIds`, { carrierInvoiceIDs }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getGLCodingsByIds, getCancelToken, ...other };
};

export const useGetGLCodingsByFilter = (): IApiHelpers & {
    getGLCodingsByFilter: (
        type: Models.CompanyInvoiceType,
        invoiceOptions: Partial<Models.CarrierInvoices>,
        invoiceNumbers: Partial<Models.InvoiceNumberSearchBody>
    ) => Promise<AxiosResponse<Record<string, Models.GLCoding[]>>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoices');
    const getGLCodingsByFilter = useCallback(
        async (type: Models.CompanyInvoiceType, invoiceOptions: Partial<Models.CarrierInvoices>, invoiceNumbers: Partial<Models.InvoiceNumberSearchBody>) => {
            const query = createInvoiceQuery(type, invoiceOptions);
            return await createConnection().post(`/codings/byFilter?${query}`, invoiceNumbers, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { getGLCodingsByFilter, getCancelToken, ...other };
};

export const useGetAvilableGLCodings = (): IApiHelpers & {
    getAvailableGLCoding: (id: string) => Promise<AxiosResponse<Models.AllowedGLCodings[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('availabelGl');
    const getAvailableGLCoding = useCallback(
        async (id: string) => {
            return await createConnection().get(
                `availablecodes/${id}`,

                { cancelToken: getCancelToken() }
            );
        },
        [createConnection, getCancelToken]
    );
    return { getAvailableGLCoding, getCancelToken, ...other };
};

export const useGetGLHistory = (): IApiHelpers & {
    getGLHistory: (carrierInvoiceID: string) => Promise<AxiosResponse<Models.GLCodingHistory[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('glhistory');
    const getGLHistory = useCallback(
        async (carrierInvoiceID: string) => await createConnection().get(`/codings/history/${carrierInvoiceID}`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getGLHistory, getCancelToken, ...other };
};

export interface ICreateCodingResponse {
    Code: string;
    Status: string;
    codings: Models.GLCoding[];
    actions: Models.Actions[];
}
export const useCreateGLCodings = (): IApiHelpers & {
    createGLCodings: (codes: Partial<Models.GLCoding>[], carrierAccount: string) => Promise<AxiosResponse<ICreateCodingResponse>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('createGl');
    const createGLCodings = useCallback(
        async (codes: Partial<Models.GLCoding>[], carrierAccount: string) => {
            const create: Partial<Models.GLCoding>[] = [];
            const update: Partial<Models.GLCoding>[] = [];
            codes.forEach((c) => {
                if (c.glCodeID !== null && c.glCodeID !== undefined) {
                    update.push(c);
                } else {
                    create.push(c);
                }
            });
            const body: { [index: string]: Partial<Models.GLCoding>[] } = {};
            if (create.length) body['createCodes'] = create;
            if (update.length) body['updateCodes'] = update;
            return await createConnection().post(`/codings`, body, { cancelToken: getCancelToken(), headers: { carrierAccount } });
        },
        [createConnection, getCancelToken]
    );
    return { createGLCodings, getCancelToken, ...other };
};

export const useDeleteGLCoding = (): IApiHelpers & { deleteGLCoding: (id: number, carrierAccount: string) => Promise<AxiosResponse<Models.GLCoding>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('delete gl');
    const deleteGLCoding = useCallback(
        async (id: number, carrierAccount: string) =>
            await createConnection().delete(`/codings/${id}`, { cancelToken: getCancelToken(), headers: { carrierAccount } }),
        [createConnection, getCancelToken]
    );
    return { deleteGLCoding, getCancelToken, ...other };
};

export const useGetClient = (): IApiHelpers & { getClient: (clientID: number) => Promise<AxiosResponse<Models.Client[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('client Companies');
    const getClient = useCallback(async (clientID: number) => await createConnection().get(`/client/${clientID}`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getClient, getCancelToken, ...other };
};

export const useGetClientCompanies = (): IApiHelpers & { getClientCompanies: (clientID: number) => Promise<AxiosResponse<Models.Company[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('client Companies');
    const getClientCompanies = useCallback(
        async (clientID: number) => await createConnection().get(`/client/${clientID}/companies`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getClientCompanies, getCancelToken, ...other };
};

export const useGetClientDivisions = (): IApiHelpers & { getClientDivisions: (clientID: number) => Promise<AxiosResponse<Models.Division[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('client Divisions');
    const getClientDivisions = useCallback(
        async (clientID: number) => await createConnection().get(`/client/${clientID}/divisions`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getClientDivisions, getCancelToken, ...other };
};

export const useGetInvoiceSum = (): IApiHelpers & {
    getInvoiceSum: (
        type: Models.CompanyInvoiceType,
        invoiceOptions?: Partial<Models.CarrierInvoices>,
        invoiceNumbers?: Partial<Models.InvoiceNumberSearchBody>
    ) => Promise<AxiosResponse<{ sum: number }>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice sum');
    const getInvoiceSum = useCallback(
        async (
            type: Models.CompanyInvoiceType,
            invoiceOptions: Partial<Models.CarrierInvoices> = {},
            invoiceNumbers: Partial<Models.InvoiceNumberSearchBody> = {}
        ) => {
            const query = createInvoiceQuery(type, invoiceOptions);
            return await createConnection().post(`/companyinvoice/total/sum?${query}`, invoiceNumbers, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { getInvoiceSum, getCancelToken, ...other };
};

export const useGetInvoiceCount = (): IApiHelpers & {
    getInvoiceCount: (
        type: Models.CompanyInvoiceType,
        invoiceOptions?: Partial<Models.CarrierInvoices>,
        invoiceNumbers?: Partial<Models.InvoiceNumberSearchBody>
    ) => Promise<AxiosResponse<{ count: number }>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice count');
    const getInvoiceCount = useCallback(
        async (
            type: Models.CompanyInvoiceType,
            invoiceOptions: Partial<Models.CarrierInvoices> = {},
            invoiceNumbers: Partial<Models.InvoiceNumberSearchBody> = {}
        ) => {
            const query = createInvoiceQuery(type, invoiceOptions);
            return await createConnection().post(`/companyinvoice/count?${query}`, invoiceNumbers, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { getInvoiceCount, getCancelToken, ...other };
};

export const useGetActions = (): IApiHelpers & {
    getActions: (carrierInvoiceID: string) => Promise<AxiosResponse<Models.Actions>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('actions');
    const getActions = useCallback(
        async (carrierInvoiceID: string) => {
            return await createConnection().get(`/action/${carrierInvoiceID}`, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { getActions, getCancelToken, ...other };
};

export const useCreateActions = (): IApiHelpers & {
    createAction: (action: Partial<Models.Actions>) => Promise<AxiosResponse<Models.Actions>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('create action');
    const createAction = useCallback(
        async (action: Partial<Models.Actions>) => {
            return await createConnection().post('/action/create', action, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { createAction, getCancelToken, ...other };
};

export const useGetPDF = (): IApiHelpers & { getPDF: (carrierCode: string, invoiceNumber: string) => Promise<AxiosResponse> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get pdf');
    const getPDF = useCallback(
        async (carrierCode: string, invoiceNumber: string) => {
            const cd = checkCarrier(carrierCode);
            return await createConnection().get(`/pdf/${cd}/${invoiceNumber}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf'
                },
                cancelToken: getCancelToken()
            });
        },
        [createConnection, getCancelToken]
    );
    return { getPDF, getCancelToken, ...other };
};

const checkCarrier = (carrierCode: string) => {
    switch (carrierCode) {
        case 'IBIH':
        case 'IBIA':
            return 'IBI';
        case 'RNLO':
            return 'RLC';
        default:
            return carrierCode;
    }
};

interface ImgCache {
    [index: string]: Promise<AxiosResponse>;
}
const imgCache: ImgCache = {};

export const useGetCarrierImg = (): IApiHelpers & { getImg: (carrierCode: string) => Promise<AxiosResponse> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get carrier img');
    const getImg = useCallback(
        async (carrierCode: string) => {
            if (!imgCache[carrierCode] || (await imgCache[carrierCode]).status !== 200) {
                const res = createConnection().get(`/carrier/image/${carrierCode}`, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'image/png'
                    },
                    cancelToken: getCancelToken()
                });
                imgCache[carrierCode] = res;
            }
            return await imgCache[carrierCode];
        },
        [createConnection, getCancelToken]
    );
    return { getImg, getCancelToken, ...other };
};

export const useGetAccountByID = (): IApiHelpers & { getAccount: (carrierAccount: string) => Promise<AxiosResponse<Models.CarrierAccount>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get account by id');
    const getAccount = useCallback(
        async (carrierAccount: string) => {
            return await createConnection().get(`/carrier/carrieraccount/${carrierAccount}`, {
                cancelToken: getCancelToken()
            });
        },
        [createConnection, getCancelToken]
    );
    return { getAccount, getCancelToken, ...other };
};

export const useGetCarriers = (): IApiHelpers & {
    getCarriers: (invoiceOptions?: Partial<Models.CarrierInvoices>) => Promise<AxiosResponse<Models.Carrier[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get carriers');
    const getCarriers = useCallback(
        async (invoiceOptions?: Partial<Models.CarrierInvoices>) => {
            return await createConnection().post(`/carrier/search`, invoiceOptions ?? {}, { cancelToken: getCancelToken() });
        },
        [createConnection, getCancelToken]
    );
    return { getCarriers, getCancelToken, ...other };
};

export const useCreatePermissions = (): IApiHelpers & {
    createPermissions: (userID: string, permissions: Partial<Models.UserEntities>[]) => Promise<AxiosResponse<Models.UserEntities[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('create permission');
    const createPermissions = useCallback(
        async (userID: string, permissions: Partial<Models.UserEntities>[]) =>
            await createConnection().post(`/user/new/permissions/${userID}`, permissions, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { createPermissions, getCancelToken, ...other };
};

export interface Disallows {
    clients: Models.Client[];
    companies: Models.Company[];
    divisions: Models.Division[];
    locations: Models.Location[];
    accounts: Models.CarrierAccount[];
    carriers: Models.Carrier[];
    [index: string]: any[];
}

export const useGetUserPermissions = (): IApiHelpers & {
    getPermissions: (userID: string) => Promise<AxiosResponse<{ permissions: Models.UserEntities[]; disallowed: Disallows }>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get permission');
    const getPermissions = useCallback(
        async (userID: string) => await createConnection().get(`/user/permissions/${userID}`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getPermissions, getCancelToken, ...other };
};

export const useGetCollabOptions = (): IApiHelpers & { getCollabOptions: (carrierInvoiceID: string) => Promise<AxiosResponse<Models.User[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get collab');
    const getCollabOptions = useCallback(
        async (carrierInvoiceID: string) => await createConnection().get(`/user/auth/collaborate/${carrierInvoiceID}`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getCollabOptions, getCancelToken, ...other };
};

export const usePostMessagesBulk = (): IApiHelpers & {
    postMessagesBulk: (carrierInvoiceID: string, messages: Partial<Models.Messages>[]) => Promise<AxiosResponse>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('post messages');
    const postMessagesBulk = useCallback(
        async (carrierInvoiceID: string, messages: Partial<Models.Messages>[]) =>
            await createConnection().post(`/messages/bulk/${carrierInvoiceID}`, messages, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { postMessagesBulk, getCancelToken, ...other };
};

export const useConnectToMessages = (): IApiHelpers & {
    connectToMessages: () => Promise<AxiosResponse>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('connect messages');
    const connectToMessages = useCallback(async () => await createConnection().get(`/messages/notifications`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { connectToMessages, getCancelToken, ...other };
};

export const useSetMessageRead = (): IApiHelpers & { setMessageRead: (messageId: number) => Promise<AxiosResponse> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('connect messages');
    const setMessageRead = useCallback(
        async (messageId: number) => await createConnection().post(`/messages/read/${messageId}`, {}, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { setMessageRead, getCancelToken, ...other };
};

export const useGetPermissionsNew = (): IApiHelpers & { getPermissions: (carrierAccount: string) => Promise<AxiosResponse<Models.UserEntities>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get permissions');
    const getPermissions = useCallback(
        async (carrierAccount: string) =>
            await createConnection().get(`/carrier/permissions/${carrierAccount}`, { cancelToken: getCancelToken(), headers: { carrierAccount } }),
        [createConnection, getCancelToken]
    );
    return { getPermissions, getCancelToken, ...other };
};

// export const useGetPermissionStatus = (): IApiHelpers & { getPermissionStatus: () => Promise<AxiosResponse<Partial<Models.UserEntities>>> } => {
//     const { createConnection, getCancelToken, ...other } = useAxiosConfig('get permissions status');
//     const getPermissionStatus = useCallback(async () => await createConnection().get(`/user/permissions/status`, { cancelToken: getCancelToken() }), [
//         createConnection,
//         getCancelToken
//     ]);
//     return { getPermissionStatus, getCancelToken, ...other };
// };

export const useGetUsersByClient = (): IApiHelpers & { getUsersByClient: (clientID: number) => Promise<AxiosResponse<Models.User[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get users by client');
    const getUsersByClient = useCallback(
        async (clientID: number) => await createConnection().get(`/user/client/${clientID}`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getUsersByClient, getCancelToken, ...other };
};

export const useRefreshToken = (): IApiHelpers & { refreshToken: () => Promise<AxiosResponse<{ Data: { token: string } }>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get users by client');
    const refreshToken = useCallback(async () => await createConnection().get(`/user/token/refresh`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { refreshToken, getCancelToken, ...other };
};

export const useGetAdjustmentTypes = (): IApiHelpers & { getAdjustmentTypes: () => Promise<AxiosResponse<Models.AdjustmentTypes[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get adjustment');
    const getAdjustmentTypes = useCallback(async () => await createConnection().get(`/adjustment`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getAdjustmentTypes, getCancelToken, ...other };
};

export const useMakeAdjustment = (): IApiHelpers & {
    makeAdjustment: (adjustment: Partial<Models.AdjustedAmounts>) => Promise<AxiosResponse<Models.CarrierInvoices>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get adjustment');
    const makeAdjustment = useCallback(
        async (adjustment: Partial<Models.AdjustedAmounts>) => await createConnection().post('/adjustment', adjustment, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { makeAdjustment, getCancelToken, ...other };
};

export const useUpdateAdustment = (): IApiHelpers & {
    updateAdjustment: (adjustment: Partial<Models.AdjustedAmounts>) => Promise<AxiosResponse<Models.AdjustedAmounts>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get adjustment');
    const updateAdjustment = useCallback(
        async (adjustment: Partial<Models.AdjustedAmounts>) => await createConnection().put('/adjustment', adjustment, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { updateAdjustment, getCancelToken, ...other };
};

export const useGetAdjustmentsByInvoice = (): IApiHelpers & {
    getAdjustmentsByInvoice: (carrierInvoiceID: string) => Promise<AxiosResponse<Models.AdjustedAmounts[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get adjustment');
    const getAdjustmentsByInvoice = useCallback(
        async (carrierInvoiceID: string) => await createConnection().get(`/adjustment/byinvoice/${carrierInvoiceID}`, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getAdjustmentsByInvoice, getCancelToken, ...other };
};

export const useGetAdjustmentCategories = (): IApiHelpers & { getAdjustmentCategories: () => Promise<AxiosResponse<Models.AdjustmentCategory[]>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('get adjustment categories');
    const getAdjustmentCategories = useCallback(async () => await createConnection().get(`/adjustment/category`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getAdjustmentCategories, getCancelToken, ...other };
};

export const useSendNotes = (): IApiHelpers & { sendNotes: (email: Partial<Models.Email>) => Promise<AxiosResponse<{ action: Models.Actions }>> } => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('email notes');
    const sendNotes = useCallback(
        async (email: Partial<Models.Email>) => await createConnection().post(`/messages/send/notes`, email, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { sendNotes, getCancelToken, ...other };
};

export const useGetInvoiceRemittance = (): IApiHelpers & {
    getInvoiceRemittance: (carrierInvoiceID: string, remittanceNumbers?: number[]) => Promise<AxiosResponse<Models.RemittanceInvoices[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice remittance');
    const getInvoiceRemittance = useCallback(
        async (carrierInvoiceID: string, remittanceNumbers?: number[]) =>
            await createConnection().post(`/remittance/byId/${carrierInvoiceID}`, { remittanceNumbers }, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getInvoiceRemittance, getCancelToken, ...other };
};

export const useGetInvoiceRemittances = (): IApiHelpers & {
    getInvoiceRemittances: (body: Models.RemittanceInvoicesSearchBody) => Promise<AxiosResponse<Models.RemittanceInvoices[]>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice remittances');
    const getInvoiceRemittancesById = useCallback(
        async (body: Models.RemittanceInvoicesSearchBody) => await createConnection().post(`/remittance/search`, body, { cancelToken: getCancelToken() }),
        [createConnection, getCancelToken]
    );
    return { getInvoiceRemittances: getInvoiceRemittancesById, getCancelToken, ...other };
};

export const useGetLatestRemittance = (): IApiHelpers & {
    getLatestRemittance: () => Promise<AxiosResponse<Models.RemittanceInvoices>>;
} => {
    const { createConnection, getCancelToken, ...other } = useAxiosConfig('invoice remittance');
    const getLatestRemittance = useCallback(async () => await createConnection().get(`/remittance/latest`, { cancelToken: getCancelToken() }), [
        createConnection,
        getCancelToken
    ]);
    return { getLatestRemittance, getCancelToken, ...other };
};

const createInvoiceQuery = (type: Models.CompanyInvoiceType, invoiceOptions: Partial<Models.CarrierInvoices>) => {
    const params: IIdx = {
        type,
        // auditStatus: Models.AuditStatus.all,
        // invoiceStatus: Models.CompanyInvoiceStatus.all,
        ...invoiceOptions
    };
    const query = Object.keys(params)
        .reduce((acc: string[], k) => {
            if (![-1, '', undefined].includes(params[k])) {
                let str = '';
                if (typeof params[k] === 'string') {
                    str = params[k];
                } else if (params[k] instanceof Date) {
                    str = date(DATE_QUERY_FORMAT)(params[k]);
                } else {
                    str = JSON.stringify(params[k]);
                }
                acc.push(`${k}=${str}`);
            }
            return acc;
        }, [])
        .join('&');
    return query;
};
