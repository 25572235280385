import React, { useContext, useReducer, useRef, useEffect } from 'react';
import { loggedReducer } from './store.helpers';
import { IInvoiceState, InvoiceActions, IInvoicePlus, IInvoiceAction } from '../types/contexts/invoice-context.type';
import { IIdx } from '../types/general.type';

const initialState: IInvoiceState = {
    invoices: [],
    loading: false
};

const reducer = (state: IInvoiceState, action: IInvoiceAction) => {
    const newState = action.storeOnly ? state : { ...state };

    switch (action.type) {
        case InvoiceActions.invoices: {
            const invMap = new Map(newState.invoices.map((inv) => [inv.carrierInvoiceID, inv]));
            action.payload.forEach((inv: IInvoicePlus) => {
                inv.updates = inv.updates ?? invMap.get(inv.carrierInvoiceID)?.updates ?? {};
                inv.receive = false;
                inv.disburse = false as any;
            });
            newState[action.type] = action.payload;
            break;
        }
        case InvoiceActions.updateInvoices: {
            const invMap = newState.invoices.reduce((acc: { [index: string]: number }, inv, i) => {
                acc[inv.carrierInvoiceID] = i;
                return acc;
            }, {});
            action.payload.forEach((newInv: IInvoicePlus) => {
                const invIdx = invMap[newInv.carrierInvoiceID];
                if (invIdx !== undefined) {
                    newState.invoices[invIdx] = { ...newInv, updates: newInv.updates || {} };
                }
            });
            break;
        }
        case InvoiceActions.addUpdate: {
            const invIdx = newState.invoices.findIndex((i) => i.carrierInvoiceID === action.id);
            let inv = newState.invoices[invIdx];
            if (!inv || !action.prop) return state;

            if ((inv as IIdx)[action.prop] === action.payload) {
                delete (inv.updates as IIdx)[action.prop];
            }
            inv = updateInvoice(inv, action.prop, action.payload, newState);
            newState.invoices[invIdx] = { ...inv };
            break;
        }

        // case InvoiceActions.addUpdates: {
        //     const invIdx = newState.invoices.findIndex((i) => i.carrierInvoiceID === action.id);
        //     let inv = newState.invoices[ invIdx ];

        //     const updates = Object.entries(action.payload);

        //     for (let i = 0; i < updates.length; i++) {
        //         const [ key, val ] = updates[ i ];
        //         if ((inv as IIdx)[ key ] === val) {
        //             delete (inv.updates as IIdx)[ key ];
        //         } else {
        //             (inv.updates as IIdx)[ key ] = val;
        //         }
        //     }

        //     break;
        // }

        case InvoiceActions.addUpdateBulk: {
            const invMap = newState.invoices.reduce((acc: { [index: string]: number }, inv, i) => {
                acc[inv.carrierInvoiceID] = i;
                return acc;
            }, {});

            action.payload.forEach((newInv: IInvoicePlus) => {
                const invIdx = invMap[newInv.carrierInvoiceID];
                let inv = newState.invoices[invIdx];
                if (!inv) return;
                const { carrierInvoiceID, ...updates } = newInv;
                Object.entries(updates).forEach(([key, value]) => {
                    if ((inv as IIdx)[key] === value) {
                        delete (inv.updates as IIdx)[key];
                    }

                    inv = updateInvoice(inv, key, value, newState);
                    newState.invoices[invIdx] = { ...inv };
                });
            });
            // newState.invoices = [...newState.invoices];
            break;
        }
        case InvoiceActions.checkAll:
            newState.invoices = newState.invoices.map((inv) => {
                const i = { ...inv };
                // const id = i.carrierInvoiceID;
                if ((i as IIdx)[action.payload]) {
                    if ((i.updates as IIdx)[action.payload] !== undefined) {
                        delete (i.updates as IIdx)[action.payload];
                    }
                } else {
                    if ((i.updates as IIdx)[action.payload] === false || (i.updates as IIdx)[action.payload] === undefined) {
                        (i.updates as IIdx)[action.payload] = true;
                    }
                }
                return i;
            });
            break;
        case InvoiceActions.unCheckAll:
            newState.invoices = newState.invoices.map((inv) => {
                const i = { ...inv };
                // const id = i.carrierInvoiceID;
                if (!(i as IIdx)[action.payload]) {
                    if ((i.updates as IIdx)[action.payload] !== undefined) {
                        delete (i.updates as IIdx)[action.payload];
                    }
                } else {
                    if ((i.updates as IIdx)[action.payload] === true || (i.updates as IIdx)[action.payload] === undefined) {
                        (i.updates as IIdx)[action.payload] = false;
                    }
                }
                return i;
            });
            break;
        case InvoiceActions.loading:
            newState[action.type] = action.payload;
            break;
        default:
            break;
    }
    return newState;
};

const logReducer = loggedReducer<IInvoiceState>(reducer, 'Invoice');

export const InvoiceStateContext: any = React.createContext({});
export const InvoiceDispatchContext: any = React.createContext({});
export const InvoiceStateRefContext: any = React.createContext({});

export const useInvoiceState = (): IInvoiceState => useContext(InvoiceStateContext);
export const useInvoiceDispatch = (): ((action: IInvoiceAction) => IInvoiceState) => useContext(InvoiceDispatchContext);
export const useInvoiceStateRef = (): React.MutableRefObject<IInvoiceState> => useContext(InvoiceStateRefContext);

export const InvoiceProvider: React.FC = ({ children }) => {
    const [invoiceState, appDispatch] = useReducer(logReducer, initialState);
    const invoiceStateRef = useRef<IInvoiceState>(invoiceState);
    useEffect(() => {
        invoiceStateRef.current = invoiceState;
    }, [invoiceState, invoiceStateRef]);
    return (
        <InvoiceStateContext.Provider value={invoiceState}>
            <InvoiceDispatchContext.Provider value={appDispatch}>
                <InvoiceStateRefContext.Provider value={invoiceStateRef}>{children}</InvoiceStateRefContext.Provider>
            </InvoiceDispatchContext.Provider>
        </InvoiceStateContext.Provider>
    );
};

export const useGetInvoiceFromState = (index: number) => {
    const invoiceState = useInvoiceState();
    return invoiceState.invoices[index];
};

const updateInvoice = (inv: IInvoicePlus, prop: string, val: any, state: IInvoiceState) => {
    const updates: IIdx<Partial<IInvoicePlus>> = inv.updates;

    const amtDue = updates.invoiceAmtDue !== undefined ? updates.invoiceAmtDue : inv.invoiceAmtDue;
    const toRec = (updates.invoiceToReceive !== undefined ? updates.invoiceToReceive : inv.invoiceToReceive) || undefined;
    const toDis = (updates.invoiceToDisburse !== undefined ? updates.invoiceToDisburse : inv.invoiceToDisburse) || undefined;
    const totalRec = (updates.invoiceTotalReceived !== undefined ? updates.invoiceTotalReceived : inv.invoiceTotalReceived) || 0;
    const totalDis = (updates.invoiceTotalDisbursed !== undefined ? updates.invoiceToDisburse : inv.invoiceTotalDisbursed) || 0;

    switch (prop) {
        case 'receive':
            if (toRec === undefined && val) {
                // updates.invoiceToReceive = parseFloat((amtDue - totalRec).toFixed(2));
                updates.invoiceToReceive = parseFloat((inv.currentAmt - totalRec).toFixed(2));
                if (updates.disburse && toDis === undefined) {
                    updates.invoiceToDisburse = totalRec + parseFloat(((toRec || 0) - totalDis).toFixed(2));
                }
            }
            if (!val) {
                updates.invoiceToReceive = undefined;
            }
            updates[prop] = val || undefined;
            break;
        case 'disburse':
            if (toDis === undefined && val) {
                if (toRec === undefined) {
                    updates.invoiceToDisburse = parseFloat((inv.currentAmt - totalDis).toFixed(2));
                } else {
                    updates.invoiceToDisburse = totalRec + parseFloat(((toRec || 0) - totalDis).toFixed(2));
                }
            }

            if (!val) {
                updates.invoiceToDisburse = undefined;
            }
            updates[prop] = val || undefined;
            break;
        case 'invoiceToReceive':
            updates.invoiceToReceive = val;
            break;
        case 'invoiceToDisburse':
            updates.invoiceToDisburse = val;
            break;
        default:
            updates[prop] = val;
            break;
    }
    return { ...inv };
};
