import React, { useMemo } from 'react';
import { useUserState } from '../../../contexts/user.context';
import { currency } from '../../../helpers/pipes';
import { FailureObject, FailureType } from '../../../hooks/stiapi.hook';
import { FailureSection } from './failuresection';

export const failureTitles: Record<string, string> = {
    failedGl: 'immbalanced GL codings',
    failedStatus: 'incompatible audit status',
    userNonExistant: 'unknown user',
    failedApprovalLimit: 'insufficient approval limit'
};

interface ApprovalFailedProps {
    fails: FailureObject<FailureType>;
}

export const ApprovalFailed: React.FC<ApprovalFailedProps> = ({ fails }) => {
    const { user } = useUserState();
    const sortedFails = useMemo(() => {
        const failureMap = Object.entries(fails).reduce((acc: Record<string, string[]>, [id, v]) => {
            const type = Object.keys(v)[0];
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(id);
            return acc;
        }, {});
        return Object.entries(failureMap);
    }, [fails]);
    return (
        <>
            {sortedFails.map(([type, ids]) => (
                <FailureSection
                    title={failureTitles[type]}
                    message={type === 'failedApprovalLimit' && user ? `Your approval limit: ${currency(user.approvalLimit)}` : undefined}
                    invoices={ids}
                />
            ))}
        </>
    );
};
