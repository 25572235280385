import React from 'react';
import { PageContainer, GridGrow } from '../components/generic';
import { useParams, useLocation } from 'react-router-dom';
import { PDF } from '../components/pdfjsreskin/pdf.component';

export const PDFPage = () => {
    const params = useParams<{ carrierCode: string; invoiceNumber: string }>();
    // const { search } = useLocation();

    return (
        <PageContainer>
            <GridGrow>
                <PDF carrierCode={params.carrierCode} invoiceNumber={params.invoiceNumber} />
            </GridGrow>
        </PageContainer>
    );
};
