import React, { memo, useEffect } from 'react';
import { Button, Typography, Box, makeStyles, IconButton } from '@material-ui/core';
import { GridMin } from '../../generic';
import { useAppDispatch } from '../../../contexts/app.context';
import { AppActions } from '../../../types/contexts/app-context.type';
import { Header, BodyContainer, ButtonContainer, ModalContainer } from './common.component';
import { useInvoiceState, useInvoiceDispatch } from '../../../contexts/invoice.context';
import { currency, prettyNum } from '../../../helpers/pipes';
import { useUpdateInvoices } from '../../../hooks/stiapi.hook';
import { Models } from 'shipmenttrackers-domain/dist';
import { InvoiceActions, IInvoicePlus } from '../../../types/contexts/invoice-context.type';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { Icon } from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';
import { useInfoModal } from '../../../hooks/info-modal.hook';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold'
    },
    list: {
        '& > *:not(:first-child)': {
            paddingTop: theme.spacing(1)
        }
    },
    lighten: {
        opacity: 0.5
    }
}));

export const Component: React.FC = () => {
    const classes = useStyles();
    const appDispatch = useAppDispatch();
    const invoiceState = useInvoiceState();
    const invoiceDispatch = useInvoiceDispatch();
    const { updateInvoices, loading } = useUpdateInvoices();
    const [ConfirmationModal, runConfirmationModal] = useInfoModal();

    const close = () => {
        appDispatch({ type: AppActions.showInvConf, payload: false });
    };

    let toReceiveCount = 0;
    let toReceiveAmt = 0;
    let toDisburseCount = 0;
    let toDisburseAmt = 0;
    let disbursingDisputed = 0;

    invoiceState.invoices.forEach(({ dispute, updates: u }) => {
        if (u.receive && u.invoiceToReceive && u.invoiceToReceive !== 0) {
            toReceiveCount++;
            toReceiveAmt += u.invoiceToReceive;
        }
        if (u.disburse && u.invoiceToDisburse && u.invoiceToDisburse !== 0) {
            toDisburseCount++;
            toDisburseAmt += u.invoiceToDisburse;
        }
        if (dispute && u.invoiceToDisburse) {
            disbursingDisputed++;
        }
    });

    const hasChanges = !!(toReceiveCount || toDisburseCount);

    const onConfirm = async () => {
        const otherUpdates: { [index: string]: Partial<Models.CarrierInvoices> } = {};
        const updates = invoiceState.invoices.reduce((acc: Partial<Models.CarrierInvoices>[], inv) => {
            const update: Partial<Models.CarrierInvoices> = {};
            const { receive, invoiceToReceive, disburse, invoiceToDisburse, dispute, ...other } = inv.updates;

            const id = inv.carrierInvoiceID;
            let hadUpdate = false;

            if (receive && invoiceToReceive !== undefined) {
                update.receive = receive;
                update.invoiceToReceive = invoiceToReceive;
                hadUpdate = true;
            }
            if (disburse && invoiceToDisburse !== undefined) {
                update.disburse = disburse;
                update.invoiceToDisburse = invoiceToDisburse;
                hadUpdate = true;
            }

            if (hadUpdate) {
                if (Object.keys(other).length > 0) {
                    otherUpdates[id] = other;
                }
                update.carrierInvoiceID = id;
                acc.push(update);
            }

            return acc;
        }, []);

        const res = await updateInvoices(updates);

        if (res.status === 200) {
            const updatedInv = res.data.invoices;
            updatedInv.forEach((inv) => {
                const id = inv.carrierInvoiceID;
                if (otherUpdates[id]) {
                    (inv as IInvoicePlus).updates = otherUpdates[id];
                }
            });
            invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: res.data.invoices });
            if (res.data.disburseNumber) {
                await runConfirmationModal({
                    title: `Success`,
                    yesTxt: 'OK',
                    no: false,
                    desc: (
                        <div>
                            <div>
                                <p>Created Disbursement:</p>
                                <p>{res.data.disburseNumber}</p>
                            </div>
                            <IconButton
                                onClick={() =>
                                    navigator.clipboard.writeText(res.data.disburseNumber!).then(
                                        () => {},
                                        () => alert('There was a problem copying automatically.')
                                    )
                                }
                            >
                                <Icon path={mdiContentCopy} size={1} />
                            </IconButton>
                        </div>
                    )
                });
            }

            close();
        }
    };

    return (
        <>
            <ConfirmationModal />
            <ModalContainer>
                <GridMin>
                    <Header variant="subtitle1">Receive/Disburse</Header>
                </GridMin>
                <BodyContainer container direction="column">
                    <Box paddingLeft={4}>
                        <Typography variant="h6" color="primary">
                            {hasChanges ? 'Please confirm that you intend to' : 'No Changes'}
                        </Typography>
                    </Box>

                    {hasChanges && (
                        <Box paddingLeft={6} paddingTop={4} paddingBottom={2}>
                            <ul className={`${classes.list} ${disbursingDisputed ? classes.lighten : ''}`}>
                                {!!toReceiveCount && (
                                    <li>
                                        <Typography variant="body1" component="span">
                                            Receive
                                        </Typography>
                                        <Typography variant="body1" component="span" className={classes.bold}>{` ${prettyNum(toReceiveCount)}`}</Typography>
                                        <Typography variant="body1" component="span">{` invoice${toReceiveCount > 1 ? 's' : ''} for`}</Typography>
                                        <Typography variant="body1" component="span" className={classes.bold}>{` ${currency(toReceiveAmt)}`}</Typography>
                                    </li>
                                )}
                                {!!toDisburseCount && (
                                    <li>
                                        <Typography variant="body1" component="span">
                                            Disburse
                                        </Typography>
                                        <Typography variant="body1" component="span" className={classes.bold}>{` ${prettyNum(toDisburseCount)}`}</Typography>
                                        <Typography variant="body1" component="span">{` invoice${toDisburseCount > 1 ? 's' : ''} for`}</Typography>
                                        <Typography variant="body1" component="span" className={classes.bold}>{` ${currency(toDisburseAmt)}`}</Typography>
                                    </li>
                                )}
                            </ul>
                        </Box>
                    )}

                    <Box paddingLeft={4} paddingTop={2} paddingBottom={4}>
                        {disbursingDisputed ? (
                            <>
                                <Typography variant="body1" className={classes.bold} color="error">{`${disbursingDisputed} invoice${
                                    disbursingDisputed > 1 ? 's' : ''
                                } is disputed.`}</Typography>

                                <Typography variant="body1" className={classes.bold} color="error">
                                    Please resolve before disbursing.
                                </Typography>
                            </>
                        ) : null}
                    </Box>

                    <ButtonContainer container wrap="nowrap" justify="center">
                        {!disbursingDisputed && (
                            <ExtendedButton variant="contained" color="primary" disabled={!hasChanges} onClick={onConfirm} loading={!!loading}>
                                Confirm
                            </ExtendedButton>
                        )}
                        <Button variant="contained" color="primary" onClick={close}>
                            {disbursingDisputed ? 'OK' : 'Cancel'}
                        </Button>
                    </ButtonContainer>
                </BodyContainer>
            </ModalContainer>
        </>
    );
};

export const InvoiceConfirmation = memo(Component);
