import React, { useMemo, useCallback, useEffect } from 'react';
import { Grid, Typography, Box, useTheme, makeStyles, fade, styled } from '@material-ui/core';
import { GridMin, GridGrow, STIButtonbase, STITooltip } from '../../generic';
import { NotesComponent } from './notes.component';
import { useAuditState, useAuditDispatch, AuditActions, useAuditStateRef } from './audit.context';
import { useInputStyle } from '../../../styles/stiinput.style';

import TableArrowLeft from 'mdi-material-ui/TableArrowLeft';
import { GLComponent } from './gl.component';
import { currency, date, SIMPLE_FORMAT } from '../../../helpers/pipes';
import { AuditPager } from './auditpager.component';
import { PDFToggle } from './pdftoggle.component';
import { AuditDisputeCheck } from './auditdisputecheck.component';
import { AuditAmountInputForm } from './amountinput.component';
import { AuditGo } from './auditgo.component';
import { CarrierImg } from '../../generic/display/carrierimg.component';
import { AuditStatusSelect } from './auditstatusselect.component';
import { InvoiceStatusSelect } from './invoicestatusselect.component';
import { useInfoModal, MODAL_TYPE, CONFIRM_TYPES } from '../../../hooks/info-modal.hook';
import { useSaveGLChanges } from './gl/savegls.hook';
// import { ReactComponent as Multipdf } from '../../../styles/images/btn_multpdfs.svg';
import { ExtendedIconButton } from '../../generic/controls/extendediconbutton.component';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { useHistory } from 'react-router-dom';
import { useSearchHandler } from '../../../mediators/appsearchinvoice.mediator';
import { useInvoiceStateRef } from '../../../contexts/invoice.context';
import { AuditApproveCheck } from './auditapprovecheck.component';
import { useAppState } from '../../../contexts/app.context';
import { useUserState } from '../../../contexts/user.context';
import { Incognito } from 'mdi-material-ui';

export const useAuditStyles = makeStyles((theme) => ({
    bgPrimary: {
        background: theme.palette.primary.main,
        color: fade(theme.palette.common.white, 0.87)
    },
    bgDark: {
        background: theme.palette.primary.dark
    },
    bgWhite: {
        background: theme.palette.common.white,
        color: theme.palette.primary.dark
    },
    bgGreen: {
        background: theme.palette.secondary.main,
        color: theme.palette.primary.dark
    },
    bgRed: {
        background: theme.palette.error.main,
        color: fade(theme.palette.common.white, 0.87)
    },
    pagePadding: {
        padding: theme.spacing(1)
    },
    notes: {
        borderTop: `1px solid ${theme.palette.primary.dark}`,
        flexGrow: 1,
        flexShrink: 0
    },
    glContainer: {
        flexGrow: 0,
        flexShrink: 1,
        // minHeight: theme.spacing(16),
        maxHeight: theme.spacing(48),
        flexWrap: 'nowrap'
    },
    layoutControlRow: {
        '& > *': {
            width: 'auto',
            flexBasis: '0px'
        },
        '& $glContainer': {
            flexGrow: 6,
            maxHeight: '100%',
            height: '100%'
        },
        '& $notes': {
            flexGrow: 4
        }
    }
}));

export const Audit: React.FC = () => {
    // const handleDispute = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {}, [auditStateRef]);
    // const handleApprove = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {}, [auditStateRef]);
    // const handlePay = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {}, [auditStateRef]);
    const userState = useUserState();
    const theme = useTheme();
    const history = useHistory();
    const invoiceStateRef = useInvoiceStateRef();
    const auditClasses = useAuditStyles();
    const inputClasses = useInputStyle();
    const auditState = useAuditState();
    const appState = useAppState();
    const checkPermission = usePermission(auditState.invoice);
    const auditDispatch = useAuditDispatch();
    const newPDFClick = useCallback(() => {
        auditDispatch({ type: AuditActions.addPDF });
    }, [auditDispatch]);
    // const invoiceStateRef = useInvoiceStateRef();
    const auditStateRef = useAuditStateRef();
    const [ConfirmationModal, runConfirmationModal] = useInfoModal();
    // const history = useHistory();
    const [saveGLs, , error, clearError] = useSaveGLChanges();

    const isSTI = !!checkPermission();
    const { fetchInvoices } = useSearchHandler();

    const checkGls = useCallback(async () => {
        const gls = auditStateRef.current.glcoding;
        const glsDel = auditStateRef.current.glsToDelete;

        if (gls.find((g) => g.updated) || glsDel.length > 0) {
            const confirmResp = await runConfirmationModal({
                title: 'Unsaved GL Coding',
                desc: (
                    <>
                        <p>You have unsaved GL Coding changes.</p>
                        <p>Do you want to save these changes before moving on?</p>
                    </>
                ),
                modalType: MODAL_TYPE.QUESTION,
                cancel: true,

                rememberOptionKey: 'savegloninvoicechange'
            });

            if (confirmResp === CONFIRM_TYPES.YES) {
                const res = await saveGLs();
                if (res && (res.status === 200 || res.status === 201)) {
                    return true;
                }
            } else if (confirmResp === CONFIRM_TYPES.NO) {
                return true;
            } else if (confirmResp === CONFIRM_TYPES.CANCEL) {
                return false;
            }
        }
        return true;
    }, [auditStateRef, runConfirmationModal, saveGLs]);

    const toTable = useCallback(async () => {
        if (await checkGls()) {
            if (invoiceStateRef.current.invoices.length) {
                fetchInvoices();
            }
            history.push('/');
        }
    }, [checkGls, invoiceStateRef, history, fetchInvoices]);

    useEffect(() => {
        error &&
            runConfirmationModal({
                title: 'Coding Error',
                desc: error,
                yesTxt: 'OK',
                no: false,
                cancel: false
            }).then(clearError);
    }, [error, runConfirmationModal, clearError]);

    const carrierName = useMemo(() => {
        if (auditState.invoice) {
            const c = appState.data.carriers.find((c) => c.carrierID === auditState.invoice!.carrierID);
            if (c) {
                return c.carrierName;
            }
        }
        return undefined;
    }, [auditState.invoice, appState.data.carriers]);

    const alias = userState.alias?.user;

    return (
        <>
            <ConfirmationModal />

            <Box height="100%">
                <AuditContainer container direction="column">
                    <Box width="100%" padding={0} className={`${alias ? auditClasses.bgRed : auditClasses.bgPrimary}`}>
                        <TrueCenterGrid item container justify="space-between" alignItems="center">
                            <Box width={0} overflow="visible" display="flex" alignItems="center">
                                {/* <CleanLink to="/"> */}
                                <STITooltip title="Exit Audit" placement="bottom">
                                    <STIButtonbase onClick={toTable}>
                                        <Box height="100%" padding={1.5} marginBottom="-5px" color={theme.palette.common.white}>
                                            <TableArrowLeft color="inherit" />
                                        </Box>
                                    </STIButtonbase>
                                </STITooltip>
                                {alias ? (
                                    <STITooltip title={`Viewing as ${alias.firstName} ${alias.lastName}`} placement="bottom">
                                        <Box height="100%" padding={1.5} marginBottom="-5px" color={theme.palette.common.white}>
                                            <Incognito color="inherit" />
                                        </Box>
                                    </STITooltip>
                                ) : null}
                                {/* </CleanLink> */}
                            </Box>
                            {/* {alias ? (
                                <SingleLine color="inherit" variant="button">
                                    {`Viewing as ${alias.firstName} ${alias.lastName}`}
                                </SingleLine>
                            ) : null} */}

                            <AuditPager beforeChange={checkGls} />

                            <Box width={0} display="flex" justifyContent="flex-end">
                                <PDFToggle />
                            </Box>
                        </TrueCenterGrid>
                    </Box>
                    <GridMin className={`${auditClasses.bgWhite} ${auditClasses.pagePadding}`} container wrap="nowrap">
                        <Grid item container spacing={2} alignItems="center">
                            <GridMin container>
                                <CarrierImg carrierCode={auditState.invoice?.carrierCode} />
                            </GridMin>
                            <GridGrow container direction="column" height100>
                                <Grid container>
                                    <Typography variant="h6" color="textPrimary">
                                        {`${auditState.invoice?.invoiceNumber} - ${date(SIMPLE_FORMAT)(auditState.invoice?.invoiceDate)}${
                                            auditState.invoice?.invoiceOriginalAmt
                                                ? ` - ${currency(auditState.invoice?.invoiceOriginalAmt, auditState.invoice?.currency)} - ${carrierName}`
                                                : ''
                                        }`}
                                    </Typography>
                                </Grid>
                                <Grid container wrap="nowrap">
                                    <Grid item container spacing={1} wrap="nowrap">
                                        <Grid item>
                                            <Typography variant="body2">{`Shipper: `}</Typography>
                                        </Grid>
                                        {auditState.invoice?.shipperCompany === 'MULTIPLE' ? (
                                            <Grid item>
                                                <Typography variant="body2">{`${auditState.invoice?.shipperCompany}`}</Typography>
                                            </Grid>
                                        ) : (
                                            <Grid item container direction="column" wrap="nowrap">
                                                {auditState.invoice?.shipperCompany ? (
                                                    <Typography variant="body2">{`${auditState.invoice?.shipperCompany}`}</Typography>
                                                ) : (
                                                    auditState.invoice?.shipperName && (
                                                        <Typography variant="body2">{`${auditState.invoice?.shipperName}`}</Typography>
                                                    )
                                                )}
                                                {auditState.invoice?.shipperAddress1 && (
                                                    <Typography variant="body2">{`${auditState.invoice?.shipperAddress1}`}</Typography>
                                                )}
                                                {auditState.invoice?.shipperAddress2 && (
                                                    <Typography variant="body2">{`${auditState.invoice?.shipperAddress2}`}</Typography>
                                                )}
                                                <Typography variant="body2">{`${auditState.invoice?.shipperCity}, ${auditState.invoice?.shipperState} ${
                                                    auditState.invoice?.shipperZip
                                                }${auditState.invoice?.shipperCountry ? `, ${auditState.invoice?.shipperCountry}` : ''}`}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item container spacing={1} wrap="nowrap">
                                        <Grid item>
                                            <Typography variant="body2">{`Consignee: `}</Typography>
                                        </Grid>
                                        {auditState.invoice?.consigneeCompany === 'MULTIPLE' ? (
                                            <Grid item>
                                                <Typography variant="body2">{`${auditState.invoice?.consigneeCompany}`}</Typography>
                                            </Grid>
                                        ) : (
                                            <Grid item container direction="column">
                                                {auditState.invoice?.consigneeCompany ? (
                                                    <Typography variant="body2">{`${auditState.invoice?.consigneeCompany}`}</Typography>
                                                ) : (
                                                    auditState.invoice?.consigneeName && (
                                                        <Typography variant="body2">{`${auditState.invoice?.consigneeName}`}</Typography>
                                                    )
                                                )}
                                                {auditState.invoice?.consigneeAddress1 && (
                                                    <Typography variant="body2">{`${auditState.invoice?.consigneeAddress1}`}</Typography>
                                                )}
                                                {auditState.invoice?.consigneeAddress2 && (
                                                    <Typography variant="body2">{`${auditState.invoice?.consigneeAddress2}`}</Typography>
                                                )}
                                                <Typography variant="body2">{`${auditState.invoice?.consigneeCity}, ${auditState.invoice?.consigneeState} ${
                                                    auditState.invoice?.consigneeZip
                                                }${auditState.invoice?.consigneeCountry ? `, ${auditState.invoice?.consigneeCountry}` : ''}`}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {/* </GridGrow> */}
                                </Grid>
                            </GridGrow>
                            <GridMin>
                                <AuditGo beforeChange={checkGls} />
                            </GridMin>
                        </Grid>
                    </GridMin>
                    <GridGrow
                        container
                        className={auditState.attachPDF ? '' : auditClasses.layoutControlRow}
                        direction={auditState.attachPDF ? 'column' : 'row'}
                    >
                        {/* <GridGrow container direction="row"> */}
                        <Grid container direction="column" className={auditClasses.glContainer}>
                            <Box borderBottom={`1px solid ${theme.palette.grey[700]}`} flexShrink={0}>
                                <GridMin container wrap="nowrap" className={`${auditClasses.bgDark} ${auditClasses.pagePadding}`} alignItems="center">
                                    <GridGrow container spacing={2} alignItems="center">
                                        <GridGrow>
                                            <AuditAmountInputForm disabled={!isSTI} />
                                        </GridGrow>
                                        <GridGrow>
                                            <AuditStatusSelect
                                                className={inputClasses.input}
                                                variant="filled"
                                                label="Audit Status"
                                                fullWidth
                                                title="Audit Status"
                                                disabled={!isSTI}
                                            />
                                        </GridGrow>
                                        <GridGrow>
                                            <InvoiceStatusSelect
                                                className={inputClasses.input}
                                                variant="filled"
                                                label="Invoice Status"
                                                fullWidth
                                                title="Invoice Status"
                                                disabled={!isSTI}
                                            />
                                        </GridGrow>
                                    </GridGrow>
                                    <GridMin item container wrap="nowrap">
                                        {/* <AuditCheck prop="dispute" text="Dispute" requireAction={true} /> */}
                                        <AuditDisputeCheck />
                                        {/* <AuditCheck prop="approve" text="Approve" /> */}
                                        <AuditApproveCheck />
                                        {/* <AuditCheck prop="pay" text="Pay" /> */}
                                    </GridMin>
                                    <STITooltip title="New PDF Window">
                                        <Box marginLeft="auto">
                                            <ExtendedIconButton
                                                onClick={newPDFClick}
                                                color={theme.palette.common.white}
                                                bgColor={theme.palette.primary.main}
                                                hoverColor={fade(theme.palette.primary.dark, 0.5)}
                                                rippleColor={theme.palette.primary.dark}
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/img/btn_multpdfs.svg`}
                                                    width={theme.spacing(3)}
                                                    height={theme.spacing(3)}
                                                    alt="Extra PDF"
                                                />
                                            </ExtendedIconButton>
                                        </Box>
                                    </STITooltip>
                                </GridMin>
                            </Box>

                            {auditState.invoice && <GLComponent />}
                        </Grid>
                        <Grid
                            // growWeight={auditState.attachPDF ? 20 : 3}
                            className={`${auditClasses.bgWhite} ${auditClasses.pagePadding} ${auditClasses.notes}`}
                            container
                        >
                            <NotesComponent />
                        </Grid>
                    </GridGrow>
                </AuditContainer>
            </Box>
        </>
    );
};

const AuditContainer = styled(Grid)(({ theme }) => ({
    height: '100%'
}));

const TrueCenterGrid = styled(Grid)({
    '& > *': {
        flexBasis: 0
        // minWidth: 'fit-content'
    }
});

const SingleLine = styled(Typography)(() => ({
    whiteSpace: 'nowrap'
}));
