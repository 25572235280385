import { useEffect, useCallback } from 'react';
import { useStateWithRef } from './statewithref.hook';
import { IAction } from '../types/contexts/context.type';

export function usePostMessages<T>(keys: string[], dispatch?: (action: IAction) => unknown) {
    const [state, updateState, stateRef] = useStateWithRef<Partial<T>>({});

    useEffect(() => {
        const handleMessage = (evt: MessageEvent) => {
            const { data } = evt;
            if (keys.includes(data.key)) {
                const { message } = data;
                if (message.hasOwnProperty('type')) {
                    dispatch && dispatch(message as IAction);
                } else {
                    updateState({ ...stateRef.current, ...message });
                }
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [updateState, stateRef, keys, dispatch]);

    const postMessage = useCallback((target: Window, message: Partial<T> | IAction, key: string) => {
        target.postMessage({ key, message }, window.location.origin);
    }, []);

    return [state, postMessage] as const;
}
