import React, { useContext, useReducer, useRef, useEffect, useMemo } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { loggedReducer } from '../../../../../contexts/store.helpers';
import { IAction } from '../../../../../types/contexts/context.type';
import { IIdx } from '../../../../../types/general.type';
import { useGetBillingHierarchy } from '../useraccesscontrol.component';
import { Disallows } from './common';

export enum PermissionTreeActions {
    allowed = 'allowed',
    disallowed = 'disallowed',
    setPermission = 'setPermission',
    setAllowed = 'setAllowed',
    setDisallowed = 'setDisallowed',
    hierarchy = 'hierarchy',
    setAllPermissions = 'setAllPermissions'
}

export interface PermissionMap {
    allowed?: Partial<Models.UserEntities>;
    disallowed: Disallows;
    hierarchy: { [index: string]: number };
}

export interface PermissionTreeState extends PermissionMap {}

const initialState: PermissionTreeState = {
    allowed: undefined,
    disallowed: {},
    hierarchy: {}
};

const permissionReset = {
    approve: false,
    createUser: false,
    dispute: false,
    editGlCodes: false,
    enterGlCodes: false,
    isAllowed: false,
    manageUser: false,
    pay: false,
    reassignDivision: false,
    viewGlCodes: false
};

const reducer = (state: PermissionTreeState, action: IAction) => {
    let newState = { ...state };

    switch (action.type) {
        case PermissionTreeActions.allowed:
            if (action.payload === undefined) {
                newState.allowed = undefined;
            } else {
                const [entityLevel, entityID] = action.payload.split(' ');
                newState.allowed = { entityLevel, entityID };
            }
            break;

        case PermissionTreeActions.disallowed:
            newState.disallowed = { ...newState.disallowed, ...action.payload };
            break;
        case PermissionTreeActions.setPermission:
            newState.allowed = { ...newState.allowed, ...action.payload };
            break;
        case PermissionTreeActions.setAllowed:
            newState.allowed = action.payload;
            break;
        case PermissionTreeActions.setDisallowed:
            newState.disallowed = action.payload;
            break;
        case PermissionTreeActions.hierarchy:
            newState.hierarchy = action.payload;
            break;
        case PermissionTreeActions.setAllPermissions:
            // add missing permisions if necessary
            newState.allowed = { ...permissionReset, ...newState.allowed };
            Object.keys(newState.allowed).forEach((p) => {
                if (typeof (newState.allowed as IIdx)[p] === 'boolean') {
                    (newState.allowed as IIdx)[p] = action.payload;
                }
            });
            break;
        default:
            return state;
    }
    return newState;
};

const logReducer = loggedReducer<PermissionTreeState, IAction>(reducer, 'PermissionTree');

export const PermissionTreeStateContext: any = React.createContext({});
export const PermissionTreeDispatchContext: any = React.createContext({});
export const PermissionTreeStateRefContext: any = React.createContext({});

export const usePermissionTreeState = (): PermissionTreeState => useContext(PermissionTreeStateContext);
export const usePermissionTreeDispatch = (): ((action: IAction) => PermissionTreeState) => useContext(PermissionTreeDispatchContext);
export const usePermissionTreeStateRef = (): React.MutableRefObject<PermissionTreeState> => useContext(PermissionTreeStateRefContext);

export const PermissionTreeProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(logReducer, initialState);
    const stateRef = useRef<PermissionTreeState>(state);
    const getHierarchy = useGetBillingHierarchy();
    stateRef.current = state;
    const lastAllow = useRef<Partial<Models.UserEntities>>();

    useEffect(() => {
        const alw = lastAllow.current;
        if (alw?.entityID !== state.allowed?.entityID || alw?.entityLevel !== state.allowed?.entityLevel) {
            const id = typeof state.allowed?.entityID === 'string' ? parseInt(state.allowed.entityID) : state.allowed?.entityID;
            dispatch({ type: PermissionTreeActions.hierarchy, payload: getHierarchy(state.allowed?.entityLevel, id) });
        }
        lastAllow.current = state.allowed;
    }, [state.allowed, getHierarchy, lastAllow, dispatch]);

    return (
        <PermissionTreeStateContext.Provider value={state}>
            <PermissionTreeDispatchContext.Provider value={dispatch}>
                <PermissionTreeStateRefContext.Provider value={stateRef}>{children}</PermissionTreeStateRefContext.Provider>
            </PermissionTreeDispatchContext.Provider>
        </PermissionTreeStateContext.Provider>
    );
};
