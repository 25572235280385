import { useRef } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { usePostMessagesBulk } from '../../../../hooks/stiapi.hook';
import { useAuditStateRef } from '../audit.context';
import { useCollabOptions } from '../../../../hooks/usecollaboptions.hook';

export const useHandleAtMentions = (invoice?: string) => {
    const auditStateRef = useAuditStateRef();
    const { postMessagesBulk } = usePostMessagesBulk();
    const tags = useRef<Models.User[]>([]);
    const addTag = (t: Models.User) => {
        tags.current.push(t);
    };

    const { collabOptions } = useCollabOptions(invoice);

    const handleNoteTags = async (newNote: string) => {
        const parts = newNote.split('@');
        const finalUsers: Models.User[] = [];

        const options = (invoice ? collabOptions : auditStateRef.current.collabOptions) ?? [];
        const inv = invoice ?? auditStateRef.current.invoice?.carrierInvoiceID;

        parts.forEach((p) => {
            if (!p) return;

            for (let i = 0; i < tags.current.length; i++) {
                const u = tags.current[i];
                if (p.startsWith(`${u.firstName} ${u.lastName}`)) {
                    if (!finalUsers.find((added) => u.userID === added.userID)) {
                        finalUsers.push(u);
                        return;
                    }
                }
            }
            for (let i = 0; i < options.length; i++) {
                const u = options[i];
                if (p.startsWith(`${u.firstName} ${u.lastName}`)) {
                    if (!finalUsers.find((added) => u.userID === added.userID)) {
                        finalUsers.push(u);
                        return;
                    }
                }
            }
        });

        if (finalUsers.length && inv) {
            const messageRes = await postMessagesBulk(
                inv,
                finalUsers.map((u) => ({ content: newNote, receiverUserID: u.userID }))
            );
            if (messageRes.status === 201) {
                tags.current = [];
            }
        }
    };
    return { addTag, handleNoteTags, collabOptions };
};
