import React, { ReactNode, useState, useCallback, useEffect, useRef } from 'react';
import { ButtonBase, Popper, Grow, styled, ClickAwayListener, ButtonBaseProps } from '@material-ui/core';
import { STITooltip } from '../feedback/stitooltip.component';

// const useRippleStyles = makeStyles((theme) => ({
//     child: {
//         backgroundColor: theme.palette.primary.main,
//     },
// }));

export type ISlideoutId = string | number | undefined;

interface ISlideout extends ButtonBaseProps<'div'> {
    open?: boolean;
    onOpen?: (id: ISlideoutId) => void;
    trigger: ReactNode;
    color?: string;
    tooltipText?: NonNullable<ReactNode>;
    slideId?: string | number;
    tooltipPlacement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined;
    placement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined;
    transformOrigin?: string;
}

export const Slideout: React.FC<ISlideout> = ({
    color,
    open,
    trigger,
    children,
    onOpen,
    tooltipText = '',
    tooltipPlacement,
    slideId,
    placement,
    transformOrigin,
    ...props
}) => {
    // const rippleClasses = useRippleStyles();
    const anchor = useRef(null);

    const [isOpen, setIsOpen] = useState<boolean>(open || false);

    useEffect(() => {
        setIsOpen(open || false);
    }, [open, setIsOpen]);

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen);
        onOpen && onOpen(slideId);
    }, [isOpen, setIsOpen, slideId, onOpen]);

    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <STITooltip title={tooltipText} placement={tooltipPlacement}>
                <ButtonBase component="div" ref={anchor} color={color} onClick={toggleOpen} {...props}>
                    {trigger}
                </ButtonBase>
            </STITooltip>

            <Popper
                open={isOpen}
                anchorEl={anchor.current}
                placement={placement}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window'
                    },
                    offset: {
                        // offset: `0, 16`,
                        // enabled: true,
                    },
                    zIndex: 100
                }}
                transition
                style={{ zIndex: 1250 }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={close}>
                        <Grow {...TransitionProps} timeout={350} style={{ transformOrigin }}>
                            <PopperWrapper>{children}</PopperWrapper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export const PopperWrapper = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    '& > *': {
        margin: '0px',
        boxShadow: theme.shadows[3]
    }
}));
