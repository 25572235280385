import React, { useEffect, useState } from 'react';
import { IBillingEntity, useAuditState } from '../audit.context';
import { ISingleSelectAuto } from '../../../generic/controls/single-selectauto.component';
import { useGetClient } from '../../../../hooks/stiapi.hook';
import { usePermission } from '../../../../hooks/permission/permissions.hook';
import { IconButton, useTheme } from '@material-ui/core';
import { STIModal } from '../../../generic';
import { BillingEntityModal } from '../../modals/billingentitymodal.component';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import { FakeInput } from '../../../generic/containers/fakeinput.component';
import { getBillingEntity } from '../../tables/columncomponents';
import { useAppState } from '../../../../contexts/app.context';

// const useStyles = makeStyles((theme) => ({
//     override: {
//         background: theme.palette.background.paper,
//         width: '100%',
//         '& .MuiFormLabel-root': {
//             transform: `translate(${theme.spacing(1.5)}px, ${theme.spacing(1.25)}px) scale(0.75)`
//         },
//         paddingTop: theme.spacing(3.375),
//         paddingRight: theme.spacing(1.125),
//         paddingBottom: theme.spacing(1.25),
//         paddingLeft: theme.spacing(1),
//         cursor: 'pointer',
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center'
//     },
//     fakeInput: {
//         paddingLeft: theme.spacing(0.5),
//         paddingRight: theme.spacing(0.5)
//     },
//     endAdornment: {
//         overflow: 'visible',
//         position: 'absolute',
//         display: 'flex',
//         alignItems: 'center',
//         top: 0,
//         right: theme.spacing(1.5),
//         height: '100%'
//     }
// }));

export const BillingEntitySelect: React.FC<Partial<ISingleSelectAuto<Partial<IBillingEntity>>>> = (props) => {
    const auditState = useAuditState();
    const appState = useAppState();
    const theme = useTheme();
    const checkPermission = usePermission(auditState.invoice);
    const { getClient } = useGetClient();
    // const auditDispatch = useAuditDispatch();
    // const [ entities, currentBillingEntity ] = useSelector(useAuditState, billingEntitySelectSelector);
    const [currentBillingEntity, setCurrentBillingEntity] = useState<string>();
    useEffect(() => {
        if (auditState.invoice) {
            const entity = getBillingEntity(appState, auditState.invoice);
            if (entity) {
                setCurrentBillingEntity(entity);
            } else {
                const inv = auditState.invoice;
                getClient(inv.clientID).then((res) => {
                    if (res.status === 200) {
                        const c = res.data[0];
                        const co = c && c.companies.find((co) => co.companyID === inv.companyID);
                        if (inv.clientBillingLevel === 'Company') {
                            setCurrentBillingEntity(co?.companyName);
                        }
                        const d = co && co.divisions.find((d) => d.divisionID === inv.divisionID);
                        if (inv.clientBillingLevel === 'Division') {
                            setCurrentBillingEntity(d?.divisionName);
                        }
                        const loc = d && d.locations.find((l) => l.locationID === inv.locationID);
                        if (inv.clientBillingLevel === 'Location') {
                            setCurrentBillingEntity(loc?.locationName);
                        }
                    }
                });
            }
        }
    }, [appState, auditState.invoice, getClient]);

    // const { updateInvoices, loading } = useUpdateInvoices();
    // const classes = useStyles();

    const [open, setOpen] = useState(false);

    // const options: Partial<IBillingEntity>[] = useMemo(() => {
    //     const e: Partial<IBillingEntity>[] = entities.sort((a, b) => a.name.localeCompare(b.name));
    //     return [{ name: 'Unknown Entity' }, ...e];
    // }, [entities]);

    // const onChange = async (event: any, value: any) => {
    //     const inv = auditState.invoice;
    //     if (!inv) return;
    //     if (value) {
    //         const field = billingEntityFieldName[inv.clientBillingLevel];
    //         const res = await updateInvoices([{ carrierInvoiceID: inv.carrierInvoiceID, [field]: value }]);
    //         if (res.status === 200) {
    //             auditDispatch({ type: AuditActions.invoice, payload: res.data.invoices[0] });
    //         }
    //     }
    // };

    const toggleOpen = () => {
        if (checkPermission('reassignDivision')) {
            setOpen(!open);
        }
    };

    return (
        <>
            <FakeInput
                label="Billing Entity"
                pointer
                onClick={toggleOpen}
                endAdornment={
                    checkPermission('reassignDivision') ? (
                        <IconButton>
                            <Icon path={mdiPencil} size={1} color={theme.palette.text.primary} />
                        </IconButton>
                    ) : undefined
                }
            >
                {currentBillingEntity}
            </FakeInput>

            {/* <SingleSelectAuto
                disabled={true}
                {...props}
                className={`${classes.override} ${props.className}`}
                value={currentBillingEntity || options[0]}
                options={options}
                // nullString="Unknown Entity"
                disableNull={false}
                loading={!!loading}
                onValChange={onChange}
                getText={(e) => e.name || ''}
                getValue={(e) => e.id}
            /> */}
            {auditState.invoice && (
                <STIModal open={open} onClose={() => setOpen(false)}>
                    <BillingEntityModal close={() => setOpen(false)} invoice={auditState.invoice} />
                </STIModal>
            )}
        </>
    );
};
