import { useCallback } from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useUserStateRef, useCurrentUser } from '../../contexts/user.context';
import { IIdx } from '../../types/general.type';

export const usePermission = (invDefault?: Models.CarrierInvoices) => {
    // const userState = useUserState();
    const [user] = useCurrentUser();
    const userStateRef = useUserStateRef();

    const checkPermission = useCallback(
        (p?: string, inv?: Models.CarrierInvoices, baseUser?: boolean) => {
            // const userState = userStateRef.current;
            if (baseUser && userStateRef.current.user?.accountType === 1) return true;
            if (user?.accountType === 1) return true;
            const invoice = inv || invDefault;
            if (!p) return false;

            // const levelID = getBillingEntityID(invoice);
            // if (!levelID || userStateRef.current.permissions.disallowed[invoice.clientBillingLevel]?.[levelID]) {
            //     return false;
            // } else {
            return !!(p && (userStateRef.current.permissions.allowed as IIdx)?.[p]);
            // }
        },
        [invDefault, user, userStateRef]
    );

    return checkPermission;
};
