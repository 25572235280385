import React from 'react';

import './styles/index.scss';
import { Switch, Route, RouteProps, Redirect, useLocation, BrowserRouter } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/core';
import { LoginPage } from './pages/login.page';
import { HomePage } from './pages/home.page';
import { AuditPage } from './pages/audit.page';
import { useUserState, TokenValidation, checkTokenValid, UserProvider } from './contexts/user.context';
import { PDFAuditPage } from './pages/auditpdf.page';
import { AppSearchInvoiceHandler } from './mediators/appsearchinvoice.mediator';
import { ManageUsers } from './pages/manageusers.page';
import { AppProvider } from './contexts/app.context';
import { InvoiceProvider } from './contexts/invoice.context';
import { PDFPage } from './pages/pdf.page';
import { MessageListener } from './mediators/messageListener.mediator';
import { ResetLoginPage } from './pages/resetforgottenpassword.component';
import { SnackbarProvider } from 'notistack';
import { InfoRequestProvider } from './hooks/inforequestmodal.hook';

const App: React.FC = () => {
    const userState = useUserState();
    const location = useLocation();

    return (
        <StyledApp>
            {/* {userState.alias?.user ? <AppWarningBorder /> : null} */}
            <Switch>
                <Route
                    path="/login"
                    exact
                    render={() => (userState.token ? <Redirect to={{ pathname: (location.state as any)?.from?.pathname || '/' }} /> : <LoginPage />)}
                />
                <Route path="/resetlogin" exact>
                    <ResetLoginPage useCode />
                </Route>
                <Route path="/createlogin" exact>
                    <ResetLoginPage useToken />
                </Route>
                <Route path="/changelogin" exact>
                    <ResetLoginPage useOldPassword />
                </Route>
                <PrivateRoute path="/">
                    <AppProvider>
                        <InfoRequestProvider>
                            <Switch>
                                <Route path="/pdf/:carrierCode/:invoiceNumber" exact>
                                    <PDFPage />
                                </Route>
                                <>
                                    <MessageListener />
                                    <Route path="/users">
                                        <ManageUsers />
                                    </Route>
                                    <Route path="/">
                                        <InvoiceProvider>
                                            <AppSearchInvoiceHandler>
                                                <Switch>
                                                    <Route path="/audit/pdf/:carrierCode/:invoiceNumber" exact>
                                                        <PDFAuditPage />
                                                    </Route>
                                                    <Route path="/audit/:carrierInvoiceID?" exact>
                                                        <AuditPage />
                                                    </Route>
                                                    <Route path="/">
                                                        <HomePage />
                                                    </Route>
                                                </Switch>
                                            </AppSearchInvoiceHandler>
                                        </InvoiceProvider>
                                    </Route>
                                </>
                            </Switch>
                        </InfoRequestProvider>
                    </AppProvider>
                </PrivateRoute>
            </Switch>
        </StyledApp>
    );
};

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
    const userState = useUserState();

    const check = checkTokenValid();
    return (
        <Route
            {...props}
            render={({ location }) => {
                if (check !== TokenValidation.valid) return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
                else if (userState.user?.forcePassword) return <Redirect to={{ pathname: '/changelogin', state: { from: location } }} />;
                else return children;
            }}
        />
    );
};

const useSnackStyles = makeStyles((theme) => ({
    containerRoot: {
        right: theme.spacing(24),
        transform: 'none'
    },
    root: {
        justifyContent: 'flex-end'
    }
    // collapseWrapper: {
    //     marginBottom: theme.spacing(12)
    // }
}));

export default () => {
    const classes = useSnackStyles();
    return (
        <BrowserRouter>
            <SnackbarProvider maxSnack={2} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} classes={classes as any}>
                <UserProvider>
                    <App />
                </UserProvider>
            </SnackbarProvider>
        </BrowserRouter>
    );
};

const StyledApp = styled('div')({
    width: '100%',
    height: '100%',
    position: 'relative'
});

const AppWarningBorder = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    border: `4px solid ${theme.palette.error.main}AA`,
    zIndex: 99999,
    pointerEvents: 'none'
}));
function useUserContext(): [any] {
    throw new Error('Function not implemented.');
}
