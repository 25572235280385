import { makeStyles, useTheme, ListItemText } from '@material-ui/core';
import { mdiEmailOffOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import React from 'react';
import { Models } from 'shipmenttrackers-domain/dist';
import { useAppState } from '../../../contexts/app.context';

const useStyles = makeStyles((theme) => ({
    textField: {
        fontFamily: "'Open Sans', sans-serif"
    },
    listItem: {
        position: 'relative',
        width: '100%'
    },
    emailOff: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.error.main
    },
    dot: {
        borderRadius: '999px',
        position: 'absolute',
        top: '12%',
        left: theme.spacing(-1.25),
        width: theme.spacing(1),
        height: theme.spacing(1)
    }
}));

export const UserListItem: React.FC<{ user: Partial<Models.User> }> = ({ user }) => {
    const theme = useTheme();
    const classes = useStyles();
    const appState = useAppState();

    const online = appState.loggedInUsers.find((u) => u.userID === user.userID);

    return (
        <div className={classes.listItem}>
            {online && <div className={classes.dot} style={{ backgroundColor: theme.palette.secondary.main }} />}
            <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
            {!user.canEmail && <Icon path={mdiEmailOffOutline} size={0.5} color={theme.palette.error.main} className={classes.emailOff} />}
        </div>
    );
};
