import React, { useCallback } from 'react';
import { useAuditState, useAuditDispatch, AuditActions } from '../audit.context';
import { killEvt } from '../../../../helpers';
import { GLRow } from './glrow.component';
import { useGLStyles } from './common';
import { usePermission } from '../../../../hooks/permission/permissions.hook';
import { Permission } from '../../../../types/contexts/user-context.type';

export const GLRows: React.FC<{ max: number; availableCodesByPosition: string[][] }> = ({ max, availableCodesByPosition }) => {
    const auditState = useAuditState();
    const { glcoding: codings, availableCodes } = auditState;
    const classes = useGLStyles();
    const checkPermission = usePermission();
    const auditDispatch = useAuditDispatch();

    const canEnter = checkPermission(Permission.enterGlCodes);
    const canEdit = checkPermission(Permission.editGlCodes);

    const onNewRow = useCallback(
        (evt: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (evt.keyCode === 9 || evt.keyCode === 13) {
                killEvt(evt);
                auditDispatch({ type: AuditActions.addGLRemaining });
            }
        },
        [auditDispatch]
    );

    return (
        <>
            {codings.map((gl, i) => (
                <GLRow
                    inputClass={classes.input}
                    key={`GL ${gl.key}`}
                    rowNum={i}
                    onTab={i === codings.length - 1 ? onNewRow : undefined}
                    glcoding={gl}
                    availableGLs={availableCodesByPosition}
                    canEnter={canEnter}
                    canEdit={canEdit}
                    colCount={max}
                />
            ))}
        </>
    );
};
