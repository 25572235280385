import { styled, Grid } from '@material-ui/core';

export const SummaryContainer = styled(Grid)(({ theme }) => ({
    height: '100%',
    textTransform: 'uppercase',
    '& > *': {
        background: theme.palette.common.white,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        minWidth: 'fit-content',
        maxWidth: '100%',
        '&:not(:last-child)': {
            borderRight: `1px solid ${theme.palette.primary.dark}`
        }
    }
}));
