import React from 'react';
import { SortableHandle, SortableElement } from 'react-sortable-hoc';
import { Typography, MenuItem, styled, TableCellProps, TableCell, Paper, makeStyles, Divider } from '@material-ui/core';
import { IColumn, DataTableActions, IDataTableState, IColWidth, ISortOption } from '../datatable.types';
import { memo, useCallback, useRef, useState, useMemo, forwardRef } from 'react';
import { useDataTableStateRef, useDataTableDispatch, useDataTableState } from '../datatable.context';
import { ResizeCallbackData, ResizableBox, ResizableProps } from 'react-resizable';
import { killEvt } from '../../../helpers';
import { STIPopper } from '../../generic/containers/stipopper.component';
import { STITooltip } from '../../generic';
import { useSelector } from '../../../contexts/store.helpers';
import { Icon } from '@mdi/react';
import { mdiCheck } from '@mdi/js';

interface IHeaderCell {
    sortable?: boolean;
    resizable?: boolean;
    column: IColumn;
    colIdx: number;
    height: number;
    menuClasses?: any;
    cellClassName?: string;
    // initialWidth?: number;
    // minWidth?: number;
    // maxWidth?: number;
}

export const HeaderCell: React.FC<IHeaderCell> = memo(({ height, column, colIdx, sortable, resizable, menuClasses, cellClassName, ...props }) => {
    const state = useDataTableState();
    const colWidth = state.colWidths?.[column.key] ?? { initialWidth: 90 };
    const { sort } = state;

    const dataTableDispatch = useDataTableDispatch();
    const dataTableStateRef = useDataTableStateRef();

    const menuAnchor = useRef<HTMLDivElement>(null);

    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const key = column.key;

    const onResize = useCallback(
        (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
            dataTableDispatch({ type: DataTableActions.colWidth, colKey: key, payload: data.size.width + 18 });
        },
        [dataTableDispatch, key]
    );

    const handleSort = useCallback(
        (evt) => {
            evt.stopPropagation();
            const sort = dataTableStateRef.current.sort;

            if (sort[0]?.key === key) {
                const newSort: ISortOption[] = [...sort];
                newSort[0].direction = newSort[0].direction === 'asc' ? 'desc' : 'asc';
                dataTableDispatch({ type: DataTableActions.sort, payload: newSort });
            } else {
                const newSort: ISortOption[] = [{ key, direction: 'desc' }, ...sort.filter((s) => s.key !== key)];
                dataTableDispatch({ type: DataTableActions.sort, payload: newSort });
            }
        },
        [dataTableDispatch, dataTableStateRef, key]
    );

    const handleMenu = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            setOpenMenu(!openMenu);
        },
        [openMenu]
    );

    const cell = useMemo(() => {
        const sortIdx = sort.findIndex((s) => s.key === column.key);
        const s = sort[sortIdx];

        return (
            <Resizecell
                resizable={resizable}
                ref={menuAnchor}
                key={key}
                sortDirection={sortIdx < 2 ? s?.direction : undefined}
                isSortSecondary={sortIdx === 1}
                width={colWidth?.width || colWidth?.initialWidth || 90}
                sticky={colWidth?.sticky}
                onDrag={killEvt}
                onResize={onResize}
                height={height}
                className={cellClassName}
                minConstraints={[colWidth?.min !== undefined ? colWidth.min : 4, 0]}
                maxConstraints={[colWidth?.max !== undefined ? colWidth.max - 18 : Infinity, Infinity]}
                style={{ zIndex: 250 - colIdx }}
            >
                <STITooltip title={column.label} placement="top">
                    <HeaderLabel onClick={handleSort} onContextMenu={handleMenu}>
                        <Typography noWrap align="center" variant="overline">
                            {column.label}
                        </Typography>
                    </HeaderLabel>
                </STITooltip>
            </Resizecell>
        );
    }, [resizable, key, sort, onResize, height, column, handleSort, handleMenu, cellClassName, colWidth, colIdx]);

    const menu = useMemo(
        () => (
            <STIPopper
                open={openMenu}
                anchorEl={menuAnchor.current}
                placement="bottom"
                close={() => {
                    setOpenMenu(false);
                }}
            >
                <Paper>
                    <MenuItem
                        key={'sticky'}
                        onClick={() => {
                            dataTableDispatch({ type: 'toggleSticky', colKey: column.key, payload: undefined });
                            setOpenMenu(false);
                        }}
                    >
                        <Icon size={1} path={mdiCheck} color={colWidth.sticky !== undefined ? 'currentColor' : 'rgba(0,0,0,.25)'} />
                        &nbsp;Sticky
                    </MenuItem>
                    {column.menu ? <Divider /> : null}
                    {column.menu?.map((m, i) => (
                        <MenuItem onClick={m.onClick} key={i} className={menuClasses?.menuItem || ''}>
                            {m.text}
                        </MenuItem>
                    ))}
                </Paper>
            </STIPopper>
        ),
        [openMenu, colWidth.sticky, column.menu, column.key, dataTableDispatch, menuClasses]
    );

    return sortable ? (
        <SortableCell index={colIdx} key={key}>
            {cell}
            {menu}
        </SortableCell>
    ) : (
        <>
            {cell}
            {menu}
        </>
    );
});

const HeaderLabel = SortableHandle(
    styled('div')({
        display: 'flex',
        minWidth: '0px',
        width: '100%',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center'
    })
);

const SortableCell = SortableElement(({ children }: { children: any }) => {
    return <>{children}</>;
});

const Resizer = styled(ResizableBox)({
    display: 'flex',
    flexWrap: 'nowrap',
    minWidth: '100%',
    padding: '0px'
});

const ResizeHandle = styled('div')({
    top: '0px',
    right: '-8px',
    height: '100%',
    width: '16px',
    position: 'absolute',
    cursor: 'e-resize',
    zIndex: 2
});

const useCellStyles = makeStyles((theme) => ({
    cell: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 8px',
        alignItems: 'center',
        overflowY: 'visible',
        '&::after': {
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            content: '" "',
            display: 'block',
            borderTopWidth: '6px',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.common.white,
            position: 'absolute',
            opacity: '.75'
        }
    },
    asc: {
        '&::after': {
            bottom: '0px',
            transform: 'rotate(180deg)'
        }
    },
    desc: {
        '&::after': {
            top: '0px'
        }
    },
    noAfter: {
        '&::after': {
            display: 'none'
        }
    },
    secondary: {
        '&::after': {
            opacity: '.375'
        }
    }
}));

const Resizecell: React.FC<Partial<TableCellProps & ResizableProps & { resizable: boolean; isSortSecondary: boolean; sticky?: number }>> = forwardRef(
    (
        { sortDirection, isSortSecondary, onResize, children, resizable, className, minConstraints, maxConstraints, width, height, sticky, style, ...props },
        ref
    ) => {
        const cellClasses = useCellStyles();
        return (
            <TableCell
                onMouseDown={killEvt}
                sortDirection={sortDirection}
                component="div"
                className={`${className} ${cellClasses.cell} ${
                    sortDirection === 'asc' ? cellClasses.asc : sortDirection === 'desc' ? cellClasses.desc : cellClasses.noAfter
                } ${isSortSecondary ? cellClasses.secondary : ''}`}
                style={{ height: `${height}px`, ...(sticky !== undefined ? { position: 'sticky', left: sticky + 'px' } : {}), ...style }}
                ref={ref}
                {...props}
                // align={column.align}
            >
                {resizable ? (
                    <Resizer
                        axis="x"
                        resizeHandles={['e']}
                        width={(width || 18) - 18}
                        height={height || 40}
                        minConstraints={minConstraints || [4, 0]}
                        maxConstraints={maxConstraints || [Infinity, Infinity]}
                        handle={<ResizeHandle />}
                        onResize={onResize}
                    >
                        {children}
                    </Resizer>
                ) : (
                    children
                )}
            </TableCell>
        );
    }
);
