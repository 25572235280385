import React, { useEffect, useRef } from 'react';
import { useStateWithRef } from '../../hooks/statewithref.hook';
import { PDFDisplay } from './pdfdisplay.component';

declare global {
    interface Window {
        PDFViewerApplication: any;
    }
}

const defaultPdfArgs = [
    'https://www.americanexpress.com/content/dam/amex/us/staticassets/pdf/GCO/Test_PDF.pdf',
    {
        httpHeaders: {
            responsetype: 'application/pdf'
        }
    }
];

export const PDFViewer: React.FC<{ data: any; onLoad?: () => void }> = ({ data, onLoad }) => {
    const [loaded, setLoaded, loadedRef] = useStateWithRef<boolean>(false);

    const onLoadRef = useRef(onLoad);
    onLoadRef.current = onLoad;

    // useEffect(() => {
    //     window.addEventListener('webviewerloaded', () => setLoaded(true));
    // }, [setLoaded]);

    useEffect(() => {
        if (!loadedRef.current) {
            const loadViewer = () => {
                const viewer = document.createElement('script');
                viewer.src = `${process.env.PUBLIC_URL}/pdf/web/viewer.js`;
                viewer.onload = () => {
                    window.PDFViewerApplication.initializedPromise.then(() => {
                        // console.log('initialized');
                        setLoaded(true);
                        window.PDFViewerApplication.isViewerEmbedded = true;
                        window.PDFViewerApplication.eventBus.on('pagesloaded', () => onLoadRef.current?.());
                    });
                };
                document.head.appendChild(viewer);
            };

            const loadWorker = () => {
                const worker = document.createElement('script');
                worker.src = `${process.env.PUBLIC_URL}/pdf/build/pdf.worker.js`;
                worker.onload = loadViewer;
                document.head.appendChild(worker);
            };
            loadWorker();
        }
    }, [setLoaded, loadedRef, onLoadRef]);

    useEffect(() => {
        if (loaded) {
            // console.log('loaded');
            data ? window.PDFViewerApplication.open(data || ' ').catch(console.log) : window.PDFViewerApplication.close();
            // data ?
            // window.PDFViewerApplication.open(...(data ? [data] : defaultPdfArgs)).catch(console.log);
            // : window.PDFViewerApplication.close();
        }
    }, [data, loaded]);

    return <PDFDisplay />;
};
