import React, { useState, useEffect, useMemo } from 'react';
import { useGetUsers, useLoginAs } from '../../../hooks/stiapi.hook';
import { Scroller } from '../../generic';
import { Models } from 'shipmenttrackers-domain/dist';
import { List, ListItem, ListItemText, ButtonBase, TextField, makeStyles } from '@material-ui/core';
import { UserActions, useUserDispatch, useUserState } from '../../../contexts/user.context';
import { mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1.5),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        minHeight: 0,
        gap: theme.spacing(1) + 'px'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        gap: theme.spacing(1) + 'px',
        flexGrow: 0,
        flexShrink: 0
    },
    magnify: {
        opacity: 0.6,
        fontSize: 'large'
    },
    listContainer: {
        overflow: 'auto',
        flexGrow: 1,
        flexShrink: 1
    },
    clearButton: {
        padding: theme.spacing(1),
        opacity: 0.6
    }
}));

export const UserAlias: React.FC = () => {
    const classes = useStyles();
    const { getUsers } = useGetUsers();
    const [users, setUsers] = useState<Models.User[]>([]);
    const { loginAs } = useLoginAs();
    const userDispatch = useUserDispatch();
    const userState = useUserState();

    useEffect(() => {
        getUsers(true).then((res) => {
            const users = res.data;
            if (!users) return;
            users.sort((a, b) => (a.firstName ?? '').localeCompare(b.firstName ?? '') || (a.lastName ?? '').localeCompare(b.lastName ?? ''));
            setUsers(users);
        });
    }, [getUsers]);

    const onSelect = (id: string) => async () => {
        const res = await loginAs(id);
        userDispatch({ type: UserActions.loginAlias, payload: res.data.Data.token });
    };

    const [searchInput, setSearchInput] = useState('');

    const displayUsers = useMemo(() => {
        return searchInput ? users.filter((u) => `${u.firstName} ${u.lastName} ${u.email}`.toLowerCase().includes(searchInput.toLowerCase())) : users;
    }, [searchInput, users]);

    return (
        // <Scroller vertical>
        <div className={classes.root}>
            <div className={classes.inputContainer}>
                <Icon className={classes.magnify} path={mdiMagnify} size={1.5} />
                <TextField label="Find User" fullWidth variant="standard" value={searchInput} onChange={(evt) => setSearchInput(evt.target.value)} />
                <ButtonBase className={classes.clearButton} onClick={() => setSearchInput('')}>
                    <Icon size={1} path={mdiClose} />
                </ButtonBase>
            </div>

            <List className={classes.listContainer}>
                {displayUsers.map((u) => (
                    <ListItem key={u.userID} button onClick={onSelect(u.userID)} selected={userState.alias?.user?.userID === u.userID}>
                        <ListItemText primary={`${u.firstName} ${u.lastName}`} secondary={u.email} />
                    </ListItem>
                ))}
            </List>
            {userState.alias?.user ? (
                <ExtendedButton fullWidth size="small" color="error" onClick={() => userDispatch({ type: UserActions.logoutAlias })}>
                    End Alias Session
                </ExtendedButton>
            ) : null}
        </div>
        // </Scroller>
    );
};
