import React, { useContext, useReducer, useRef, useEffect, useCallback } from 'react';
import { IAction } from '../types/contexts/context.type';
import { loggedReducer, StorageKeys } from './store.helpers';
import {
    IAppState,
    AppActions,
    SearchType,
    AppFilterActions,
    AppDataActions,
    ClientWId,
    CompanyWId,
    DivisionWId,
    LocationWId,
    CarrierAccountWId,
    IViewMode,
    EntitiesById,
    StoredTableDef,
    IAppFilter,
    KeysToInit
} from '../types/contexts/app-context.type';

import {
    useGetClients,
    useGetAuditStatusOptions,
    useGetInvoiceStatusOptions,
    useGetAdjustmentTypes,
    useGetAdjustmentCategories,
    useGetInvoiceSummary
} from '../hooks/stiapi.hook';
import { Models } from 'shipmenttrackers-domain/dist';
import { getDefaultColumnWidths } from '../components/layout/tables/common';
import { usePermission } from '../hooks/permission/permissions.hook';
import { getStoredItem, setStoredItem } from './localstorage';
import { defaultHomeTableColumns, auditColumns, payColumns, reviewColumns } from '../components/layout/tables/columncomponents';
import { IDataTableState } from '../components/datatable/datatable.types';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { subMonths, subYears } from 'date-fns';
import { IIdx } from '../types/general.type';
import { useInvoiceStateRef } from './invoice.context';

export const noActions: string[] = [
    'carrierCode',
    'divisionID',
    'carrierAccount',
    'payer',
    'invoiceNumber',
    'pdf',
    'currentAmt',
    'invoiceAmtDue',
    'adjustedAmt',
    'invoiceDate',
    'invoiceDueDate',
    'invoiceStatus',
    'invoicePaidAmt'
];

export const amounts: string[] = [
    'carrierCode',
    'divisionID',
    'carrierAccount',
    'payer',
    'invoiceNumber',
    'currentAmt',
    'invoiceAmtDue',
    'adjustedAmt',
    'invoicePaidAmt'
];

const getStiTableDefs: () => { [index: string]: IViewMode } = () => ({
    default: {
        title: 'Home',
        key: 'default',
        desc: 'View all invoices with no filters applied',
        columns: defaultHomeTableColumns as string[],
        widths: getDefaultColumnWidths(),
        sort: [],
        filter: {
            startInvDate: startOfDay(subMonths(new Date(Date.now()), 3)),
            exactIds: false
        }
    },
    audit: {
        title: 'Audit Mode',
        sti: true,
        desc: 'View unaudited and pending audit status invoices for audit processing',
        key: 'audit',
        columns: auditColumns,
        widths: getDefaultColumnWidths(),
        auditStatus: {
            statuses: ['Unaudited', 'Pending ST Review', 'Pending Client Review', 'Pending Carrier Review'],
            order: ['Unaudited', 'Pending ST Review', 'Pending Client Review', 'Pending Carrier Review']
        },
        sort: [
            { key: 'billingEntity', direction: 'asc' },
            { key: 'auditStatus', direction: 'desc' },
            { key: 'invoiceDate', direction: 'asc' }
        ],
        filter: {
            startInvDate: undefined,
            endInvDate: undefined,
            startDueDate: undefined,
            endDueDate: undefined,
            exactIds: false
        },
        disable: {
            startInvDate: true,
            endInvDate: true,
            startDueDate: true,
            endDueDate: true
        }
    },
    pay: {
        title: 'Pay Mode',
        sti: true,
        desc: 'View all approved non-paid invoices for payment processing',
        key: 'pay',
        columns: payColumns,
        widths: getDefaultColumnWidths(),
        invoiceStatus: {
            statuses: ['Allocated/Requested', 'Open', 'Past Due', 'Funds Received']
            // invert: true
        },
        sort: [
            { key: 'billingEntity', direction: 'asc' },
            { key: 'carrierCode', direction: 'asc' },
            { key: 'invoiceDate', direction: 'asc' }
        ],
        filter: {
            startInvDate: startOfDay(subYears(new Date(Date.now()), 1)),
            exactIds: true
        }
    },
    review: {
        title: 'Review',
        desc: 'View audited, non-approved invoices for approval processing',
        key: 'review',
        columns: reviewColumns,
        widths: getDefaultColumnWidths(),
        auditStatus: {
            statuses: ['Audited', 'Non-Audit', 'Pending Client Review']
        },
        sort: [
            { key: 'approve', direction: 'asc' },
            { key: 'dispute', direction: 'asc' }
        ],
        filter: {
            startInvDate: undefined,
            endInvDate: undefined,
            startDueDate: undefined,
            endDueDate: undefined,
            exactIds: false
        },
        forceFilter: {
            approve: false
        },
        disable: {
            startInvDate: true,
            endInvDate: true,
            startDueDate: true,
            endDueDate: true
        }
    }
});

const getTableDefs: () => { [index: string]: IViewMode } = () => ({
    default: {
        title: 'Home',
        key: 'default',
        desc: 'View all invoices with no filters applied',
        columns: defaultHomeTableColumns as string[],
        widths: getDefaultColumnWidths(),
        auditStatus: {
            statuses: ['Audited', 'Non-Audit', 'Pending Client Review']
        },
        sort: []
    },
    review: {
        title: 'Review',
        key: 'review',
        desc: 'View audited, non-approved invoices for approval processing',
        columns: reviewColumns,
        widths: getDefaultColumnWidths(),
        auditStatus: {
            statuses: ['Audited', 'Non-Audit', 'Pending Client Review']
        },
        sort: [
            { key: 'approve', direction: 'asc' },
            { key: 'dispute', direction: 'asc' }
        ],
        filter: {
            startInvDate: undefined,
            endInvDate: undefined,
            startDueDate: undefined,
            endDueDate: undefined
        },
        disable: {
            startInvDate: true,
            endInvDate: true,
            startDueDate: true,
            endDueDate: true
        },
        forceFilter: {
            approve: false
        }
    }
});

const initialState: IAppState = {
    [AppActions.showInvConf]: false,
    [AppActions.searchType]: SearchType.division,
    [AppActions.invoiceCountAll]: 0,
    [AppActions.invoiceSumAll]: 0,
    [AppActions.invoiceCountFiltered]: 0,
    [AppActions.invoiceSumFiltered]: 0,
    [AppActions.homeTableMode]: 'default',

    data: {
        [AppDataActions.clients]: [],
        [AppDataActions.clientsAll]: [],
        [AppDataActions.companies]: [],
        [AppDataActions.carriers]: [],
        [AppDataActions.tableDefs]: getTableDefs(),
        [AppDataActions.auditStatusOptions]: [],
        [AppDataActions.invoiceStatusOptions]: [],
        [AppDataActions.adjustmentTypes]: [],
        [AppDataActions.adjustmentCategories]: [],
        entitiesById: {
            clients: {},
            companies: {},
            divisions: {},
            locations: {},
            carriers: {}
        }
    },
    filter: {
        [AppFilterActions.invoiceIDS]: [],
        [AppFilterActions.minInv]: undefined,
        [AppFilterActions.maxInv]: undefined,
        [AppFilterActions.startInvDate]: undefined,
        [AppFilterActions.endInvDate]: undefined,
        [AppFilterActions.startDueDate]: undefined,
        [AppFilterActions.endDueDate]: undefined,
        [AppFilterActions.remittanceNumbers]: [],
        [AppFilterActions.disbursementInvoiceIDS]: [],
        [AppFilterActions.paymentConfirmationIDS]: [],
        [AppFilterActions.auditStatus]: [],
        [AppFilterActions.invoiceStatus]: [],
        [AppFilterActions.autofill]: true,
        [AppFilterActions.linkIds]: false
    },
    [AppActions.disabledFilters]: {},
    [AppActions.filterIDQuery]: {},
    [AppActions.dataTableState]: {},
    [AppActions.messages]: [],
    [AppActions.loggedInUsers]: [],
    [AppActions.initializing]: new Set<KeysToInit>([
        AppDataActions.clients,
        AppDataActions.companies,
        AppDataActions.carriers,
        AppActions.invoiceCountAll,
        AppActions.invoiceSumAll,
        AppActions.invoiceCountFiltered,
        AppActions.invoiceSumFiltered
    ])
};

const reducer = (state: IAppState, action: IAction) => {
    const newState = action.storeOnly ? state : { ...state };
    switch (action.type) {
        case AppActions.showInvConf:
            newState[action.type] = action.payload;
            break;

        case AppActions.updateMessages:
            newState.messages = [...newState.messages];
            action.payload.forEach((m: Models.Messages) => {
                const idx = newState.messages.findIndex((msg) => msg.rowID === m.rowID);
                newState.messages[idx] = { ...newState.messages, ...m };
            });
            break;

        case AppActions.newMessages:
            newState.messages = [...action.payload, ...newState.messages];
            break;
        case AppActions.loggedInUsers:
            //server sends multiple times
            const changed =
                newState.loggedInUsers.length !== action.payload.length || newState.loggedInUsers.find((l, i) => l.userID !== action.payload[i].userID);
            if (changed) {
                newState[action.type] = action.payload;
            } else {
                return state;
            }
            break;
        case AppActions.updateDataTableState:
            newState.dataTableState = { ...newState.dataTableState, ...action.payload };
            saveTableLayout(newState.homeTableMode, newState.dataTableState);
            break;
        case AppActions.dataTableState:
            newState[action.type] = action.payload;
            saveTableLayout(newState.homeTableMode, action.payload);
            break;
        case AppActions.storeDataTableState:
            {
                const curr = newState.homeTableMode;
                const def = newState.data.tableDefs[curr];
                const tState = newState.dataTableState;
                if (def) {
                    def.columns =
                        tState.columns?.reduce((acc: string[], c) => {
                            if (c.active) {
                                acc.push(c.key);
                            }
                            return acc;
                        }, []) || def.columns;
                }
                def.widths = tState.colWidths ? { ...tState.colWidths } : def.widths;
                def.sort = tState.sort || [];
                saveTableLayout(def.key, tState, true);
            }
            break;
        case AppActions.messages:
            newState[action.type] = action.payload;
            break;
        case AppActions.invoiceCountAll:
        case AppActions.invoiceCountFiltered:
        case AppActions.invoiceSumAll:
        case AppActions.invoiceSumFiltered:
            newState[action.type] = action.payload || 0;
            newState.initializing.delete(action.type);
            newState.initializing = new Set(newState.initializing);
            break;
        case AppActions.filterIDQuery:
            newState[action.type] = action.payload;
            break;

        case AppActions.homeTableMode:
            // Save current first
            {
                const curr = newState.homeTableMode;
                const def = newState.data.tableDefs[curr];
                const tState = newState.dataTableState;
                if (def) {
                    def.columns =
                        tState.columns?.reduce((acc: string[], c) => {
                            if (c.active) {
                                acc.push(c.key);
                            }
                            return acc;
                        }, []) || def.columns;
                }
                def.widths = tState.colWidths ? { ...tState.colWidths } : def.widths;
                def.sort = tState.sort || [];
                saveTableLayout(def.key, tState, true);

                Object.keys(def.filter ?? {}).forEach((k) => {
                    (def.filter as IIdx)[k] = (newState.filter as IIdx)[k];
                });
            }

            // apply new
            {
                const mode = newState.data.tableDefs[action.payload] ? action.payload : 'default';
                newState[action.type] = mode;
                const tableDef = newState.data.tableDefs[mode];

                let auditStatuses: Models.IpAuditStatus[] | undefined = [];

                if (tableDef.auditStatus?.invert) {
                    auditStatuses = newState.data.auditStatusOptions.filter((s) => !tableDef.auditStatus?.statuses.includes(s.auditStatus));
                } else {
                    auditStatuses = tableDef.auditStatus?.statuses.reduce((acc: Models.IpAuditStatus[], sts) => {
                        const s = newState.data.auditStatusOptions.find((o) => o.auditStatus === sts);
                        if (s) {
                            acc.push(s);
                        }
                        return acc;
                    }, []);
                }
                let invoiceStatuses: Models.IpInvoiceStatus[] | undefined = [];
                if (tableDef.invoiceStatus?.invert) {
                    invoiceStatuses = newState.data.invoiceStatusOptions.filter((s) => !tableDef.invoiceStatus?.statuses.includes(s.invoiceStatus));
                } else {
                    invoiceStatuses = tableDef.invoiceStatus?.statuses.reduce((acc: Models.IpInvoiceStatus[], sts) => {
                        const s = newState.data.invoiceStatusOptions.find((o) => o.invoiceStatus === sts);
                        if (s) {
                            acc.push(s);
                        }
                        return acc;
                    }, []);
                }
                newState.filter.otherfilters = tableDef.forceFilter;

                newState.filter = { ...newState.filter, ...tableDef.filter };
                newState.filter.auditStatus = auditStatuses || [];
                newState.filter.invoiceStatus = invoiceStatuses || [];
                newState.disabledFilters = { ...tableDef.disable };
                break;
            }
        case AppActions.searchType:
            newState[action.type] = action.payload;
            break;

        case AppDataActions.clients:
            const clients = hydrateClientIds(action.payload);
            const companies: CompanyWId[] = clients.reduce((acc: CompanyWId[], c: ClientWId) => {
                acc = acc.concat(c.companies);
                return acc;
            }, []);

            newState.data = { ...newState.data, clients, companies, entitiesById: { ...newState.data.entitiesById, ...entitiesbyIdFromClient(clients) } };
            adjustFilter(newState);
            newState.initializing.delete(AppDataActions.clients);
            newState.initializing.delete(AppDataActions.companies);
            newState.initializing = new Set(newState.initializing);
            break;
        case AppDataActions.companies:
            const cos = hydrateCompanyIds(action.payload);
            newState.data = { ...newState.data, companies: cos, entitiesById: { ...newState.data.entitiesById, ...entitiesbyIdFromCompany(cos) } };
            newState.initializing.delete(AppDataActions.clients);
            newState.initializing.delete(AppDataActions.companies);
            newState.initializing = new Set(newState.initializing);
            break;

        case AppDataActions.carriers:
            newState.data = { ...newState.data, [action.type]: action.payload };
            newState.data.entitiesById = {
                ...newState.data.entitiesById,
                carriers: action.payload.reduce((acc: { [index: number]: Models.Carrier }, c: Models.Carrier) => {
                    acc[c.carrierID] = c;
                    return acc;
                }, {})
            };
            newState.initializing.delete(AppDataActions.carriers);
            newState.initializing = new Set(newState.initializing);
            break;

        case AppDataActions.tableDefs:
            newState.data.tableDefs = { ...newState.data.tableDefs, ...action.payload };
            break;

        case AppDataActions.auditStatusOptions:
            {
                const options: Models.IpAuditStatus[] = action.payload;
                newState.data = { ...newState.data, [action.type]: options };
                newState.data.tableDefs = { ...newState.data.tableDefs };
                if (newState.data.tableDefs.audit) {
                    newState.data.tableDefs.audit = { ...newState.data.tableDefs.audit };
                    newState.data.tableDefs.audit.auditStatus = {
                        ...newState.data.tableDefs.audit.auditStatus,
                        statuses: options.filter((o) => !!o.auditMode).map((o) => o.auditStatus)
                    };
                }
                if (newState.data.tableDefs.review) {
                    newState.data.tableDefs.review = { ...newState.data.tableDefs.review };
                    newState.data.tableDefs.review.auditStatus = {
                        ...newState.data.tableDefs.review.auditStatus,
                        statuses: options.filter((o) => !!o.reviewMode).map((o) => o.auditStatus)
                    };
                }
                if (newState.data.tableDefs.pay) {
                    newState.data.tableDefs.pay = { ...newState.data.tableDefs.pay };
                    newState.data.tableDefs.pay.auditStatus = {
                        ...newState.data.tableDefs.pay.auditStatus,
                        statuses: options.filter((o) => !!o.payMode).map((o) => o.auditStatus)
                    };
                }
            }
            break;
        case AppDataActions.invoiceStatusOptions:
            {
                const options: Models.IpInvoiceStatus[] = action.payload;
                newState.data = { ...newState.data, [action.type]: options };
                newState.data.tableDefs = { ...newState.data.tableDefs };
                if (newState.data.tableDefs.pay) {
                    newState.data.tableDefs.pay = { ...newState.data.tableDefs.pay };
                    newState.data.tableDefs.pay.invoiceStatus = {
                        ...newState.data.tableDefs.pay.invoiceStatus,
                        statuses: options.filter((o) => !!o.payMode).map((o) => o.invoiceStatus)
                    };
                }
            }
            break;
        case AppDataActions.adjustmentTypes: {
            const items: Models.AdjustmentTypes[] = action.payload;
            newState.data = {
                ...newState.data,
                [action.type]: items.filter((c) => !!c.appViewable).sort((a, b) => a.appOrder - b.appOrder)
            };
            break;
        }
        case AppDataActions.adjustmentCategories: {
            const items: Models.AdjustmentCategory[] = action.payload;
            newState.data = {
                ...newState.data,
                [action.type]: items.filter((c) => !!c.appViewable).sort((a, b) => a.appOrder - b.appOrder)
            };
            break;
        }
        case AppFilterActions.client:
            newState.filter = { ...newState.filter, client: action.payload };
            if (action.payload) {
                const companies = action.payload.companies;
                newState.filter.company = companies.length === 1 ? companies[0] : undefined;
            } else {
                newState.filter.company = undefined;
            }

            adjustFilter(newState);
            break;

        case AppFilterActions.company:
            newState.filter = { ...newState.filter, company: action.payload };
            adjustFilterToCompany(newState);
            adjustFilter(newState);
            break;

        case AppFilterActions.division:
            newState.filter = {
                ...newState.filter,
                [action.type]: action.payload
            };
            adjustFilterToDivision(newState);
            adjustFilter(newState);
            break;
        case AppFilterActions.location:
            newState.filter = {
                ...newState.filter,
                [action.type]: action.payload
            };
            adjustFilterToLocation(newState);
            adjustFilter(newState);
            break;
        case AppFilterActions.carrierAccount:
            newState.filter = {
                ...newState.filter,
                [action.type]: action.payload
            };
            adjustFilterToCarrierAccount(newState);
            adjustFilter(newState);
            break;
        case AppFilterActions.carrier:
            newState.filter.userCarrieSelection = action.payload;
            newState.filter = {
                ...newState.filter,
                [action.type]: action.payload
            };

            adjustFilter(newState);
            break;

        case AppFilterActions.invoiceIDS:
        case AppFilterActions.minInv:
        case AppFilterActions.maxInv:
        case AppFilterActions.startInvDate:
        case AppFilterActions.endInvDate:
        case AppFilterActions.startDueDate:
        case AppFilterActions.endDueDate:
        case AppFilterActions.remittanceNumbers:
        case AppFilterActions.disbursementInvoiceIDS:
        case AppFilterActions.paymentConfirmationIDS:
        case AppFilterActions.auditStatus:
        case AppFilterActions.invoiceStatus:
        case AppFilterActions.autofill:
        case AppFilterActions.linkIds:
            // case AppFilterActions.audited:
            newState.filter = {
                ...newState.filter,
                [action.type]: action.payload
            };
            break;
        case AppFilterActions.filterBulk:
            newState.filter = {
                ...newState.filter,
                ...action.payload
            };
            break;
        case AppFilterActions.resetDivisions:
            newState.filter = {
                ...newState.filter,
                client: undefined,
                company: undefined,
                division: undefined,
                location: undefined,
                carrierAccount: undefined,
                carrier: undefined
            };
            break;
        default:
            return state;
    }
    return newState;
};

const logReducer = loggedReducer<IAppState>(reducer, 'App');

export const AppStateContext: any = React.createContext({});
export const AppDispatchContext: any = React.createContext({});
export const AppStateRefContext: any = React.createContext({});

export const useAppState = (): IAppState => useContext(AppStateContext);
export const useAppDispatch = (): ((action: IAction) => IAppState) => useContext(AppDispatchContext);
export const useAppStateRef = (): React.MutableRefObject<IAppState> => useContext(AppStateRefContext);

export const useGetTableDef = (): ((def: keyof ReturnType<typeof getStiTableDefs>) => IViewMode | undefined) => {
    const checkPermission = usePermission();
    return (def: keyof ReturnType<typeof getStiTableDefs>) => {
        const isSTI = checkPermission();
        if (isSTI) {
            return getStiTableDefs()[def];
        } else {
            return getTableDefs()[def];
        }
    };
};

export const AppProvider: React.FC = ({ children }) => {
    const checkPermission = usePermission();
    const [appState, appDispatch] = useReducer(logReducer, initialState, (s) => {
        const isSTI = checkPermission();
        const today = new Date(Date.now());
        if (isSTI) {
            s.data.tableDefs = getStiTableDefs();
            s.filter.startInvDate = startOfDay(subMonths(today, 3));
            s.filter.endInvDate = endOfDay(today);
        } else {
            s.data.tableDefs = getTableDefs();
            s.filter.startInvDate = startOfDay(subYears(today, 1));
            s.filter.endInvDate = endOfDay(today);
        }
        const storedLayouts: { [index: string]: StoredTableDef } = getStoredItem(StorageKeys.layouts);
        if (storedLayouts) {
            Object.keys(s.data.tableDefs).forEach((k) => {
                if (storedLayouts[k]) {
                    s.data.tableDefs[k].columns = storedLayouts[k].columns;
                    const sticky = new Set(storedLayouts[k].sticky);
                    let currStickyPos = 0;
                    Object.keys(storedLayouts[k].widths).forEach((w) => {
                        if (!s.data.tableDefs[k].widths[w]) {
                            s.data.tableDefs[k].widths[w] = { initialWidth: 90, initialExpand: true };
                        }
                        s.data.tableDefs[k].widths[w].width = storedLayouts[k].widths[w];
                        if (sticky.has(w)) {
                            s.data.tableDefs[k].widths[w].sticky = currStickyPos;
                            currStickyPos += s.data.tableDefs[k].widths[w].width ?? 0;
                        }
                    });
                    s.data.tableDefs[k].sort = storedLayouts[k].sort || s.data.tableDefs[k].sort || [];
                }
            });
        }
        return s;
    });
    const appStateRef = useRef<IAppState>(appState);
    // const { getCompanies } = useGetCompanies();
    const { getClients } = useGetClients();
    // const { getInvoiceCount } = useGetInvoiceCount();
    // const { getInvoiceSum } = useGetInvoiceSum();
    const { getInvoiceSummary } = useGetInvoiceSummary();

    const { getAuditStatusOptions } = useGetAuditStatusOptions();
    const { getInvoiceStatusOptions } = useGetInvoiceStatusOptions();
    const { getAdjustmentTypes } = useGetAdjustmentTypes();
    const { getAdjustmentCategories } = useGetAdjustmentCategories();

    // useEffect(() => {
    //     const saveAll = (evt?: BeforeUnloadEvent) => {
    //         if (evt) {
    //             //     evt.preventDefault();
    //             evt.returnValue = null;
    //         }
    //         saveTableLayout(Object.values(appStateRef.current.data.tableDefs));

    //         return null;
    //     };
    //     // window.onbeforeunload = saveAll;
    //     window.addEventListener('beforeunload', saveAll);
    //     return () => {
    //         saveAll();
    //     };
    // }, [appStateRef]);

    appStateRef.current = appState;

    useEffect(() => {
        (async () => {
            const res = await getClients();
            const clients = res.data;
            if (res.status === 200) {
                appDispatch({
                    type: AppDataActions.clients,
                    payload: clients
                });
            }
        })();

        (async () => {
            const res = await getAdjustmentTypes();
            if (res.status === 200) {
                appDispatch({ type: AppDataActions.adjustmentTypes, payload: res.data });
            }
        })();

        (async () => {
            const res = await getAdjustmentCategories();
            if (res.status === 200) {
                appDispatch({ type: AppDataActions.adjustmentCategories, payload: res.data });
            }
        })();

        (async () => {
            const res = await getAuditStatusOptions();
            if (res.status === 200) {
                let filtered: Models.IpAuditStatus[] = res.data;
                if (!checkPermission()) {
                    filtered = filtered.filter((s) => ['Audited', 'Non-Audit', 'Pending Client Review'].includes(s.auditStatus));
                }
                appDispatch({ type: AppDataActions.auditStatusOptions, payload: res.data });
            }
        })();

        (async () => {
            const res = await getInvoiceStatusOptions();
            if (res.status === 200) {
                appDispatch({ type: AppDataActions.invoiceStatusOptions, payload: res.data });
            }
        })();

        (async () => {
            const summaryRes = await getInvoiceSummary();
            if (summaryRes.status === 200) {
                appDispatch({ type: AppActions.invoiceCountAll, payload: summaryRes.data.count });
                appDispatch({ type: AppActions.invoiceSumAll, payload: summaryRes.data.sum });
            } else {
                appDispatch({ type: AppActions.invoiceCountAll, payload: 0 });
                appDispatch({ type: AppActions.invoiceSumAll, payload: 0 });
            }
        })();

        // (async () => {
        //     const countRes = await getInvoiceCount(CompanyInvoiceType.Division, {});
        //     if (countRes.status === 201) {
        //         appDispatch({ type: AppActions.invoiceCountAll, payload: countRes.data.count });
        //     } else {
        //         appDispatch({ type: AppActions.invoiceCountAll, payload: 0 });
        //     }
        // })();
        // (async () => {
        //     const sumRes = await getInvoiceSum(CompanyInvoiceType.Division, {});
        //     if (sumRes.status === 201) {
        //         appDispatch({ type: AppActions.invoiceSumAll, payload: sumRes.data.sum });
        //     } else {
        //         appDispatch({ type: AppActions.invoiceSumAll, payload: 0 });
        //     }
        // })();
    }, [
        getClients,
        appDispatch,
        getAdjustmentTypes,
        getAuditStatusOptions,
        getInvoiceStatusOptions,
        getAdjustmentCategories,
        checkPermission,
        getInvoiceSummary
    ]);

    return (
        <AppStateContext.Provider value={appState}>
            <AppDispatchContext.Provider value={appDispatch}>
                <AppStateRefContext.Provider value={appStateRef}>{children}</AppStateRefContext.Provider>
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

const adjustFilter = (state: IAppState) => {
    const filter = state.filter;
    if (!filter.autofill) return;
    if (filter.client?.clientID && !state.data.entitiesById.clients[filter.client.clientID]) filter.client = undefined;
    let client: ClientWId | undefined = filter.client || (state.data.clients.length === 1 ? state.data.clients[0] : undefined);
    let company: CompanyWId | undefined;
    let division: DivisionWId | undefined;
    let location: LocationWId | undefined;
    let account: CarrierAccountWId | undefined;

    if (client) {
        company = client.companies.find((c) => filter.company?.companyID === c.companyID);

        if (!company) {
            if (client.companies.length === 1) {
                company = client.companies[0];
                filter.company = company;
            } else {
                filter.company = undefined;
            }
        }
    } else {
        filter.company = undefined;
    }

    if (company) {
        division = company.divisions.find((d) => filter.division?.divisionID === d.divisionID);
        if (!division) {
            if (company.divisions.length === 1) {
                division = company.divisions[0];
                filter.division = division;
            } else {
                filter.division = undefined;
            }
        }
    } else {
        filter.division = undefined;
    }

    if (division) {
        location = division?.locations.find((l) => filter.location?.locationID === l.locationID);
        if (!location) {
            if (division && division.locations.length === 1) {
                location = division.locations[0];
                filter.location = location;
            } else {
                filter.location = undefined;
            }
        }
    } else {
        filter.location = undefined;
    }

    if (location) {
        account = location?.accounts.find((a) => filter.carrierAccount?.carrierAccountID === a.carrierAccountID);
        if (filter.carrier && account && filter.carrier?.carrierID !== account.carrierID) {
            account = undefined;
        }
        if (!account) {
            if (location && location?.accounts.length === 1) {
                filter.carrierAccount = location.accounts[0];
            } else {
                filter.carrierAccount = undefined;
            }
        }

        if (filter.carrierAccount) {
            filter.carrier = state.data.carriers.find((c) => c.carrierID === filter.carrierAccount?.carrierID);
        }
    } else {
        filter.carrierAccount = undefined;
        // filter.carrier = undefined;
    }
};

const hydrateClientIds = (clients: ClientWId[]): ClientWId[] => {
    clients.forEach((c) => hydrateCompanyIds(c.companies));
    return clients;
};

const hydrateCompanyIds = (companies: CompanyWId[]): CompanyWId[] => {
    companies.forEach((c) => {
        const cId = c.clientID;
        const wId = c.companyID;
        c.divisions.forEach((d) => {
            d.clientID = cId;
            const dId = d.divisionID;
            d.locations.forEach((l) => {
                l.clientID = cId;
                l.companyID = wId;
                l.accounts.forEach((a) => {
                    a.clientID = cId;
                    a.companyID = wId;
                    a.divisionID = dId;
                });
            });
        });
    });
    return companies;
};

const entitiesbyIdFromClient = (clients: ClientWId[]) => {
    const entities: EntitiesById = {
        clients: {},
        companies: {},
        divisions: {},
        locations: {}
    };

    clients.forEach((c) => {
        entities.clients[c.clientID] = c;
        c.companies.forEach((co) => {
            entities.companies[co.companyID] = co;
            co.divisions.forEach((d) => {
                entities.divisions[d.divisionID] = d;
                d.locations.forEach((l) => {
                    entities.locations[l.locationID] = l;
                });
            });
        });
    });
    return entities;
};

const entitiesbyIdFromCompany = (companies: CompanyWId[]) => {
    const entities: EntitiesById = {
        clients: {},
        companies: {},
        divisions: {},
        locations: {}
    };

    companies.forEach((co) => {
        entities.companies[co.companyID] = co;
        co.divisions.forEach((d) => {
            entities.divisions[d.divisionID] = d;
            d.locations.forEach((l) => {
                entities.locations[l.locationID] = l;
            });
        });
    });
    return entities;
};

const adjustFilterToCarrierAccount = (state: IAppState) => {
    if (!state.filter.autofill) return state;
    const carrierAccount = state.filter.carrierAccount;
    if (!carrierAccount) return state;
    let client;
    let company;
    let division;
    let location;
    client = state.data.clients.find((c) => {
        company = c.companies.find((w) => {
            division = w.divisions.find((d) => {
                location = d.locations.find((l) => l.locationID === carrierAccount.locationID);
                return location?.divisionID === d.divisionID;
            });
            return division?.companyID === w.companyID;
        });
        return company?.clientID === c.clientID;
    });
    state.filter = { ...state.filter, client, company, division, location };
    return state;
};

const adjustFilterToLocation = (state: IAppState) => {
    if (!state.filter.autofill) return state;
    const location = state.filter.location;
    if (!location) return state;
    let client;
    let company;
    let division;
    client = state.data.clients.find((c) => {
        company = c.companies.find((w) => {
            division = w.divisions.find((d) => {
                return location?.divisionID === d.divisionID;
            });
            return division?.companyID === w.companyID;
        });
        return company?.clientID === c.clientID;
    });
    state.filter = { ...state.filter, client, company, division };
    return state;
};

const adjustFilterToDivision = (state: IAppState) => {
    if (!state.filter.autofill) return state;
    const division = state.filter.division;
    if (!division) return state;
    let client;
    let company;
    client = state.data.clients.find((c) => {
        company = c.companies.find((w) => {
            return division?.companyID === w.companyID;
        });
        return company?.clientID === c.clientID;
    });
    state.filter = { ...state.filter, client, company };
    return state;
};

const adjustFilterToCompany = (state: IAppState) => {
    if (!state.filter.autofill) return state;
    const company = state.filter.company;
    if (!company) return state;
    let client;
    client = state.data.clients.find((c) => {
        return company?.clientID === c.clientID;
    });
    state.filter = { ...state.filter, client };
    return state;
};

const timeouts: { [index: string]: number | undefined } = {
    default: undefined,
    audit: undefined,
    pay: undefined,
    review: undefined
};

const saveTableLayout = async (key: string, def: Partial<IDataTableState>, immediate?: boolean) => {
    const f = () => {
        const stored: { [index: string]: StoredTableDef } = getStoredItem(StorageKeys.layouts) ?? {};
        const trimmedW: Record<string, number> = {};
        const sticky: string[] = [];
        Object.entries(def.colWidths || {}).forEach(([k, v]) => {
            if (v.width !== undefined) {
                trimmedW[k] = v.width;
            }
            if (v.sticky !== undefined) {
                sticky.push(k);
            }
        }, {});
        const columns =
            def.columns?.reduce((acc: string[], c) => {
                if (c.active) {
                    acc.push(c.key);
                }
                return acc;
            }, []) || [];

        stored[key] = { columns, sticky, widths: trimmedW, sort: def.sort || [] };
        setStoredItem(StorageKeys.layouts, stored);
    };
    if (timeouts[key] !== undefined) {
        window.clearTimeout(timeouts[key]);
    }
    if (immediate) {
        f();
    } else {
        const t = window.setTimeout(f, 2000);
        timeouts[key] = t;
    }
};

export const useEntityNames = () => {
    const state = useAppState();

    return useCallback(
        (level: string, id: number) => {
            switch (level) {
                case 'Client':
                    return state.data.entitiesById.clients[id]?.clientName;
                // return state.data.clients.find((c) => c.clientID === invoice.clientID)?.clientName;
                case 'Company':
                    return state.data.entitiesById.companies[id]?.companyName;
                // return state.data.clients.find((c) => c.clientID === invoice.clientID)?.companies.find((c) => c.companyID === invoice.companyID)?.companyName;
                case 'Division':
                    return state.data.entitiesById.divisions[id]?.divisionName;
                case 'Location':
                    return state.data.entitiesById.locations[id]?.locationName;
                // return state.data.clients
                //     .find((c) => c.clientID === invoice.clientID)
                //     ?.companies.find((c) => c.companyID === invoice.companyID)
                //     ?.divisions.find((d) => d.divisionID === invoice.divisionID)?.divisionName;
                default:
                    return 'Unknown';
            }
        },
        [state.data.entitiesById]
    );
};

// use primarily for a bottom up starting with the account.
export const useValidateFilterAgainstData = () => {
    const stateRef = useAppStateRef();

    const invoiceRef = useInvoiceStateRef();

    const validate = useCallback(
        (carrierAccount?: Partial<CarrierAccountWId>) => {
            const account = carrierAccount?.carrierAccount ?? '';
            const invoices = invoiceRef.current.invoices;

            const { filter, data } = stateRef.current;
            const { entitiesById } = data;
            const { clients, companies, divisions, locations, carriers } = entitiesById;

            const validClients: Set<number> = new Set();
            const validCompanies: Set<number> = new Set();
            const validDivisions: Set<number> = new Set();
            const validLocations: Set<number> = new Set();
            const validCarriers: Set<number> = new Set();

            invoices.forEach((inv) => {
                if (inv.carrierAccountID === (account ?? (filter.carrierAccount?.carrierAccountID as any))) {
                    validClients.add(inv.clientID);
                    validCompanies.add(inv.companyID);
                    validDivisions.add(inv.divisionID);
                    validLocations.add(inv.locationID);
                    carriers && validCarriers.add(inv.carrierID);
                }
            });

            const newFilter: IAppFilter = {
                ...filter
            };

            if (validClients.size === 1) newFilter.client = clients[Array.from(validClients.keys())[0]];
            if (validCompanies.size === 1) newFilter.company = companies[Array.from(validCompanies.keys())[0]];
            if (validDivisions.size === 1) newFilter.division = divisions[Array.from(validDivisions.keys())[0]];
            if (validLocations.size === 1) newFilter.location = locations[Array.from(validLocations.keys())[0]];
            if (validCarriers.size === 1) newFilter.carrier = carriers?.[Array.from(validCarriers.keys())[0]];
            if (carrierAccount && account) newFilter.carrierAccount = carrierAccount as CarrierAccountWId;
            return newFilter;
        },
        [stateRef, invoiceRef]
    );
    return validate;
};
