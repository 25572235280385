import { ButtonBase, Grid, ModalProps, TextField } from '@material-ui/core';
import { TableArrowRight } from 'mdi-material-ui';
import React, { useState } from 'react';
import { CheckControl, GridMin, STIModal, STITooltip } from '../../generic';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { ModalContainer, BodyContainer, Header } from '../modals/common.component';
import { useAppState } from '../../../contexts/app.context';
import { useInvoiceStateRef } from '../../../contexts/invoice.context';
import { tableToExcel } from '../../../helpers/excelexport';
import { useUserState } from '../../../contexts/user.context';
import { useGetGLCodingsByFilter } from '../../../hooks/stiapi.hook';
import { Models } from 'shipmenttrackers-domain/dist';
import { IColumn } from '../../datatable/datatable.types';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useSearchHandler } from '../../../mediators/appsearchinvoice.mediator';
import { success } from '../../../hooks/api.hook';

export const ExportButton: React.FC = () => {
    const [open, setOpen] = useState(false);

    const onClick = () => setOpen(true);
    const close = () => setOpen(false);

    return (
        <>
            {open ? <ExportModal open={open} onClose={close} /> : null}
            <STITooltip title="Export" placement="bottom-end">
                <ButtonBase component="div" color="inherit" onClick={onClick}>
                    <TableArrowRight />
                </ButtonBase>
            </STITooltip>
        </>
    );
};

const glColumns: IColumn[] = [
    { label: 'GL1', key: 'gl1', active: true },
    { label: 'GL2', key: 'gl2', active: true },
    { label: 'GL3', key: 'gl3', active: true },
    { label: 'GL4', key: 'gl4', active: true },
    { label: 'GL5', key: 'gl5', active: true },
    { label: 'GL6', key: 'gl6', active: true },
    { label: 'GL7', key: 'gl7', active: true },
    { label: 'GL8', key: 'gl8', active: true },
    { label: 'GL9', key: 'gl9', active: true },
    { label: 'GL10', key: 'gl10', active: true },
    { label: 'GL Amount', key: 'glAmount', active: true }
];

const ExportModal: React.FC<Omit<ModalProps, 'children'>> = (props) => {
    const { onClose } = props;
    const [loading, setLoading] = useState(false);
    const [withGLs, setWithGLs] = useState(false);
    const appState = useAppState();
    const invoiceStateRef = useInvoiceStateRef();
    const userState = useUserState();
    const { getGLCodingsByFilter } = useGetGLCodingsByFilter();
    const [fileName, setFileName] = useState('');
    const inputClasses = useInputStyle();
    const { getFilterQuery } = useSearchHandler();

    const [message, setMessage] = useState('');

    const handleExport = async () => {
        const s = appState;
        setLoading(true);
        let toTable: Models.CarrierInvoices[] | (Models.CarrierInvoices & Models.GLCoding)[] = invoiceStateRef.current.invoices;
        let table = { ...s.dataTableState, columns: [...(s.dataTableState.columns ?? [])] };
        if (withGLs) {
            // const batchSize = 2000;
            // const requests = [];
            // for (let i = 0; i < invoiceStateRef.current.invoices.length; i += batchSize) {
            //     const req = async () => {
            //         const res = await getGLCodingsByIds(invoiceStateRef.current.invoices.slice(i, i + batchSize).map((i) => i.carrierInvoiceID));
            //         if (res.status === 200 || res.status === 201) {
            //             fetchedCount.current += Math.min(batchSize, invoiceStateRef.current.invoices.length - i);
            //             setMessage(`Fetched ${fetchedCount.current} invoices`);
            //         }
            //         return res;
            //     };
            //     requests.push(req());
            // }
            // const responses = await Promise.all(requests);
            // const codings: Record<string, Models.GLCoding[]> = {};
            // for (let i = 0; i < responses.length; i++) {
            //     const res = responses[i];
            //     if (res.status === 200 || res.status === 201) {
            //         Object.assign(codings, res.data);
            //     }
            // }
            setMessage(`Fetching GLs`);
            const { type, query, body } = getFilterQuery();
            const res = await getGLCodingsByFilter(type, query, body);
            if (success(res)) {
                setMessage(`Building Export`);
                toTable = [];
                const codings = res.data;
                for (let i = 0; i < invoiceStateRef.current.invoices.length; i++) {
                    const inv = invoiceStateRef.current.invoices[i];
                    const codes = codings[inv.carrierInvoiceID];
                    if (codes) {
                        for (let j = 0; j < codes.length; j++) {
                            const combined = { ...inv, ...codes[j] };
                            toTable.push(combined);
                        }
                    } else {
                        toTable.push(inv as any);
                    }
                }
                table.columns?.push(...glColumns);
            }
        }
        setMessage(`Building Export`);
        await tableToExcel({ filter: s.filter, invoices: toTable, table, user: userState.user, fileName, onMessage: setMessage });
        setMessage(`Complete!`);
        setLoading(false);
        onClose?.({}, 'escapeKeyDown');
    };
    return (
        <STIModal {...props}>
            <ModalContainer>
                <GridMin>
                    <Header variant="subtitle1">Export Options</Header>
                </GridMin>
                <BodyContainer item container direction="column">
                    <Grid container spacing={2} direction="column" className={inputClasses.bottomPadding}>
                        <Grid item>
                            <CheckControl onChange={(evt, checked) => setWithGLs(checked)}>Include GL Coding</CheckControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="File Name"
                                data-lpignore="true"
                                InputProps={{ endAdornment: '.xlsx' }}
                                inputProps={{ 'data-lpignore': 'true' }}
                                variant="filled"
                                className={`${inputClasses.input}`}
                                value={fileName}
                                onChange={(evt) => setFileName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container wrap="nowrap" spacing={2} justify="flex-end" alignItems="center">
                        <Grid item>{message}</Grid>
                        <Grid item>
                            <ExtendedButton loading={loading} color="secondary" variant="contained" type="submit" onClick={handleExport}>
                                Export
                            </ExtendedButton>
                        </Grid>
                        <Grid item>
                            <ExtendedButton variant="contained" onClick={() => onClose?.({}, 'escapeKeyDown')} color="error">
                                Cancel
                            </ExtendedButton>
                        </Grid>
                    </Grid>
                </BodyContainer>
            </ModalContainer>
        </STIModal>
    );
};
