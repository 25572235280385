import React from 'react';
import { useAuditStateRef, useAuditDispatch, AuditActions } from '../audit.context';
import { useGetGLCodings } from '../../../../hooks/stiapi.hook';
import ReloadAlert from 'mdi-material-ui/ReloadAlert';
import { ExtendedIconButton, IExtendedIconButton } from '../../../generic/controls/extendediconbutton.component';
import { useInfoModal, CONFIRM_TYPES } from '../../../../hooks/info-modal.hook';
import { STITooltip } from '../../../generic';

export const ReloadGL: React.FC<IExtendedIconButton> = (props) => {
    const auditStateRef = useAuditStateRef();
    const { getGLCoding, loading } = useGetGLCodings();
    const auditDispatch = useAuditDispatch();
    const [ConfirmationBox, runConfirmation] = useInfoModal();

    const onReload = async () => {
        if (auditStateRef.current.invoice?.carrierInvoiceID === undefined) return;
        const confirmRes = await runConfirmation({
            title: 'Reload',
            desc: (
                <>
                    <p>Are you sure you want to reload?</p>
                    <p>You will lose any changes you have made.</p>
                </>
            ),
            rememberOptionKey: 'reloadGls'
        });

        if (confirmRes === CONFIRM_TYPES.YES) {
            const res = await getGLCoding(auditStateRef.current.invoice?.carrierInvoiceID, auditStateRef.current.invoice.carrierAccount);
            if (res.status === 200) {
                auditDispatch({ type: AuditActions.setGL, payload: res.data });
            }
        }
    };

    return (
        <>
            <ConfirmationBox />
            <ExtendedIconButton color="inherit" onClick={onReload} loading={!!loading} {...props}>
                <STITooltip title="Reload Stored GLs">
                    <ReloadAlert />
                </STITooltip>
            </ExtendedIconButton>
        </>
    );
};
