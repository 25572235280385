import React, { useState, ReactNode, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface ITabPanel {
    defaultTab?: number;
    labels: ReactNode[];
}

export const TabPanel: React.FC<ITabPanel> = ({ children, defaultTab = 0, labels }) => {
    const [value, setValue] = useState<number>(defaultTab);
    useEffect(() => setValue(defaultTab), [defaultTab]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    return (
        <TabContainer>
            <Paper>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                    {labels.map((l, i) => (
                        <StyledTab label={labels[i]} key={i} />
                    ))}
                </Tabs>
            </Paper>
            {React.Children.toArray(children).map((c, i) => (
                <TabMainWrapper key={i} hide={i !== value}>
                    {c}
                </TabMainWrapper>
            ))}
        </TabContainer>
    );
};

const TabContainer = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    '& > *': {
        width: '100%'
    }
});

const StyledTab = styled(Tab)({
    flexBasis: 0,
    flexGrow: 1,
    minWidth: '0px'
});

interface ITabMainWrapper {
    hide: boolean;
}

const TabMainWrapper: React.ComponentType<ITabMainWrapper> = styled(({ hide, children, ...props }) => <div {...props}>{children}</div>)(({ theme, hide }) => ({
    flexGrow: 1,
    maxHeight: hide ? '0px' : '100%',
    overflow: hide ? 'hidden' : 'auto',
    display: 'flex',
    flexDirection: 'column'
}));
