import React from 'react';
import { TableHead, TableRow, TableCell, Box, useTheme, fade } from '@material-ui/core';
import { Caps } from '../../../generic/display/typocaps.component';

export const GLHeader: React.FC<{ max: number }> = ({ max }) => {
    const theme = useTheme();
    const c = fade(theme.palette.common.white, 0.6);

    return (
        <TableHead>
            <TableRow>
                {new Array(max).fill(null).map((n, i) => (
                    <TableCell align="center" key={i}>
                        <Box color={c}>
                            <Caps variant="body2">{`GL${i + 1}`}</Caps>
                        </Box>
                    </TableCell>
                ))}
                <TableCell align="center" width="72px">
                    <Box color={c}>
                        <Caps variant="body2">%</Caps>
                    </Box>
                </TableCell>
                <TableCell align="center" width="120px">
                    <Box color={c}>
                        <Caps variant="body2">Amount</Caps>
                    </Box>
                </TableCell>
                <TableCell width="30px">{''}</TableCell>
            </TableRow>
        </TableHead>
    );
};
