import { Models } from 'shipmenttrackers-domain';

export interface PermissionsMap {
    allowed?: Models.UserEntities;
    disallowed: { [index: string]: { [index: string]: Models.UserEntities & { model: any; name: string } } };
}
export interface IUserState {
    token: string | null;
    user: Models.User | null;
    // summary: IIdx<Partial<Models.UserEntities>>;
    permissions: PermissionsMap;
    permissionFetching: { [key: string]: true };
    alias?: {
        user: Models.User | null;
        token: string | null;
    };
}

export enum Permission {
    approve = 'approve',
    createUser = 'createUser',
    dispute = 'dispute',
    enterGlCodes = 'enterGlCodes',
    manageUser = 'manageUser',
    pay = 'pay',
    reassignDivision = 'reassignDivision',
    viewGlCodes = 'viewGlCodes',
    editGlCodes = 'editGlCodes'
}
