import { Grow, List, ListItemText, makeStyles, Popper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useAppState } from '../../../contexts/app.context';
import { usePermission } from '../../../hooks/permission/permissions.hook';
import { Panel, Scroller } from '../../generic';
import { GrowUnderline } from '../../generic/feedback/growunderline.component';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.common.white
    },
    line: {
        backgroundColor: theme.palette.common.white
    },
    popper: {
        zIndex: theme.zIndex.tooltip
    },
    panel: {
        boxShadow: theme.shadows[3],
        maxHeight: '50vh',
        overflow: 'auto'
    }
}));

export const LoggedOnUsers: React.FC = () => {
    const classes = useStyles();
    const appState = useAppState();
    const checkPermission = usePermission();
    // const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setIsOpen(!isOpen);
        setAnchorEl(event.currentTarget);
    };

    return checkPermission() ? (
        <GrowUnderline onHover pointer lineProps={{ className: classes.line }} onClick={handleClick}>
            {!!appState.loggedInUsers.length && (
                <Popper open={isOpen} anchorEl={anchorEl} placement="top-end" className={classes.popper} transition>
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: 'right bottom' }}>
                            <Panel className={classes.panel}>
                                <List disablePadding>
                                    {appState.loggedInUsers.map((u) => (
                                        <ListItemText primary={`${u.firstName} ${u.lastName}`} secondary={u.email} />
                                    ))}
                                </List>
                            </Panel>
                        </Grow>
                    )}
                </Popper>
            )}
            <Typography className={classes.text}>{`Logged In Users: ${appState.loggedInUsers.length}`}</Typography>
        </GrowUnderline>
    ) : null;
};
