import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import { ModalContainer, Header, BodyContainer } from './common.component';
import { GridMin, CheckControl } from '../../generic';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { useCreateActions, useCreateGLCodings, useGetInvoiceSum, useMakeAdjustment } from '../../../hooks/stiapi.hook';
import { useAuditStateRef, useAuditDispatch, AuditActions } from '../audit/audit.context';
import { IAction } from '../../../types/contexts/context.type';
import { useReducerWithRef } from '../../../hooks/reducerwithref.hook';
import { AuditAmountInput } from '../audit/amountinput.component';
import { ExtendedButton } from '../../generic/controls/extendedbutton.component';
import { useAppDispatch, useAppState } from '../../../contexts/app.context';
import { SingleSelectAuto } from '../../generic/controls/single-selectauto.component';
import { Models } from 'shipmenttrackers-domain/dist';
import { AppActions } from '../../../types/contexts/app-context.type';
import { CompanyInvoiceType } from 'shipmenttrackers-domain/dist/models';
import { useSearchHandler } from '../../../mediators/appsearchinvoice.mediator';
import { currency } from '../../../helpers/pipes';
import { NoteInput } from '../audit/noteinput/noteinput.component';
import { useHandleAtMentions } from '../audit/noteinput/useHandleAtMentions.hook';

interface IUpdateState {
    amount?: string;
    billable?: boolean;
    type?: Models.AdjustmentTypes;
    category?: Models.AdjustmentCategory;
    action?: string;
    note: string;
}

const reducer = (state: IUpdateState, action: IAction) => {
    // console.log(action);
    return { ...state, [action.type]: action.payload };
};

export const UpdateAmountModal: React.FC<{ amount: string; close: () => void }> = ({ amount, close }) => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const appState = useAppState();
    const appDispatch = useAppDispatch();
    const { getInvoiceSum, loading: loadingSum } = useGetInvoiceSum();
    const auditStateRef = useAuditStateRef();
    const auditDispatch = useAuditDispatch();
    const { createAction, loading: loadingAction } = useCreateActions();
    const { makeAdjustment, loading: adjustmentLoading } = useMakeAdjustment();
    const [adjError, setAdjError] = useState<ReactNode>(null);
    const { fetchSum } = useSearchHandler();
    const { createGLCodings, loading: createLoading } = useCreateGLCodings();

    const [state, dispatch, stateRef] = useReducerWithRef(reducer, { amount, note: '' });
    const { addTag, handleNoteTags } = useHandleAtMentions();

    const denomination = auditStateRef.current.invoice?.currency;

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const state = stateRef.current;
            const inv = auditStateRef.current.invoice;
            if (!inv) return;

            if (state?.type && state?.note && state?.category && state?.amount !== undefined) {
                const amt = parseFloat(state.amount.replace('$', '').replace(',', '').replace('€', '').replace('CAD', '').trim());

                const adjRes = await makeAdjustment({
                    adjustmentTypeID: state.type.adjustmentTypeID,
                    adjustmentCategoryID: state.category?.adjustmentCategoryID,
                    billable: !!state.billable,
                    carrierInvoiceID: inv.carrierInvoiceID,
                    amount: amt,
                    notes: state.note
                });
                await handleNoteTags(state.note);

                if (adjRes.status === 201) {
                    const oldDif = (auditStateRef.current.invoice?.currentAmt || 0) - auditStateRef.current.encoded;
                    // check and update GLS
                    const encodedDif = (adjRes.data?.currentAmt || 0) - auditStateRef.current.encoded;
                    // check if only 1 GL, that it exists and theres a difference
                    if (
                        auditStateRef.current.glcoding.length === 1 &&
                        auditStateRef.current.glcoding[0].carrierInvoiceID !== undefined &&
                        oldDif === 0 &&
                        encodedDif !== 0
                    ) {
                        //check if there are updates aready pending
                        const gl = auditStateRef.current.glcoding[0];
                        const glsDel = auditStateRef.current.glsToDelete;
                        if (!gl.updated && glsDel.length === 0) {
                            const glRes = await createGLCodings(
                                [{ ...gl, glAmount: adjRes.data?.currentAmt }],
                                auditStateRef.current.invoice?.carrierAccount || ''
                            );
                            if (glRes.status === 201) {
                                auditDispatch({ type: AuditActions.setGL, payload: glRes.data.codings });
                            }
                        }
                    }

                    auditDispatch({ type: AuditActions.invoice, payload: adjRes.data });
                    setAdjError(null);
                    // Create relevant action
                    const messageRes = await createAction({
                        carrierInvoiceID: inv.carrierInvoiceID.toString(),
                        action: `Adjusted invoice amount by ${currency(amt - inv.currentAmt, denomination)} - ${state.note}`
                    });
                    if (messageRes.status === 201) {
                        auditDispatch({ type: AuditActions.addAction, payload: messageRes.data });
                    }

                    // refetch sums on adjustment
                    const sumRes = await getInvoiceSum(CompanyInvoiceType.Division);
                    if (sumRes.status === 201) {
                        appDispatch({ type: AppActions.invoiceSumAll, payload: sumRes.data.sum });
                    } else {
                        appDispatch({ type: AppActions.invoiceSumAll, payload: 0 });
                    }
                    await fetchSum();
                    close();
                } else {
                    setAdjError(
                        <>
                            <p>There was a problem saving the adjustment.</p>
                            <p>Invoice amount has not been changed.</p>
                        </>
                    );
                }
            }
        },
        [
            stateRef,
            auditStateRef,
            makeAdjustment,
            handleNoteTags,
            auditDispatch,
            createAction,
            denomination,
            getInvoiceSum,
            fetchSum,
            close,
            createGLCodings,
            appDispatch
        ]
    );

    const onChange = useCallback(
        (evt) => {
            dispatch({ type: evt.target.name, payload: evt.target.value });
        },
        [dispatch]
    );

    const onChangeCheck = useCallback(
        (evt) => {
            dispatch({ type: evt.target.name, payload: evt.target.checked });
        },
        [dispatch]
    );

    const typeOptions = appState.data.adjustmentTypes;

    const catOptions = appState.data.adjustmentCategories;

    return (
        <ModalContainer>
            <GridMin>
                <Header variant="subtitle1">Adjust Invoice Amount</Header>
            </GridMin>
            <BodyContainer item container>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2} direction="column">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item>
                                <AuditAmountInput
                                    autoFocus
                                    onFocus={(evt) => evt.target.select()}
                                    onChange={onChange}
                                    value={state?.amount || ''}
                                    required
                                    name="amount"
                                />
                            </Grid>
                            <Grid item>
                                <Box minWidth="180px">
                                    <SingleSelectAuto
                                        required
                                        onValChange={(evt, val) => dispatch({ type: 'type', payload: val })}
                                        fullWidth
                                        variant="filled"
                                        options={typeOptions}
                                        className={inputClasses.input}
                                        menuClasses={menuClasses}
                                        label="Type"
                                        name="type"
                                        getText={(o) => o.adjustmentCode}
                                        getSubtext={(o) => o.adjustmentDescription}
                                    />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box minWidth="180px">
                                    <SingleSelectAuto
                                        required
                                        onValChange={(evt, val) => dispatch({ type: 'category', payload: val })}
                                        fullWidth
                                        variant="filled"
                                        options={catOptions}
                                        className={inputClasses.input}
                                        menuClasses={menuClasses}
                                        label="Category"
                                        name="category"
                                        getText={(o) => o.adjustmentCategoryCode}
                                        getSubtext={(o) => o.adjustmentCategoryDescription}
                                    />
                                </Box>
                            </Grid>
                            <Grid item>
                                <CheckControl name="billable" onChange={onChangeCheck}>
                                    Billable
                                </CheckControl>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <NoteInput
                                newNote={state?.note ?? ''}
                                setNewNote={(v) => dispatch({ type: 'note', payload: v })}
                                variant="filled"
                                className={inputClasses.input}
                                label="Notes"
                                multiline
                                rows={1}
                                rowsMax={4}
                                fullWidth
                                required
                                addTag={addTag}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                        <Grid item>
                            <Typography color="error">{adjError}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={inputClasses.charCount}
                                color={(state?.note.length || 0) > 250 ? 'error' : 'textPrimary'}
                                variant="caption"
                            >{`${state?.note.length}/250`}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item container wrap="nowrap" spacing={2} justify="flex-end">
                        <Grid item>
                            <ExtendedButton
                                loading={!!loadingAction || !!adjustmentLoading || !!loadingSum || !!createLoading}
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </ExtendedButton>
                        </Grid>
                        <Grid item>
                            <ExtendedButton variant="contained" onClick={close} color="error">
                                Cancel
                            </ExtendedButton>
                        </Grid>
                    </Grid>
                </form>
            </BodyContainer>
        </ModalContainer>
    );
};
