import React, { memo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IDatePicker {
    name: string;
    onDateChange: (date: Date, value?: string | null, name?: string | undefined) => void;
}

// const useSelectMenuStyles = makeStyles((theme) => ({
//     paper: {
//         border: `1px solid rgba(255,255,255,.25)`,
//     },
// }));

const Component: React.FC<Partial<IDatePicker> & React.HTMLAttributes<Element> & Partial<KeyboardDatePickerProps>> = ({
    value,
    className,
    onDateChange,
    name,
    ...props
}) => {
    // const [selectedDate, setSelectedDate] = React.useState(value);
    // const popoverClasses = useSelectMenuStyles();

    const handleDateChange = (date: MaterialUiPickersDate, value?: string | null) => {
        if (!date || (date && isNaN(date?.getTime()))) return;
        // setSelectedDate(date as Date);
        onDateChange && onDateChange(date as Date, value, name);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                {...props}
                // PopoverProps={{ classes: popoverClasses }}
                autoOk
                className={`${className}`}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date'
                }}
                value={value ?? null}
            />
        </MuiPickersUtilsProvider>
    );
};

export const DateSelect = memo(Component);
