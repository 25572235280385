import { Models } from 'shipmenttrackers-domain/dist';
import { ITableDef } from '../entity.type';
import { IDataTableState, ISortOption } from '../../components/datatable/datatable.types';

export enum AppActions {
    showInvConf = 'showInvConf',
    searchType = 'searchType',
    invoiceCountAll = 'invoiceCountAll',
    invoiceSumAll = 'invoiceSumAll',
    invoiceCountFiltered = 'invoiceCountFiltered',
    invoiceSumFiltered = 'invoiceSumFiltered',
    homeTableMode = 'homeTableMode',
    dataTableState = 'dataTableState',
    messages = 'messages',
    newMessages = 'newMessages',
    updateMessages = 'updateMessages',
    loggedInUsers = 'loggedInUsers',
    updateDataTableState = 'updateDataTableState',
    storeDataTableState = 'storeDataTableState',
    filterIDQuery = 'filterIDQuery',
    initializing = 'initializing',
    disabledFilters = 'disabledFilters'
}

export enum AppDataActions {
    clients = 'clients',
    companies = 'companies',
    carriers = 'carriers',
    tableDefs = 'tableDefs',
    auditStatusOptions = 'auditStatusOptions',
    invoiceStatusOptions = 'invoiceStatusOptions',
    clientsAll = 'clientsAll',
    adjustmentTypes = 'adjustmentTypes',
    adjustmentCategories = 'adjustmentCategories'
}

export enum AppFilterActions {
    autofill = 'autofill',
    resetDivisions = 'resetDivisions',
    client = 'client',
    company = 'company',
    division = 'division',
    location = 'location',
    carrier = 'carrier',
    carrierAccount = 'carrierAccount',
    invoiceIDS = 'invoiceIDS',
    minInv = 'minInv',
    maxInv = 'maxInv',
    startInvDate = 'startInvDate',
    endInvDate = 'endInvDate',
    startDueDate = 'startDueDate',
    endDueDate = 'endDueDate',
    remittanceNumbers = 'remittanceNumbers',
    disbursementInvoiceIDS = 'disbursementInvoiceIDS',
    paymentConfirmationIDS = 'paymentConfirmationIDS',
    auditStatus = 'auditStatus',
    invoiceStatus = 'invoiceStatus',
    filterBulk = 'filterBulk',
    audited = 'audited',
    amountType = 'amountType',
    linkIds = 'linkIds'
}

// export enum AppFilterIDQueryActions {
//     type = 'type',
//     invoiceIDS = 'invoiceIDS',
//     remittanceInvoiceIDS = 'remittanceInvoiceIDS',
//     disbursementInvoiceIDS = 'disbursementInvoiceIDS'
// }

export interface IDFetchSummary {
    queries: string[];
    results: number;
    exact: string[];
    fuzzy: string[];
    none: string[];
    [index: string]: any;
}

export interface IAppFilterIDQuery {
    [AppFilterActions.invoiceIDS]?: IDFetchSummary;
    [AppFilterActions.remittanceNumbers]?: IDFetchSummary;
    [AppFilterActions.disbursementInvoiceIDS]?: IDFetchSummary;
    [AppFilterActions.paymentConfirmationIDS]?: IDFetchSummary;
}

export interface IAppFilter {
    autofill?: boolean;
    client?: ClientWId;
    company?: CompanyWId;
    division?: DivisionWId;
    location?: LocationWId;
    carrier?: Models.Carrier;
    carrierAccount?: CarrierAccountWId;
    invoiceIDS: string[];
    minInv?: string;
    maxInv?: string;
    startInvDate?: Date;
    endInvDate?: Date;
    startDueDate?: Date;
    endDueDate?: Date;
    remittanceNumbers: string[];
    disbursementInvoiceIDS: string[];
    paymentConfirmationIDS: string[];
    auditStatus: Models.IpAuditStatus[];
    invoiceStatus: Models.IpInvoiceStatus[];
    otherfilters?: Partial<Models.CarrierInvoices>;
    amountType?: Models.AmountType;
    linkIds?: boolean;
    exactIds?: boolean;
    userCarrieSelection?: Models.Carrier;
}

export interface EntitiesById {
    clients: { [index: string]: ClientWId };
    companies: { [index: string]: CompanyWId };
    divisions: { [index: string]: DivisionWId };
    locations: { [index: string]: LocationWId };
    carriers?: { [index: string]: Models.Carrier };
}

export interface AppStateData {
    companies: CompanyWId[];
    clients: ClientWId[];
    clientsAll: ClientWId[];
    carriers: Models.Carrier[];
    tableDefs: { [index: string]: IViewMode };
    auditStatusOptions: Models.IpAuditStatus[];
    invoiceStatusOptions: Models.IpInvoiceStatus[];
    adjustmentTypes: Models.AdjustmentTypes[];
    adjustmentCategories: Models.AdjustmentCategory[];
    entitiesById: EntitiesById;
}

export type KeysToInit = keyof AppStateData | 'invoiceCountAll' | 'invoiceSumAll' | 'invoiceCountFiltered' | 'invoiceSumFiltered';
export interface IAppState {
    showInvConf: boolean;
    searchType: SearchType;
    invoiceCountAll: number;
    invoiceSumAll: number;
    invoiceCountFiltered: number;
    invoiceSumFiltered: number;
    homeTableMode: string;
    data: AppStateData;
    filter: IAppFilter;
    disabledFilters: Partial<Record<keyof IAppFilter, boolean>>;
    dataTableState: Partial<IDataTableState>;
    messages: Models.Messages[];
    loggedInUsers: Models.User[];
    filterIDQuery: IAppFilterIDQuery;
    initializing: Set<KeysToInit>;
}

export enum SearchType {
    division = 'Division',
    invoice = 'Invoice',
    dateAmt = 'DateAmt',
    remittance = 'Remittance',
    disbursement = 'Disbursement',
    paymentConfirmation = 'paymentConfirmation'
}

export interface IViewMode extends ITableDef {
    auditStatus?: IStatusSetting;
    invoiceStatus?: IStatusSetting;
    forceFilter?: Partial<Models.CarrierInvoices>;
    filter?: Partial<IAppFilter>;
    disable?: Partial<Record<keyof IAppFilter, boolean>>;
}

interface IStatusSetting {
    statuses: string[];
    order?: string[];
    invert?: boolean;
}
export interface CarrierAccountWId extends Models.CarrierAccount {
    clientID: number;
    companyID: number;
    divisionID: number;
}
export interface LocationWId extends Models.Location {
    clientID: number;
    companyID: number;
    accounts: CarrierAccountWId[];
}
export interface DivisionWId extends Models.Division {
    clientID: number;
    locations: LocationWId[];
}
export interface CompanyWId extends Models.Company {
    divisions: DivisionWId[];
}
export interface ClientWId extends Models.Client {
    companies: CompanyWId[];
}

export interface StoredTableDef {
    columns: string[];
    sticky: string[];
    widths: { [index: string]: number };
    sort: ISortOption[];
}
