import React, { forwardRef } from 'react';
import { IconButtonProps, makeStyles, IconButton, CircularProgressProps, CircularProgress, Box, BoxProps, Theme } from '@material-ui/core';
import { STITooltip } from '../feedback/stitooltip.component';

const useStyles = makeStyles<Theme, { hoverColor?: string; iconSize: number; bgColor?: string; progressColor?: string }>((theme) => ({
    button: {
        borderRadius: '999px',
        position: 'relative',

        '&:hover': {
            backgroundColor: (props) => (props.hoverColor ? props.hoverColor : theme.palette.action.hover)
        },
        '& svg': {
            width: ({ iconSize }) => theme.spacing(iconSize),
            height: ({ iconSize }) => theme.spacing(iconSize)
        }
    },
    progress: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        opacity: '75',
        width: '100% !important',
        height: '100% !important',
        '& circle': {
            stroke: (props) => (props.progressColor ? props.progressColor : 'currentColor')
        }
    },
    forceSize: {
        '& > button': {
            width: '100%',
            height: '100%'
        }
    }
}));

const useRippleStyles = makeStyles<
    Theme,
    {
        rippleColor?: string;
    }
>((theme) => ({
    child: {
        backgroundColor: (props) => (props.rippleColor ? props.rippleColor : theme.palette.action.selected)
    }
}));

export interface IExtendedIconButton extends Omit<IconButtonProps, 'color'> {
    loading?: boolean;
    progressProps?: CircularProgressProps;
    color?: 'primary' | 'secondary' | string;
    overflow?: BoxProps['overflow'];
    bgColor?: string;
    hoverColor?: string;
    rippleColor?: string;
    iconSize?: number;
    progressColor?: string;
}

export const ExtendedIconButton: React.FC<IExtendedIconButton> = forwardRef(
    (
        {
            children,
            loading,
            progressProps,
            color,
            size = 'medium',
            iconSize = 3,
            overflow = 'hidden',
            hoverColor,
            rippleColor,
            bgColor,
            progressColor,
            title,
            ...props
        },
        ref
    ) => {
        const classes = useStyles({ hoverColor, iconSize, bgColor, progressColor });
        const rippleClasses = useRippleStyles({ rippleColor });

        const c = color === 'primary' || color === 'secondary' ? color : 'inherit';

        // const sizeProps = useMemo(() => {
        //     const props: BoxProps = {};
        //     if (typeof size === 'number') {
        //         props.className = classes.forceSize;
        //         props.width = theme.spacing(size);
        //         props.height = theme.spacing(size);
        //     }
        //     return props;
        // }, [size, classes, theme]);

        const button = (
            <Box
                color={c === 'inherit' ? color : undefined}
                overflow={overflow}
                position="relative"
                bgcolor={bgColor ? bgColor : 'transparent'}
                borderRadius="99999px"
            >
                <IconButton
                    {...props}
                    ref={ref}
                    size={size === 'small' || size === 'medium' ? size : undefined}
                    className={`${props.className} ${classes.button}`}
                    color={c}
                    TouchRippleProps={{ classes: rippleColor ? rippleClasses : undefined }}
                    disabled={loading}
                >
                    {children}
                </IconButton>
                {loading && (
                    <CircularProgress
                        className={classes.progress}
                        variant="indeterminate"
                        // size={size === 'small' ? theme.spacing(iconSize + 0.75) : size === 'medium' ? theme.spacing(iconSize + 3) : undefined}
                        thickness={2}
                        color={c}
                        {...progressProps}
                    />
                )}
            </Box>
        );
        return title ? <STITooltip title={title}>{button}</STITooltip> : button;
    }
);
