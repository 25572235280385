// import ExcelJS from ;
import { Models } from 'shipmenttrackers-domain/dist';
import { IDataTableState } from '../components/datatable/datatable.types';
import { getBillingEntity } from '../components/layout/tables/columncomponents';
import { IAppFilter, IAppState, IDFetchSummary } from '../types/contexts/app-context.type';
import { IIdx } from '../types/general.type';
import { tableHeaderMap } from './mapping';
import { currency, date, SIMPLE_FORMAT } from './pipes';

interface ExportConfig {
    user: Models.User | null;
}

interface TableToExcelConfig extends ExportConfig {
    table: Partial<IDataTableState>;
    invoices: Models.CarrierInvoices[] | (Models.CarrierInvoices & Models.GLCoding)[];
    filter: IAppFilter;
    fileName?: string;
    onMessage?: (message: string) => void;
}

declare global {
    interface Window {
        ExcelJS: any;
    }
}

const DFormat = 'MM-dd-yyyy';

export const tableToExcel = async ({ user, table, invoices, filter, fileName, onMessage }: TableToExcelConfig) => {
    // const ExcelJS = await import('exceljs/dist/es5/exceljs.browser' as any);
    onMessage?.('Building Workbook');
    const workbook = new window.ExcelJS.Workbook();
    workbook.creator = `${user?.firstName}${user?.lastName ? ` ${user?.lastName}` : ''}`;
    workbook.lastModifiedBy = `${user?.firstName}${user?.lastName ? ` ${user?.lastName}` : ''}`;
    workbook.created = new Date(Date.now());
    workbook.modified = new Date();
    workbook.properties.date1904 = true;
    workbook.calcProperties.fullCalcOnLoad = true;

    const displayColumns = table.columns?.filter((c) => c.active && c.key !== 'pdf');

    workbook.views = [{ x: 0, y: 0, width: 10000, height: 20000, firstSheet: 0, activeTab: 1, visibility: 'visible' }];

    const filterSheet = workbook.addWorksheet('Filters');
    filterSheet.columns = [{ header: 'Filter' }, { header: 'Value' }] as any;

    filterSheet.addRow(['Client', filter.client?.clientName || '']);
    filterSheet.addRow(['Company', filter.company?.companyName || '']);
    filterSheet.addRow(['Division', filter.division?.divisionName || '']);
    filterSheet.addRow(['Location', filter.location?.locationName || '']);
    filterSheet.addRow(['Carrier', filter.carrier?.carrierSTICode || '']);
    filterSheet.addRow(['Account', filter.carrierAccount?.carrierAccount || '']);

    const min = parseFloat(filter.minInv || '');
    filterSheet.addRow(['Min Invoice', isNaN(min) ? '' : currency(min)]);
    const max = parseFloat(filter.maxInv || '');
    filterSheet.addRow(['Max Invoice', isNaN(max) ? '' : currency(max)]);
    filterSheet.addRow(['Invoice Date Earliest', filter.startInvDate ? date(DFormat)(filter.startInvDate) : '']);
    filterSheet.addRow(['Invoice Date Latest', filter.endInvDate ? date(DFormat)(filter.endInvDate) : '']);
    filterSheet.addRow(['Invoice Due Date Earliest', filter.startDueDate ? date(DFormat)(filter.startDueDate) : '']);
    filterSheet.addRow(['Invoice Due Date Latest', filter.endDueDate ? date(DFormat)(filter.endDueDate) : '']);
    filterSheet.addRow(['Audit Statuses', filter.auditStatus.map((s) => s.auditStatus).join(', ')]);
    filterSheet.addRow(['Invoice Statuses', filter.invoiceStatus.map((s) => s.invoiceStatus).join(', ')]);
    if (filter.otherfilters) {
        Object.keys(filter.otherfilters).forEach((k) => {
            filterSheet.addRow([tableHeaderMap[k], (filter.otherfilters as IIdx)[k] || '']);
        });
    }

    const dataSheet = workbook.addWorksheet('Data');

    dataSheet.columns = (displayColumns?.map((c) => {
        const def: any = {
            header: c.label
        };
        switch (c.key) {
            case 'invoiceOriginalAmt':
            case 'currentAmt':
            case 'invoiceAmtDue':
            case 'invoiceAllocFundReqAmt':
            case 'invoicePaidAmt':
            case 'glAmount':
                def.numFmt = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
                break;
            default:
                break;
        }

        return def;
    }) || []) as any;
    onMessage?.('Writing Data');
    invoices.forEach((inv: IIdx) => {
        dataSheet.addRow(
            displayColumns?.map((c) => {
                const k = c.key;
                const val = c.value ? c.value(inv) : inv[k];
                let display;

                switch (c.key) {
                    case 'invoiceOriginalAmt':
                    case 'currentAmt':
                    case 'invoiceAmtDue':
                    case 'invoiceAllocFundReqAmt':
                    case 'invoicePaidAmt':
                    case 'glAmount':
                        display = val;
                        break;
                    case 'invoiceDate':
                    case 'invoiceDueDate':
                    case 'invoiceAllocFundReqDate':
                    case 'invoiceLastReceivedDate':
                    case 'invoiceLastPaidDate':
                        display = date(DFormat)(val);
                        break;
                    default:
                        if (typeof val === 'boolean') {
                            display = val.toString();
                        } else {
                            display = c.pipe ? c.pipe(val, inv) : val;
                        }
                        break;
                }
                return display;
            }) || []
        );
    });
    onMessage?.('Initiating Save');
    const buffer = await workbook.xlsx.writeBuffer();

    const url = URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement('a');
    if (link) {
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName}.xlsx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const summaryToExcel = async (user: Models.User | null, appState: IAppState, summary?: IDFetchSummary, invoices?: Models.CarrierInvoices[]) => {
    if (!summary) return;
    const getCurrency = (n: number, inv: Models.CarrierInvoices) => currency(n, inv?.currency);
    const colMap: { key: string; title: string; pipe?: (val: any, inv: Models.CarrierInvoices) => string | number }[] = [
        { key: 'invoiceNumber', title: 'Invoice Number' },
        { key: 'invoiceDate', title: 'Invoice Date', pipe: date(SIMPLE_FORMAT) },
        { key: 'carrierID', title: 'Carrier' },
        { key: 'billingEntity', title: 'Billing Entity', pipe: (val: any, inv: Models.CarrierInvoices) => getBillingEntity(appState, inv) },
        { key: 'invoiceOriginalAmt', title: 'Original Amount', pipe: getCurrency },
        { key: 'currentAmt', title: 'Current Amount', pipe: getCurrency },
        { key: 'invoiceTotalReceived', title: 'Received Amount', pipe: getCurrency },
        { key: 'invoicePaidAmt', title: 'Paid Amount', pipe: getCurrency }
    ];
    const invoicesById = invoices?.reduce((acc: { [index: string]: Models.CarrierInvoices }, inv) => {
        acc[inv.invoiceNumber] = inv;
        return acc;
    }, {});

    const headers = colMap.map((entry) => entry.title);

    const workbook = new window.ExcelJS.Workbook();
    workbook.creator = `${user?.firstName}${user?.lastName ? ` ${user?.lastName}` : ''}`;
    workbook.lastModifiedBy = `${user?.firstName}${user?.lastName ? ` ${user?.lastName}` : ''}`;
    workbook.created = new Date(Date.now());
    workbook.modified = new Date();
    workbook.properties.date1904 = true;
    workbook.calcProperties.fullCalcOnLoad = true;

    const dataSheet = workbook.addWorksheet('Fetch Results');

    dataSheet.addRow(['Searched', summary.queries.length]);
    dataSheet.addRow(['Exact Match', summary.exact.length]);
    dataSheet.addRow(['No Match', summary.none.length]);
    dataSheet.addRow(['Partial Match', summary.fuzzy.length]);
    dataSheet.addRow([]);
    dataSheet.addRow(['No Match']);
    dataSheet.addRow([colMap[0].title]);
    summary.none.forEach((id) => dataSheet.addRow([id]));
    dataSheet.addRow([]);
    dataSheet.addRow(['Exact Match']);
    dataSheet.addRow(headers);
    summary.exact.forEach((id) => {
        const inv = invoicesById?.[id];
        if (inv) {
            dataSheet.addRow(
                colMap.map((c) => {
                    const val = (inv as IIdx)[c.key];
                    return c.pipe ? c.pipe(val, inv) : val;
                })
            );
        }
    });
    dataSheet.addRow([]);
    dataSheet.addRow(['Wildcard']);
    dataSheet.addRow(headers);
    summary.fuzzy.forEach((id) => {
        const inv = invoicesById?.[id];
        if (inv) {
            dataSheet.addRow(
                colMap.map((c) => {
                    const val = (inv as IIdx)[c.key];
                    return c.pipe ? c.pipe(val, inv) : val;
                })
            );
        }
    });

    // dataSheet.columns = Object.keys(summary).map((k) => ({ header: k, key: k }));

    // Object.keys(summary).forEach((k, i) => {
    //     if (k === 'results') {
    //         dataSheet.getColumn(k).values = [k, summary[k]];
    //     } else {
    //         dataSheet.getColumn(k).values = [k, ...summary[k]];
    //     }
    // });

    const buffer = await workbook.xlsx.writeBuffer();

    const url = URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement('a');
    if (link) {
        link.setAttribute('href', url);
        link.setAttribute('download', 'newexport.xlsx');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
