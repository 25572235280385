import React, { useMemo, useEffect, useRef } from 'react';
import { useSelector } from '../../../contexts/store.helpers';
import { Models } from 'shipmenttrackers-domain/dist';
import { IAppState, AppFilterActions } from '../../../types/contexts/app-context.type';
import { useAppState, useAppDispatch } from '../../../contexts/app.context';
import { useInputStyle } from '../../../styles/stiinput.style';
import { useMenuStyles } from '../../../styles/stimenu.style';
import { IMultiSelectAuto, MultiSelectAuto } from '../../generic/controls/multi-selectauto.component';

const selector = (state: IAppState): [Models.IpInvoiceStatus[], Models.IpInvoiceStatus[]] => [state.data.invoiceStatusOptions, state.filter.invoiceStatus];

const allVal: Models.IpInvoiceStatus = { invoiceStatus: 'All', invoiceStatusDescription: 'Do not limit by Invoice Status.', payMode: true, appOrder: -1 };

export const InvoiceStatusSelect: React.FC<IMultiSelectAuto<Models.IpInvoiceStatus>> = () => {
    const inputClasses = useInputStyle();
    const menuClasses = useMenuStyles();
    const appDispatch = useAppDispatch();
    const [statusOptions, currentStatus] = useSelector(useAppState, selector);
    const lastWasAll = useRef<boolean>(!currentStatus.length);

    useEffect(() => {
        if (currentStatus.length === 0 || (currentStatus.length === 1 && currentStatus[0].invoiceStatus === allVal.invoiceStatus)) {
            lastWasAll.current = true;
        } else {
            lastWasAll.current = false;
        }
    }, [lastWasAll, currentStatus]);

    const options = useMemo<Models.IpInvoiceStatus[]>(
        () => [allVal, ...statusOptions.sort((a, b) => ((a as any).appOrder || 0) - ((b as any).appOrder || 0))],
        [statusOptions]
    );

    const onChange = (event: React.ChangeEvent<{}>, value: Models.IpInvoiceStatus[]) => {
        let v = value;
        const hasAll = value.find((s) => s.invoiceStatus === allVal.invoiceStatus);
        if (hasAll) {
            if (lastWasAll.current && value.length > 1) {
                v = value.filter((s) => s.invoiceStatus !== allVal.invoiceStatus);
            } else if (!lastWasAll.current) {
                v = [];
            }
        }
        appDispatch({ type: AppFilterActions.invoiceStatus, payload: v });
    };

    return (
        <MultiSelectAuto<Models.IpInvoiceStatus>
            fullWidth
            variant="filled"
            className={inputClasses.input}
            menuClasses={menuClasses}
            initialVal={currentStatus.length ? currentStatus : [allVal]}
            options={options}
            label="Invoice Status"
            nullString="Any Status"
            onValChange={onChange}
            multiple
            getText={(o) => o.invoiceStatus}
            getSubtext={(o) => o.invoiceStatusDescription}
            getValue={(o) => o.invoiceStatus}
        />
    );
};
