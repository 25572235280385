import React, { useState } from 'react';
import { ISingleSelectAuto, SingleSelectAuto } from '../../../generic/controls/single-selectauto.component';
import { useAuditDispatch } from '../audit.context';
import { Input } from '@material-ui/core';
interface IGLInput {
    rowNum: number;
    name: string;
    canEnter?: boolean;
    canEdit?: boolean;
}

export const GLInput: React.FC<Partial<ISingleSelectAuto<string> & IGLInput>> = ({ value, rowNum, className, name, canEnter, canEdit, ...props }) => {
    const auditDispatch = useAuditDispatch();
    const [val, setVal] = useState<any>(value);

    const update = (event: any, value: any) => {
        const v = value === 'No Code' || !value ? null : value;
        setVal(v);
        auditDispatch({ type: name || '', payload: v, rowNum });
    };

    return (
        <div className={className}>
            <SingleSelectAuto
                nullString="No Code"
                disableNull={false}
                freeSolo={canEnter}
                disabled={!canEdit}
                {...props}
                onValChange={update}
                value={val || ''}
                placeholder=""
                renderInput={({ InputProps, InputLabelProps, ...params }) => {
                    return (
                        <Input
                            disableUnderline
                            {...InputProps}
                            {...params}
                            onBlur={(evt) => {
                                canEnter && update(null, evt.target.value);
                            }}
                            endAdornment={null}
                        />
                    );
                }}
            />
        </div>
    );
};
