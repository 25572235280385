import React from 'react';
import { styled, Grid, Typography, TextField, Button, Box } from '@material-ui/core';
import { useLogin } from '../hooks/stiapi.hook';
import { useInputState } from '../hooks/input.hook';
import { useUserDispatch, UserActions } from '../contexts/user.context';
import { useState } from 'react';
import { ForgotPassword } from '../components/layout/modals/forgotpassword.component';
import { STIModal } from '../components/generic';
import { GrowUnderline } from '../components/generic/feedback/growunderline.component';
import { version } from '../../package.json';

export const LoginPage: React.FC = () => {
    const { login } = useLogin();
    const userDispatch = useUserDispatch();

    const [remember, setRemember] = useState(true);

    const [, , /*name setName*/ onNameChange, nameRef] = useInputState<string>();
    const [, , /*password setPassword*/ onPasswordChange, passwordRef] = useInputState<string>();

    const [error, setError] = useState<string>();
    const [forgotOpen, setForgotOpen] = useState(false);

    const submitLogin = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setError(undefined);
        const res = await login(nameRef.current!, passwordRef.current!);
        if (res.data?.Status === 200) {
            // if (remember) {
            //     userDispatch({ type: UserActions.LOGIN, payload: res.data.Data.token });
            // } else {
            userDispatch({ type: UserActions.LOGIN_SESSION, payload: res.data.Data.token });
            // }
        } else if (res.data?.Status === 403) {
            setError(res.data.Message);
        } else {
            setError('Problem Logging In');
        }
        // console.log(res);
    };

    return (
        <Main container alignItems="center" justify="center">
            <STIModal open={forgotOpen} onClose={() => setForgotOpen(false)}>
                <ForgotPassword setOpen={setForgotOpen} />
            </STIModal>

            <Center item container direction="column" justify="center">
                <LogoContainer item container justify="center">
                    <img src={`${process.env.PUBLIC_URL}/img/sti_logo.png`} alt="sti logo" />
                </LogoContainer>
                <LoginCard item container direction="column" justify="center">
                    <LoginForm item container direction="column">
                        <LoginHeader fontWeight="fontWeightLight" textAlign="center">
                            <Typography variant="h4">
                                Welcome to<br></br>
                                Shipment Trackers<br></br>
                                Invoice Payment!
                            </Typography>
                        </LoginHeader>
                        <form onSubmit={submitLogin}>
                            <Grid container direction="column" spacing={2} justify="center">
                                <Grid item>
                                    <TextField
                                        required
                                        type="string"
                                        label="Username"
                                        fullWidth
                                        onChange={(evt) => {
                                            onNameChange(evt);
                                            setError(undefined);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        type="password"
                                        label="Password"
                                        fullWidth
                                        onChange={(evt) => {
                                            onPasswordChange(evt);
                                            setError(undefined);
                                        }}
                                    />
                                </Grid>
                                {error ? (
                                    <Grid item>
                                        <Typography color="error">{error}</Typography>
                                    </Grid>
                                ) : null}
                                <Grid item>
                                    <Button type="submit" variant="contained" fullWidth>
                                        LOGIN
                                    </Button>
                                </Grid>
                                {/* <Grid item container justify="center">
                                    <CheckControl checked={remember} name="login" onChange={(evt) => setRemember(evt.target.checked)}>
                                        Remember me
                                    </CheckControl>
                                </Grid> */}
                            </Grid>
                        </form>
                    </LoginForm>
                    <LoginFooter item container justify="center">
                        <Box textAlign="center">
                            <GrowUnderline onHover pointer onClick={() => setForgotOpen(true)}>
                                <Typography>Forgot Password</Typography>
                            </GrowUnderline>
                        </Box>
                    </LoginFooter>
                </LoginCard>
            </Center>
            <Version />
        </Main>
    );
};

const Main = styled(Grid)(({ theme }) => ({
    width: '100%',
    height: '100%',
    background: `url(${process.env.PUBLIC_URL}/img/bkgrdlogin.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
}));

const Center = styled(Grid)(({ theme }) => ({
    position: 'relative',
    width: 'auto'
}));

const LogoContainer = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    bottom: `calc(100% + ${theme.spacing(4)}px)`
}));

const LoginCard = styled(Grid)(({ theme }) => ({
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5)
}));

const LoginHeader = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& *': {
        fontWeight: 'inherit'
    }
}));

const LoginForm = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4)
}));

const LoginFooter = styled(Grid)(({ theme }) => ({
    background: 'lightgray',
    borderBottomRightRadius: theme.spacing(1.5),
    borderBottomLeftRadius: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
}));

const VersionDiv = styled('div')(({ theme, color }: any) => ({
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: color === 'dark' ? 'black' : 'white',
    opacity: 0.25
}));

export const Version: React.FC<{ color?: 'dark' | 'light' }> = ({ color = 'light' }) => {
    return <VersionDiv color={color}>v{version}</VersionDiv>;
};
