import { makeStyles, fade } from '@material-ui/core';

export const useInputStyle = makeStyles((theme) => ({
    input: {
        borderTopRightRadius: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
        overflow: 'hidden',
        // width: 'inherit',
        '& label': {
            color: theme.palette.common.black
        },
        '& *': {
            background: 'rgba(0,0,0,0)'
        },
        '& input::selection': {
            background: fade(theme.palette.primary.main, 0.75)
        },
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white
        },
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            marginBottom: '3px'
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        /* Firefox */
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        }
    },
    charCount: {
        opacity: 0.67,
        marginLeft: theme.spacing(0.5),
        textAlign: 'right'
    },
    bottomPadding: {
        paddingBottom: theme.spacing(2)
    }
}));
