import React, { useState, useEffect, useRef } from 'react';
import { PDFViewer } from './pdfviewer.component';
import { useGetPDF } from '../../hooks/stiapi.hook';
import Icon from '@mdi/react';
import { mdiFilePdfOutline } from '@mdi/js';
import { useTheme, fade, Box, Backdrop, Typography, styled, List, ListItem, Paper } from '@material-ui/core';
import { CheckControl, STIPopper } from '../generic';
import { killEvt } from '../../helpers';

const fallbackPdf = 'https://www.americanexpress.com/content/dam/amex/us/staticassets/pdf/GCO/Test_PDF.pdf';

const justDont = { current: false };
export const PDF: React.FC<{ carrierCode: string; invoiceNumber: string }> = ({ carrierCode, invoiceNumber }) => {
    const theme = useTheme();
    const { loading, getPDF, canceler } = useGetPDF();
    const [data, setData] = useState<any | string>('');
    // const [forceUpdate, setForceUpdate] = useState<number>();
    const [rendering, setRendering] = useState(false);
    const scrollPosRef = useRef<[number, number]>();
    const [maintainScroll, setMaintainScroll] = useState(false);

    useEffect(() => {
        justDont.current = false;
        (async () => {
            // console.log('PDF Loading', invoiceNumber);
            canceler();
            const container = document.querySelector('#viewerContainer') as HTMLDivElement;
            if (container) scrollPosRef.current = [container.scrollLeft, container.scrollTop];

            const res = await getPDF(carrierCode, invoiceNumber);
            if (res.status === 200) {
                setRendering(true);
                setData(res.data ?? '');
                // console.log('PDF Set', invoiceNumber);
                // setForceUpdate(Date.now());
            } else {
                // console.log('PDF None', invoiceNumber);
                setData('');
            }
        })();
        return () => {
            justDont.current = true;
            canceler();
        };
    }, [carrierCode, invoiceNumber, getPDF, setData, setRendering, canceler]);

    const finishLoad = () => {
        setRendering(false);
        if (scrollPosRef.current && maintainScroll) (document.querySelector('#viewerContainer') as HTMLDivElement)?.scrollTo(...scrollPosRef.current);
    };

    const [openContext, setOpenContext] = useState<number | undefined>();
    const contextPoint = useRef<HTMLDivElement>(null);

    return (
        <Box
            width="100%"
            height="100%"
            position="relative"
            onContextMenuCapture={(evt) => {
                killEvt(evt);
                if (!contextPoint.current) return;
                if (openContext) {
                }

                const box = evt.currentTarget;
                contextPoint.current.style.left = evt.pageX - box.offsetLeft + 'px';
                contextPoint.current.style.top = evt.pageY - box.offsetTop + 'px';
                setOpenContext(Date.now());
            }}
        >
            <div ref={contextPoint} style={{ position: 'absolute', width: '0px', height: '0px' }} />
            <STIPopper anchorEl={contextPoint.current} open={!!openContext} placement={'top-start'} close={() => setOpenContext(undefined)}>
                <Paper>
                    <List>
                        <ListItem>
                            <CheckControl checked={maintainScroll} onChange={(evt, checked) => setMaintainScroll(checked)}>
                                Lock Scroll Position
                            </CheckControl>
                        </ListItem>
                    </List>
                </Paper>
            </STIPopper>

            {!justDont.current && <PDFViewer data={data} onLoad={finishLoad} />}
            <RelativeBackdrop open={!!loading || rendering}>
                <Icon size={2} path={mdiFilePdfOutline} spin={true} color={fade(theme.palette.common.white, 0.75)} />
            </RelativeBackdrop>
            <RelativeBackdrop open={!loading && !data}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Icon size={2} path={mdiFilePdfOutline} color={fade(theme.palette.error.light, 0.75)} />
                    <Box color={theme.palette.common.white}>
                        <Typography color="inherit">No PDF Found</Typography>
                    </Box>
                </Box>
            </RelativeBackdrop>
        </Box>
    );
};

const RelativeBackdrop = styled(Backdrop)({
    position: 'absolute',
    zIndex: 1000
});
