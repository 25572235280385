import React, { memo, useEffect, useRef, useCallback } from 'react';
import { Grid, styled, Box, Modal, Typography, useTheme, Fade } from '@material-ui/core';
import { PageContainer, GridGrow, STIModal } from '../components/generic';
import { Audit } from '../components/layout/audit/audit.component';
import { useParams, useHistory } from 'react-router-dom';
import { AuditProvider, useAuditDispatch, useAuditState, AuditActions, useAuditStateRef, IAuditState } from '../components/layout/audit/audit.context';
import {
    useGetInvoice,
    useGetGLCodings,
    useGetAvilableGLCodings,
    useGetClientCompanies,
    useGetClientDivisions,
    useGetClient,
    useGetActions,
    useGetCollabOptions
} from '../hooks/stiapi.hook';
import { useInvoiceStateRef, useInvoiceDispatch } from '../contexts/invoice.context';
import { usePostMessages } from '../hooks/postmessage.hook';
import { IAuditPDFState } from './auditpdf.page';
import { getBillingEntityID } from '../components/layout/audit/helpers';
import { useSelector } from '../contexts/store.helpers';
import { InvoiceActions } from '../types/contexts/invoice-context.type';
import { PDF } from '../components/pdfjsreskin/pdf.component';
import { EmailNotesModal } from '../components/layout/modals/emailnotesmodal.component';
import { useInfoModal } from '../hooks/info-modal.hook';
import { success } from '../hooks/api.hook';

export const AuditMessageKey = 'audit';

const invoiceIDSelector = (
    state: IAuditState
): [string | undefined, string | undefined, number | undefined, string | undefined, string | undefined, number | undefined] | [] => {
    const inv = state.invoice;
    if (!inv) return [];
    return [inv.carrierInvoiceID, inv.clientBillingLevel, getBillingEntityID(inv), inv.carrierCode, inv.invoiceNumber, inv.clientID];
};

const Component: React.FC = memo(() => {
    const theme = useTheme();
    const invoiceStateRef = useInvoiceStateRef();
    const invoiceDispatch = useInvoiceDispatch();
    const auditState = useAuditState();
    const auditStateRef = useAuditStateRef();
    const auditDispatch = useAuditDispatch();
    const { getInvoice } = useGetInvoice();
    const { carrierInvoiceID } = useParams<{ carrierInvoiceID: string }>();
    const [, /*receivedMessages*/ postMessage] = usePostMessages<IAuditPDFState>([AuditMessageKey], auditDispatch);
    const { getGLCoding, loading: glLoading } = useGetGLCodings();
    const { getAvailableGLCoding, loading: availableGlLoading } = useGetAvilableGLCodings();
    const { getClientCompanies, loading: companiesLoading } = useGetClientCompanies();
    const { getClientDivisions, loading: divisionsLoading } = useGetClientDivisions();
    const { getClient, loading: clientLoading } = useGetClient();
    const { getActions, loading: actionsLoading } = useGetActions();
    const { getCollabOptions } = useGetCollabOptions();
    const history = useHistory();
    const [ConfirmationModal, runConfirmationModal] = useInfoModal();

    const [invID, billingLevel, billingLevelID, carrierCode, invNum, clientID] = useSelector(useAuditState, invoiceIDSelector);
    const invoice = auditState.in;

    const pdfWindow = useRef<Window | null>(null);

    const extraPDFWindows = useRef<(Window | null)[]>([]);

    useEffect(() => {
        (async () => {
            const invoiceIdx = invoiceStateRef.current.invoices.findIndex((i) => i.carrierInvoiceID?.toString() === carrierInvoiceID);
            auditDispatch({ type: AuditActions.invoiceAppIdx, payload: invoiceIdx });
            if (invoiceIdx === -1) {
                const res = await getInvoice(carrierInvoiceID);
                if (success(res)) {
                    auditDispatch({ type: AuditActions.invoice, payload: res.data });
                }
                if (res.status === 403) {
                    const redirect = setTimeout(() => history.replace('/'), 5000);
                    const res = await runConfirmationModal({
                        title: 'Insufficient Permissions',
                        desc: (
                            <>
                                <p>You do not have permission to access this invoice</p>
                                <p>You will be redirected to the table</p>
                            </>
                        ),
                        yesTxt: 'OK',
                        no: false
                    });
                    if (res) {
                        clearTimeout(redirect);
                        history.replace('/');
                    }
                }
            } else {
                auditDispatch({ type: AuditActions.invoice, payload: invoiceStateRef.current.invoices[invoiceIdx] });
            }
        })();
    }, [carrierInvoiceID, auditDispatch, invoiceStateRef, getInvoice, history, runConfirmationModal]);

    useEffect(() => {
        (async () => {
            const inv = auditState.invoice;
            if (inv) {
                const res = await getCollabOptions(inv.carrierInvoiceID);
                if (success(res)) {
                    auditDispatch({ type: AuditActions.collabOptions, payload: res.data });
                }
            }
        })();
    }, [auditDispatch, auditState.invoice, getCollabOptions]);

    useEffect(() => {
        if (invoiceStateRef.current.invoices[auditState.invoiceAppIdx] !== undefined) {
            const targetUrl = `/audit/${invoiceStateRef.current.invoices[auditState.invoiceAppIdx].carrierInvoiceID}`;
            if (history.location.pathname !== targetUrl) {
                history.push(`/audit/${invoiceStateRef.current.invoices[auditState.invoiceAppIdx].carrierInvoiceID}`);
            }
        }
    }, [auditState.invoiceAppIdx, history, invoiceStateRef]);

    useEffect(() => {
        auditState.invoice && invoiceDispatch({ type: InvoiceActions.updateInvoices, payload: [auditState.invoice] });
    }, [auditState.invoice, invoiceDispatch]);

    const createPDFWindow = useCallback(() => {
        const pdf = window.open(
            `${window.location.origin}/audit/pdf/${auditStateRef.current.invoice?.carrierCode}/${auditStateRef.current.invoice?.invoiceNumber}`,
            `STI: PDF ${Math.random()}`,
            'height=960,width=960'
        );
        pdf?.addEventListener('load', () => {
            pdf &&
                postMessage(
                    pdf,
                    { [AuditActions.invoiceAppIdx]: auditStateRef.current.invoiceAppIdx, totalCount: invoiceStateRef.current.invoices.length },
                    AuditMessageKey
                );
        });
        return pdf;
    }, [auditStateRef, postMessage, invoiceStateRef]);

    useEffect(() => {
        if (auditState.extraPDFs > extraPDFWindows.current?.length) {
            const pdf = createPDFWindow();
            extraPDFWindows.current.push(pdf);

            const handleLoad = () => {
                pdf?.addEventListener('beforeunload', (evt) => {
                    evt.preventDefault();

                    extraPDFWindows.current = extraPDFWindows.current.filter((w) => w?.name !== pdf.name);
                    auditDispatch({ type: AuditActions.setPDFCount, payload: extraPDFWindows.current.length });
                    pdf?.removeEventListener('load', handleLoad);
                    return;
                    // evt.returnValue = false;
                });
            };

            pdf?.addEventListener('load', handleLoad);
        }
    }, [auditDispatch, auditState.extraPDFs, createPDFWindow, extraPDFWindows]);

    useEffect(() => {
        if (!auditState.attachPDF) {
            const pdf = createPDFWindow();

            const handleLoad = () => {
                pdf?.addEventListener('beforeunload', (evt: BeforeUnloadEvent) => {
                    evt.preventDefault();
                    auditDispatch({ type: AuditActions.attachPDF, payload: true });
                    pdf?.removeEventListener('load', handleLoad);
                    return;
                    // evt.returnValue = false;
                });
                pdfWindow.current = pdf;
            };
            pdf?.addEventListener('load', handleLoad);
        } else {
            pdfWindow.current && pdfWindow.current.close();
        }
    }, [auditState.attachPDF, auditStateRef, pdfWindow, auditDispatch, createPDFWindow]);

    useEffect(
        () => () => {
            pdfWindow.current?.close();
            extraPDFWindows.current.forEach((w) => w?.close());
        },
        [extraPDFWindows, pdfWindow]
    );

    useEffect(() => {
        [pdfWindow.current, ...extraPDFWindows.current].forEach((w) => {
            if (!w) return;
            carrierCode &&
                invNum &&
                postMessage(
                    w,
                    {
                        carrierCode: carrierCode,
                        invoiceNumber: invNum,
                        [AuditActions.invoiceAppIdx]: auditStateRef.current.invoiceAppIdx
                    },
                    AuditMessageKey
                );
        });
    }, [pdfWindow, extraPDFWindows, carrierCode, invNum, auditStateRef, postMessage]);

    useEffect(() => {
        (async () => {
            if (invID === undefined) return;
            const res = await getGLCoding(invID, auditStateRef.current.invoice?.carrierAccount || '');
            if (success(res)) {
                auditDispatch({ type: AuditActions.setGL, payload: res.data });
            }
        })();
    }, [invID, auditDispatch, getGLCoding, auditStateRef]);

    useEffect(() => {
        (async () => {
            if (billingLevel === undefined) return;

            if (billingLevelID === undefined) return;

            const inv = auditStateRef.current.invoice;
            if (!inv) return;
            const res = await getAvailableGLCoding(inv.carrierInvoiceID);
            if (success(res)) {
                auditDispatch({
                    type: AuditActions.availableCodes,
                    payload: res.data.length > 0 ? res.data : new Array(3).fill(null).map((n, i) => ({ glCode: null, glPosition: i + 1 }))
                });
            }
        })();
    }, [billingLevel, billingLevelID, auditDispatch, getAvailableGLCoding, auditStateRef]);

    useEffect(() => {
        (async () => {
            if (billingLevel === undefined || clientID === undefined) return;

            if (billingLevel === 'Client') {
                const res = await getClient(clientID);
                if (success(res)) {
                    const c = res.data[0];
                    auditDispatch({ type: AuditActions.billingEntities, payload: [{ name: c.clientName, id: c.clientID }] });
                }
            } else if (billingLevel === 'Company') {
                const res = await getClientCompanies(clientID);
                if (success(res)) {
                    const companies = res.data;
                    auditDispatch({ type: AuditActions.billingEntities, payload: companies.map((c) => ({ name: c.companyName, id: c.companyID })) });
                }
            } else if (billingLevel === 'Division') {
                const res = await getClientDivisions(clientID);
                if (success(res)) {
                    const divisions = res.data;
                    auditDispatch({ type: AuditActions.billingEntities, payload: divisions.map((d) => ({ name: d.divisionName, id: d.divisionID })) });
                }
            }
        })();
    }, [billingLevel, clientID, auditDispatch, getClient, getClientCompanies, getClientDivisions]);

    useEffect(() => {
        (async () => {
            if (invID === undefined) return;
            const res = await getActions(invID);
            if (success(res)) {
                auditDispatch({ type: AuditActions.actions, payload: res.data });
            }
        })();
    }, [invID, auditDispatch, getActions]);

    return (
        <>
            <ConfirmationModal />
            <PageContainer>
                <GridGrow>
                    <MainContainer container wrap="nowrap">
                        <Box className="auditSide">
                            <Modal
                                open={!!glLoading || !!availableGlLoading || !!companiesLoading || !!divisionsLoading || !!clientLoading || !!actionsLoading}
                                closeAfterTransition
                            >
                                <Fade
                                    in={!!glLoading || !!availableGlLoading || !!companiesLoading || !!divisionsLoading || !!clientLoading || !!actionsLoading}
                                >
                                    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                        <Box bgcolor={theme.palette.common.white} borderRadius="999px" padding={3}>
                                            <Typography color="primary" variant="h4">{`Loading ${
                                                glLoading + availableGlLoading + companiesLoading + divisionsLoading + clientLoading + actionsLoading
                                            } of 4 ...`}</Typography>
                                        </Box>
                                    </Box>
                                </Fade>
                            </Modal>
                            <Audit />
                        </Box>
                        {auditState.attachPDF && auditState.invoice?.carrierCode && auditState.invoice?.invoiceNumber ? (
                            <Box overflow="hidden">
                                <PDF carrierCode={auditState.invoice.carrierCode} invoiceNumber={auditState.invoice.invoiceNumber} />
                            </Box>
                        ) : null}
                    </MainContainer>
                </GridGrow>
            </PageContainer>
        </>
    );
});

export const AuditPage: React.FC = () => (
    <AuditProvider>
        <Component />
    </AuditProvider>
);

const MainContainer = styled(Grid)({
    height: '100%',
    '& > *': {
        flexBasis: 0,
        flexGrow: 1,
        minWidth: 0
    }
});
