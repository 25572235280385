import { useState, useCallback, useRef, MutableRefObject, Dispatch, SetStateAction } from 'react';

export function useInputState<T>(
    initial?: T
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, (evt: React.ChangeEvent<any>) => void, MutableRefObject<T | undefined>] {
    const [val, setVal] = useState<T | undefined>(initial);
    const valRef = useRef<T | undefined>(initial);
    const onChange = useCallback(
        (evt: React.ChangeEvent<any>) => {
            setVal(evt.target.value);
            valRef.current = evt.target.value;
        },
        [setVal, valRef]
    );

    return [val, setVal, onChange, valRef];
}
