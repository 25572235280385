import React, { useEffect, ChangeEvent, useState, useRef } from 'react';
import { InputProps, Input } from '@material-ui/core';
import { useAuditState, useAuditStateRef, useAuditDispatch } from '../audit.context';
import { currency } from '../../../../helpers/pipes';
import { useStateWithRef } from '../../../../hooks/statewithref.hook';

interface IGLControlledInput {
    rowNum: number;
    className: string | undefined;
    isCurrency?: boolean;
}

export const GLInputControlled: React.FC<InputProps & IGLControlledInput> = ({ rowNum, className, name, isCurrency, ...props }) => {
    const auditState = useAuditState();
    const denomination = auditState.invoice?.currency;
    const stateValue = auditState.glcoding[rowNum] ? auditState.glcoding[rowNum][name || ''] : '';
    const auditStateRef = useAuditStateRef();
    const auditDispatch = useAuditDispatch();
    const [val, setVal, valRef] = useStateWithRef<string | number | undefined>(undefined);
    const [error, setError] = useState(false);
    const focusRef = useRef(false);

    useEffect(() => {
        if (!focusRef.current) {
            setVal(isCurrency ? currency(stateValue, denomination) : stateValue);
        }
    }, [valRef, setVal, stateValue, isCurrency, focusRef, denomination]);

    const update = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const nVal = event.target.value;
        setVal(nVal);
        const asNum = parseFloat(nVal.replace(/\$|,/g, ''));

        if (!isNaN(asNum) && asNum !== auditStateRef.current.glcoding[rowNum][name || '']) {
            auditDispatch({ type: event.target.name, payload: asNum, rowNum });
        }
    };

    const onBlur = () => {
        focusRef.current = false;
        if (isCurrency && typeof val === 'string' && val !== undefined) {
            const asNum = parseFloat(val.replace(/\$|,/g, ''));
            if (isNaN(asNum)) {
                setError(true);
            } else {
                setError(false);
                setVal(undefined);
            }
        } else {
            setError(false);
            setVal(undefined);
        }
    };

    const onFocus = () => {
        focusRef.current = true;
    };

    return (
        <div className={className}>
            <Input
                autoComplete="off"
                disableUnderline
                name={name}
                {...props}
                onChange={update}
                onFocus={onFocus}
                onBlur={onBlur}
                value={val ?? (!isNaN(stateValue) ? (isCurrency ? currency(stateValue, denomination) : stateValue) : '')}
                endAdornment={null}
            />
        </div>
    );
};
